import { FormularioConsultoria } from '../models/formulario-consultoria';
import { FormularioEnquadramento } from '../models/formulario-enquadramento';
import { PerguntaAvaliacao, TipoRespostaPerguntaAvaliacao } from '../models/pergunta-avaliacao';
import { FormularioConsultoriaCategorizado } from '../models/formulario-consultoria-categorizado';
import { FormularioEnquadramentoCategorizado } from '../models/formulario-enquadramento-categorizado';

type GenericFormularioCategorizado =
  | FormularioConsultoriaCategorizado
  | FormularioEnquadramentoCategorizado;

export const getProperties = (pergunta: PerguntaAvaliacao) => {
  let _type = 'string';
  let format: string = '';
  let properties = {};

  switch (pergunta?.tipoResposta) {
    case TipoRespostaPerguntaAvaliacao.descritiva:
      format = 'Descritiva';
      properties = {
        max: pergunta?.descritiva?.maximo,
        min: pergunta?.descritiva?.minimo,
      };
      break;
    case TipoRespostaPerguntaAvaliacao.multiplaEscolha:
      format = 'MultiplaEscolha';
      properties = {
        oneOf: pergunta?.multiplaEscolha?.map(
          ({ alternativa, id }: { alternativa: string; id: number }) => ({
            const: id,
            title: alternativa,
          }),
        ),
      };
      break;
    case TipoRespostaPerguntaAvaliacao.nota:
      format = 'Nota';
      properties = {
        minimo: pergunta?.nota?.notaMinima,
        maximo: pergunta?.nota?.notaMaxima,
      };
      break;
  }

  return { type: _type, format, properties };
};

export const buildFormularioUiSchema = (
  isCategorizado: boolean,
  categorias?: GenericFormularioCategorizado[],
) => {
  if (isCategorizado) {
    return {
      type: 'VerticalLayout',
      elements: categorias?.map((categoria: GenericFormularioCategorizado) => {
        return {
          type: 'Control',
          scope: `#/properties/form-${categoria.id}`,
        };
      }),
    };
  } else {
    return {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/form',
        },
      ],
    };
  }
};

export const buildSchemaFormularioConsultoria = (
  formularioConsultoria: FormularioConsultoria,
  isCategorizado: boolean,
) => {
  if (isCategorizado) {
    return {
      type: 'object',
      properties: Object.fromEntries(
        formularioConsultoria?.formularioConsultoriaCategorizado?.map(
          (categoria: FormularioConsultoriaCategorizado, indexCategory: number) => {
            const posicao = categoria.posicao ?? indexCategory + 1;
            return [
              `form-${categoria.id}`,
              {
                type: 'object',
                title: `${posicao}) ${categoria.tituloCategoria}`,
                properties: Object.fromEntries(
                  categoria?.formularioConsultoriaCategorizadoPerguntaAvaliacao?.map(
                    (pergunta: PerguntaAvaliacao, index: number) => {
                      const { type, format, properties } = getProperties(pergunta);
                      return [
                        `pergunta-${pergunta.id}`,
                        {
                          type,
                          format,
                          ...properties,
                          title: `${posicao}.${pergunta.posicao ?? index + 1}) ${pergunta.pergunta}`,
                        },
                      ];
                    },
                  ) ?? [],
                ),
              },
            ];
          },
        ) ?? [],
      ),
    };
  } else {
    return {
      type: 'object',
      properties: {
        form: {
          type: 'object',
          properties: Object.fromEntries(
            formularioConsultoria?.formularioConsultoriaPergunta?.map(
              (pergunta: PerguntaAvaliacao, index: number) => {
                const { type, format, properties } = getProperties(pergunta);
                return [
                  `pergunta-${pergunta.id}`,
                  {
                    type,
                    format,
                    ...properties,
                    title: `${pergunta.posicao ?? index + 1}) ${pergunta.pergunta}`,
                  },
                ];
              },
            ) ?? [],
          ),
        },
      },
    };
  }
};

export const buildSchemaFormularioEnquadramento = (
  formularioEnquadramento: FormularioEnquadramento,
  isCategorizado: boolean,
) => {
  if (isCategorizado) {
    return {
      type: 'object',
      properties: Object.fromEntries(
        formularioEnquadramento?.formularioEnquadramentoCategorizado?.map(
          (categoria: FormularioEnquadramentoCategorizado, indexCategory: number) => {
            const posicao = categoria.posicao ?? indexCategory + 1;
            return [
              `form-${categoria.id}`,
              {
                type: 'object',
                title: `${posicao}) ${categoria.tituloCategoria}`,
                properties: Object.fromEntries(
                  categoria?.formularioEnquadramentoCategorizadoPerguntaAvaliacao?.map(
                    (pergunta: PerguntaAvaliacao, index: number) => {
                      const { type, format, properties } = getProperties(pergunta);
                      return [
                        `pergunta-${pergunta.id}`,
                        {
                          type,
                          format,
                          ...properties,
                          title: `${posicao}.${pergunta.posicao ?? index + 1}) ${pergunta.pergunta}`,
                        },
                      ];
                    },
                  ) ?? [],
                ),
              },
            ];
          },
        ) ?? [],
      ),
    };
  } else {
    return {
      type: 'object',
      properties: {
        form: {
          type: 'object',
          properties: Object.fromEntries(
            formularioEnquadramento?.formularioEnquadramentoPergunta?.map(
              (pergunta: PerguntaAvaliacao, index: number) => {
                const { type, format, properties } = getProperties(pergunta);
                return [
                  `pergunta-${pergunta.id}`,
                  {
                    type,
                    format,
                    ...properties,
                    title: `${pergunta.posicao ?? index + 1}) ${pergunta.pergunta}`,
                  },
                ];
              },
            ) ?? [],
          ),
        },
      },
    };
  }
};
