import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';
import { Pergunta, PerguntaMultiplaEscolha } from '../../../models/pergunta';

export interface IPerguntaUiSchemaOptions {
  withoutTipoPergunta: boolean;
}

const perguntaUiSchema = (options?: IPerguntaUiSchemaOptions): IUiSchema => ({
  type: 'VerticalLayout',
  elements: [
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'LongText',
          scope: '#/properties/pergunta',
          label: 'Texto da Pergunta',
          options: {
            required: true,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/tipoPergunta',
          label: 'Tipo da Pergunta',
          options: {
            required: true,
          },
          rule: {
            effect: RuleEffect.HIDE,
            condition: {
              schema: {
                const: options?.withoutTipoPergunta ? undefined : '',
              },
            } as Condition,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/tipoResposta',
          label: 'Tipo da Resposta',
          options: {
            required: true,
          },
        },
        {
          type: 'Control',
          scope: '#/properties/obrigatoria',
          label: 'Será pergunta obrigatória',
          options: {
            defaultValue: false,
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      rule: {
        condition: {
          conditions: [
            {
              type: 'Date',
              scope: '#/properties/tipoResposta',
              schema: { enum: ['DATA', null] },
              options: {
                required: true,
              },
            },
          ],
        } as Condition,
      } as Rule,
    },
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/multiplaEscolha',
          label: 'Adicionar Alternativa',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/alternativa',
                  label: 'Texto Alternativa',
                  options: {
                    handleChange: async (value: any, handler, formData: Pergunta, ctx, path) => {
                      const crrPath = path.split('.').slice(0, -2).join('.');

                      const multiplaEscolha =
                        crrPath.split('.').reduce((acc, part) => acc?.[part], formData) ||
                        formData[path] ||
                        [];

                      const allHavePosition =
                        Array.isArray(multiplaEscolha) &&
                        multiplaEscolha.every((item) => item.posicao !== undefined);

                      if (!allHavePosition) {
                        const updatedMultiplaEscolha = multiplaEscolha.map(
                          (item: PerguntaMultiplaEscolha, index: number) => ({
                            ...item,
                            posicao: index + 1,
                          }),
                        );
                        handler(crrPath, updatedMultiplaEscolha);
                      }
                    },
                  },
                },
              ],
            },
            showSortButtons: true,
          },
        },
      ],
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          type: 'AND',
          conditions: [
            {
              scope: '#/properties/tipoResposta',
              schema: { enum: ['MULTIPLA_ESCOLHA', 'MULTIPLA_SELECAO', null] },
            },
            {},
          ],
        } as Condition,
      } as Rule,
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/descritiva/properties/minimo',
          label: 'Mínimo de palavras',
        },
        {
          type: 'Control',
          scope: '#/properties/descritiva/properties/maximo',
          label: 'Máximo de palavras',
        },
      ],
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          type: 'AND',
          conditions: [
            {
              scope: '#/properties/tipoResposta',
              schema: { enum: ['DESCRITIVA', null] },
            },
            {},
          ],
        } as Condition,
      } as Rule,
    },
  ],
});

export default perguntaUiSchema;
