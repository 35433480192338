import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Money } from 'styled-icons/fluentui-system-regular';
import { makeApi } from '../../../api/generic-api';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import dayjs from '../../../components/dayjs/sig-dayjs';
import { positiveNumberOrEmpty } from '../../../helpers/custom-errors';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { CronogramaDesembolso } from '../../../models/cronograma-desembolso';
import { useAuthContext } from '../../login/auth/auth-context';
import { makeCronogramaDesembolsoApi } from './cronograma-desembolso-api';
import { headCells } from './cronograma-desembolso.headcell';
import cronogramaDesembolsoUiSchemaFactory from './cronograma-desembolso.uischema';
import { CrudStatesOptions } from '../../../jsonforms/uischema';

function InstituicaoInstrumentoJuridico() {
  const customErrors = [positiveNumberOrEmpty('valorFirmado')];

  const { configuracoesFundacao } = useAuthContext();
  const [uiSchema, setUiSchema] = useState<any>(null);

  const { instituicaoInstrumentoJuridicoId: instituicaoInstrumentoJuridicoIdStr } = useParams();
  const instituicaoInstrumentoJuridicoId = Number(instituicaoInstrumentoJuridicoIdStr);

  const {
    parameterFilters: {
      instituicaoInstrumentoJuridico: { instituicaoId },
    },
    crudStates,
    updateCrudStates,
    setLoad,
    setFormData,
    setValidationMode,
    showSuccess,
    showError,
    schema,
  } = useCrudContext();

  useEffect(() => {
    setUiSchema(
      cronogramaDesembolsoUiSchemaFactory({
        tesouro: instituicaoId == configuracoesFundacao?.fundacaoInstituicaoId,
      }),
    );
  }, [instituicaoId, configuracoesFundacao]);

  const hasFormat = (obj: any): obj is { format: unknown } =>
    obj && typeof obj === 'object' && 'format' in obj;

  const fixDate = (data: any) => {
    // Arruma o formato das datas ao carregar para editar ou visualizar uma row
    for (const [key, value] of Object.entries(schema?.properties ?? [])) {
      if (hasFormat(value) && value.format === 'date') {
        if (data[key]) {
          data[key] =
            dayjs?.(data?.[key], 'YYYY-MM-DD', 'UTC')?.format?.('YYYY-MM-DD') ?? data[key];
        }
      }
    }
  };

  const makePayment = {
    hasPermission: 'REALIZAR_PAGAMENTO',
    icon: <Money size='24' />,
    title: 'Efetivar Pagamento',
    moduleName: moduleNameFor('cronograma-desembolso'),
    handler: async (row: any) => {
      const id = row.id;
      const api = makeApi('/cronograma-desembolso');

      setLoad(true);
      try {
        const info = await api.get(id);
        fixDate(info);
        setFormData(info);
      } catch (err) {
        console.error(err);
      }
      setLoad(false);

      updateCrudStates(CrudStatesOptions.EDIT);
    },
  };

  const showMakePayment = (row: any) => {
    if (row.dataEfetiva == null) return makePayment;
  };

  const saveHandler = {
    handler: async (
      _: number,
      formData: CronogramaDesembolso,
      defaultSave: () => Promise<void>,
    ) => {
      const apiClient = makeCronogramaDesembolsoApi('/cronograma-desembolso');
      setValidationMode('ValidateAndShow');

      try {
        if (crudStates.edit) {
          const response = await apiClient.makePayment(formData.id, formData);
          if (response.status === 200) {
            setValidationMode('ValidateAndHide');
            updateCrudStates(CrudStatesOptions.LIST);
            showSuccess('Editado com sucesso.');
          } else {
            console.error(response);
          }
        } else {
          await defaultSave();
        }
      } catch (error: any) {
        console.error(error);
        let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
        if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
        showError(errorMessage || 'Ocorreu um erro.');
      }
    },
  };

  return (
    <Crud<CronogramaDesembolso>
      headCells={headCells}
      apiUrl={'/cronograma-desembolso'}
      titleConfig={{ value: 'Cronograma de Desembolso', show: true }}
      uischema={uiSchema}
      customErrors={customErrors}
      defaultForm={{ instituicaoInstrumentoJuridicoId }}
      queryFilters={{ instituicaoInstrumentoJuridicoId: instituicaoInstrumentoJuridicoId }}
      customActions={[showMakePayment]}
      customSave={saveHandler}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export default InstituicaoInstrumentoJuridico;

export const rootViews = ['instrumento-juridico', 'instituicao-instrumento-juridico'];
