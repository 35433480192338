import { useNavigate } from 'react-router-dom';
import { BuildingBankLink } from 'styled-icons/fluentui-system-filled';
import { makeApi } from '../../api/generic-api';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import { Instituicao as InstituicaoModel } from '../../models/instituicao';
import { headCells, moduleName } from './instituicao.headcell';
import instituicaoUiSchema from './instituicao.uischema';

function Instituicao() {
  const defaultForm = { 'paisId-codigo': 'brasil', endereco: {} };

  const navigate = useNavigate();
  const { updateCrudStates, setFormData, setId } = useCrudContext();
  const instituicaoApi = makeApi<InstituicaoModel>('/instituicao');

  const hideActionsByRow = (row: InstituicaoModel) => {
    return row.acknowledgedAt === null;
  };

  const vincularinstituicao = {
    hasPermission: ['ATUALIZAR'],
    moduleName: moduleName,
    icon: <BuildingBankLink size='24' color='#5DC1F2' />,
    title: 'Vincular Instituicao',
    handler: (row: any) => {
      instituicaoApi
        ?.get?.(row.id)
        .then((data: InstituicaoModel) => {
          setFormData(data);
        })
        .catch((err) => {
          console.error(err);
        });
      setId(row.id);
      navigate(`/instituicao/${row.id}`);
      updateCrudStates(CrudStatesOptions.EDIT);
    },
  };

  const showVincularinstituicao = (row: InstituicaoModel) => {
    if (row.acknowledgedAt === null) return vincularinstituicao;
  };

  return (
    <>
      <Crud<InstituicaoModel>
        titleConfig={{ value: 'Instituição', show: true }}
        headCells={headCells}
        apiUrl={'/instituicao'}
        defaultForm={defaultForm}
        uischema={instituicaoUiSchema}
        hideActionsByRow={hideActionsByRow}
        customActions={[showVincularinstituicao]}
        queryFilters={{ withDeleted: true }}
      ></Crud>
    </>
  );
}

export default Instituicao;
