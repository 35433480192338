import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Hidden from '@mui/material/Hidden';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useCrudContext } from '../../../components/crud/crud-context';
import { getFirstError } from '../../../utils/get-first-error';
import { IControlElement } from '../../uischema';
import '../renderer.css';
import './multiple.css';
import { CustomSelectProps, displayPropertiesComputed, getLabel } from './select-utils';

interface SelectMultipleProps extends CustomSelectProps {
  optionsBuilder?: (content: any) => any[];
}

export function SelectMultiple(props: SelectMultipleProps) {
  const {
    path,
    selectedItem,
    setSelectedItem,
    isVisible,
    isDisabled,
    uischema,
    apiUrl,
    options,
    optionsBuilder,
  } = props;

  const ctx = useCrudContext();

  const { validationMode, formData, isRequiredField } = ctx;

  // Mapper das options
  const defaultBuilder = (content: any) =>
    content?.map((item: any) => ({
      label: displayPropertiesComputed(uischema as IControlElement, item?.data, apiUrl) ?? '',
      value: item?.value,
      codigo: item?.codigo,
      data: item?.data ?? item,
    }));

  // Carrega os items selecionados
  React.useEffect(() => {
    if (props?.data?.length && options?.length && !selectedItem?.length) {
      const items: any[] = [];
      options?.forEach?.((option: any) => {
        const comparator = uischema?.options?.optionsComparator;

        props?.data?.forEach?.((item: any) => {
          const isMatch = comparator ? comparator(option, item) : option?.value === item?.id;

          if (isMatch) {
            items.push(option);
          }
        });
      });

      const optionsSelected = optionsBuilder?.(items) ?? defaultBuilder(items) ?? [];
      setSelectedItem(optionsSelected);
    }
  }, [props.data, options]);

  const handleChangeMultiple = (content: any) => {
    const { formData } = ctx;
    const options = optionsBuilder?.(content) ?? defaultBuilder(content) ?? [];
    const mappedData = content?.map((item: any) => item?.data);
    if (uischema.options?.handleChange) {
      uischema.options?.handleChange?.(
        content ?? null,
        props.handleChange,
        formData,
        ctx,
        props.path,
      );
    } else {
      props.handleChange(props.path, mappedData ?? null);
    }
    setSelectedItem(options);
  };

  const getClassNamesMultiple = () =>
    `${
      (isDisabled && 'disabled-field') ||
      (props.errors && validationMode === 'ValidateAndShow' && 'has-error')
    }`;

  return (
    <Hidden xsUp={!isVisible} key={`${path}-select-default-multiple`}>
      <div className='custom-input-container'>
        <Autocomplete
          multiple
          className={getClassNamesMultiple()}
          options={options}
          value={selectedItem ?? []}
          getOptionLabel={(option) => String(option?.label || '')}
          onChange={(_, value) => handleChangeMultiple(value)}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          disabled={isDisabled}
          disableCloseOnSelect
          noOptionsText={'Sem opções'}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label={getLabel(uischema as IControlElement, apiUrl)}
              required={isRequiredField(formData, ctx)}
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{
                shrink: formData[props.path] && formData[props.path].length > 0,
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={`li-${option?.value}`}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                checked={selected}
              />
              {option.label}
            </li>
          )}
        />
        {validationMode === 'ValidateAndShow' && props.errors && (
          <span className='error-message'>{getFirstError(props.errors)}</span>
        )}
      </div>
    </Hidden>
  );
}
