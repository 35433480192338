import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useCrudContext } from './crud-context';
import { forwardRef } from 'react';
import { Severity } from './protocols/severity';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function AlertCustom({ open, severity, message, anchorOrigin }: AlertCustomProps) {
  const { setOpenAlert } = useCrudContext();

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleCloseAlert}
        anchorOrigin={anchorOrigin}
      >
        <Alert severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

interface AlertCustomProps {
  open: boolean;
  severity: Severity;
  message: string;
  handleClose?: (event: Event | React.SyntheticEvent<any, Event>, reason: string) => void;
  anchorOrigin?: any;
}
