import { IconProps } from './icon-props';

export const EditIcon = ({ color = '#1351B4', size = 16 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5087 1.48424C11.7275 1.26563 12.0241 1.14282 12.3334 1.14282C12.6427 1.14282 12.9393 1.26563 13.1581 1.48424L14.8821 3.20824C15.1007 3.42701 15.2235 3.72363 15.2235 4.03291C15.2235 4.34218 15.1007 4.6388 14.8821 4.85757L13.0201 6.71957L13.0121 6.72824L5.79339 13.6182C5.66147 13.7443 5.50188 13.8376 5.32739 13.8909L1.64539 15.0109C1.55882 15.0371 1.46674 15.0394 1.37902 15.0173C1.29129 14.9952 1.21122 14.9497 1.14739 14.8856C1.08329 14.8217 1.03777 14.7417 1.01569 14.6539C0.993612 14.5662 0.995813 14.4741 1.02206 14.3876L2.13739 10.7209C2.19589 10.529 2.30289 10.3554 2.44806 10.2169L9.65072 3.34157L11.5087 1.48424ZM3.13872 10.9402C3.11811 10.9602 3.10276 10.9849 3.09406 11.0122L2.25139 13.7816L5.03606 12.9342C5.06103 12.9265 5.08385 12.913 5.10272 12.8949L11.9514 6.35757L9.99139 4.39824L3.13872 10.9402ZM12.6667 5.65957L14.1754 4.15157C14.1909 4.13609 14.2032 4.1177 14.2116 4.09745C14.22 4.0772 14.2244 4.0555 14.2244 4.03357C14.2244 4.01165 14.22 3.98994 14.2116 3.9697C14.2032 3.94945 14.1909 3.93106 14.1754 3.91557L12.4514 2.19157C12.4359 2.17605 12.4175 2.16374 12.3973 2.15534C12.377 2.14693 12.3553 2.14261 12.3334 2.14261C12.3115 2.14261 12.2898 2.14693 12.2695 2.15534C12.2493 2.16374 12.2309 2.17605 12.2154 2.19157L10.7074 3.69957L12.6667 5.65957Z'
      fill={color}
    />
  </svg>
);
