import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { DocumentoPessoal } from '../../../models/documento-pessoal';
import { headCells } from './documento-pessoal.headcell';
import documentoPessoalUiSchema from './documento-pessoal.uischema';

function DocumentosPessoais() {
  const customErrors = [whiteSpaces('nome')];

  return (
    <Crud<DocumentoPessoal>
      defaultForm={{}}
      headCells={headCells}
      apiUrl={'/documento-pessoal'}
      titleConfig={{ value: 'Documentos Pessoais', show: true }}
      customErrors={customErrors}
      uischema={documentoPessoalUiSchema}
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default DocumentosPessoais;
