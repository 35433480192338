import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { MoedaEstrangeira } from '../../../models/moeda-estrangeira';

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.MOEDA_ESTRANGEIRA';

export const moduleLabel = 'Moedas Estrangeiras';

export const headCells: readonly HeadCell<MoedaEstrangeira>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'simbolo',
    numeric: false,
    disablePadding: false,
    label: 'Símbolo',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
];
