import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { ChangeEvent, useEffect, useState } from 'react';
import { makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import Loading from '../../components/crud/loading';
import {
  BoxContainer,
  ItemGridButton,
  SearchBox,
  SearchIcon,
  SearchInput,
} from './button-data-grid/button-data-grid';

const ButtonDataGridTester = rankWith(5, uiTypeIs('ButtonDataGrid'));

interface Option {
  label: string;
  data: any;
}

export const ButtonDataGrid = {
  tester: ButtonDataGridTester,
  renderer: withJsonFormsControlProps(({ schema, uischema, data, path, visible, ...props }) => {
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [apiListData, setApiListData] = useState<any>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

    const ctx = useCrudContext();

    const { crudStates, disabledFields } = ctx;

    useEffect(() => {
      const fetchData = async () => {
        try {
          const apiInstance = makeApi('/estado');

          const dataFromApi = await apiInstance.getAll();
          setApiListData(dataFromApi);
          loadSavedOptions(dataFromApi);
          setLoading(false);

          const options: Option[] = dataFromApi.map((data: any) => ({
            label: processLabel(data),
            data,
          }));
          setOptions(options);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, []);

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value);
    };

    const handleButtonClick = (option: Option) => {
      //Verifica se a opção já está selecionada
      const updatedSelectedOptions = selectedOptions.some(
        (selectedOption) => selectedOption.data === option.data,
      )
        ? selectedOptions.filter((selectedOption) => selectedOption.data !== option.data)
        : [...selectedOptions, option];

      setSelectedOptions(updatedSelectedOptions);
      props.handleChange(path, updatedSelectedOptions.map(processData));
    };

    const handleSelectAllClick = () => {
      // Seleciona todas as opções se não estiverem todos selecionados;
      // Caso contrário, desmarca todas as opções.
      const updatedSelectedOptions =
        selectedOptions.length === apiListData.length ? [] : apiListData.map(processOption);

      setSelectedOptions(updatedSelectedOptions);
      props.handleChange(
        path,
        updatedSelectedOptions.map((option: Option) => option.data),
      );
    };

    const isDisabled: boolean =
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path);

    const filteredOptions = options.filter((option: Option) =>
      option.label.toLowerCase().includes(filter.toLowerCase()),
    );

    const loadSavedOptions = (dataFromApi: any[]) => {
      if (data) {
        const savedOptions = data.map((item: any) => dataFromApi.find((x) => x.id === item.id));
        const processedSavedOptions = savedOptions.map(processOption);

        setSelectedOptions(processedSavedOptions);
      }
    };

    const processData = (option: Option) => option.data;

    const processOption = (data: any) => {
      return { label: processLabel(data), data };
    };

    const processLabel = (data: any) => {
      return `${data.nome}`;
    };

    return (
      <Hidden xsUp={!visible}>
        <BoxContainer sx={{ mb: 1 }}>
          <SearchBox>
            <SearchInput
              type='text'
              sx={{ flex: 1 }}
              placeholder='Busque por estados'
              onChange={handleFilterChange}
              value={filter}
            />
            <SearchIcon size={24} />
          </SearchBox>
          {loading && <Loading isLoading={loading} />}
          {!loading && (
            <Grid container spacing={1.8} columnSpacing={4} columns={{ xs: 3, sm: 3, md: 4 }}>
              {filter.length === 0 && (
                <Grid item xs={1}>
                  <ItemGridButton
                    disabled={isDisabled}
                    variant={
                      selectedOptions.length === apiListData.length && !loading
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={handleSelectAllClick}
                  >
                    Todos
                  </ItemGridButton>
                </Grid>
              )}
              {filteredOptions.map((option: Option, index: number) => (
                <Grid item xs={1} key={index}>
                  <ItemGridButton
                    disabled={isDisabled}
                    key={index}
                    variant={
                      selectedOptions.some((selectedOption) => selectedOption.data === option.data)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleButtonClick(option)}
                  >
                    {option.label}
                  </ItemGridButton>
                </Grid>
              ))}
            </Grid>
          )}
        </BoxContainer>
      </Hidden>
    );
  }),
};
