import { kebabCase } from 'change-case';
import fileClient from '../config/axios-file-config';

export interface IFileData {
  addFile: (
    file: File,
    setFileProgress: Function,
    baseUrl: string,
    path: string,
    formDataId?: number,
  ) => any;
  removeFile: (baseUrl: string, path: string, formDataId: number, fileRemoveId: number) => any;
  getFile: (baseUrl: string, path: string, formDataId?: number) => any;
}

export class FileApi implements IFileData {
  async addFile(
    file: File,
    setFileProgress: Function,
    baseUrl: string,
    path: string,
    formDataId?: number,
    category?: string,
    options?: { [key: string]: any },
    additionalFileApiData?: { [key: string]: any },
  ): Promise<any> {
    const localFormData = new FormData();
    localFormData.append('file', file);

    if (additionalFileApiData) {
      Object.entries(additionalFileApiData).forEach(([key, value]) => {
        localFormData.append(key, value);
      });
    }

    const urlParams = [baseUrl, formDataId, kebabCase(path), category];
    const url = urlParams.filter((x) => x).join('/');

    const urlBuild = Object.entries(options).reduce((acc, [chave, valor], i) => {
      return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
    }, url);

    const response = await fileClient.post<any>(urlBuild, localFormData, {
      onUploadProgress: (ProgressEvent: any) => {
        const percentCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
        setFileProgress((prevProgress: any) => ({
          ...prevProgress,
          [file.name]: percentCompleted,
        }));
      },
    });

    return response.data;
  }

  async removeFile(
    baseUrl: string,
    path: string,
    fileId: number | string,
    formDataId?: number,
  ): Promise<any> {
    const urlParams = [baseUrl, formDataId, kebabCase(path), fileId];
    const url = urlParams.filter((x) => x).join('/');

    await fileClient.delete<any>(url);
  }

  async getFile(baseUrl: string, path: string, formDataId?: number): Promise<any> {
    const urlParams = [baseUrl, formDataId, kebabCase(path)];
    const url = urlParams.filter((x) => x).join('/');

    const response = await fileClient.get<any>(url);
    return response;
  }

  async downloadFile(
    baseUrl: string,
    path: string,
    anexoId: number,
    formDataId?: number,
  ): Promise<any> {
    const urlParams = [baseUrl, formDataId, kebabCase(path), anexoId];
    const url = urlParams.filter((x) => x).join('/');
    const response = await fileClient.get<any>(url, { responseType: 'blob' });

    return response;
  }

  async getCategories(
    baseUrl: string,
    options: { [key: string]: any } = {},
  ): Promise<string[] | undefined> {
    const urlBuild = Object.entries(options).reduce((acc, [chave, valor], i) => {
      return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
    }, baseUrl);

    const response = await fileClient.get<string[]>(urlBuild);
    return response.data;
  }
}
