import { scopeToPath } from '../../../helpers/scope-to-path';
import { CrudContextData, IStepCallback } from '../../../jsonforms/uischema/uischema.type';
import { makeDadosFundacaoApi } from './dados-fundacao-api';

export const dadosFundacaoStepHandlerSave = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | string> => {
  const { errorsJsonForms, additionalErrors, setValidationMode, crudStates } = ctx;
  const { paths, instancePaths, requiredScopes } = fieldsConfig;
  const requiredFields = requiredScopes?.map?.((scope) => scopeToPath(scope));

  const jsonFormErrors = errorsJsonForms
    ?.map((error: any) => {
      if (requiredFields?.includes?.(error?.params?.missingProperty)) {
        return error;
      }
      if (instancePaths?.includes?.(error?.instancePath)) {
        return error;
      }
    })
    .filter((error: any) => error !== undefined);

  const hasCustomError = !!additionalErrors?.find?.((error: any) =>
    paths?.includes?.(error?.propertyName),
  );

  if (jsonFormErrors.length || hasCustomError) {
    setValidationMode('ValidateAndShow');
    return false;
  }

  try {
    if (crudStates.edit) {
      const api = makeDadosFundacaoApi(url);
      return api?.put?.(0, data).then((res) => {
        if (res.status === 200) {
          return true;
        } else {
          console.error(res);
          return false;
        }
      });
    }
  } catch (error: any) {
    console.error(error);
  }

  if (callback) {
    return !!(await callback?.(ctx));
  }

  return true;
};
