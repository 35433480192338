import { AxiosResponse } from 'axios';
import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
import { EdicaoLinhaDeAcao } from '../../../models/edicao-linha-de-acao';

class EdicaoLinhaDeAcaoApi extends GenericApi<EdicaoLinhaDeAcao> {
  constructor(url: string) {
    super(url);
  }

  async get(): Promise<EdicaoLinhaDeAcao> {
    try {
      const response = await client.get<EdicaoLinhaDeAcao>(`${this.url}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async put(_: number, data: any): Promise<AxiosResponse<EdicaoLinhaDeAcao, any>> {
    try {
      const response = await client.put<EdicaoLinhaDeAcao>(this.url, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }

  async getByYear(year: string): Promise<EdicaoLinhaDeAcao> {
    try {
      const response = await client.get<EdicaoLinhaDeAcao>(`${this.url}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }
}

export const makeEdicaoLinhaDeAcaoApi = () => {
  return new EdicaoLinhaDeAcaoApi('edicao-linha-de-acao');
};
