import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IPropostaApi, makePropostaApi } from '../../api/proposta-api';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import { Proposta as PropostaModel } from '../../models/proposta';
import { headCells } from './proposta.headcell';
import propostaUiSchema from './proposta.uischema';

export enum TrechoPassagemProposta {
  nacional = 'nacional',
  internacional = 'internacional',
}

function Proposta() {
  const { formData } = useCrudContext();
  const [propostaApi, setPropostaApi] = useState<IPropostaApi | undefined>();

  const location = useLocation();

  useEffect(() => {
    if (formData?.editalId || location?.state?.editalId)
      setPropostaApi(makePropostaApi(formData.editalId ?? location.state.editalId));
  }, [formData?.editalId, location?.state?.editalId]);

  return propostaApi ? (
    <Crud<PropostaModel>
      headCells={headCells}
      titleConfig={{ value: 'Submissão de Propostas', show: true }}
      uischema={propostaUiSchema}
      queryFilters={{ withDeleted: true, orderBy: 'tituloDoProjeto', orderDirection: 'ASC' }}
      apiUrl={'/proposta'}
      apiClient={propostaApi}
      hideDelete
      hideCreate
    ></Crud>
  ) : null;
}

export default Proposta;
