import { NaturezaDespesa } from './natureza-despesa';
import { Edital } from './edital';
import { MoedaEstrangeira } from './moeda-estrangeira';
import { EditalRubricaMoedaEstrangeira } from './edital-rubrica-moeda-estrangeira';
export enum TipoEditalRubrica {
  diarias = 'DIARIAS',
  hospedagemAlimentacao = 'HOSPEDAGEM_ALIMENTACAO',
  servicosDeTerceiros = 'SERVICOS_DE_TERCEIROS',
  materialDeConsumo = 'MATERIAL_DE_CONSUMO',
  materialPermanenteEquipamentos = 'MATERIAL_PERMANENTE_EQUIPAMENTOS',
  passagens = 'PASSAGENS',
  pessoal = 'PESSOAL',
  encargos = 'ENCARGOS',
  bolsa = 'BOLSA',
}
export interface EditalRubrica {
  id: number;
  tipoEditalRubrica: TipoEditalRubrica;
  naturezaDespesaId?: number;
  naturezaDespesa: NaturezaDespesa;
  editalId?: number;
  edital: Edital;
  temJustificativaObrigatoria?: boolean;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeira: MoedaEstrangeira[];
  editalRubricaMoedaEstrangeira: EditalRubricaMoedaEstrangeira[];
}

export type TipoEditalRubricaI18N = {
  [key in keyof typeof TipoEditalRubrica]: string;
};

export const tipoEditalRubricaI18N: TipoEditalRubricaI18N = {
  diarias: 'Diárias',
  hospedagemAlimentacao: 'Hospedagem e Alimentação',
  servicosDeTerceiros: 'Servicos de Terceiros',
  materialDeConsumo: 'Material de Consumo',
  materialPermanenteEquipamentos: 'Material Permanente e Equipamentos',
  passagens: 'Passagens',
  pessoal: 'Pessoal',
  encargos: 'Encargos',
  bolsa: 'Bolsa',
};
