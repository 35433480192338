import { JsonSchema, ValidationMode } from '@jsonforms/core';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import {
  CrudContextData,
  CrudStates,
  CrudStatesOptions,
  IUiSchema,
  RequiredType,
} from '../../jsonforms/uischema';
import { Severity } from './protocols/severity';

const CrudContext = createContext<CrudContextData | undefined>(undefined);

export const CrudProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [crudStates, setCrudStates] = useState<CrudStates>({ list: true });
  const [errorsJsonForms, setErrorsJsonForm] = useState<any[]>([]);
  const [errorsCustom, setErrorsCustom] = useState<any[]>([]);
  const [validationMode, setValidationMode] = useState<ValidationMode>('ValidateAndHide');
  const [apiListData, setApiListData] = useState<any>([]);
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState<JsonSchema>({});
  const [currentUiSchema, setCurrentUiSchema] = useState({} as IUiSchema);
  const [additionalErrors, setAdditionalErrors] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [parameterFilters, setParameterFilters] = useState<any>({});
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentApiUrl, setCurrentApiUrl] = useState('');
  const [id, setId] = useState(-1);

  // Custom Alert
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);

  const clearForm = () => {
    setErrorsJsonForm([]);
    setErrorsCustom([]);
    setAdditionalErrors([]);
    setValidationMode('ValidateAndHide');
    setFormData({});
    setSchema({});
    setId(-1);
    setDisabledFields([]);
  };

  const updateCrudStates = (option: CrudStatesOptions) => {
    let data = {
      list: false,
      add: false,
      edit: false,
      view: false,
    };
    switch (option) {
      case CrudStatesOptions.LIST:
        data.list = true;
        break;
      case CrudStatesOptions.ADD:
        data.add = true;
        break;
      case CrudStatesOptions.EDIT:
        data.edit = true;
        break;
      case CrudStatesOptions.VIEW:
        data.view = true;
        break;
    }
    setCrudStates(data);
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const isRequiredField = useCallback(
    (uischemaRequired: RequiredType, ctx: CrudContextData) => {
      return Boolean(
        typeof uischemaRequired === 'function' ? uischemaRequired(ctx) : uischemaRequired,
      );
    },
    [formData],
  );

  return (
    <CrudContext.Provider
      value={{
        crudStates,
        setCrudStates,
        updateCrudStates,
        errorsJsonForms,
        setErrorsJsonForm,
        errorsCustom,
        setErrorsCustom,
        additionalErrors,
        setAdditionalErrors,
        validationMode,
        setValidationMode,
        isRequiredField,
        apiListData,
        setApiListData,
        formData,
        setFormData,
        schema,
        setSchema,
        clearForm,
        currentUiSchema,
        setCurrentUiSchema,
        severityAlert,
        messageAlert,
        openAlert,
        setOpenAlert,
        showSuccess,
        showError,
        load,
        setLoad,
        parameterFilters,
        setParameterFilters,
        disabledFields,
        setDisabledFields,
        currentTitle,
        setCurrentTitle,
        currentApiUrl,
        setCurrentApiUrl,
        id,
        setId,
      }}
    >
      {children}
    </CrudContext.Provider>
  );
};

export const useCrudContext = () => {
  const context = useContext(CrudContext);

  if (!context) {
    throw new Error('useCrudContext deve ser usado dentro de um CrudProvider');
  }

  return context;
};
