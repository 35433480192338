import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { Banco as BancoModel } from '../../../models/banco';
import { headCells } from './banco.headcell';
import bancoUiSchema from './banco.uischema';

function Banco() {
  const customErrors = [whiteSpaces('nome')];

  return (
    <Crud<BancoModel>
      headCells={headCells}
      titleConfig={{ value: 'Bancos', show: true }}
      uischema={bancoUiSchema}
      apiUrl={'/banco'}
      hideView
      queryFilters={{ withDeleted: true, orderBy: 'codigo', orderDirection: 'ASC' }}
      customErrors={customErrors}
    ></Crud>
  );
}

export default Banco;
