import { Crud } from '../../../components/crud/crud';
import { IndicadorProducao as IndicadorProducaoModel } from '../../../models/edital';
import { headCells } from './indicador-producao.headcell';
import indicadorProducaoUiSchema from './indicador-producao.uischema';

function IndicadorProducao() {
  const defaultForm = { nacional: false };

  return (
    <Crud<IndicadorProducaoModel>
      headCells={headCells}
      titleConfig={{ value: 'Indicadores de Produção', show: true }}
      uischema={indicadorProducaoUiSchema}
      apiUrl={'/indicador-producao'}
      defaultForm={defaultForm}
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default IndicadorProducao;
