import dayjs from 'dayjs';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Proposta } from '../../models/proposta';
import {
  StyledCalendarIcon,
  DateContent,
  DateLabel,
  InformacoesLabel,
  StyledBackgroundList,
  StyledBackgroundListItem,
  StyledBackgroundListItemButton,
  StyledContent,
  TituloLabel,
  StyledLineContent,
} from './style/lista-propostas';
import ListaStatus from './lista-status';
import { CrudStatesOptions } from '../../jsonforms/uischema';

type ListaPropostasProps = {
  splitLength: number;
  propostas: Proposta[];
};

export default function ListaPropostas({ splitLength, propostas }: ListaPropostasProps) {
  const navigate = useNavigate();

  const goToPropostaEdicao = ({ id, edital: { id: editalId } }: Partial<Proposta>) => {
    navigate(`/proposta/${id}`, {
      state: {
        crudState: CrudStatesOptions.EDIT,
        editalId,
      },
    });
  };

  return (
    <StyledBackgroundList>
      {propostas.map((item, index) => (
        <Fragment key={index}>
          <StyledBackgroundListItem disablePadding onClick={() => goToPropostaEdicao(item)}>
            <StyledBackgroundListItemButton>
              <StyledContent>
                <TituloLabel>
                  {splitLength % 2 === 0 && item.tituloDoProjeto.length > 80
                    ? `${item.tituloDoProjeto.slice(0, 80)}...`
                    : item.tituloDoProjeto}
                </TituloLabel>
                <TituloLabel>
                  {splitLength % 2 === 0 && item.edital.nome.length > 80
                    ? `${item.edital.nome.slice(0, 80)}...`
                    : item.edital.nome}
                </TituloLabel>
                <StyledLineContent>
                  <DateContent>
                    <StyledCalendarIcon />
                    <InformacoesLabel>{'Data de criação: '}</InformacoesLabel>
                    <DateLabel>{dayjs(item.createdAt).format('DD/MM/YYYY')}</DateLabel>
                  </DateContent>
                  <ListaStatus status={item.statusProposta} />
                </StyledLineContent>
              </StyledContent>
            </StyledBackgroundListItemButton>
          </StyledBackgroundListItem>
        </Fragment>
      ))}
    </StyledBackgroundList>
  );
}
