import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormApi, makeApi } from '../../api/generic-api';
import { customRequired } from '../../helpers/custom-errors';
import { fixDate } from '../../helpers/fix-date';
import getRouteParam from '../../helpers/get-route-param';
import { getUischemaConfigs } from '../../helpers/get-uischema-configs';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import { moduleNames } from '../../views';
import { useAuthContext } from '../../views/login/auth/auth-context';
import AlertCustom from './alert-custom';
import { useCrudContext } from './crud-context';
import DataTable from './data-table/data-table';
import { Action } from './data-table/protocols/actions';
import DeleteDialog from './delete-dialog';
import { makeErrorsManager } from './errors-manager';
import JsonForm from './json-form';
import Loading from './loading';
import { HeadCell } from './protocols/head-cell';
import { Container, CustomDoneIcon, SaveButton, TitleField } from './style/crud.styled';

export function Crud<T extends Record<string, any>>({
  headCells,
  checkboxes,
  titleConfig,
  uischema,
  apiUrl,
  apiClient,
  customErrors,
  hideView,
  hideDelete,
  hideUpdate,
  hideCreate,
  customActions,
  hideActions,
  initView,
  defaultForm,
  ignorePermissions = false,
  deleteMessageHandler,
  customTableHeader,
  tableData,
  queryFilters,
  customSave,
  width,
  height,
  horizontalScroll,
  verticalScroll,
  customButtons,
}: CrudProps<T>) {
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { configuracoesFundacao } = useAuthContext();
  const configuracaoPaginacao =
    configuracoesFundacao?.paginacao
      ?.split(',')
      .map((x: any) => parseInt(x))
      .filter((x: any) => !isNaN(x)) ?? [];

  const [rowsPerPage, setRowsPerPage] = useState<number>(first(configuracaoPaginacao) ?? 10);
  const [rowsPerPageOptions] = useState(
    configuracaoPaginacao?.length ? configuracaoPaginacao : [10, 20, 30],
  );

  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const routeId = Number(routeParams[getRouteParam(apiUrl)] ?? null) ?? null;
  const moduleName: string = moduleNames.find((x) => x.id === apiUrl?.substring(1))?.value ?? '';

  const [openDialog, setOpenDialog] = useState(false);
  const [api, setApi] = useState<FormApi<T> | null>(null);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);

  const ctx = useCrudContext();
  const errorsManager = makeErrorsManager(ctx);
  const { addCustomErrors, hasInsertedError, clearErrors, removeCustomErrors } = errorsManager;

  const {
    crudStates,
    updateCrudStates,
    errorsJsonForms,
    setErrorsJsonForm,
    errorsCustom,
    setErrorsCustom,
    additionalErrors,
    validationMode,
    setValidationMode,
    apiListData,
    setApiListData,
    formData,
    setFormData,
    currentUiSchema,
    setCurrentUiSchema,
    openAlert,
    messageAlert,
    severityAlert,
    showError,
    showSuccess,
    schema,
    setSchema,
    load,
    setLoad,
    setDisabledFields,
    setCurrentTitle,
    setCurrentApiUrl,
    id,
    setId,
  } = ctx;

  useEffect(() => {
    // Pega o id da rota e seta em visualização
    if (api && routeId > 0 && routeId !== id) {
      select(routeId);
    }
  }, [api, routeId]);

  useEffect(() => {
    // Seta o título para o breadcrumb pegar do contexto
    titleConfig?.value && setCurrentTitle(titleConfig.value);
    // Inicializa os erros customizados
    addCustomErrors();
  }, [schema]);

  useEffect(() => {
    if (apiClient) {
      setApi(apiClient);
    } else if (apiUrl && !api) {
      const apiInstance = makeApi(apiUrl);
      setApi(apiInstance as any);
    }
    setCurrentApiUrl((apiClient?.url ?? apiUrl)!);
  }, [apiUrl, apiClient]);

  const fetchData = async () => {
    if (api && crudStates.list) {
      setLoad(true);
      try {
        const response = await api.getAllForPagination({
          ...queryFilters,
          skip: page * rowsPerPage,
          take: rowsPerPage,
        });

        const data = response.data !== undefined ? response.data : response;
        const count = response.count !== undefined ? response.count : data.length;

        setApiListData(data);
        setTotalCount(count);
      } catch (err) {
        console.error(err);
      } finally {
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    setLoad(true);
    api
      ?.getSchema?.()
      .then((schema) => {
        schema && setSchema(schema);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    fetchData();
  }, [api, crudStates.list, page, rowsPerPage]);

  // Pega os custom errors do uischema, dentro de options.customErrors
  useEffect(() => {
    const errors: any[] = customErrors || [];
    uischema?.elements?.forEach?.((el: any) => {
      if (el?.options?.customErrors) {
        const tokens: string[] = el?.scope?.split?.('/properties/');
        const path = tokens.filter((token) => token !== '#').join('.');
        for (const error of el?.options?.customErrors) {
          const errorToInsert = error(path);
          if (!hasInsertedError(errors, errorToInsert)) {
            errors.push(errorToInsert);
          }
        }
      }
    });
    setCurrentUiSchema(uischema);
    setErrorsCustom(errors);
  }, [uischema]);

  // Gerencia os campos obrigatórios
  useEffect(() => {
    const { requiredPaths } = getUischemaConfigs(uischema, ctx);
    if (isEqual(requiredPaths, requiredFields)) return;
    let errors: any[] = cloneDeep(errorsCustom) || [];
    // Remove os erros customizados de campos obrigatórios que não estão mais na lista
    for (const error of errorsCustom || []) {
      if (error?.error?.keyword === 'customRequired' && !requiredPaths.includes(error.field)) {
        errors = errors.filter(
          (e) => !(e?.error?.keyword === 'customRequired' && e.field === error.field),
        );
      }
    }
    // Adiciona os erros customizados de campos obrigatórios
    for (const path of requiredPaths) {
      const errorToInsert = customRequired(path);
      if (!hasInsertedError(errors, errorToInsert)) {
        errors.push(errorToInsert);
      }
    }
    setErrorsCustom(errors);
    setRequiredFields(requiredPaths);
  }, [formData]);

  // Quando inicia no modo de visualização seta o primeiro elemento
  useEffect(() => {
    if (crudStates.view && initView) {
      let initialData = { ...(defaultForm || {}) };

      api?.getAll?.(queryFilters).then((data: any) => {
        if (Array.isArray(data) && data.length) {
          initialData = data[0] || {};
          setApiListData(data);
        } else if (data) {
          initialData = data;
          setApiListData([data]);
        }
        fixDate(initialData, schema);
        setFormData(initialData);
        setId(initialData?.id || 0);
      });
    }
  }, [api, crudStates.view, initView]);

  useEffect(() => {
    if (initView) {
      select(1);
    }
  }, [initView, id]);

  const onChangeJsonForms = (errorsFromJsonForm: any, dataFromJsonForm: any) => {
    setFormData(dataFromJsonForm);
    setErrorsJsonForm(errorsFromJsonForm);
    fixDate(formData, schema);
    removeCustomErrors();
  };

  const save = async () => {
    setValidationMode('ValidateAndShow');

    const additionalErrorsLocal = addCustomErrors();
    if (errorsJsonForms?.length || additionalErrorsLocal?.length) {
      return;
    }

    try {
      if (crudStates.add) {
        const response = await api?.post?.(formData);
        if (response?.status === 201) {
          back();
          showSuccess('Adicionado com sucesso.');
        } else {
          console.error(response);
        }
      } else if (crudStates.edit) {
        const response = await api?.put?.(id, formData);
        if (response?.status === 200) {
          if (initView) {
            back(CrudStatesOptions.VIEW);
          } else {
            back();
          }
          showSuccess('Editado com sucesso.');
        } else {
          console.error(response);
        }
      }
    } catch (error: any) {
      console.error(error);
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
    }
  };

  const destroy = async () => {
    try {
      const response = await api?.delete?.(id);
      if (response.status === 200) {
        api?.getAll?.(queryFilters).then((data: any) => {
          setApiListData(data);
        });
        handleCloseDelete();
        showSuccess('Desativado com sucesso.');
      }
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const back = (state = CrudStatesOptions.LIST) => {
    clearErrors();
    if (state === CrudStatesOptions.VIEW) {
      updateCrudStates(state);
    } else if (state === CrudStatesOptions.LIST) {
      updateCrudStates(state);
      setFormData({});
      setDisabledFields([]);
      const idToRemoveRegex = new RegExp(`/${id}$`);
      setId(-1);
      if (idToRemoveRegex.test(location.pathname)) {
        const newPath = location.pathname.replace(idToRemoveRegex, '');
        navigate(newPath);
      }
    }
  };

  const select = (newId: number) => {
    if (newId === id) return;
    setLoad(true);
    api
      ?.get?.(newId)
      .then((data: any) => {
        fixDate(data, schema);
        setFormData(data);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    updateCrudStates(location.state?.crudState ?? CrudStatesOptions.VIEW);
    setId(newId);
    const idRegex = new RegExp(`/${newId}$`);
    if (!idRegex.test(location.pathname)) navigate(`${location.pathname}/${newId}`);
  };

  const addView = () => {
    updateCrudStates(CrudStatesOptions.ADD);
    setFormData(defaultForm);
  };

  const editView = (id: number) => {
    setLoad(true);
    api
      ?.get?.(id)
      .then((data: any) => {
        data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
        fixDate(data, schema);
        setFormData(data);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    setId(id);
    const idRegex = new RegExp(`/${id}$`);
    if (!idRegex.test(location.pathname)) navigate(`${location.pathname}/${id}`);
    updateCrudStates(CrudStatesOptions.EDIT);
  };

  const restore = async (id: number) => {
    try {
      const response = await api?.restore?.(id);
      if (response?.status === 200) {
        api?.getAll?.(queryFilters).then((data: any) => {
          setApiListData(data);
        });
        showSuccess('Ativado com sucesso.');
      }
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const handleOpenDelete = (id: number) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDialog(false);
    setTimeout(() => setId(-1), 100);
  };

  return (
    <>
      {load && <Loading isLoading={load} />}
      {/* {!load && !crudStates.list && (
        <>
          {(!initView || crudStates.edit) && (
            <div
              style={{
                position: 'fixed',
                top: '110px',
                backgroundColor: '#fafafa',
                zIndex: 11,
              }}
            >
              <Button
                className='btn-back'
                onClick={() => {
                  return initView ? back(CrudStatesOptions.VIEW) : back();
                }}
              >
                <ArrowBackIcon></ArrowBackIcon>
                Voltar
              </Button>
            </div>
          )}
        </>
      )} */}
      {!load && (crudStates.add || crudStates.edit || crudStates.view) && titleConfig?.show && (
        <TitleField>{titleConfig?.value}</TitleField>
      )}
      {!load && crudStates.list && !initView && (
        <DataTable<T>
          headCells={headCells}
          rows={apiListData}
          checkboxes={checkboxes}
          title={titleConfig?.value}
          viewHandler={select}
          addHandler={addView}
          editHandler={editView}
          deleteHandler={handleOpenDelete}
          restoreHandler={restore}
          hideActions={hideActions}
          hideView={hideView}
          hideDelete={hideDelete}
          hideUpdate={hideUpdate}
          hideCreate={hideCreate}
          customActions={customActions}
          customTableHeader={customTableHeader}
          moduleName={moduleName}
          ignorePermissions={ignorePermissions}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
      {!load && !crudStates.list && (
        <>
          <JsonForm
            uischema={currentUiSchema}
            schema={schema}
            load={load}
            data={formData}
            onChange={({ errors, data }) => onChangeJsonForms(errors, data)}
            additionalErrors={additionalErrors}
            readonly={crudStates.view || false}
            validationMode={validationMode}
            width={width}
            height={height}
            horizontalScroll={horizontalScroll}
            verticalScroll={verticalScroll}
          />
          <Container>
            {customButtons}
            {(customSave || (crudStates.edit && !hideUpdate) || (crudStates.add && !hideCreate)) &&
              customSave?.show !== false &&
              !crudStates.view && (
                <SaveButton
                  onClick={() => {
                    if (customSave?.handler) customSave.handler(id, formData, save);
                    else save();
                  }}
                >
                  {customSave?.saveButton ?? (
                    <>
                      <CustomDoneIcon />
                      Salvar
                    </>
                  )}
                </SaveButton>
              )}
          </Container>
        </>
      )}
      <DeleteDialog
        openDialog={openDialog}
        body={
          <b>
            {deleteMessageHandler
              ? `${deleteMessageHandler((apiListData as any)?.find((i: any) => i?.id === id))}`
              : ''}
          </b>
        }
        handleCloseDelete={handleCloseDelete}
        destroy={destroy}
      />
      <AlertCustom open={openAlert} severity={severityAlert} message={messageAlert} />
    </>
  );
}

export type CrudProps<T> = {
  headCells: readonly HeadCell<T>[];
  checkboxes?: boolean;
  titleConfig?: { value: string; show: boolean };
  uischema?: any;
  apiUrl?: string;
  customErrors?: any;
  hideView?: boolean;
  hideDelete?: boolean;
  hideUpdate?: boolean;
  hideCreate?: boolean;
  hideActions?: boolean;
  initView?: boolean;
  defaultForm?: any;
  deleteMessageHandler?: Function;
  rowsPerPageOptions?: number[];

  // Botões customizados
  customButtons?: ReactNode;

  // Ignora permissonamento
  ignorePermissions?: boolean;

  // Qtd de linhas do DataTable
  rowsLength?: number;

  // Altera o client default pelo fornecido
  apiClient?: FormApi<T>;

  // Ações customizadas do DataTable
  customActions?: ((row: T) => Action | undefined)[];

  // Botão e ação de salvar custom (objeto com botão e handler ou apenas função de handler)
  customSave?: {
    show?: boolean;
    saveButton?: JSX.Element;
    handler?: (id: number, formData: T, defaultSave: () => Promise<void>) => Promise<void>;
  };

  // Substitui o Header do DataTable
  customTableHeader?: ReactNode;

  // Substitui os dados default do DataTable
  tableData?: T[];

  // Filtros enviados pela url (query params)
  queryFilters?: { [key: string]: any };

  // Ajusta o tamanho do formulário em px e pode adicionar scroll tanto no eixo x quanto no y se necessário
  width?: number;
  height?: number;
  horizontalScroll?: boolean;
  verticalScroll?: boolean;
};
