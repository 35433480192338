import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  codigo: string;
}

export const displayProperties = ['codigo'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
];
