import { FormApi, makeApi } from '../../../api/generic-api';
import { IUiSchema } from '../../../jsonforms/uischema';

const edicaoRecursoProgramaUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Select',
      scope: '#/properties/edicaoLinhaDeAcaoId',
      label: 'Linha de Ação',
      options: {
        required: true,
        apiInstance: makeApi('/edicao-linha-de-acao'),
        filter: {
          handler: (formData: any, listData: any[], api: FormApi) => {
            if (!formData.ano) return Promise.resolve([formData.edicaoLinhaDeAcao]);
            const resultFn = async () => {
              return (await api.getAll({ ano: formData?.ano })).filter(
                (x: any) =>
                  formData.id ||
                  !formData.edicoesRecurso?.some((y: any) => y.edicaoLinhaDeAcao.id === x.id),
              );
            };
            return resultFn();
          },
        },
        displayProperties: ['linhaDeAcao.titulo', 'ano'],
        route: 'edicao-linha-de-acao',
        formatOptionLabel: (option: any) => {
          return `${option.linhaDeAcao.titulo} - ${option.ano}`;
        },
      },
    },
    {
      type: 'Select',
      scope: '#/properties/funcionalProgramaticaId',
      options: {
        required: true,
      },
    },
    {
      type: 'Control',
      scope: '#/properties/descricao',
      label: 'Observação',
    },
    {
      type: 'Control',
      scope: '#/properties/edicaoRecursoProgramaNaturezaDespesa',
      label: 'Natureza da Despesa',
      options: {
        itemLayout: 'SimpleItemLayout',
        detail: {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/naturezaDespesaId',
              label: 'Natureza da Despesa',
              options: {
                filter: {
                  handler: (formData: any, listData: any[], api: FormApi, onLoad, path) => {
                    const filter =
                      formData.edicaoRecursoProgramaNaturezaDespesa?.map(
                        (value: any) => value.naturezaDespesaId,
                      ) || [];

                    return api.getAll().then((data) => {
                      if (!formData.ano) {
                        return data;
                      }
                      return data.filter((x: any) => !filter.includes(x.id));
                    });
                  },
                },
              },
            },
            {
              type: 'MonetaryInput',
              scope: '#/properties/valor',
              label: 'Valor',
            },
          ],
        },
      },
    },
  ],
};

export default edicaoRecursoProgramaUiSchema;
