import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import isNumber from 'lodash/isNumber';
import { FormApi, GenericApi } from '../../api/generic-api';
import { formatCurrency } from '../../helpers/format-currency';
import { CrudContextData, CrudStatesOptions, IUiSchema } from '../../jsonforms/uischema';
import { DocumentoPessoal } from '../../models/documento-pessoal';
import { EdicaoPrograma } from '../../models/edicao-programa';
import {
  DocumentoPessoalEdital,
  Edital,
  IndicadorProducao,
  NaturezaDespesaEdital,
} from '../../models/edital';
import { EditalRubricaMoedaEstrangeira } from '../../models/edital-rubrica-moeda-estrangeira';
import { NaturezaDespesa } from '../../models/natureza-despesa';
import { NivelBolsa } from '../../models/nivel-bolsa';
import { Pergunta, TipoPergunta, TipoResposta } from '../../models/pergunta';
import { PerguntaEdital } from '../../models/pergunta-edital';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { stepHandlerEditalRubrica } from './handlers/step-handler-edital-rubrica';
import perguntaUiSchema from './pergunta/pergunta.uischema';

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

export const formatLabel = (item: any) => {
  const valueInReal = item.valorAtualBolsa / 100;
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valueInReal);

  if (item.cargaHoraria !== null)
    return `${item.nivel} (${item.cargaHoraria}H - ${formattedValue})`;

  return `${item.nivel} (${formattedValue})`;
};

const hideIfTipoEvento = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/restricaoTipoEvento',
    schema: {
      const: false,
    },
  },
} as Rule;

const hideIfFuncaoParticipante = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/restricaoParticipante',
    schema: {
      const: false,
    },
  },
} as Rule;

const hideIfNotMoedaEstrangeira = {
  effect: 'SHOW',
  condition: {
    type: 'AND',
    conditions: [
      {
        scope: '#/properties/temMoedaEstrangeira',
        schema: {
          const: true,
        },
      },
      {},
    ],
  },
} as Rule;

const hideIfNotDefineMonth = {
  effect: 'SHOW',
  condition: {
    type: 'AND',
    conditions: [
      {
        scope: '#/properties/definirDuracaoProjetoEmMeses',
        schema: {
          const: true,
        },
      },
      {},
    ],
  },
} as Rule;

const hideIfNoCoordinatorMinimumAcademicLevelRequired = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/possuiNivelAcademicoMinimoCoordenador',
    schema: {
      const: false,
    },
  },
} as Rule;

const showIfHasQuantityOfScholarshipPerProposal = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/possuiQuantidadeBolsaPorProposta',
    schema: {
      const: true,
    },
  },
} as Rule;

const showIfHasConsultingForm = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/formularioConsultoria',
    schema: {
      type: 'array',
      minItems: 1,
    },
  } as Condition,
} as Rule;

const limparPergunta = (pergunta: Pergunta) => {
  if (!pergunta) return null;

  let multiplaEscolha = undefined;
  let descritiva = undefined;

  if (
    pergunta.tipoResposta === TipoResposta.multiplaEscolha ||
    pergunta.tipoResposta === TipoResposta.multiplaSelecao
  ) {
    multiplaEscolha = pergunta.multiplaEscolha.map((item) => ({
      alternativa: item.alternativa,
      posicao: item.posicao,
    }));
  } else if (pergunta.tipoResposta === TipoResposta.descritiva) {
    descritiva = { minimo: pergunta.descritiva.minimo, maximo: pergunta.descritiva.maximo };
  }

  return {
    ...pergunta,
    perguntaBase: false,
    id: undefined,
    multiplaEscolha,
    descritiva,
  };
};

const editalUiSchema: IUiSchema = {
  type: 'CategorizationMenu',
  elements: [
    {
      type: 'Category',
      label: 'Informações do Edital',
      elements: [
        {
          type: 'Category',
          label: 'Identificação do Edital',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/nome',
              label: 'Título do Edital',
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Restrições',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/definirDuracaoProjetoEmMeses',
                      label: 'Definir a duração do projeto em meses',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/duracaoProjetoEmMeses',
                      label: 'Duração do projeto em meses',
                      options: {
                        isPositiveNumber: true,
                      },
                      rule: hideIfNotDefineMonth,
    
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/coordenadorPodeReceberBolsa',
                      label: 'Coordenador do projeto poderá receber bolsa',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/vinculoInstitucionalObrigatorio',
                      label: 'Vínculo institucional obrigatório',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/pesquisadorSubmeterVariasPropostas',
                      label: 'Pesquisador pode submeter mais de uma proposta',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/restricaoConviteParticipantes',
                      label: 'Será um edital para participantes convidados',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/restricaoParticipante',
                      label: 'Possui restrição de Funções de Participantes',
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/funcaoParticipante',
                      label: 'Funções Participantes',
                      rule: hideIfFuncaoParticipante,
                      options: {
                        selectMultiple: true,
                        route: 'funcao-participante',
                      },
                    },
                   
                  ]
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/possuiNivelAcademicoMinimoCoordenador',
                      label: 'Possui nível acadêmico mínimo do coordenador',
                      options: {
                        handleChange: async (value: any, handler, formData: Edital) => {
                          if (!value) {
                            handler('nivelAcademicoMinimoCoordenadorId', null);
                            handler('nivelAcademicoMinimoCoordenador', null);
                            handler('nivelAcademicoMinimoCoordenadorId-codigo', null);
                          }
                        },
                      },
                      
                      
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/nivelAcademicoMinimoCoordenadorId',
                      label: 'Nível acadêmico do coordenador',
                      rule: hideIfNoCoordinatorMinimumAcademicLevelRequired,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/vinculoEmpregaticioObrigatorio',
                      label: 'Vínculo empregatício obrigatório',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/coordenadorPodeParticiparOutroProjeto',
                      label: 'Coordenador pode participar da equipe de outro projeto',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/obrigatorioLinkLattesCoordenador',
                      label: 'Obrigatório o Coordenador possuir link do Lattes',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/obrigatorioLinkLattesMembros',
                      label: 'Obrigatório membros possuir link do Lattes',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/restricaoTipoEvento',
                      label: 'Possui restrição para Tipos de Eventos',
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/tipoEvento',
                      label: 'Tipo(s) de Evento(s)',
                      rule: hideIfTipoEvento,
                      options: {
                        selectMultiple: true,
                        route: 'tipo-evento',
                      },
                    },
                    
                    
                   
                   
                  ]
                }
              ],
              
            },
          ],
        },
        {
          type: 'Category',
          label: 'Termo de Aceite',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CkEditor',
                  scope: '#/properties/termoDeAceite',
                  options: {
                    ckeditorSize: { height: 400 },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Texto do Edital',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CkEditor',
                  scope: '#/properties/texto',
                  options: {
                    ckeditorSize: { height: 400 },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Abrangência',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'ButtonDataGrid',
                  scope: '#/properties/editalEstado',
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Informações Complementares',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/perguntaInfoId',
                  label: 'Pergunta(s) de Informações Complementares',
                  options: {
                    addEmptyOption: true,
                    handleChange: (values: Pergunta, handler) => {
                      handler('perguntaInfo', limparPergunta(values));
                      handler('perguntaInfoId', values);
                    },
                    filter: {
                      handler: (
                        data: Pergunta,
                        listData: Pergunta[],
                        api: GenericApi<Pergunta>,
                        queryFilterValues: any,
                        path: string,
                      ): Promise<any> => {
                        return api.getAll({ perguntaBase: true }).then((response) => {
                          return response.filter(
                            (x: Pergunta) =>
                              x.tipoPergunta === TipoPergunta.informacoesComplementares,
                          );
                        });
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/informacaoComplementarEditalPergunta',
                  label: 'Informações Complementares',
                  options: {
                    detail: perguntaUiSchema({ withoutTipoPergunta: true }),
                    elementLabelProp: 'pergunta',
                    defaultValue: (formData: any) =>
                      formData.perguntaInfo ?? {
                        tipoPergunta: TipoPergunta.informacoesComplementares,
                        descritiva: {
                          minimo: 0,
                          maximo: 0,
                        },
                        perguntaBase: false,
                        obrigatoria: false,
                      },
                    showSortButtons: true,
                    handleChange: async (value: any, handler, formData: Edital, ctx, path) => {
                      const formDataPergunta =
                        path.split('.').reduce((acc, part) => acc && acc[part], formData) || [];

                      const allHavePosition =
                        Array.isArray(formDataPergunta) &&
                        formDataPergunta.every((item: Pergunta) => item.posicao !== undefined);

                      if (!allHavePosition) {
                        const updatedFormDataPergunta = allHavePosition
                          ? formDataPergunta
                          : formDataPergunta.map((item: Pergunta, index: number) => ({
                              ...item,
                              posicao: index + 1,
                            }));

                        handler(path, updatedFormDataPergunta);
                      }
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Anexos',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'File',
                  scope: '#/properties/editalAnexo',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Cronograma',
      elements: [
        {
          type: 'Category',
          label: 'Período de Submissão',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/chamadaEdital',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/inicio',
                      label: 'Data e Horário Inicial de Submissão',
                      options: {
                        format: 'date-time',
                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                        ampm: false,
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/termino',
                      label: 'Data e Horário Final de Submissão',
                      options: {
                        format: 'date-time',
                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                        ampm: false,
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/chamadaSuspensa',
                      label: 'Suspender Chamada',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Formulário de Consultoria',
          rule: showIfHasConsultingForm,
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioConsultoria',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                disabled: true,
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/titulo',
                      label: 'Título',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/dataInicio',
                          label: 'Início',
                          options: {
                            format: 'date-time',
                            dateTimeFormat: 'DD/MM/YYYY',
                            ampm: false,
                          },
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/dataTermino',
                          label: 'Término',
                          options: {
                            format: 'date-time',
                            dateTimeFormat: 'DD/MM/YYYY',
                            ampm: false,
                          },
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/dataExpiracaoConvite',
                          label: 'Expiração do Convite',
                          options: {
                            format: 'date-time',
                            dateTimeFormat: 'DD/MM/YYYY',
                            ampm: false,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Orçamento',
      elements: [
        {
          type: 'Category',
          label: 'Programa',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/programaId',
                  label: 'Programa',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/naturezaDespesaEdital',
                  label: 'Natureza da Despesa',
                  options: {
                    headCells: [
                      {
                        label: 'Natureza da Despesa',
                        field: (row: NaturezaDespesaEdital) => row.naturezaDespesa?.nome,
                      },
                      {
                        label: 'Valor',
                        field: (row: NaturezaDespesaEdital) =>
                          row.valor ? formatCurrency(row.valor) : formatCurrency(0),
                      },
                      {
                        label: 'Edição do Programa',
                        field: (row: NaturezaDespesaEdital) => row.edicaoPrograma?.ano,
                      },
                    ],
                    itemLayout: 'SimpleItemLayout',
                    detail: {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/naturezaDespesaId',
                          label: 'Natureza da Despesa',
                          options: {
                            handleChange: (
                              item: NaturezaDespesa,
                              handler: (path: string, value: NaturezaDespesa) => void,
                              formData: Edital,
                              ctx?: CrudContextData,
                              path?: string,
                            ) => {
                              const dataPath = path?.split?.('.').slice(0, -1).join('.');
                              handler(`${dataPath}.naturezaDespesa`, item);
                            },
                          },
                        },
                        {
                          type: 'MonetaryInput',
                          scope: '#/properties/valor',
                          label: 'Valor',
                          options: {
                            isPositiveNumber: true,
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/edicaoProgramaId',
                          label: 'Edição',
                          options: {
                            filter: {
                              formFields: ['programaId'],
                              handler: (
                                formData: Edital,
                                listData: EdicaoPrograma[],
                                api: FormApi,
                              ) => {
                                if (!formData.programaId) return Promise.resolve([]);
                                return api.getAll({ programaId: formData?.programaId });
                              },
                            },
                            handleChange: (
                              item: NaturezaDespesa,
                              handler: (path: string, value: NaturezaDespesa) => void,
                              formData: Edital,
                              ctx?: CrudContextData,
                              path?: string,
                            ) => {
                              const dataPath = path?.split?.('.').slice(0, -1).join('.');
                              handler(`${dataPath}.edicaoPrograma`, item);
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Rubricas',
          options: {
            stepHandler: {
              handler: stepHandlerEditalRubrica,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/editalRubrica',
              label: '',
              options: {
                elementLabelProp: 'tipoEditalRubrica',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/tipoEditalRubrica',
                          label: 'Rubrica',
                          options: {
                            handleChange: async (values: any, handler, formData, ctx, path) =>
                              handler(`${path}`, values),
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/naturezaDespesaId',
                          label: 'Natureza Despesa',
                        },
                      ],
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/temJustificativaObrigatoria',
                          label: 'Justificativa Obrigatória',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/temJustificativaGlobal',
                          label: 'Justificativa Global',
                        },
                      ],
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/temMoedaEstrangeira',
                          label: 'Moeda estrangeira',
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/editalRubricaMoedaEstrangeira',
                          label: 'Moeda estrangeira',
                          rule: hideIfNotMoedaEstrangeira,
                          options: {
                            selectMultiple: true,
                            route: 'moeda-estrangeira',
                            handleChange: async (values: any, handler, formData, ctx, path) => {
                              const mappedValues = values?.map((value: any) => ({
                                moedaEstrangeiraId: value?.value,
                              }));
                              handler(`${path}`, mappedValues);
                            },
                            optionsComparator: (option: any, item: EditalRubricaMoedaEstrangeira) =>
                              option.value === item?.moedaEstrangeiraId,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Faixas de Financiamento',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/faixaFinanciamento',
              label: '',
              options: {
                elementLabelProp: 'nome',
                defaultValue: (formData: Edital) => {
                  if (formData?.faixaFinanciamento?.length > 0) {
                    const valorMinimo = formData.faixaFinanciamento.at(-1)?.valorMaximo + 1;
                    return { valorMinimo };
                  }
                },
                beforeCreate: (formData: Edital, path: string) => {
                  const crrFaixa = formData?.faixaFinanciamento?.at?.(-1);
                  return crrFaixa && isNumber(crrFaixa?.valorMaximo);
                },
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/nome',
                          label: 'Nome da Faixa',
                        },
                      ],
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'MonetaryInput',
                          scope: '#/properties/valorMinimo',
                          label: 'Valor Mínimo',
                        },
                        {
                          type: 'MonetaryInput',
                          scope: '#/properties/valorMaximo',
                          label: 'Valor Máximo',
                          options: {
                            handleChange: async (value: number, handler, formData, ctx, path) => {
                              const index = Number(path?.split?.('.')?.at?.(-2));

                              const nextPath = path
                                ?.replace?.(index.toString(), (index + 1).toString())
                                .split('.')
                                .slice(0, -1)
                                .join('.');

                              const nextValue = formData?.faixaFinanciamento?.[index + 1];

                              if (nextValue) {
                                handler(`${nextPath}.valorMinimo`, value + 1);
                              }
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/observacao',
                          label: 'Observação',
                          options: {
                            required: false,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Documentos',
      elements: [
        {
          type: 'Category',
          label: 'Documentos da Proposta',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/documentoPropostaEdital',
              label: '',
              options: {
                elementLabelProp: 'nome',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/nome',
                          label: 'Nome do Documento da Proposta',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/descricao',
                          label: 'Descrição do Documento da Proposta',
                        },
                      ],
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/formatoArquivo',
                          label: 'Formato do Arquivo',
                        },
                        {
                          type: 'Number',
                          scope: '#/properties/tamanhoArquivo',
                          label: 'Tamanho Máximo do Arquivo',
                        },
                      ],
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/arquivoSubmissaoObrigatoria',
                      label: 'Arquivo de submissão obrigatória',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Documentos Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/documentoPessoalEdital',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/documentoPessoalId',
                      label: 'Nome do Documento',
                      options: {
                        filter: {
                          handler: (
                            { documentoPessoalEdital }: Edital,
                            listData: DocumentoPessoalEdital[],
                            api: GenericApi<DocumentoPessoal>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<any> => {
                            const index = Number(path?.split?.('.')?.at?.(-2));
                            if (Number.isNaN(index)) return Promise.resolve([]);

                            return api.getAll([]).then((response) => {
                              return response?.filter((x: DocumentoPessoal) => {
                                if (!documentoPessoalEdital) return true;
                                return documentoPessoalEdital
                                  ?.filter((_: any, i: number) => i !== index)
                                  ?.every(
                                    (y: DocumentoPessoalEdital) => y.documentoPessoalId !== x.id,
                                  );
                              });
                            });
                          },
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/obrigatorio',
                      label: 'Arquivo de submissão obrigatório',
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Perguntas',
      elements: [
        {
          type: 'Category',
          label: 'Descrição do Projeto',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/perguntaId',
                  label: 'Pergunta(s) de Descrição do Projeto',
                  options: {
                    addEmptyOption: true,
                    handleChange: (values: Pergunta, handler) => {
                      handler('pergunta', limparPergunta(values));
                      handler('perguntaId', values);
                    },
                    filter: {
                      handler: (
                        data: Pergunta,
                        listData: Pergunta[],
                        api: GenericApi<Pergunta>,
                        queryFilterValues: any,
                        path: string,
                      ): Promise<Pergunta[]> => {
                        return api.getAll({ perguntaBase: true }).then((response) => {
                          return response.filter(
                            (x: Pergunta) => x.tipoPergunta === TipoPergunta.descricaoProjeto,
                          );
                        });
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/editalPergunta',
                  label: 'Descrição do Projeto',
                  options: {
                    detail: perguntaUiSchema({ withoutTipoPergunta: true }),
                    elementLabelProp: 'pergunta',
                    defaultValue: (formData: any | Pergunta) =>
                      formData.pergunta ?? {
                        tipoPergunta: TipoPergunta.descricaoProjeto,
                        descritiva: {
                          minimo: 0,
                          maximo: 0,
                        },
                        perguntaBase: false,
                        obrigatoria: false,
                      },
                    showSortButtons: true,
                    handleChange: async (value: PerguntaEdital, handler, formData: Edital) => {
                      const allHavePosition = formData.editalPergunta.every(
                        (item) => item.posicao !== undefined,
                      );
                      if (!allHavePosition) {
                        const updatedEditalPergunta = allHavePosition
                          ? formData.editalPergunta
                          : formData.editalPergunta.map((item: Pergunta, index: number) => ({
                              ...item,
                              posicao: index + 1,
                            }));

                        handler('editalPergunta', updatedEditalPergunta);
                      }
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Indicadores de Produção',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              url: '/edital',
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/indicadorProducaoEdital',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/indicadorProducaoId',
                      label: 'Indicador de Produção',
                      options: {
                        filter: {
                          handler(
                            formData: Edital,
                            listData: IndicadorProducao[],
                            api: GenericApi<IndicadorProducao>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<any> {
                            const { indicadorProducaoEdital } = formData;
                            const simpleItemIndex = Number(path?.split?.('.')?.at?.(-2));

                            return api
                              .getAll()
                              .then((response) =>
                                response.filter(
                                  (indicadorProducao: IndicadorProducao) =>
                                    !indicadorProducaoEdital?.find(
                                      (item, index) =>
                                        item?.indicadorProducaoId === indicadorProducao?.id &&
                                        index !== simpleItemIndex,
                                    ),
                                ),
                              );
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Bolsas do Edital',
      elements: [
        {
          type: 'Category',
          label: 'Bolsas',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData) => {
                const { clearForm, showSuccess, updateCrudStates } = ctx;
                createResource(ctx, '/edital');
                clearForm();
                updateCrudStates(CrudStatesOptions.LIST);
                showSuccess('Editado com sucesso.');
                return true;
              },
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/bolsaEdital',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/modalidadeBolsaId',
                      label: 'Modalidade da Bolsa',
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/nivelBolsaId',
                      label: 'Nível da Bolsa',
                      options: {
                        formatOptionLabel: formatLabel,
                        visibleHandler: visibleIfHasFilter,
                        filter: {
                          formFields: ['modalidadeBolsaId'],
                          handler: (
                            { bolsaEdital }: Edital,
                            listData: NivelBolsa[],
                            api: GenericApi<NivelBolsa>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<NivelBolsa[]> => {
                            if (!queryFilterValues?.[0]?.value) return Promise.resolve([]);
                            const index = Number(path?.split?.('.')?.at?.(-2));
                            if (Number.isNaN(index)) return Promise.resolve([]);
                            return api
                              .getAll({ modalidadeBolsaId: queryFilterValues?.[0]?.value })
                              .then((response) =>
                                response.filter((x: any) =>
                                  bolsaEdital
                                    .filter((_: any, i: number) => i !== index)
                                    .every((y: any) => y.nivelBolsaId !== x.id),
                                ),
                              );
                          },
                        },
                      },
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/possuiQuantidadeBolsaPorProposta',
                          label: 'Possui quantidade de bolsa por proposta',
                          options: {
                            handleChange: async (value: any, handler, formData, ctx, path) => {
                              if (!value) {
                                handler(
                                  `${path}`.replace(
                                    'possuiQuantidadeBolsaPorProposta',
                                    'quantidadeBolsaPorProposta',
                                  ),
                                  null,
                                );
                              }
                            },
                          },
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/quantidadeBolsaPorProposta',
                          label: 'Quantidade de bolsa por proposta',
                          rule: showIfHasQuantityOfScholarshipPerProposal,
                          options: {
                            isPositiveNumber: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    route: '/edital',
  },
};

export default editalUiSchema;
