import React from 'react';
import { useEffect, useState } from 'react';
import Footer from '../../components/layout/footer';
import { AvisosHome } from '../avisos/avisos-home';
import { ConvitesHome } from '../convites/convites-home';
import { EditalHome } from '../edital/lista-editais/edital-home';
import { StyledContainer, StyledItem } from './style/home';
import { PropostaHome } from '../proposta/lista-propostas/proposta-home';

function Home() {
  const [validItems, setValidItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const items = [
      <ConvitesHome key='convites' onValidate={updateItems(0)} />,
      <EditalHome key='edital' onValidate={updateItems(1)} />,
      <PropostaHome key='proposta' onValidate={updateItems(2)} />,
    ];
    setValidItems(items);
  }, []);

  const updateItems = (index: number) => (isValid: boolean) => {
    if (!isValid) {
      setValidItems((prevItems) => {
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
      });
    }
  };

  const splitLength = validItems.length;

  return (
    <>
      <AvisosHome />
      <StyledContainer>
        {validItems.map((item, index) => {
          const isFullWidth = index === 0 && splitLength % 2 !== 0;
          return (
            <StyledItem key={index} isFullWidth={isFullWidth}>
              {React.cloneElement(item, { splitLength })}
            </StyledItem>
          );
        })}
      </StyledContainer>
      <Footer />
    </>
  );
}

export default Home;
