import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { NavigateFunction } from 'react-router-dom';
import { FormApi, GenericApi } from '../../api/generic-api';
import { circleFlagBuilder } from '../../components/circle-flag';
import { formatCurrency } from '../../helpers/format-currency';
import { formatFloat } from '../../helpers/format-float';
import {
  CrudContextData,
  hideIfBrasil,
  IFieldsConfig,
  IHandleChange,
  IUiSchema,
  RequiredType,
  showIfBrasil,
} from '../../jsonforms/uischema';
import { BolsaProposta } from '../../models/bolsa-proposta';
import { DiariaProposta } from '../../models/diaria-proposta';
import { BolsaEdital, FaixaFinanciamentoEdital } from '../../models/edital';
import { EditalRubrica, TipoEditalRubrica } from '../../models/edital-rubrica';
import { Estado } from '../../models/estado';
import { ModalidadeBolsa } from '../../models/modalidade-bolsa';
import { MoedaEstrangeira } from '../../models/moeda-estrangeira';
import { NivelBolsa } from '../../models/nivel-bolsa';
import { CodigoPais, Pais } from '../../models/pais';
import { PessoalProposta } from '../../models/pessoal-proposta';
import {
  EncargoProposta,
  HospedagemAlimentacaoProposta,
  MaterialConsumoProposta,
  MaterialPermanenteProposta,
  PassagemProposta,
  Proposta,
  ServicoTerceiroProposta,
  tipoOrigemI18N,
  tipoPassagemPropostaI18N,
  tipoPessoaI18N,
  TrechoPassagemProposta,
  trechoPassagemPropostaI18N,
} from '../../models/proposta';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { formatLabel } from '../edital/edital.uischema';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { stepHandlerAbrangencia } from './handlers/step-handler-abrangencia';
import { stepHandlerDadosCoordenador } from './handlers/step-handler-dados-coordenador';
import { stepHandlerDescricao } from './handlers/step-handler-descricao';
import { stepHandlerIndicadores } from './handlers/step-handler-indicadores';
import { IbgeApi, makeIbgeApi } from './ibge.api';
import { buildTableLayoutHeader } from './table-layout-header';
import { getOrcamentoConsolidadoTable } from './util/calcular-rubricas';
import { getRubricasTrimestralTable } from './util/calcular-rubricas-trimestral';
import { Instituicao } from '../../models/instituicao';
import {
  setLabelWhenChangeDateFuncao,
  setLabelWhenChangeDateServico,
} from '../editar-perfil/handlers/set-label-when-change-date';
import { areaDeConhecimentoHandleChange } from '../editar-perfil/editar-perfil.uischema';

const showIfPossuiVinculoInstitucional: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/criadoPor/properties/possuiVinculoInstitucional',
    schema: {
      const: true,
    },
  } as Condition,
};

const showIfBrasilDadosCoordenador = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/criadoPor/properties/pais/properties/codigo',
    schema: {
      const: CodigoPais.brasil,
    },
  } as Condition,
} as Rule;

const hideIfBrasilDadosCoordenador = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/criadoPor/properties/pais/properties/codigo',
    schema: {
      const: CodigoPais.brasil,
    },
  } as Condition,
} as Rule;

const moedaHandleChange: IHandleChange = (
  item: MoedaEstrangeira,
  handler: (path: string, value: any) => void,
  formData: Proposta,
  ctx,
  path: string,
) => {
  //Adiciona o objeto inteiro no formulário provisório
  handler(`${path}`.replace('moedaEstrangeiraId', 'moedaEstrangeira'), item);
};

const requiredIfEmMoedaEstrangeira =
  (customPath: string): RequiredType =>
  (ctx: CrudContextData) => {
    const { formData } = ctx;
    return formData?.[customPath]?.emMoedaEstrangeira;
  };

const requiredIfContrapartida =
  (customPath: string): RequiredType =>
  (ctx: CrudContextData) => {
    const { formData } = ctx;
    return formData?.[customPath]?.contrapartida;
  };

const requiredIfTrechoNacional = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData?.['passagemPropostaUnsaved']?.trecho === TrechoPassagemProposta.nacional;
};

const requiredIfTrechoInternacional = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData?.['passagemPropostaUnsaved']?.trecho === TrechoPassagemProposta.internacional;
};

const requiredIfBrasil = (customPath: string) => (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData[customPath]?.['paisId-codigo'] === 'brasil';
};

const requiredIfNotBrasil = (customPath: string) => (ctx: CrudContextData) => {
  const { formData } = ctx;
  return (
    (formData[customPath]?.['paisId-codigo'] &&
      formData[customPath]?.['paisId-codigo'] !== 'brasil') ||
    false
  );
};

const formatLabelFaixaFinanciamento = (item: any) => {
  const valorMinimoInReal = item.valorMinimo / 100;
  const valormaximoInReal = item.valorMaximo / 100;

  const formattedValorMinimo = formatCurrency(valorMinimoInReal);
  const formattedValorMaximo = formatCurrency(valormaximoInReal);

  return `${item.nome} (${formattedValorMinimo} - ${formattedValorMaximo})`;
};

const disableTituloDoEdital = {
  effect: 'DISABLE',
  condition: {},
} as Rule;

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const showIfNationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'NACIONAL',
    },
  },
} as Rule;

const showIfInternationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'INTERNACIONAL',
    },
  },
} as Rule;

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const apiInstanceIbge = makeIbgeApi();

const showIfContrapartida = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/contrapartida',
    schema: {
      const: true,
    },
  },
} as Rule;

const showIfForeignCurrency = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/emMoedaEstrangeira',
    schema: {
      const: true,
    },
  },
} as Rule;

const createShowRuleForEditalRubrica = (tipoEditalRubricaValue: TipoEditalRubrica): Rule => {
  return {
    effect: 'SHOW',
    condition: {
      scope: '#/properties/edital',
      schema: {
        properties: {
          editalRubrica: {
            type: 'array',
            contains: {
              properties: {
                tipoEditalRubrica: {
                  enum: [tipoEditalRubricaValue],
                },
              },
            },
          },
        },
      },
    },
  } as Rule;
};

const propostaUiSchema: IUiSchema = {
  type: 'CategorizationMenu',
  elements: [
    {
      type: 'Category',
      label: 'Caracterização da Proposta',
      elements: [
        {
          type: 'Category',
          label: 'Informações Iniciais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/editalId',
              rule: {
                effect: 'HIDE',
                condition: {},
              } as Rule,
            },
            {
              type: 'Control',
              scope: '#/properties/edital/properties/nome',
              label: 'Título do Edital',
              rule: disableTituloDoEdital,
            },
            {
              type: 'Control',
              scope: '#/properties/tituloDoProjeto',
              label: 'Título do Projeto',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/duracao',
              label: 'Duração do Projeto em Meses',
              options: {
                required: true,
                isPositiveNumber: true,
              },
            },
            {
              type: 'Select',
              scope: '#/properties/instituicaoExecutoraId',
              label: 'Instituição Executora',
            },
            {
              type: 'Select',
              scope: '#/properties/unidadeExecutoraId',
              label: 'Unidade Executora',
              options: {
                filter: {
                  formFields: ['instituicaoExecutoraId'],
                  handler: (formData: Proposta, listData: any[], api: FormApi) => {
                    if (!formData.instituicaoExecutoraId) return Promise.resolve(listData);
                    return api.getAll({ instituicaoId: formData?.instituicaoExecutoraId });
                  },
                },
              },
            },
            {
              type: 'Control',
              scope: '#/properties/areaDeConhecimento',
              label: 'Áreas de Conhecimento da Proposta',
              options: {
                label: 'Área de Conhecimento',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/grandeAreaId',
                      label: 'Grande Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['grandeAreaNome'],
                        filter: {
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                          ) => {
                            return api.buscarGrandesAreas();
                          },
                        },
                        disableHandler: disableIfNoOptions,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/areaId',
                      label: 'Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['areaNome'],
                        filter: {
                          formFields: ['grandeAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/subAreaId',
                      label: 'Sub-área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['subAreaNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['areaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/especialidadeId',
                      label: 'Especialidade',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['especialidadeNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['subAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarEspecialidadePorSubAreaId(
                              queryFilterValues?.[0].value,
                            );
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Informações Complementares',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaInformacaoComplementar',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/informacaoComplementarEditalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Abrangência',
          options: {
            stepHandler: {
              handler: stepHandlerAbrangencia,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/abrangencia',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/estadoId',
                          label: 'Estado',
                          options: {
                            displayProperties: ['nome'],
                            filter: {
                              handler(formData, listData, api, queryFilterValues, path) {
                                const editalId = formData?.editalId;
                                if (!editalId) return Promise.resolve([]);

                                const index = Number(path?.split?.('.')?.at?.(-2));
                                if (Number.isNaN(index)) return Promise.resolve([]);

                                const { abrangencia } = formData;

                                return api.getAll({ editalId: editalId }).then((response: any) => {
                                  return response?.filter((x: any) => {
                                    if (!abrangencia) return true;
                                    return abrangencia
                                      ?.filter((_: any, i: number) => i !== index)
                                      ?.every((y: any) => y.estadoId !== x.id);
                                  });
                                });
                              },
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/abrangenciaMunicipio',
                          options: {
                            selectMultiple: true,
                            displayProperties: ['nome'],
                            apiInstance: apiInstanceIbge,
                            filter: {
                              formFields: ['estadoId'],
                              handler: (
                                formData: Proposta,
                                listData: any[],
                                api: IbgeApi,
                                queryFilterValues: any,
                              ) => {
                                if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                              },
                            },
                            handleChange: async (values: any, handler, formData, ctx, path) => {
                              const mappedValues = values?.map((value: any) => {
                                return {
                                  municipio: value?.data?.nome,
                                };
                              });
                              handler(`${path}`, mappedValues);
                            },
                            optionsComparator: (option: any, item: any) =>
                              option?.data?.nome === item?.municipio,
                            visibleHandler: visibleIfHasFilter,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Dados do Coordenador',
      elements: [
        {
          type: 'Category',
          label: 'Dados Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/nome',
                      label: 'Nome',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/nomeSocial',
                      label: 'Nome social',
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/criadoPor/properties/dataNascimento',
                      label: 'Data de nascimento',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/email',
                      label: 'E-mail',
                      options: {
                        mask: 'email',
                        disabled: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/racaCorId',
                      label: 'Raça/Cor',
                      options: {
                        route: 'raca-cor',
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/paisId',
                      label: 'País',
                      options: {
                        route: 'pais',
                        iconBuilder: circleFlagBuilder,
                        handleChange: (
                          item: Pais,
                          handler: (path: string, value: any) => void,
                          formData: Proposta,
                          ctx?: CrudContextData,
                          path?: string,
                        ) => {
                          //Coloca objeto inteiro para ser usado na RULE
                          handler(`${path}`.replace('paisId', 'pais'), item);

                          //Limpa o documento se o país não for Brasil
                          if (item?.codigo !== CodigoPais.brasil) {
                            handler(`${path}`.replace('paisId', 'documento'), null);
                            handler(`${path}`.replace('paisId', 'endereco'), null);
                          }
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/documento',
                      label: 'CPF',
                      rule: showIfBrasilDadosCoordenador,
                      options: {
                        mask: '999.999.999-99',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Endereço',
          options: {
            additionalPaths: ['paisId'],
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Cep',
                      scope: '#/properties/criadoPor/properties/endereco/properties/cep',
                      label: 'CEP',
                      rule: showIfBrasilDadosCoordenador,
                      options: {
                        mask: '99999-999',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/cep',
                      label: 'ZipCode',
                      rule: hideIfBrasilDadosCoordenador,
                      options: {
                        mask: '999999999',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/logradouro',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/numero',
                      label: 'Número',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/complemento',
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/bairro',
                      label: 'Bairro',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/municipio',
                      label: 'Município',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/estado',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/estado',
                      label: 'Estado/Região',
                      rule: hideIfBrasilDadosCoordenador,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Dados Acadêmicos',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/instituicaoId',
                      label: 'Instituição',
                      options: {
                        route: 'instituicao',
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/unidadeId',
                      label: 'Unidade',
                      options: {
                        route: 'unidade',
                        filter: {
                          formFields: ['instituicaoId'],
                          handler: (
                            formData: Proposta,
                            listData: Instituicao[],
                            api: any,
                            queryFilterValues: any,
                            path: string,
                          ) => {
                            if (!queryFilterValues[0].value) return Promise.resolve([]);
                            return api.getAll({ instituicaoId: queryFilterValues[0].value });
                          },
                        },
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/nivelAcademicoId',
                      label: 'Nível Acadêmico',
                      options: {
                        route: 'nivel-academico',
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/lattes',
                      label: 'Currículo Lattes',
                    },
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/linkedin',
                      label: 'Linkedin',
                    },
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/interesseConsultor',
                      label: 'Interesse em ser consultor?',
                    },
                  ],
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/criadoPor/properties/areaDeConhecimento',
              label: 'Suas Áreas de Conhecimento',
              options: {
                label: 'Área de Conhecimento',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/grandeAreaId',
                      label: 'Grande área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['grandeAreaNome'],
                        filter: {
                          handler: (formData: any, listData: any[], api: AreaDeConhecimentoApi) => {
                            return api.buscarGrandesAreas();
                          },
                        },
                        disableHandler: disableIfNoOptions,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/areaId',
                      label: 'Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['areaNome'],
                        filter: {
                          formFields: ['grandeAreaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/subAreaId',
                      label: 'Sub-área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['subAreaNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['areaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/especialidadeId',
                      label: 'Especialidade',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['especialidadeNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['subAreaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarEspecialidadePorSubAreaId(
                              queryFilterValues?.[0].value,
                            );
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Dados Profissionais',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Boolean',
                  scope: '#/properties/criadoPor/properties/possuiVinculoInstitucional',
                  label: 'Possuo vínculo institucional',
                },
                {
                  type: 'VerticalLayout',
                  rule: showIfPossuiVinculoInstitucional,
                  elements: [
                    {
                      type: 'Select',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/tipoVinculoInstitucionalId',
                      label: 'Tipo de vínculo',
                      options: {
                        route: 'tipo-vinculo-institucional',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData.criadoPor?.possuiVinculoInstitucional;
                        },
                      },
                    },
                    {
                      type: 'Boolean',
                      scope: '#/properties/criadoPor/properties/possuiVinculoEmpregaticio',
                      label: 'Possuo vínculo empregatício',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Date',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/inicioServico',
                          label: 'Início de Serviço',
                          options: {
                            dateFormat: 'DD/MM/YYYY',
                            required: (ctx: CrudContextData) => {
                              const { formData } = ctx;
                              return formData.criadoPor?.possuiVinculoEmpregaticio;
                            },
                          },
                        },
                        {
                          type: 'Label',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/tempoServico',
                          label: 'Tempo de Serviço',
                          options: {
                            formatOptionLabel: setLabelWhenChangeDateServico,
                            componentProps: {
                              variant: 'h5',
                              align: 'left',
                              sx: {
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              },
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'Select',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/regimeTrabalhoId',
                      label: 'Regime de Trabalho',
                      options: {
                        route: 'regime-trabalho',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData.criadoPor?.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/funcao',
                      label: 'Função/Cargo',
                      options: {
                        required: (cxt: CrudContextData) => {
                          const { formData } = cxt;
                          return formData.criadoPor?.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Date',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/inicioFuncao',
                          label: 'Início de Função/Cargo',
                          options: {
                            dateFormat: 'DD/MM/YYYY',
                            required: (cxt: CrudContextData) => {
                              const { formData } = cxt;
                              return formData.criadoPor?.possuiVinculoEmpregaticio;
                            },
                          },
                        },
                        {
                          type: 'Label',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/tempoFuncao',
                          label: 'Tempo de Função/Cargo',
                          options: {
                            formatOptionLabel: setLabelWhenChangeDateFuncao,
                            componentProps: {
                              variant: 'h5',
                              align: 'left',
                              sx: {
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              },
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexos',
      elements: [
        {
          type: 'Category',
          label: 'Documentos Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos Pessoais',
                  type: 'File',
                  options: {
                    categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                    route: 'usuario/usuario-anexo',
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                    withoutFk: true,
                    filterByCategories: true,
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Documentos da Proposta',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos da Proposta',
                  type: 'File',
                  scope: '#/properties/documentoPropostaAnexo',
                  options: {
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Plano de Trabalho',
      elements: [
        {
          type: 'Category',
          label: 'Descrição',
          options: {
            stepHandler: {
              handler: stepHandlerDescricao,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaDescritiva',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/editalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Indicadores de Produção',
          options: {
            stepHandler: {
              handler: stepHandlerIndicadores,
            },
          },
          elements: [
            {
              type: 'IndicadorProducaoProposta',
              scope: '#/properties/producaoProposta',
              label: 'Indicadores de Produção',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/indicadorProducaoEdital',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Membros',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CustomDataTableUserSelect',
                  scope: '#/properties/propostaConviteMembro',
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Atividades',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/propostaAtividade',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/titulo',
                      label: 'Título',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/mesInicio',
                          label: 'Mês de Início',
                          options: {
                            oneOf: (formData: Proposta) => {
                              return Array.from({ length: formData?.duracao }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1,
                              }));
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/duracao',
                          label: 'Duração (Em meses)',
                          options: {
                            formFields: (formData, path) => {
                              return [
                                formData?.propostaAtividade?.at(path.split('.').at(-2))?.mesInicio,
                              ];
                            },
                            oneOf: (formData: Proposta, path: string) => {
                              const { duracao } = formData;
                              const mesInicio = formData?.propostaAtividade?.at(
                                parseInt(path.split('.').at(-2)),
                              )?.mesInicio;
                              if (!duracao || !mesInicio) return [];

                              const mesesRestantes = duracao - mesInicio + 1;

                              return Array.from({ length: mesesRestantes }, (_, i) => {
                                const quantidadeMeses = i + 1;
                                return {
                                  title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                  const: quantidadeMeses,
                                };
                              });
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/cargaHorariaSemanal',
                          label: 'Carga Horária Semanal',
                          options: {
                            oneOf: () => {
                              return Array.from({ length: 60 }, (_, i) => {
                                const horas = i + 1;
                                return {
                                  title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                  const: horas,
                                };
                              });
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'CustomSelectMembro',
                      scope: '#/properties/membroResponsavelId',
                      label: 'Responsável',
                    },
                    {
                      type: 'CustomMultipleSelectMembro',
                      scope: '#/properties/propostaAtividadeMembro',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Orçamento',
          elements: [
            {
              type: 'Category',
              label: 'Faixa de Financiamento',
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                  callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                    createResource(ctx, '/proposta', fieldsConfig),
                },
              },
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/faixaFinanciamentoEditalId',
                      label: '',
                      options: {
                        required: true,
                        displayProperties: ['nome', 'valorMinimo', 'valorMaximo'],
                        filter: {
                          handler: (
                            formData: FaixaFinanciamentoEdital,
                            listData: FaixaFinanciamentoEdital[],
                            api: GenericApi<FaixaFinanciamentoEdital>,
                          ) => {
                            if (!formData.editalId) return Promise.resolve(listData);
                            return api.getAll({
                              editalId: formData?.editalId,
                            });
                          },
                        },
                        formatOptionLabel: formatLabelFaixaFinanciamento,
                      },
                    },
                  ],
                },
              ],
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital/properties/faixaFinanciamento',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                } as Condition,
              } as Rule,
            },
            {
              type: 'Category',
              label: 'Diárias',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.diarias),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/diariaProposta',
                  label: '',
                  options: {
                    label: 'Diária',
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    defaultValue: (formData: DiariaProposta) => ({ paisId: 1 }),
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Localidade',
                        field: (row: DiariaProposta) => {
                          return row.pais?.codigo === CodigoPais.brasil
                            ? `${row.pais?.nomePt}-${row.estado?.nome}-${row.municipio}`
                            : `${row.pais?.nomePt}-${row.estadoRegiao}`;
                        },
                      },
                      {
                        label: 'Número de Diárias	',
                        field: (row: DiariaProposta) =>
                          row.numeroDiaria ? formatFloat(row.numeroDiaria, 'pt-BR', 0, 1) : '-',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: DiariaProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: DiariaProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: DiariaProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: DiariaProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: DiariaProposta) =>
                          row.valorTotal ? formatFloat(row.valorTotal) : '-',
                      },
                    ],
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  if (item?.codigo === CodigoPais.brasil) {
                                    handler('diariaPropostaUnsaved.estadoRegiao', null);
                                  }

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('diariaPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/estadoId',
                              label: 'Estado',
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfBrasil('diariaPropostaUnsaved'),
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(`${path}`.replace('estadoId', 'estado'), item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/municipio',
                              label: 'Município',
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                required: requiredIfBrasil('diariaPropostaUnsaved'),
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                          rule: showIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  if (item?.codigo !== CodigoPais.brasil) {
                                    handler('diariaPropostaUnsaved.estadoId', null);
                                    handler('diariaPropostaUnsaved.municipio', null);
                                  }

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('diariaPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/estadoRegiao',
                              label: 'Estado/Região',
                              options: {
                                required: requiredIfNotBrasil('diariaPropostaUnsaved'),
                              },
                            },
                          ],
                          rule: hideIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Float',
                              scope: '#/properties/numeroDiaria',
                              label: 'Número de Diárias',
                              options: {
                                required: true,
                                numberStep: 0.5,
                                isPositiveNumber: true,
                                handleChange: (
                                  numeroDiaria: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const custoUnitario =
                                    formData?.['diariaPropostaUnsaved']?.custoUnitario;

                                  if (!numeroDiaria && !custoUnitario) {
                                    handler(`${path}`.replace('numeroDiaria', 'valorTotal'), null);
                                    return;
                                  }

                                  handler(
                                    `${path}`.replace('numeroDiaria', 'valorTotal'),
                                    (custoUnitario * numeroDiaria) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const numeroDiaria =
                                    formData?.['diariaPropostaUnsaved']?.numeroDiaria;

                                  if (!numeroDiaria && !custoUnitario) {
                                    handler(`${path}`.replace('numeroDiaria', 'valorTotal'), null);
                                    return;
                                  }

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    (custoUnitario * numeroDiaria) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.diarias &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },
                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira('diariaPropostaUnsaved'),
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    const editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.diarias,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('diariaPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('diariaPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Material de Consumo',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.materialDeConsumo),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/materialConsumoProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Quantidade',
                        field: (row: MaterialConsumoProposta) =>
                          row.quantidade ? formatFloat(row.quantidade, 'pt-BR', 0, 0) : '-',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: MaterialConsumoProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: MaterialConsumoProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: MaterialConsumoProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: DiariaProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: MaterialConsumoProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Material de Consumo',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/unidadeMedida',
                              label: 'Unidade de Medida',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'Float',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['materialConsumoPropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;
                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    (custoUnitario * quantidade) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['materialConsumoPropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    (custoUnitario * quantidade) / 100,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.materialDeConsumo &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira(
                                  'materialConsumoPropostaUnsaved',
                                ),
                                handleChange: moedaHandleChange,
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    const editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.materialDeConsumo,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('materialConsumoPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/entidade',
                          label: 'Entidade',
                          rule: showIfContrapartida,
                          options: {
                            required: requiredIfContrapartida('materialConsumoPropostaUnsaved'),
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Material Permanente',
              rule: createShowRuleForEditalRubrica(
                TipoEditalRubrica.materialPermanenteEquipamentos,
              ),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/materialPermanenteProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Tipo',
                        field: (row: MaterialPermanenteProposta) =>
                          tipoOrigemI18N[row.tipoOrigem?.toUpperCase()],
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: MaterialPermanenteProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: MaterialPermanenteProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: MaterialPermanenteProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: MaterialPermanenteProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: MaterialPermanenteProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Material Permanente',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipoOrigem',
                              label: 'Tipo Origem',
                              options: { required: true },
                            },
                            {
                              type: 'Number',
                              label: 'Quantidade',
                              scope: '#/properties/quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['materialPermanentePropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;
                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              label: 'Custo Unitário',
                              scope: '#/properties/custoUnitario',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['materialPermanentePropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.materialPermanenteEquipamentos &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira(
                                  'materialPermanentePropostaUnsaved',
                                ),
                                handleChange: moedaHandleChange,
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    const editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.materialPermanenteEquipamentos,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida(
                                  'materialPermanentePropostaUnsaved',
                                ),
                              },
                            },
                          ],
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/entidade',
                          label: 'Entidade',
                          rule: showIfContrapartida,
                          options: {
                            required: requiredIfContrapartida('materialPermanentePropostaUnsaved'),
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Passagens',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.passagens),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/passagemProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    defaultValue: (formData: PassagemProposta) => ({ paisId: 1 }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Trecho',
                        field: (row: PassagemProposta) =>
                          trechoPassagemPropostaI18N[row.trecho?.toUpperCase()],
                      },
                      {
                        label: 'Ida e Volta',
                        field: (row: PassagemProposta) => (row.idaVolta ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Origem',
                        field: (row: PassagemProposta) =>
                          row.trecho === TrechoPassagemProposta.nacional
                            ? `Brasil - ${row.estadoOrigem?.nome}-${row.municipioOrigem}`
                            : `${row.paisOrigem?.nomePt}-${row.estadoRegiaoOrigem}`,
                      },
                      {
                        label: 'Destino',
                        field: (row: PassagemProposta) =>
                          row.trecho === TrechoPassagemProposta.nacional
                            ? `Brasil-${row.estadoDestino?.nome}-${row.municipioDestino}`
                            : `${row.paisDestino?.nomePt}-${row.estadoRegiaoDestino}`,
                      },
                      {
                        label: 'Tipo',
                        field: (row: PassagemProposta) =>
                          tipoPassagemPropostaI18N[row.tipo?.toUpperCase()],
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: PassagemProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Mês',
                        field: (row: PassagemProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: PassagemProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: PassagemProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: PassagemProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Passagem',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Trecho',
                              scope: '#/properties/trecho',
                              options: {
                                required: true,
                                handleChange: (
                                  trecho: TrechoPassagemProposta,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  if (trecho === TrechoPassagemProposta.nacional) {
                                    handler(`${path}`.replace('trecho', 'paisOrigemId'), null);
                                    handler(
                                      `${path}`.replace('trecho', 'estadoRegiaoOrigem'),
                                      null,
                                    );
                                    handler(`${path}`.replace('trecho', 'paisDestinoId'), null);
                                    handler(
                                      `${path}`.replace('trecho', 'estadoRegiaoDestino'),
                                      null,
                                    );
                                  } else if (trecho === TrechoPassagemProposta.internacional) {
                                    handler(`${path}`.replace('trecho', 'estadoOrigemId'), null);
                                    handler(`${path}`.replace('trecho', 'estadoOrigem'), null);
                                    handler(`${path}`.replace('trecho', 'municipioOrigem'), null);
                                    handler(`${path}`.replace('trecho', 'estadoDestinoId'), null);
                                    handler(`${path}`.replace('trecho', 'estadoDestino'), null);
                                    handler(`${path}`.replace('trecho', 'municipioDestino'), null);
                                  }
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Ida e Volta',
                              scope: '#/properties/idaVolta',
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Estado de Origem',
                              scope: '#/properties/estadoOrigemId',
                              rule: showIfNationalTicket,
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfTrechoNacional,
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(
                                    `${path}`.replace('estadoOrigemId', 'estadoOrigem'),
                                    item,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Município de Origem',
                              scope: '#/properties/municipioOrigem',
                              rule: showIfNationalTicket,
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                defaultValue: 'municipioOrigem',
                                required: requiredIfTrechoNacional,
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoOrigemId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'País de Origem',
                              scope: '#/properties/paisOrigemId',
                              rule: showIfInternationalTicket,
                              options: {
                                displayProperties: ['nomePt'],
                                required: requiredIfTrechoInternacional,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(`${path}`.replace('paisOrigemId', 'paisOrigem'), item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Estado/Região de Origem',
                              rule: showIfInternationalTicket,
                              scope: '#/properties/estadoRegiaoOrigem',
                              options: {
                                required: requiredIfTrechoInternacional,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Estado de Destino',
                              scope: '#/properties/estadoDestinoId',
                              rule: showIfNationalTicket,
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfTrechoNacional,
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(
                                    `${path}`.replace('estadoDestinoId', 'estadoDestino'),
                                    item,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Município de Destino',
                              scope: '#/properties/municipioDestino',
                              rule: showIfNationalTicket,
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                defaultValue: 'municipioOrigem',
                                displayProperties: ['nome'],
                                required: requiredIfTrechoNacional,
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoDestinoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'País de Destino',
                              scope: '#/properties/paisDestinoId',
                              rule: showIfInternationalTicket,
                              options: {
                                displayProperties: ['nomePt'],
                                required: requiredIfTrechoInternacional,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(`${path}`.replace('paisDestinoId', 'paisDestino'), item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Estado/Região de Destino',
                              rule: showIfInternationalTicket,
                              scope: '#/properties/estadoRegiaoDestino',
                              options: {
                                required: requiredIfTrechoInternacional,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Tipo',
                              scope: '#/properties/tipo',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              label: 'Custo Unitário',
                              scope: '#/properties/custoUnitario',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['passagemPropostaUnsaved']?.quantidade;

                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Number',
                              label: 'Quantidade',
                              scope: '#/properties/quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['passagemPropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              label: 'Valor Total',
                              scope: '#/properties/valorTotal',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Mês Previsto',
                              scope: '#/properties/mesPrevisto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.passagens &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Moeda Estrangeira',
                              scope: '#/properties/moedaEstrangeiraId',
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfEmMoedaEstrangeira('passagemPropostaUnsaved'),
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica.tipoEditalRubrica ===
                                        TipoEditalRubrica.passagens,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                              rule: showIfForeignCurrency,
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              label: 'Contrapartida',
                              scope: '#/properties/contrapartida',
                              options: {
                                handleChange: async (
                                  value: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx: CrudContextData,
                                  path: string,
                                ) => {
                                  if (!value) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoEntidade'),
                                      null,
                                    );
                                    handler(`${path}`.replace('contrapartida', 'entidade'), null);
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Tipo de Entidade',
                              scope: '#/properties/tipoEntidade',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('passagemPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              scope: '#/properties/entidade',
                              options: {
                                required: requiredIfContrapartida('passagemPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Hospedagem e Alimentação',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.hospedagemAlimentacao),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/hospedagemAlimentacaoProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Localidade',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.pais?.codigo === CodigoPais.brasil
                            ? `${row.pais?.nomePt}-${row.estado?.nome}-${row.municipio}`
                            : `${row.pais?.nomePt}-${row.estadoRegiao}`,
                      },
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Mês',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.valorTotal ? formatFloat(row.valorTotal) : '-',
                      },
                    ],
                    label: 'Hospedagem e Alimentação',
                    defaultValue: (formData: HospedagemAlimentacaoProposta) => ({ paisId: 1 }),
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  if (item?.codigo === CodigoPais.brasil) {
                                    handler(
                                      'hospedagemAlimentacaoPropostaUnsaved.estadoRegiao',
                                      null,
                                    );
                                  }

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('hospedagemAlimentacaoPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/estadoId',
                              label: 'Estado',
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfBrasil('hospedagemAlimentacaoPropostaUnsaved'),
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(`${path}`.replace('estadoId', 'estado'), item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/municipio',
                              label: 'Município',
                              options: {
                                required: requiredIfBrasil('hospedagemAlimentacaoPropostaUnsaved'),
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                          rule: showIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  if (item?.codigo !== CodigoPais.brasil) {
                                    handler('hospedagemAlimentacaoPropostaUnsaved.estadoId', null);
                                    handler('hospedagemAlimentacaoPropostaUnsaved.municipio', null);
                                  }

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('hospedagemAlimentacaoPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/estadoRegiao',
                              label: 'Estado/Região',
                              options: {
                                required: requiredIfNotBrasil(
                                  'hospedagemAlimentacaoPropostaUnsaved',
                                ),
                              },
                            },
                          ],
                          rule: hideIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Number',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                required: true,
                                numberStep: 1,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const custoUnitario =
                                    formData?.['hospedagemAlimentacaoPropostaUnsaved']
                                      ?.custoUnitario;

                                  if (!quantidade && !custoUnitario) return;

                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const quantidade =
                                    formData?.['hospedagemAlimentacaoPropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.hospedagemAlimentacao &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira(
                                  'hospedagemAlimentacaoPropostaUnsaved',
                                ),
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.hospedagemAlimentacao,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: (ctx) => {
                                  const { formData } = ctx;
                                  return formData?.['hospedagemAlimentacaoPropostaUnsaved']
                                    ?.contrapartida;
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: (ctx) => {
                                  const { formData } = ctx;
                                  return formData?.['hospedagemAlimentacaoPropostaUnsaved']
                                    ?.contrapartida;
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Serviço de Terceiros',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.servicosDeTerceiros),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/servicoTerceiroProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader, field: 'custoUnitario' },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Tipo',
                        field: (row: ServicoTerceiroProposta) =>
                          tipoPessoaI18N[row.tipo?.toUpperCase()],
                      },
                      {
                        label: 'Mês',
                        field: (row: ServicoTerceiroProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: ServicoTerceiroProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: ServicoTerceiroProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor',
                        field: (row: ServicoTerceiroProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                    ],
                    label: 'Serviço de Terceiros',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipo',
                              label: 'Tipo',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.servicosDeTerceiros &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira(
                                  'servicoTerceiroPropostaUnsaved',
                                ),
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: Proposta,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.servicosDeTerceiros,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: (ctx) => {
                                  const { formData } = ctx;
                                  return formData?.['servicoTerceiroPropostaUnsaved']
                                    ?.contrapartida;
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: (ctx) => {
                                  const { formData } = ctx;
                                  return formData?.['servicoTerceiroPropostaUnsaved']
                                    ?.contrapartida;
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Pessoal',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.pessoal),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/pessoalProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader, field: 'valor' },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Função',
                        field: 'funcao',
                      },
                      {
                        label: 'Formação',
                        field: 'formacaoProfissional',
                      },
                      {
                        label: 'Perfil Desejado',
                        field: 'perfilDesejado',
                      },
                      {
                        label: 'Mês',
                        field: (row: PessoalProposta) =>
                          row.mesInicio ? `${row.mesInicio}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: PessoalProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: PessoalProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor',
                        field: (row: PessoalProposta) => (row.valor ? formatFloat(row.valor) : '-'),
                      },
                    ],
                    label: 'Pessoal',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/funcao',
                              label: 'Função',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/formacaoProfissional',
                              label: 'Formação Profissional',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/perfilDesejado',
                              label: 'Perfil Desejado',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesInicio',
                              label: 'Mês de Início',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                                handleChange: (
                                  item: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  handler(`${path}`.replace('mesInicio', 'duracao'), null);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/duracao',
                              label: 'Duração (Em meses)',
                              options: {
                                formFields: (formData, path) => {
                                  return [formData?.['pessoalPropostaUnsaved']?.mesInicio];
                                },
                                oneOf: (formData: Proposta, path: string) => {
                                  const { duracao } = formData;
                                  const mesInicio = formData?.['pessoalPropostaUnsaved']?.mesInicio;
                                  if (!duracao || !mesInicio) return [];

                                  const mesesRestantes = duracao - mesInicio + 1;

                                  return Array.from({ length: mesesRestantes }, (_, i) => {
                                    const quantidadeMeses = i + 1;
                                    return {
                                      title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                      const: quantidadeMeses,
                                    };
                                  });
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/cargaHorariaSemanal',
                              label: 'Carga Horária Semanal',
                              options: {
                                required: true,
                                oneOf: () => {
                                  return Array.from({ length: 60 }, (_, i) => {
                                    const horas = i + 1;
                                    return {
                                      title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                      const: horas,
                                    };
                                  });
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoHoraCustoMes',
                              label: 'Custo Hora ou Custo Mês',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valor',
                              label: 'Valor',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'HorizontalLayout',
                              elements: [
                                {
                                  type: 'Select',
                                  scope: '#/properties/tipoPessoa',
                                  label: 'Tipo Pessoa',
                                  rule: showIfContrapartida,
                                  options: {
                                    required: requiredIfContrapartida('pessoalPropostaUnsaved'),
                                  },
                                },
                                {
                                  type: 'Control',
                                  scope: '#/properties/entidade',
                                  label: 'Entidade',
                                  rule: showIfContrapartida,
                                  options: {
                                    required: requiredIfContrapartida('pessoalPropostaUnsaved'),
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.pessoal &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },
                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira('pessoalPropostaUnsaved'),
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: Proposta,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.pessoal,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Encargos',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.encargos),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/encargoProposta',
                  options: {
                    route: '/proposta',
                    label: 'Encargos',
                    additionalData: (formData: Proposta) => ({
                      editalId: formData?.editalId,
                    }),
                    buildHeader: { field: 'custoUnitario', builder: buildTableLayoutHeader },
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Mês',
                        field: (row: EncargoProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: EncargoProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: EncargoProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira?.nome} (${row.moedaEstrangeira?.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor',
                        field: (row: EncargoProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                    ],
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda Estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.encargos &&
                                      editalRubrica?.moedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda Estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: requiredIfEmMoedaEstrangeira('encargoPropostaUnsaved'),
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.encargos,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira) =>
                                          editalRubrica?.moedaEstrangeira?.some(
                                            (me) => me.id === moedaEstrangeira.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: moedaHandleChange,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('encargoPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('encargoPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.bolsa),
              label: 'Bolsas',
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/bolsaProposta',
                  label: 'Bolsas',
                  options: {
                    route: '/proposta',
                    additionalData: (formData: Proposta) => ({
                      editalId: formData?.editalId,
                    }),
                    buildHeader: { field: 'valorTotal', builder: buildTableLayoutHeader },
                    headCells: [
                      {
                        label: 'Modalidade e Nível',
                        field: (row: BolsaProposta) =>
                          `${row.modalidadeBolsa?.nome} - ${row.nivelBolsa?.nivel}`,
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Duração',
                        field: 'duracao',
                      },
                      {
                        label: 'Valor Bolsa',
                        field: (row: BolsaProposta) =>
                          row.nivelBolsa?.valorAtualBolsa
                            ? formatFloat(row.nivelBolsa?.valorAtualBolsa)
                            : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: BolsaProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Valor Total',
                        field: (row: BolsaProposta) =>
                          row.valorTotal ? formatFloat(row.valorTotal) : '-',
                      },
                    ],
                    label: 'Bolsa',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/modalidadeBolsaId',
                              label: 'Modalidade da Bolsa',
                              options: {
                                required: true,
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<ModalidadeBolsa>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<ModalidadeBolsa[]> => {
                                    const bolsaEdital = formData?.edital?.bolsaEdital?.map(
                                      (bolsaEdital: BolsaEdital) => bolsaEdital?.modalidadeBolsaId,
                                    );

                                    //Filtra as modalidades de bolsa que estão no edital
                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((modalidadeBolsa: ModalidadeBolsa) =>
                                          bolsaEdital?.find(
                                            (modalidadeBolsaEdital: number) =>
                                              modalidadeBolsaEdital === modalidadeBolsa?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: (
                                  item: ModalidadeBolsa,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(
                                    `${path}`.replace('modalidadeBolsaId', 'modalidadeBolsa'),
                                    item,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/nivelBolsaId',
                              label: 'Nível da Bolsa',
                              options: {
                                required: true,
                                formatOptionLabel: formatLabel,
                                handleChange: (
                                  item: NivelBolsa,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('nivelBolsaId', 'nivelBolsa'), item);
                                },
                                filter: {
                                  formFields: ['modalidadeBolsaId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: NivelBolsa[],
                                    api: GenericApi<NivelBolsa>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<NivelBolsa[]> => {
                                    const bolsaPropostaArray = formData.bolsaProposta;
                                    const nivelBolsaEditalArray = formData?.edital?.bolsaEdital;
                                    const bolsaPropostaUnsaved = formData['bolsaPropostaUnsaved'];

                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                                    //Filtra os níveis de bolsa que não estão na proposta e estão no edital
                                    return api
                                      .getAll({ modalidadeBolsaId: queryFilterValues?.[0]?.value })
                                      .then((response) =>
                                        response.filter((nivelBolsa: NivelBolsa) => {
                                          const isInProposta = bolsaPropostaArray?.some(
                                            (bolsaProposta: BolsaProposta) =>
                                              bolsaProposta.nivelBolsaId === nivelBolsa.id &&
                                              bolsaProposta !== bolsaPropostaUnsaved,
                                          );

                                          const isInEdital = nivelBolsaEditalArray?.some(
                                            (nivelBolsaEdital) =>
                                              nivelBolsaEdital.nivelBolsaId === nivelBolsa.id,
                                          );

                                          return !isInProposta && isInEdital;
                                        }),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Number',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: any,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const duracao = formData?.['bolsaPropostaUnsaved']?.duracao ?? 0;
                                  const valorAtualBolsa =
                                    formData?.['bolsaPropostaUnsaved']?.nivelBolsa
                                      ?.valorAtualBolsa ?? 0;

                                  const valorTotal = duracao * quantidade * valorAtualBolsa;

                                  handler(`quantidade`, quantidade);
                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    isNaN(valorTotal) ? 0 : valorTotal,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/duracao',
                              label: 'Duração (Em meses)',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}`,
                                    const: i + 1,
                                  }));
                                },

                                handleChange: (
                                  duracao: any,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['bolsaPropostaUnsaved']?.quantidade ?? 0;
                                  const valorAtualBolsa =
                                    formData?.['bolsaPropostaUnsaved']?.nivelBolsa
                                      ?.valorAtualBolsa ?? 0;

                                  const valorTotal = duracao * quantidade * valorAtualBolsa;

                                  handler(
                                    path.replace('duracao', 'valorTotal'),
                                    isNaN(valorTotal) ? 0 : valorTotal,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                required: true,
                                disabled: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('bolsaPropostaUnsaved'),
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo Pessoa',
                              rule: showIfContrapartida,
                              options: {
                                required: requiredIfContrapartida('bolsaPropostaUnsaved'),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Consolidação',
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital',
                  schema: {
                    properties: {
                      editalRubrica: {
                        type: 'array',
                        minItems: 1,
                      },
                    },
                  },
                } as Condition,
              } as Rule,
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                  callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                    createResource(ctx, '/proposta', fieldsConfig),
                },
              },
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'MoneyExchangeRate',
                      scope: '#/properties/propostaCotacaoMoedaEstrangeira',
                      label: 'Cotação Moeda Estrangeira',
                      options: {
                        isPositiveNumber: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'CustomTableView',
                      scope: '#/properties/orcamentoConsolidado',
                      options: {
                        tableOf: (formData: Proposta) => {
                          return getOrcamentoConsolidadoTable(formData);
                        },
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: 'Category',
              label: 'Solicitado à Fundação',
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital',
                  schema: {
                    properties: {
                      editalRubrica: {
                        type: 'array',
                        minItems: 1,
                      },
                    },
                  },
                } as Condition,
              } as Rule,
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                  callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                    createResource(ctx, '/proposta', fieldsConfig),
                },
              },
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'CustomMultiTableView',
                      scope: '#/properties/solicitadoFundacao',
                      options: {
                        tablesOf: (formData: Proposta) => {
                          return getRubricasTrimestralTable(formData);
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Termos',
      elements: [
        {
          type: 'Category',
          label: 'Termo de Aceite',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
            nextButtonTitle: 'Submeter Proposta',
            nextButtonDisabled: (data: any) => {
              return !data?.termoDeAceiteAceito;
            },
            onFinish: (navigate: NavigateFunction, ctx: CrudContextData) => {
              navigate('/');
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CkEditor',
                  scope: '#/properties/edital/properties/termoDeAceite',
                  options: {
                    ckeditorSize: { height: 400 },
                    toolbar: [],
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/termoDeAceiteAceito',
              label: 'Li e estou de acordo com o Termo de Aceite',
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    showProgress: true,
    route: '/proposta',
  },
};

export default propostaUiSchema;
