import { Crud } from '../../../components/crud/crud';
import { NivelAcademico as NivelAcademicoModel } from '../../../models/nivel-academico';
import { headCells } from './nivel-academico.headcell';
import nivelAcademicoUiSchema from './nivel-academico.uischema';

function NivelAcademico() {
  return (
    <Crud<NivelAcademicoModel>
      headCells={headCells}
      titleConfig={{ value: 'Níveis Acadêmicos', show: true }}
      uischema={nivelAcademicoUiSchema}
      apiUrl={'/nivel-academico'}
      hideActions
      rowsLength={12}
    ></Crud>
  );
}

export default NivelAcademico;
