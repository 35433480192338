import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProps {
  isLoading: boolean;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ isLoading, className }) => {
  if (!isLoading) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={className}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
