import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ResizeHandleIcon } from './resize-handle';

export const StyledBox = styled('div')({
  position: 'relative',
  fontFamily: 'Raleway',
  fontSize: '12px',
  width: '100%',
  margin: '8px 0 !important',
});

export const StyledTitle = styled(Typography)<{ error: any }>(({ error }) => ({
  color: (error ? '#C42525' : 'black') + ' !important',
  fontFamily: 'Raleway',
  fontSize: '12px',
  fontWeight: 700,
}));

export const StyledSubtitle = styled(Typography)<{ error: any }>(({ error }) => ({
  color: error ? '#C42525' : '#1351B4',
  fontStyle: 'italic',
  fontFamily: 'Raleway',
  fontSize: '12px',
}));

export const StyledError = styled(Typography)({
  color: '#C42525',
  marginTop: '4px',
  fontFamily: 'Raleway',
  fontSize: '12px',
});

export const TextAreaContainer = styled(Box)<{ error?: any }>(({ error }) => ({
  border: error ? '0.5px solid #C42525' : '0.5px solid #454545',
  borderRadius: '4px',
  width: '100%',
  maxHeight: '250px',
  height: '400px',
  resize: 'both',
  display: 'flex',
  overflowY: 'hidden',
  overflowX: 'hidden',
  justifyContent: 'space-between',
  flexDirection: 'column',
  zIndex: '0',
  '::-webkit-resizer': {
    display: 'none',
  },
  backgroundColor: 'white',
  fontFamily: 'Raleway',
  fontSize: '12px',
}));

export const DescritivaTextArea = styled('textarea')({
  overflow: 'auto',
  marginRight: '5px',
  marginTop: '5px',
  height: '100% !important',
  padding: '15px',
  border: 'none',
  fontFamily: 'Raleway',
  fontSize: '12px',
  color: '#333435',

  '&:focus': {
    outline: 'none',
  },

  '&::-webkit-resizer': {
    display: 'none',
  },

  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
    margin: '2px',
    height: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const FooterContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',
  height: '100%',
});

export const TextAreaFooter = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '0px 5px 1px 5px',
  height: '30px',
  backgroundColor: 'white',
  fontFamily: 'Raleway',
  fontSize: '12px',
});

export const ResizeHandleIconStyled = styled(ResizeHandleIcon)({
  stroke: '#939495',
  width: '22px',
  height: '22px',
});

export const TextLengthIndicator = styled(Typography)({
  marginLeft: '8px',
  fontFamily: 'Raleway',
  fontSize: '12px',
  color: '#333435',
});
