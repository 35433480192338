import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { FuncionalProgramatica } from '../../../models/funcional-programatica';

export const displayProperties = ['codigo', 'descricao'];

export const moduleName = 'CONFIGURACOES_INICIAIS.FUNCIONAL_PROGRAMATICA';

export const moduleLabel = 'Funcionais Programáticas';

export const headCells: readonly HeadCell<FuncionalProgramatica>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Funcional Programática',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
