import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { FormularioEnquadramento } from '../../../models/formulario-enquadramento';

export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';

export const displayProperties = ['titulo'];

export const moduleLabel = 'Formulário de Enquadramento';

export const headCells: readonly HeadCell<FormularioEnquadramento>[] = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Título',
  },
];
