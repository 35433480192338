import { Crud } from '../../../components/crud/crud';
import { Unidade as UnidadeModel } from '../../../models/unidade';
import { headCells } from './unidade.headcell';
import unidadeUiSchema from './unidade.uischema';

function Unidade() {
  const defaultForm = { endereco: {} };

  return (
    <Crud<UnidadeModel>
      titleConfig={{ value: 'Unidades', show: true }}
      apiUrl={'/unidade'}
      headCells={headCells}
      defaultForm={defaultForm}
      uischema={unidadeUiSchema}
      queryFilters={{ withDeleted: true, orderDirection: 'ASC' }}
    ></Crud>
  );
}

export default Unidade;
