import { CrudContextData, IFieldsConfig, IStepCallback } from '../../../jsonforms/uischema';
import { Usuario } from '../../../models/usuario';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { makeUsuarioApi } from '../../editar-perfil/usuario-api';

export const stepHandlerDadosCoordenador = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { crudStates } = ctx;
  const errorsResult = await stepHandlerErrors(fieldsConfig, ctx, data, url);

  if (errorsResult) {
    const usuarioResult = crudStates.edit || crudStates.add ? await atualizarUsuario(ctx) : true;

    if (callback && usuarioResult) {
      return callback(ctx, fieldsConfig);
    }

    return usuarioResult;
  }
  return false;
};

const atualizarUsuario = async (ctx: CrudContextData) => {
  const { formData, showError, showSuccess } = ctx;
  const usuarioApi = makeUsuarioApi();
  let valid = false;
  try {
    const usuario: Usuario = formData.criadoPor;

    await usuarioApi.atualizarPerfil(usuario);
    showSuccess('Dados do usuário atualizados com sucesso.');
    valid = true;
  } catch (error: any) {
    showError(error?.cause?.response?.data?.message?.[0] || 'Erro ao atualizar dados do usuário.');
    console.error(error);
  }
  return valid;
};
