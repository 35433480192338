import { BuildingBankLink } from 'styled-icons/fluentui-system-filled';
import { Crud } from '../../../components/crud/crud';
import { Unidade as UnidadeModel } from '../../../models/unidade';
import { headCells, moduleName } from './unidade.headcell';
import unidadeUiSchema from './unidade.uischema';
import { useNavigate } from 'react-router-dom';
import { useCrudContext } from '../../../components/crud/crud-context';
import { makeApi } from '../../../api/generic-api';
import { CrudStatesOptions } from '../../../jsonforms/uischema';

function Unidade() {
  const defaultForm = { endereco: {} };
  const navigate = useNavigate();
  const { updateCrudStates, setFormData, setId } = useCrudContext();
  const unidadeApi = makeApi<UnidadeModel>('/unidade');

  const hideActionsByRow = (row: UnidadeModel) => {
    return row.acknowledgedAt === null;
  };

  const vincularUnidade = {
    hasPermission: ['ATUALIZAR'],
    moduleName: moduleName,
    icon: <BuildingBankLink size='24' color='#5DC1F2' />,
    title: 'Vincular Unidade',
    handler: (row: any) => {
      unidadeApi
        ?.get?.(row.id)
        .then((data: UnidadeModel) => {
          setFormData(data);
        })
        .catch((err) => {
          console.error(err);
        });
      setId(row.id);
      updateCrudStates(CrudStatesOptions.EDIT);
      navigate(`/unidade/${row.id}`);
    },
  };

  const showVincularUnidade = (row: UnidadeModel) => {
    if (row?.acknowledgedAt === null && row?.instituicao?.acknowledgedAt !== null)
      return vincularUnidade;
  };

  return (
    <Crud<UnidadeModel>
      titleConfig={{ value: 'Unidades', show: true }}
      apiUrl={'/unidade'}
      headCells={headCells}
      defaultForm={defaultForm}
      uischema={unidadeUiSchema}
      hideActionsByRow={hideActionsByRow}
      customActions={[showVincularUnidade]}
      queryFilters={{ withDeleted: true, orderDirection: 'ASC' }}
    ></Crud>
  );
}

export default Unidade;
