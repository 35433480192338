import { statusPropostaI18N } from '../../models/proposta';
import { StyledEdicaoChip } from './style/lista-status';

type listaPropostasProps<T> = {
  status: string;
};

export default function ListaStatus<T extends Record<string, any>>({
  status,
}: listaPropostasProps<T>) {
  let statusComponent = null;
  switch (status) {
    case statusPropostaI18N.EM_EDICAO:
      statusComponent = <StyledEdicaoChip variant='outlined' label={status} />;
  }
  return statusComponent;
}
