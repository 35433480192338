import { Dispatch } from 'react';
import { GenericApi } from '../../api/generic-api';
import { Usuario } from '../../models/usuario';
import AlertCustom from '../crud/alert-custom';
import DataTable from '../crud/data-table/data-table';
import DeleteDialog from '../crud/delete-dialog';
import { HeadCell } from '../crud/protocols/head-cell';
import CustomMultipleSelectUserChip from './custom-multiple-select-user-chip';
import { Action } from '../crud/data-table/protocols/actions';
import { Severity } from '../crud/protocols/severity';

type CustomMultipleUserSelectProps<T> = {
  headCells: readonly HeadCell<T>[];
  moduleName: string;
  moduleLabel: string;
  apiSearchInstance: GenericApi<Usuario>;
  apiListData: any[];
  customActions: ((row: any) => Action | undefined)[];
  onTextChange: (searchText: string) => void;
  handleAdd: (e: any) => void;
  usuariosState: {
    usuarios: Usuario[];
    setUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  selectedUsuariosState: {
    selectedUsuarios: Usuario[];
    setSelectedUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  dialogProps: {
    openDialog: boolean;
    handleCloseDelete: () => void;
    destroy: () => Promise<void>;
  };
  alertCustom: {
    openAlert: boolean;
    severityAlert: Severity;
    messageAlert: string;
  };
  pageState: {
    page: number;
    setPage: Dispatch<React.SetStateAction<number>>;
  };
  rowsPerPageState: {
    rowsPerPage: number;
    setRowsPerPage: Dispatch<React.SetStateAction<number>>;
  };
  rowsPerPageOptions: number[];
  totalCount: number;
  hasSecure: boolean;
  enabled?: boolean;
};

export default function CustomMultipleUserSelect<T extends Record<string, any>>(
  props: CustomMultipleUserSelectProps<T>,
) {
  const { openDialog, handleCloseDelete, destroy } = props.dialogProps;
  const { openAlert, severityAlert, messageAlert } = props.alertCustom;
  const { page, setPage } = props.pageState;
  const { rowsPerPage, setRowsPerPage } = props.rowsPerPageState;

  return (
    <>
      <DataTable
        headCells={props.headCells}
        rows={props.apiListData}
        title={props.moduleLabel}
        moduleName={props.moduleName}
        customActions={props.customActions}
        customTableHeader={
          <CustomMultipleSelectUserChip
            apiSearchInstance={props.apiSearchInstance}
            apiListData={props.apiListData}
            onTextChange={props.onTextChange}
            usuariosState={props.usuariosState}
            selectedUsuariosState={props.selectedUsuariosState}
            handleAdd={props.handleAdd}
            hasSecure={props.hasSecure}
            enabled={props.enabled}
          ></CustomMultipleSelectUserChip>
        }
        styledPaperSx={{ margin: '0px !important' }}
        hideView
        hideUpdate
        hideDelete
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageOptions={props.rowsPerPageOptions}
        totalCount={props.totalCount}
      ></DataTable>
      <DeleteDialog
        openDialog={openDialog}
        body={<></>}
        handleCloseDelete={handleCloseDelete}
        destroy={destroy}
        customHeaderText='Deseja realmente deletar este item?'
      ></DeleteDialog>
      <AlertCustom
        open={openAlert}
        severity={severityAlert}
        message={messageAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}
