import { IUiSchema } from '../../../jsonforms/uischema';

const linhaDeAcaoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      label: 'Título',
      type: 'Control',
      scope: '#/properties/titulo',
    },
    {
      type: 'Control',
      scope: '#/properties/objetivo',
    },
    {
      label: 'Descrição',
      type: 'Control',
      scope: '#/properties/descricao',
    },
    {
      label: 'Informação da Publicação',
      type: 'Control',
      scope: '#/properties/informacaoPublicacao',
    },
    {
      label: 'Data de Início',
      type: 'Date',
      scope: '#/properties/dataInicio',
    },
    {
      label: 'Data de Término',
      type: 'Date',
      scope: '#/properties/dataTermino',
    },
  ],
};

export default linhaDeAcaoUiSchema;
