import {
  Categorization,
  Category,
  RankedTester,
  StatePropsOfLayout,
  and,
  categorizationHasCategory,
  isVisible,
  optionIs,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import {
  AjvProps,
  MaterialLayoutRenderer,
  MaterialLayoutRendererProps,
  withAjvProps,
} from '@jsonforms/material-renderers';
import { TranslateProps, withJsonFormsLayoutProps, withTranslateProps } from '@jsonforms/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCrudContext } from '../../components/crud/crud-context';
import { CustomDoneIcon } from '../../components/crud/style/crud.styled';
import { getUischemaConfigs } from '../../helpers/get-uischema-configs';
import { createResource } from '../../utils/create-resource';
import { stepHandlerBack } from '../../utils/step-handler-back';
import { useAuthContext } from '../../views/login/auth/auth-context';
import {
  CrudStatesOptions,
  IControlElement,
  IStepHandler,
  IUiSchema,
} from '../uischema/uischema.type';
import { ButtonWrapperStyled, NextButtonStyled, PrevButtonStyled } from './stepper-layout/styles';
import {
  ButtonContainer,
  Container,
  CounterText,
  Header,
  LeftContent,
  Main,
  MenuItem,
  RightMenu,
  StepsCompletedContainer,
  StepsCompletedText,
  StyledButtonContained,
  StyledButtonOutlined,
  StyledCheckCheckIcon,
  StyledCircleIcon,
  StyledHorizontalRuleIcon,
  StyledLinearProgress,
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledListSubItemButton,
  StyledListSubItemText,
  TitleCategory,
} from './styled/categorization-menu';

const materialCategorizationMenuTester: RankedTester = rankWith(
  3,
  and(uiTypeIs('CategorizationMenu'), categorizationHasCategory, optionIs('variant', 'stepper')),
);

interface MaterialCategorizationMenuLayoutRendererProps
  extends StatePropsOfLayout,
    AjvProps,
    TranslateProps {
  data: any;
}

const CategorizationMenuLayoutRenderer = (props: MaterialCategorizationMenuLayoutRendererProps) => {
  const { data, path, renderers, schema, visible, cells, config, ajv } = props;
  const ctx = useCrudContext();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const uischema = props?.uischema as IUiSchema;
  const categorization = uischema as Categorization;
  const showProgress = uischema.options?.showProgress;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const { crudStates, updateCrudStates, clearForm, showSuccess, showError } = ctx;
  const url = uischema.options?.route;

  const categories = useMemo(() => {
    const filterCategories = (elements: IControlElement[]) =>
      elements
        .filter(
          (category: Category | Categorization) =>
            category.type !== 'Category' || isVisible(category, data, '', ajv),
        )
        .map((category: Category | Categorization) => ({
          ...category,
          elements: category.elements ? filterCategories(category.elements) : [],
        }));

    return filterCategories(categorization?.elements || []);
  }, [categorization, ajv, data]);

  const categoryElements = useMemo(() => {
    const finalElements: IControlElement[] = [];
    const findFinalElements = (items: IControlElement[]) => {
      items.forEach((item) => {
        if (item.type === 'Category') {
          if (item.elements && item.elements.some((x: IControlElement) => x.type === 'Category')) {
            findFinalElements(item.elements);
          } else {
            finalElements.push(item);
          }
        }
      });
    };
    findFinalElements(categories);
    return finalElements.filter((x) => x.elements.length > 0);
  }, [categories]);

  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [open, setOpen] = useState<Array<{ [key: string]: boolean }>>([]);
  const [selectedCategory, setSelectedCategory] = useState<IControlElement | null>(
    categoryElements[0],
  );

  const childProps: MaterialLayoutRendererProps = {
    elements: selectedCategory?.elements,
    schema,
    path,
    direction: 'column',
    visible,
    renderers,
    cells,
  };

  const handleClick = (key: string, depth: number) => {
    setOpen((prevState) => {
      const newOpen = [...prevState];
      newOpen[depth] = {
        ...Object.keys(newOpen[depth] || {}).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      };
      newOpen[depth][key] = !prevState[depth]?.[key];
      return newOpen;
    });
  };

  const getRootSchema = () => {
    return categoryElements?.[activeCategory];
  };

  const getNextButtonTitle = (): string => {
    const rootSchema = getRootSchema();
    return rootSchema?.options?.nextButtonTitle || 'Finalizar';
  };

  const nextButtonDisabled = (): boolean => {
    const rootSchema = getRootSchema();
    const nextButtonDisabled = rootSchema?.options?.nextButtonDisabled;
    if (nextButtonDisabled && typeof nextButtonDisabled === 'function') {
      return nextButtonDisabled(data);
    }
    return false;
  };

  const isDisabled = crudStates.view || nextButtonDisabled();

  const getStepHandler = (): IStepHandler => {
    const rootStepHandler = uischema?.options?.stepHandler;
    const rootSchema = getRootSchema();
    const categoryStepHandler = rootSchema?.options?.stepHandler;
    return (categoryStepHandler ?? rootStepHandler) as IStepHandler;
  };

  const handleStep = (step: number) => {
    setActiveCategory(step);
    setSelectedCategory(categoryElements[step]);
  };

  const prevHandler = () => {
    if (activeCategory <= 0) return;
    handleStep(activeCategory - 1);
  };

  const executeStepHandler = async () => {
    const stepHandler: IStepHandler = getStepHandler();
    if (!stepHandler) return false;
    const { handler, url, callback } = stepHandler;
    const rootSchema = getRootSchema();
    const uiSchemaConfigs = getUischemaConfigs(rootSchema, ctx);
    const result = await handler?.(uiSchemaConfigs, ctx, data, url, callback);
    return result;
  };

  const nextHandler = async () => {
    setOpen([]);
    const nextStep = activeCategory + 1;

    if (nextStep > categoryElements.length) return;

    if (crudStates.view) {
      handleStep(nextStep);
      return;
    }

    const stepHandlerResult = await executeStepHandler();

    if (stepHandlerResult) {
      if (nextStep < categoryElements.length) {
        handleStep(nextStep);
      } else {
        const rootSchema = getRootSchema();
        if (rootSchema?.options?.onFinish) {
          rootSchema?.options?.onFinish(navigate, ctx, authContext);
        } else {
          navigate(url);
        }
      }
    }
  };

  const onClickElement = async (element: IControlElement) => {
    if (!crudStates.view && activeCategory < categoryElements.length - 1) {
      const stepHandlerResult = await executeStepHandler();
      if (!stepHandlerResult) return;
    }
    handleStep(parseInt(findKey(categoryElements, element)));
  };

  const onClickFinish = async () => {
    const navigateToList = () => {
      updateCrudStates(CrudStatesOptions.LIST);
      navigate(url);
    };

    if (crudStates.view) {
      await stepHandlerBack(undefined, ctx, data, '', undefined);
    } else {
      const result = await createResource(ctx, url);
      if (!result) return;
      clearForm();
    }

    navigateToList();
  };

  const onClickSave = async () => {
    await createResource(ctx, url).then((result) => {
      if (result) {
        showSuccess('Salvo com sucesso!');
      } else {
        showError('Erro ao salvar!');
      }
    });
  };

  const iconsByDepth = {
    0: <StyledCheckCheckIcon />,
    1: <StyledCircleIcon />,
    2: <StyledHorizontalRuleIcon />,
  };

  const countSubItems = (item: any, currentDepth: number, targetDepth: number): number => {
    if (!item.elements) return 0;

    return item.elements.reduce((count: number, subItem: any) => {
      if (subItem.type === 'Category' && subItem.elements.length > 0) {
        if (currentDepth + 1 === targetDepth) {
          return count + 1;
        } else {
          return count + countSubItems(subItem, currentDepth + 1, targetDepth);
        }
      }
      return count;
    }, 0);
  };

  const isAnyParentSelected = (item: any): boolean => {
    if (selectedCategory.label === item.label) return true;
    return item.elements?.some(
      (child: IControlElement) =>
        selectedCategory.label === child.label ||
        (child.type === 'Category' && isAnyParentSelected(child)),
    );
  };

  const renderMenuItems = (items: any[], depth: number = 0) => {
    return items
      .filter((x) => x.type === 'Category')
      .map((item, index) => {
        let subItemCount = 0;
        const icon = iconsByDepth[depth] || iconsByDepth[2];

        if (showProgress) subItemCount = countSubItems(item, depth, depth + 1);

        const isOpen = isAnyParentSelected(item);

        if (item.elements.some((x: IControlElement) => x.type === 'Category')) {
          return (
            <MenuItem key={index} depth={depth}>
              <StyledListItemButton onClick={() => handleClick(item.label, depth)}>
                <StyledListItemIcon>{icon}</StyledListItemIcon>
                <StyledListItemText primary={item.label} />
                {showProgress && <CounterText>{`0/${subItemCount}`}</CounterText>}
                {isOpen || open[depth]?.[item.label] ? <ExpandLess /> : <ExpandMore />}
              </StyledListItemButton>
              <Collapse in={isOpen || open[depth]?.[item.label]} timeout='auto' unmountOnExit>
                <StyledList dense role='list'>
                  {renderMenuItems(item.elements, depth + 1)}
                </StyledList>
              </Collapse>
            </MenuItem>
          );
        } else if (item.elements.length > 0) {
          return (
            <StyledListSubItemButton
              key={index}
              depth={depth}
              onClick={() => onClickElement(item)}
              selected={selectedCategory.label === item.label}
            >
              <StyledListItemIcon>{icon}</StyledListItemIcon>
              <StyledListSubItemText primary={item.label} />
            </StyledListSubItemButton>
          );
        }

        return null;
      });
  };

  return (
    <Hidden xsUp={!visible}>
      <Main>
        <Container>
          <LeftContent>
            <Header>
              <TitleCategory>{getRootSchema()?.label}</TitleCategory>
              <MaterialLayoutRenderer key={getRootSchema()?.label} {...childProps} />
            </Header>
            {appliedUiSchemaOptions.showNavButtons && (
              <ButtonWrapperStyled>
                {activeCategory < categoryElements.length - 1 ? (
                  <NextButtonStyled
                    disabled={activeCategory >= categoryElements.length - 1}
                    onClick={() => nextHandler()}
                  >
                    Próximo
                  </NextButtonStyled>
                ) : (
                  <NextButtonStyled disabled={isDisabled} onClick={() => nextHandler()}>
                    {getNextButtonTitle()}
                  </NextButtonStyled>
                )}
                <PrevButtonStyled disabled={activeCategory <= 0} onClick={() => prevHandler()}>
                  Anterior
                </PrevButtonStyled>
              </ButtonWrapperStyled>
            )}
          </LeftContent>

          <RightMenu>
            {showProgress && (
              <StepsCompletedContainer>
                <StepsCompletedText>Etapas Concluídas</StepsCompletedText>
                <StyledLinearProgress
                  variant='determinate'
                  value={!crudStates.view ? (activeCategory / categoryElements.length) * 100 : 100}
                />
                <Divider />
              </StepsCompletedContainer>
            )}

            <ButtonContainer>
              <List>{renderMenuItems(categories)}</List>
              {!crudStates.view && (
                <StyledButtonOutlined
                  fullWidth
                  marginBottom='20px'
                  variant='outlined'
                  size='small'
                  aria-label='Salvar'
                  onClick={() => onClickSave()}
                >
                  <CustomDoneIcon />
                  Salvar
                </StyledButtonOutlined>
              )}
              <StyledButtonContained
                fullWidth
                variant='contained'
                size='small'
                aria-label='Finalizar'
                onClick={() => onClickFinish()}
              >
                {crudStates.view ? 'Fechar' : 'Finalizar'}
              </StyledButtonContained>
            </ButtonContainer>
          </RightMenu>
        </Container>
      </Main>
    </Hidden>
  );
};

export const categorizationMenuLayoutRenderer = {
  tester: materialCategorizationMenuTester,
  renderer: withAjvProps(
    withTranslateProps(withJsonFormsLayoutProps(CategorizationMenuLayoutRenderer)),
  ),
};
