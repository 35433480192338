export const makeCustomRequiredIfDefineMonth = (field: string) => ({
  field,
  hasError: (value: any, formData: any) =>
    formData?.['definirDuracaoProjetoEmMeses'] === true ? !value : false,
  error: {
    keyword: 'customRequired',
    message: 'Campo obrigatório',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    schemaPath: '#/required',
    params: {
      missingProperty: field,
    },
  },
});

export const makeRangeValidationMonth = (field: string, maxValue: number) => ({
  field,
  hasError: (value: any, formData: any) =>
    formData?.['duracaoProjetoEmMeses']
      ? Number.isNaN(Number(value)) || Number(value) > maxValue
      : false,
  error: {
    keyword: 'outOfRange',
    message: `A quantidade de meses definida é maior que o permitido (1 a ${maxValue}).`,
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});
