import { useNavigate } from 'react-router-dom';
import { LockClosed } from 'styled-icons/zondicons';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Perfil as PerfilModel } from '../../../models/perfil';
import { headCells } from './perfil.headcell';
import perfilUiSchema from './perfil.uischema';

function Perfil() {
  const navigate = useNavigate();
  const customErrors = [whiteSpaces('nome'), whiteSpaces('descricao')];
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const perfilActions = {
    hasPermission: ['ATUALIZAR'],
    moduleName: moduleNameFor('perfil-permissionamento'),
    icon: <LockClosed size='18' color='var(--color-icon)' />,
    title: 'Permissões do Perfil',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        perfil: row,
      });
      navigate(`/perfil-permissionamento`);
    },
  };

  return (
    <Crud<PerfilModel>
      headCells={headCells}
      titleConfig={{ value: 'Perfis', show: true }}
      customErrors={customErrors}
      uischema={perfilUiSchema}
      apiUrl={'/perfil'}
      queryFilters={{ withDeleted: true }}
      customActions={[() => perfilActions]}
    ></Crud>
  );
}

export default Perfil;
