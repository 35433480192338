import { IControlElement } from '../../../jsonforms/uischema';

export const EnderecoUiSchema: IControlElement[] = [
  {
    type: 'Cep',
    scope: '#/properties/endereco/properties/cep',
    label: 'CEP',
    options: {
      mask: '99999-999',
    },
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/logradouro',
    label: 'Rua',
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/numero',
    label: 'Número',
    options: {
      isPositiveNumber: true,
    },
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/complemento',
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/bairro',
    label: 'Bairro',
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/estado',
  },
  {
    type: 'Control',
    scope: '#/properties/endereco/properties/municipio',
    label: 'Município',
  },
];
