import { Crud } from '../../../components/crud/crud';
import { RacaCor as RacaCorModel } from '../../../models/raca-cor';
import { headCells } from './raca-cor.headcell';
import racaCorUiSchema from './raca-cor.uischema';

function RacaCor() {
  return (
    <Crud<RacaCorModel>
      headCells={headCells}
      titleConfig={{ value: 'Raça/Cor', show: true }}
      uischema={racaCorUiSchema}
      apiUrl={'/raca-cor'}
      hideActions
    ></Crud>
  );
}

export default RacaCor;
