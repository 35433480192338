import { PerguntaAvaliacaoDescritiva } from './pergunta-avaliacao-descritiva';
import { PerguntaAvaliacaoMultiplaEscolha } from './pergunta-avaliacao-multipla-escolha';
import { PerguntaAvaliacaoNota } from './pergunta-avaliacao-nota';

export enum TipoRespostaPerguntaAvaliacao {
  descritiva = 'DESCRITIVA',
  multiplaEscolha = 'MULTIPLA_ESCOLHA',
  nota = 'NOTA',
}

export interface PerguntaAvaliacao {
  id: number;
  pergunta: string;
  tipoResposta: TipoRespostaPerguntaAvaliacao;
  descritiva?: PerguntaAvaliacaoDescritiva;
  multiplaEscolha?: PerguntaAvaliacaoMultiplaEscolha[];
  nota: PerguntaAvaliacaoNota;
  deletedAt: Date;
  posicao: number;
}
