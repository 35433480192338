import { useMemo, useState } from 'react';
import { withMask } from 'use-mask-input';
import Hidden from '@mui/material/Hidden';
import { IControlElement } from '../uischema';
import TextField from '@mui/material/TextField';
import { getFirstError } from '../../utils/get-first-error';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useCrudContext } from '../../components/crud/crud-context';
import { ControlProps, or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { StyledBox, StyledError, StyledSubtitle, StyledTitle } from './styled/descritiva';

interface MinMax {
  minimo: number;
  maximo: number;
}

const NotaRenderComponent = (props: ControlProps) => {
  const error = undefined;
  const ctx = useCrudContext();
  const { validationMode } = ctx;
  const uischema = props.uischema as IControlElement;
  const { path, data, handleChange, label, visible } = props;
  const [localErrors, setLocalErrors] = useState<string | undefined>('');
  const decimals = uischema?.options?.decimals ?? 1;

  const getMinMax = (): MinMax => {
    const obj = props.rootSchema.properties;
    const keys = path.split('.');

    let currentObj = obj;

    for (const key of keys) {
      if (!('properties' in currentObj[key])) {
        const { minimo, maximo } = currentObj[key] as MinMax;
        return { minimo, maximo };
      }

      currentObj = currentObj[key].properties;
    }
    return { minimo: 1, maximo: 10 };
  };

  const { minimo, maximo }: MinMax = getMinMax();
  const length = useMemo(() => ({ minimo, maximo }), [minimo, maximo]);

  const lengthTitleLabel = () => {
    if (minimo === 0 && maximo === 0) return '';
    else if (minimo >= 0 && maximo > 0) return `(Dê uma nota de ${minimo} a ${maximo})`;
    else if (minimo === 0 && maximo > 0) return `(Dê uma nota de até ${maximo})`;
    else if (minimo > 0 && maximo === 0) return `(Dê uma nota de ${minimo} ou mais)`;
    else return '';
  };

  const handleChangeNota = (value: string) => {
    handleChange(path, value === '' ? undefined : value);

    if (length.maximo && Number(value) > length.maximo) {
      setLocalErrors(`Nota máxima permitida: ${length.maximo}`);
    } else if (length.minimo && Number(value) < length.minimo) {
      setLocalErrors(`Nota mínima permitida: ${length.minimo}`);
    } else {
      setLocalErrors(undefined);
    }

    if (decimals > 0) {
      const regex = new RegExp(`^\\d+(\\.\\d{1,${decimals}})?$`);
      if (!regex.test(value)) {
        setLocalErrors(`Máximo de ${decimals} casas decimais`);
      }
    } else if (decimals === 0) {
      const regex = new RegExp(`^\\d+$`);
      if (!regex.test(value)) {
        setLocalErrors(`Somente números inteiros`);
      }
    }
  };

  return (
    <Hidden xsUp={!visible}>
      <StyledBox>
        {label && (
          <StyledTitle error={error}>
            {label}
            {length && (
              <StyledSubtitle error={error} variant='caption'>
                {' ' + lengthTitleLabel()}
              </StyledSubtitle>
            )}{' '}
            {uischema?.options?.required ? '*' : ''}
          </StyledTitle>
        )}

        <TextField
          {...props}
          label=''
          fullWidth
          size='small'
          type='number'
          value={data}
          onChange={(event) => handleChangeNota(event.target.value)}
          inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
        />

        {validationMode === 'ValidateAndShow' && props.errors && (
          <StyledError variant='caption'>{getFirstError(props.errors)}</StyledError>
        )}

        {!props.errors && localErrors && <StyledError variant='caption'>{localErrors}</StyledError>}
      </StyledBox>
    </Hidden>
  );
};

const NotaRendererTester = rankWith(
  6,
  or(
    schemaMatches((schema) => schema.format === 'Nota'),
    uiTypeIs('Nota'),
  ),
);

export const NotaRender: any = {
  tester: NotaRendererTester,
  renderer: withJsonFormsControlProps(NotaRenderComponent),
};
