import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Loading from '../crud/loading';

export type CkEditorSize = {
  width?: number;
  height?: number;
};

interface EditorProps {
  isDisabled?: boolean;
  placeholder?: string;
  initialValue?: string;
  size?: CkEditorSize;
  toolbar?: string[];
  onChange: (editorData: string) => void;
}

const Editor: React.FC<EditorProps> = ({
  isDisabled,
  placeholder,
  initialValue,
  size,
  onChange,
  toolbar,
}) => {
  const [loading, setLoading] = useState(false);

  const customConfig: any = {};
  if (toolbar) customConfig['toolbar'] = toolbar;

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        width: size?.width ? `${size?.width}px` : '100%',
        '& .ck-editor__editable_inline': {
          minHeight: size?.height ? `${size?.height}px` : '200px',
          height: size?.height ? `${size?.height}px` : '200px',
          width: size?.width ? `${size?.width}px` : '100%',
        },
      }}
    >
      {loading ? (
        <CKEditor
          disabled={isDisabled}
          editor={ClassicEditor}
          config={{
            language: 'pt-br',
            placeholder: placeholder,
            ...customConfig,
          }}
          onChange={(event, editor) => {
            onChange(editor?.getData?.());
          }}
          data={initialValue ?? ''}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Box>
  );
};

export default Editor;
