import styled from '@emotion/styled';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Button } from '@mui/material';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Box from '@mui/material/Box';
import Circle from '@mui/icons-material/RadioButtonUnchecked';
import {
  BaseButton,
  DisabledButton,
} from '../../../components/layout/components/button/base-button';

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#1351B4',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#1351B4',
  }),
}));

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#000000',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#000000',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#000000',
    borderTopWidth: 1,
    borderRadius: '267px',
    width: 26,
  },
}));

export const StyledCircleIcon = styled(Circle)`
  width: 11px;
`;

export const StyledStepper = styled(Stepper)`
  position: fixed;
  background-color: #fafafa;
  z-index: 10;
  padding-left: 10rem;
  padding-right: 10rem;
`;

export const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    font-family: 'Raleway', sans-serif;
    font-weight: 1000;
    font-size: 16px;
    text-transform: none;
  }
`;

export const ButtonWrapperStyled = styled(Box)`
  text-align: right;
  position: sticky;
  bottom: 10%;
  gap: 10px;
  display: flex;
  flex-direction: row-reverse;  
  padding: 10px;
  background-color: #fafafa;
  border-radius: 10px;
  margin-left: auto;
  width: fit-content;
}
`;

export const NextButtonStyled = styled(Button)`
  ${BaseButton}
  float: right;
  color: white;

  &:hover {
    background-color: var(--color-button);
    filter: brightness(0.9);
  }

  &.Mui-disabled {
    ${DisabledButton}
  }
`;

export const PrevButtonStyled = styled(Button)`
  ${BaseButton}

  color: var(--color-button);
  height: 36px;
  border: 1px solid var(--color-button);
  background-color: white;

  &:hover {
    background-color: var(--color-button-disabled);
  }

  &.Mui-disabled {
    ${DisabledButton}
  }
`;
