import { ControlProps, or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import {
  DescritivaTextArea,
  FooterContent,
  ResizeHandleIconStyled,
  StyledBox,
  StyledError,
  StyledSubtitle,
  StyledTitle,
  TextAreaContainer,
  TextAreaFooter,
  TextLengthIndicator,
} from './styled/descritiva';
import { IControlElement } from '../uischema';
import { Hidden } from '@mui/material';

const TextAreaRenderComponent = (props: ControlProps) => {
  const { path, data, handleChange, id, enabled, config, label, visible } = props;

  const getMinMaxFromPath = (obj: any, path: string): { min: number; max: number } => {
    const keys = path.split('.');

    let currentObj = obj;

    for (const key of keys) {
      if (!('properties' in currentObj[key])) {
        return { min: currentObj[key].min, max: currentObj[key].max };
      }

      currentObj = currentObj[key].properties;
    }
    return { min: 0, max: 255 };
  };

  const { min, max } = getMinMaxFromPath(props.rootSchema.properties, path);

  const length: { min: number; max: number } = { min, max };
  const [localErrors, setLocalErrors] = useState<string | undefined>('');

  const ctx = useCrudContext();
  const { validationMode } = ctx;
  const uischema = props.uischema as IControlElement;

  const error = undefined;

  const numberOfWords = (text: string) => {
    if (!text) return 0;
    return text.split(' ').filter((word) => word !== '').length;
  };

  useEffect(() => {
    if (length.min && length.min > 0) {
      if (!data || (data && numberOfWords(data) < length.min)) {
        setLocalErrors(`No mínimo ${length.min} palavras *`);
      } else {
        setLocalErrors(undefined);
      }
    }
  }, [data]);

  const customHandleChange = (value: string) => {
    if (length.max && numberOfWords(value) > length.max) {
      handleChange(path, value.split(' ').slice(0, length.max).join(' ') + ' ');
    } else {
      handleChange(path, value === '' ? undefined : value);
    }
  };

  const lengthTitleLabel = () => {
    const min = length.min > 0 ? `Mínimo ${length.min}` : undefined;
    const max = length.max > 0 ? `Máximo ${length.max}` : undefined;
    if (!min && !max) return '';
    else if (min && max) {
      return ` (${min}. ${max} palavras)`;
    } else {
      return ` (${min || ''}${max || ''} palavras)`;
    }
  };

  const lengthIndicatorLabel = () => {
    const count = numberOfWords(data);

    if (length.max === 0) return count;
    return `${count}/${length.max}`;
  };

  return (
    <Hidden xsUp={!visible}>
      <StyledBox>
        {label && (
          <StyledTitle error={error}>
            {label}
            {length && (
              <StyledSubtitle error={error} variant='caption'>
                {lengthTitleLabel()}
              </StyledSubtitle>
            )}{' '}
            {uischema?.options?.required ? '*' : ''}
          </StyledTitle>
        )}

        <TextAreaContainer error={error}>
          <DescritivaTextArea
            id={id}
            value={data || ''}
            disabled={!enabled}
            autoFocus={uischema?.options?.focus}
            placeholder={'Escreva seu texto aqui'}
            onChange={(ev) => customHandleChange(ev.target.value)}
          />

          <TextAreaFooter>
            <FooterContent>
              <TextLengthIndicator variant='caption'>{lengthIndicatorLabel()}</TextLengthIndicator>
              <div style={{ marginTop: '8px' }}>
                <ResizeHandleIconStyled />
              </div>
            </FooterContent>
          </TextAreaFooter>
        </TextAreaContainer>

        {validationMode === 'ValidateAndShow' && props.errors && (
          <StyledError variant='caption'>{getFirstError(props.errors)}</StyledError>
        )}

        {!props.errors && localErrors && <StyledError variant='caption'>{localErrors}</StyledError>}
      </StyledBox>
    </Hidden>
  );
};

const TextAreaRendererTester = rankWith(
  6,
  or(
    schemaMatches((schema) => schema.format === 'Descritiva'),
    uiTypeIs('Descritiva'),
  ),
);

export const TextAreaRender: any = {
  tester: TextAreaRendererTester,
  renderer: withJsonFormsControlProps(TextAreaRenderComponent),
};
