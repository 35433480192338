import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { InstrumentoJuridico } from '../../../models/instrumento-juridico';
import { tipoInstrumentoJuridicoI18N } from '../../../models/tipo-instrumento-juridico';

export const moduleName = 'ORCAMENTO.INSTRUMENTO_JURIDICO.INSTRUMENTO_JURIDICO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Instrumentos Jurídicos';

export const headCells: readonly HeadCell<InstrumentoJuridico>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'tipoInstrumentoJuridico',
    numeric: true,
    disablePadding: false,
    label: 'Tipo',
    resource: {
      target: (row: InstrumentoJuridico): string => {
        return tipoInstrumentoJuridicoI18N[row.tipoInstrumentoJuridico];
      },
    },
  },
  {
    id: 'inicioVigencia',
    numeric: false,
    disablePadding: false,
    label: 'Início da Vigência',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'terminoVigencia',
    numeric: false,
    disablePadding: false,
    label: 'Término da Vigência',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'valorFirmado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Firmado',
    mask: 'money',
  },
  {
    id: 'valorFinal',
    numeric: true,
    disablePadding: false,
    label: 'Valor Final',
    mask: 'money',
  },
  {
    id: 'valorDesembolso',
    numeric: true,
    disablePadding: false,
    label: 'Valor Desembolso',
    mask: 'money',
  },
  {
    id: 'desembolsoPago',
    numeric: true,
    disablePadding: false,
    label: 'Desembolso Pago',
    mask: 'money',
  },
  {
    id: 'valorAlocado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Alocado',
    mask: 'money',
  },
];
