import ListItemText from '@mui/material/ListItemText';
import { cloneElement, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CrudStatesOptions } from '../../../../jsonforms/uischema';
import { BreadcrumbsContext } from '../../../breadcrumb/breadcrumb-context';
import { useCrudContext } from '../../../crud/crud-context';
import { StyledListItemButton, StyledListItemIcon } from './nav-item.styled';
export interface NavItemProps {
  title: string;
  route: string;
  icon: any;
  state?: CrudStatesOptions;
  subItem?: boolean;
}

export function NavItem(props: NavItemProps) {
  const { route, state = CrudStatesOptions.LIST, subItem = false, icon, title } = props;

  const { updateCrudStates, clearForm } = useCrudContext();
  const { breadcrumb } = useContext(BreadcrumbsContext);
  let location = useLocation();

  const clickHandler = () => {
    breadcrumb.splice(0, breadcrumb.length);
    updateCrudStates(state);
    clearForm();
  };

  const isActive = location.pathname.split('/')[1] === route.split('/')[1];

  const activeColor = isActive ? '#017096' : '';

  return (
    <Link to={route}>
      <StyledListItemButton
        onClick={() => (location.pathname === route ? {} : clickHandler())}
        sx={{
          backgroundColor: isActive ? '#d4e0e4' : '',
          '&:hover': {
            backgroundColor: isActive ? '#d4e0e4' : '#f0f0f0',
          },
        }}
      >
        <StyledListItemIcon>
          {cloneElement(icon, {
            style: {
              color: activeColor,
            },
          })}
        </StyledListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            style: {
              color: activeColor,
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: 'medium',
            },
          }}
        />
      </StyledListItemButton>
    </Link>
  );
}
