import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Secure } from '../../auth/secure';
import { AddButton } from '../style/data-table.styled';

export interface EnhancedTableToolbarProps {
  numSelected?: number;
  title?: string;
  addHandler?: Function;
  hideCreate?: boolean;
  buttonText?: string;
  customTableHeader?: ReactNode;
  customTableFooter?: ReactNode;
  className?: string;
  moduleName: string;
  ignorePermissions?: boolean;
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    addHandler,
    hideCreate,
    title,
    customTableHeader,
    buttonText,
    customTableFooter,
    className,
    moduleName,
    ignorePermissions = false,
  } = props;

  return (
    <Toolbar
      className={className}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected !== undefined &&
          numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {numSelected !== undefined && numSelected > 0 && !title && (
        <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
          {numSelected} selected
        </Typography>
      )}
      {title && (
        <Typography variant='h6' id='tableTitle' component='div'>
          {title}
        </Typography>
      )}
      {numSelected !== undefined && numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {!hideCreate && !customTableHeader && (
            <Secure
              hasPermission={'CRIAR'}
              moduleName={moduleName}
              ignorePermission={ignorePermissions}
            >
              <Tooltip title='Adicionar' arrow>
                <AddButton
                  onClick={() => {
                    addHandler?.();
                  }}
                >
                  <AddIcon></AddIcon>
                  {buttonText ? buttonText : 'Adicionar'}
                </AddButton>
              </Tooltip>
            </Secure>
          )}
          {customTableHeader}
          {/* TODO: filtrar items do data table 
          <Tooltip title='Filtrar'>
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}
      {customTableFooter}
    </Toolbar>
  );
}
