export enum TipoPergunta {
  informacoesComplementares = 'INFORMACOES_COMPLEMENTARES',
  descricaoProjeto = 'DESCRICAO_PROJETO',
}
export enum TipoResposta {
  descritiva = 'DESCRITIVA',
  data = 'DATA',
  multiplaEscolha = 'MULTIPLA_ESCOLHA',
  multiplaSelecao = 'MULTIPLA_SELECAO',
}
export interface Pergunta {
  id: number;
  pergunta: string;
  tipoPergunta: TipoPergunta;
  perguntaBase?: boolean;
  tipoResposta: TipoResposta;
  obrigatoria: boolean;
  descritiva: PerguntaDescritiva;
  multiplaEscolha: PerguntaMultiplaEscolha[];
  posicao: number;
  deletedAt: Date;
}

export interface PerguntaMultiplaEscolha {
  id?: number;
  alternativa: string;
  perguntaId: number;
  pergunta: Pergunta;
  posicao: number;
}

export interface PerguntaDescritiva {
  id?: number;
  perguntaId: number;
  minimo: number;
  maximo: number;
  pergunta: Pergunta;
  posicao: number;
}
