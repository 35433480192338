import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { SearchInput } from '../../components/search-input/search-input';
import {
  AvisoDescricao,
  ComponentBox,
  Header,
  ListItemAviso,
  ListItemAvisoName,
  Search,
  StyledCustomModal,
  WidthFull,
  WidthFullBox,
  WidthFullListItem,
} from '../avisos/style/todos-avisos';
import { truncateByCharacters } from '../../components/layout/truncate-by-characters';
import { tagsEntitiesHtmlRemoval } from '../../helpers/tags-entities-html-removal';
import Editor from '../../components/layout/ckeditor';

function TodosAvisos() {
  const [data, setData] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedAviso, setSelectedAviso] = useState<string>('');
  const { setCurrentTitle } = useCrudContext();
  const title = 'Avisos';
  const avisosApi = makeApi('/aviso');
  const [open, setOpen] = useState(false);
  const handleOpen = (aviso: string) => {
    setSelectedAviso(aviso);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedAviso('');
    setOpen(false);
  };

  useEffect(() => {
    setCurrentTitle(title);
    avisosApi
      .getAll({ dataAtual: new Date().toISOString(), orderBy: 'dataInicio' })
      .then((x: any[]) => {
        const avisos = x.map((item) => {
          item.truncatedText = truncateByCharacters(tagsEntitiesHtmlRemoval(item.aviso), 255, {
            linkText: 'Ver Mais...',
            callback: () => {
              handleOpen(item.aviso);
            },
          });
          return item;
        });
        setData(avisos);
      });
  }, []);

  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.titulo.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <>
      <StyledCustomModal
        content={
          <Editor
            isDisabled={true}
            initialValue={selectedAviso}
            onChange={function (editorData: string): void {}}
            toolbar={[]}
            size={{ height: 450 }}
          />
        }
        openModalState={open}
        hasWindowCloseBtn={true}
        handleCloseModal={handleClose}
      />
      <ComponentBox>
        <Header>
          <h3>Avisos</h3>
        </Header>
        <Search>
          <SearchInput
            value={searchInput}
            onChange={handleSearchChange}
            placeholder='Pesquisar aviso'
          />
        </Search>
        <WidthFull>
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemAviso disablePadding>
                <WidthFullListItem>
                  <WidthFullBox>
                    <ListItemAvisoName>{item.titulo}</ListItemAvisoName>
                    <AvisoDescricao>{item?.truncatedText}</AvisoDescricao>
                  </WidthFullBox>
                </WidthFullListItem>
              </ListItemAviso>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </WidthFull>
      </ComponentBox>
    </>
  );
}

export default TodosAvisos;
