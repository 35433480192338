import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeApi } from '../../../../api/generic-api';
import { useCrudContext } from '../../../../components/crud/crud-context';
import JsonForm from '../../../../components/crud/json-form';
import { CrudStatesOptions, IUiSchema } from '../../../../jsonforms/uischema';
import { Pergunta } from '../../../../models/pergunta';

const PerguntaDetalhes = () => {
  const { perguntaDetalhesId } = useParams();
  const [pergunta, setPergunta] = useState<Pergunta>(null);
  const perguntaId = Number(perguntaDetalhesId ?? null) ?? null;

  const { updateCrudStates } = useCrudContext();

  const location = useLocation();

  const limiarDeAlternativas = 7;
  let type: string = '';

  const perguntaApi = makeApi<Pergunta>('/pergunta', {
    orderRelationBy: { orderMultiplaEscolhaBy: (a, b) => a.posicao - b.posicao },
  });

  useEffect(() => {
    updateCrudStates(location.state?.crudState ?? CrudStatesOptions.VIEW);
    perguntaApi.get(perguntaId).then((x) => {
      setPergunta(x);
    });
  }, []);

  switch (pergunta?.tipoResposta) {
    case 'DESCRITIVA':
      type = 'Descritiva';
      break;
    case 'MULTIPLA_ESCOLHA':
      type = 'MultiplaEscolha';
      break;
    case 'MULTIPLA_SELECAO':
      if (pergunta?.multiplaEscolha?.length > limiarDeAlternativas) type = 'MultiplaSelecaoGrande';
      else type = 'MultiplaSelecaoPequena';
      break;
    case 'DATA':
      type = 'Date';
      break;
    default:
      type = 'Control';
  }

  const uischema: IUiSchema = {
    type: 'VerticalLayout',
    elements: [
      {
        type,
        scope: '#/properties/field',
        options: {
          required: pergunta?.obrigatoria,
        },
      },
    ],
  };

  const properties = ((pergunta: Pergunta) => {
    let properties = {};
    if (pergunta?.tipoResposta === 'DESCRITIVA') {
      properties = {
        max: pergunta?.descritiva?.maximo,
        min: pergunta?.descritiva?.minimo,
      };
    } else if (
      pergunta?.tipoResposta === 'MULTIPLA_ESCOLHA' ||
      pergunta?.tipoResposta === 'MULTIPLA_SELECAO'
    ) {
      properties = {
        oneOf: pergunta?.multiplaEscolha?.map(({ alternativa, id }) => ({
          const: id,
          title: alternativa,
        })),
      };
    } else if (pergunta?.tipoResposta === 'DATA') {
      properties = {
        title: pergunta?.pergunta,
      };
    }
    return properties;
  })(pergunta);

  const schema = {
    type: 'object',
    properties: {
      field: {
        type: 'string',
        ...properties,
      },
    },
    required: ['field'],
  };

  const data = {
    field: pergunta?.tipoResposta === 'DATA' ? '0000-00-00' : undefined,
  };

  return <JsonForm uischema={uischema} schema={schema} data={data} />;
};

export default PerguntaDetalhes;
