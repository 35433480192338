import React from 'react';
import styled from '@emotion/styled';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';

const WarningContainer = styled(Button)`
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  text-transform: none;
  justify-content: flex-start;
  color: black;

  width: 1170px;
  background-color: #faf2f5;
  border: 1px solid #ffcccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  max-width: 80%;

  @media (max-width: 1758px) {
    width: 76%;
  }

  @media (max-width: 1450px) {
    width: 80%;
  }

  &:hover {
    background-color: #e6dbe0;
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 36px;
  height: 36px;
  color: #ef6c00;
`;

interface WarningAlertProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const WarningAlert: React.FC<WarningAlertProps> = ({ children, onClick }) => {
  return (
    <WarningContainer onClick={onClick}>
      <IconWrapper>
        <StyledWarningIcon />
      </IconWrapper>
      {children}
    </WarningContainer>
  );
};

export default WarningAlert;
