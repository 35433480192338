import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { Action } from './protocols/actions';

interface ActionsTableCellProps {
  action: Action;
  row: any;
}

const ActionButton = (props: ActionsTableCellProps) => {
  const { action, row } = props;
  const { icon, title, handler } = action;

  return (
    <Tooltip title={title} arrow>
      <IconButton
        onClick={() => {
          handler?.(row);
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default ActionButton;
