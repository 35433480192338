export enum TipoInstrumentoJuridico {
  contratoDeRepasse = 'CONTRATO_DE_REPASSE',
  contratoDeSubvencao = 'CONTRATO_DE_SUBVENCAO',
  convenio = 'CONVENIO',
  convenioEContratoDeRepasse = 'CONVENIO_E_CONTRATO_DE_REPASSE',
  termoDeCooperacao = 'TERMO_DE_COOPERACAO',
  termoDeParceria = 'TERMO_DE_PARCERIA',
  superavit = 'SUPERAVIT',
}

export type TipoInstrumentoJuridicoI18N = {
  [key in TipoInstrumentoJuridico]: string;
};

export const tipoInstrumentoJuridicoI18N: TipoInstrumentoJuridicoI18N = {
  [TipoInstrumentoJuridico.contratoDeRepasse]: 'Contrato de Repasse',
  [TipoInstrumentoJuridico.contratoDeSubvencao]: 'Contrato de Subvenção',
  [TipoInstrumentoJuridico.convenio]: 'Convênio',
  [TipoInstrumentoJuridico.convenioEContratoDeRepasse]: 'Convênio e Contrato de Repasse',
  [TipoInstrumentoJuridico.termoDeCooperacao]: 'Termo de Cooperação',
  [TipoInstrumentoJuridico.termoDeParceria]: 'Termo de Parceria',
  [TipoInstrumentoJuridico.superavit]: 'Superávit',
};
