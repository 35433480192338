import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme, { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuthContext } from '../../views/login/auth/auth-context';
import CustomModal from './custom-modal';
import LogoLedes from './images/logo-ledes.png';
import { useState } from 'react';
import Editor from './ckeditor';

const theme = createTheme();

const Footer = () => {
  const blueBase = '#225ABD';
  const isExtraLargeOrLower = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { configuracoesFundacao } = useAuthContext();
  const enderecoObject = configuracoesFundacao?.instituicao?.endereco;
  const paisObject = configuracoesFundacao?.instituicao?.pais;
  const telefone = configuracoesFundacao?.telefone;
  const email = configuracoesFundacao?.email;
  const termoPoliticaDePrivacidadeText = configuracoesFundacao?.politicaDePrivacidade;

  const endereco = enderecoObject
    ? `${enderecoObject.logradouro}, n. 
       ${enderecoObject.numero}, 
       ${enderecoObject.bairro}: - 
       ${enderecoObject.cep} - 
       ${enderecoObject.municipio} - 
       ${enderecoObject.estado}, 
       ${paisObject?.nomePt}`
    : '';

  return (
    <>
      <CustomModal
        title={
          <>
            <b>{'Política Privacidade'}</b>
          </>
        }
        content={
          <Editor
            isDisabled={true}
            initialValue={termoPoliticaDePrivacidadeText}
            onChange={function (editorData: string): void {}}
            toolbar={[]}
            size={{ height: 450 }}
          />
        }
        openModalState={open}
        hasWindowCloseBtn={true}
        handleCloseModal={handleClose}
      />
      <Grid
        container
        spacing={2}
        component='footer'
        className='footer'
        style={{ paddingBottom: '4px' }}
      >
        <ThemeProvider theme={theme}>
          {!isExtraLargeOrLower ? (
            <>
              <Grid item xs={2}>
                <div className='start'></div>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  className='mid'
                  align='center'
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Grid item xs={4} style={{ maxWidth: '15%' }}>
                    <a onClick={handleOpen} href='#' style={{ color: blueBase }}>
                      Políticas de Privacidade
                    </a>
                  </Grid>
                  <Grid item xs={2} style={{ maxWidth: '2%' }}>
                    <span style={{ color: blueBase, paddingLeft: 0, paddingRight: 0 }}>|</span>
                  </Grid>
                  <Grid item xs={2} style={{ maxWidth: '4%' }}>
                    <a style={{ color: blueBase }}>Termos</a>
                  </Grid>
                  <Grid item xs={2} style={{ maxWidth: '2%' }}>
                    <span style={{ color: blueBase }}>|</span>
                  </Grid>
                  <Grid item xs={2} style={{ maxWidth: '4%' }}>
                    <a style={{ color: blueBase }}>Cookies</a>
                  </Grid>
                </Typography>
                <Typography
                  className='mid'
                  align='center'
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Grid item xs={5} style={{ maxWidth: '14%' }}>
                    <a
                      href={`mailto:${email}`}
                      style={{ textDecoration: 'underline', color: blueBase }}
                    >
                      {email}
                    </a>
                  </Grid>
                  <Grid item xs={2} style={{ maxWidth: '2%' }}>
                    <span style={{ color: blueBase }}>|</span>
                  </Grid>
                  <Grid item xs={5} style={{ maxWidth: '15%' }}>
                    <a style={{ color: blueBase }} href='tel:+556733166700'>
                      Tel/Fax: {telefone}
                    </a>
                  </Grid>
                </Typography>
                <Typography
                  className='mid'
                  align='center'
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Grid item xs={12}>
                    <a href='#' style={{ color: blueBase }}>
                      {endereco}
                    </a>
                  </Grid>
                </Typography>
              </Grid>
            </>
          ) : (
            ''
          )}
          <Grid
            item
            xs={!isExtraLargeOrLower ? 2 : 12}
            textAlign={'center'}
            display={'flex'}
            flexDirection={'column'}
          >
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={!isExtraLargeOrLower ? 'flex-end' : 'center'}
              marginRight={'30px'}
            >
              <Grid item xs={6} style={{ maxWidth: '28%' }}>
                <b style={{ fontSize: '12px', color: '#225ABD' }}>FUNDECT</b>
              </Grid>
              <Grid item xs={1} style={{ maxWidth: '2%' }}>
                <span style={{ color: blueBase }}>|</span>
              </Grid>
              <Grid item xs={5} style={{ maxWidth: '14%' }}>
                <img
                  src={LogoLedes}
                  alt='Logo'
                  style={{ width: '40px', height: '35px', marginLeft: '5px', marginBottom: '0px' }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={'row-reverse'}
              justifyContent={!isExtraLargeOrLower ? 'flex-end' : 'center'}
              marginLeft={!isExtraLargeOrLower ? '75%' : '27.5%'}
            >
              <span style={{ fontSize: '8px', color: blueBase }}>&copy; 2024 LEDES</span>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </>
  );
};

export default Footer;
