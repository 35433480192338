import { JsonSchema } from '@jsonforms/core';

export const getParentSchema = (rootSchema: JsonSchema, path: string): JsonSchema =>
  path
    .split('.')
    .slice(0, -1)
    .reduce(
      (acc, data) =>
        Object.entries(acc.properties)
          .find(([key]) => key === data)
          .pop() || acc,
      rootSchema,
    );
