import styled from '@emotion/styled';
import ButtonBase from '@mui/material/ButtonBase';

interface ButtonCustomProps {
  disabled?: boolean;
  variant?: 'filled' | 'outline' | 'none';
}

const ButtonCustom = styled(ButtonBase)<ButtonCustomProps>(({ disabled, variant }) => ({
  padding: variant !== 'none' ? '10px 30px' : 0,
  backgroundColor:
    variant === 'outline'
      ? 'transparent'
      : variant === 'none'
        ? 'transparent'
        : disabled
          ? '#d7d5d6'
          : '#1351b4',
  color:
    variant === 'outline'
      ? '#1351b4'
      : variant === 'none'
        ? '#1351b4'
        : disabled
          ? '#8e8e91'
          : '#FFFFFF',
  border: variant === 'outline' ? '1px solid #1351b4' : variant === 'none' ? 'none' : 'none',
  fontFamily: 'Raleway',
  gap: 8,
  fontSize: 16,
  borderRadius: '4px',
}));

export default ButtonCustom;
