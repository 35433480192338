import Typography from '@mui/material/Typography';
import { withJsonFormsLabelProps } from '@jsonforms/react';
import { useCrudContext } from '../../components/crud/crud-context';
import { LabelProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { useEffect, useState } from 'react';

const LabelRenderComponent = (props: LabelProps) => {
  const { uischema } = props;
  const [labelProps, setLabelProps] = useState<any>({});
  const [value, setValue] = useState<string | null>(null);
  const { formData } = useCrudContext();

  useEffect(() => {
    const label = uischema?.options?.formatOptionLabel?.(formData) ?? null;
    setValue(label);
  }, [formData]);

  useEffect(() => {
    const props = uischema.options?.componentProps ?? {};
    const sx = props.sx ?? {};
    setLabelProps({
      ...props,
      sx,
    });
  }, []);

  return <Typography {...labelProps}>{value}</Typography>;
};

const LabelRendererTester: RankedTester = rankWith(5, uiTypeIs('Label'));

export const LabelRender: any = {
  tester: LabelRendererTester,
  renderer: withJsonFormsLabelProps(LabelRenderComponent),
};
