import Typography from '@mui/material/Typography';
import { Dayjs } from 'dayjs';
import dayjs from '../dayjs/sig-dayjs';
import {
  CallendarRangeIcon,
  ComponentContent,
  StyledListItemText,
  Vigencia,
  VigenciaLabel,
} from './style/validity-checker';
import {
  changeTimeFromDate,
  compareDates,
  normalize,
  ptBrformatDate,
} from '../pt-br-format-date/pt-br-format-date';

const isNearToClose = (date: Dayjs) => {
  const nearDateToClose = changeTimeFromDate(date, -72);
  const currentDate = dayjs();
  return compareDates(currentDate, nearDateToClose, '>=') ? '#BA1A1A' : '#44464F';
};

const ValidityChecker = (item: any): JSX.Element => {
  const edital = item.item;

  if (Object.keys(edital).length === 0) {
    return <Typography>Loading...</Typography>;
  }

  console.log(edital);

  const chamadaVigente = edital.chamadaVigente ?? edital.chamadaEdital?.[0];
  const inicio = normalize(chamadaVigente?.inicio);
  const fim = normalize(chamadaVigente?.termino);
  const color = isNearToClose(fim);

  return (
    <StyledListItemText
      primary={
        <>
          <Typography variant='body1'>{item.nome}</Typography>
          <Typography variant='body2'>
            <ComponentContent>
              <CallendarRangeIcon />
              <VigenciaLabel>Vigência:</VigenciaLabel>
              <Vigencia colorValue={color}>
                {ptBrformatDate(inicio)} - {ptBrformatDate(fim)}
              </Vigencia>
            </ComponentContent>
          </Typography>
        </>
      }
    />
  );
};

export default ValidityChecker;
