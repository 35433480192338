import { formatCurrency } from '../../../helpers/format-currency';
import { insertDecimals } from '../../../helpers/insert-decimals';
import {
  HeaderModel,
  RowsModel,
  SubtitleModel,
} from '../../../jsonforms/renderers/custom-table-view-render';
import { BolsaProposta } from '../../../models/bolsa-proposta';
import { DiariaProposta } from '../../../models/diaria-proposta';
import {
  EditalRubrica,
  TipoEditalRubrica,
  tipoEditalRubricaI18N,
} from '../../../models/edital-rubrica';
import { MoedaEstrangeira } from '../../../models/moeda-estrangeira';
import { PessoalProposta } from '../../../models/pessoal-proposta';
import {
  EncargoProposta,
  HospedagemAlimentacaoProposta,
  MaterialConsumoProposta,
  MaterialPermanenteProposta,
  PassagemProposta,
  Proposta,
  ServicoTerceiroProposta,
} from '../../../models/proposta';

interface CalculoRubricaResult {
  reais: number;
  moedaEstrangeira: Record<number, number>;
  contrapartida: number;
  contrapartidaEstrangeira: Record<number, number>;
}

const calcularTotais = <T>(
  items: T[],
  getTotal: (item: T) => {
    total: number;
    contrapartida: boolean;
    emMoedaEstrangeira: boolean;
    moedaId?: number;
  },
): CalculoRubricaResult => {
  return items.reduce<CalculoRubricaResult>(
    (acc, item) => {
      const { total, contrapartida, emMoedaEstrangeira, moedaId } = getTotal(item);

      if (contrapartida) {
        if (emMoedaEstrangeira && moedaId !== undefined) {
          acc.contrapartidaEstrangeira[moedaId] =
            (acc.contrapartidaEstrangeira[moedaId] || 0) + total;
        } else {
          acc.contrapartida += total;
        }
      } else if (emMoedaEstrangeira && moedaId !== undefined) {
        acc.moedaEstrangeira[moedaId] = (acc.moedaEstrangeira[moedaId] || 0) + total;
      } else {
        acc.reais += total;
      }

      return acc;
    },
    {
      reais: 0,
      moedaEstrangeira: {},
      contrapartida: 0,
      contrapartidaEstrangeira: {},
    },
  );
};

const calcularDiariaTotal = (diaria: DiariaProposta) => {
  const total = insertDecimals((diaria.numeroDiaria ?? 0) * (diaria.custoUnitario ?? 0));
  return {
    total,
    contrapartida: diaria.contrapartida,
    emMoedaEstrangeira: diaria.emMoedaEstrangeira,
    moedaId: diaria.emMoedaEstrangeira ? diaria.moedaEstrangeiraId : undefined,
  };
};

const calcularMaterialConsumoTotal = (material: MaterialConsumoProposta) => {
  const total = insertDecimals((material.quantidade ?? 0) * (material.custoUnitario ?? 0));
  return {
    total,
    contrapartida: material.contrapartida,
    emMoedaEstrangeira: material.emMoedaEstrangeira,
    moedaId: material.emMoedaEstrangeira ? material.moedaEstrangeiraId : undefined,
  };
};

const calcularMaterialPermanenteTotal = (material: MaterialPermanenteProposta) => {
  const total = (material.quantidade ?? 0) * (material.custoUnitario ?? 0);
  return {
    total,
    contrapartida: material.contrapartida,
    emMoedaEstrangeira: material.emMoedaEstrangeira,
    moedaId: material.emMoedaEstrangeira ? material.moedaEstrangeiraId : undefined,
  };
};

const calcularPassagemTotal = (passagem: PassagemProposta) => {
  const total = (passagem.quantidade ?? 0) * (passagem.custoUnitario ?? 0);
  return {
    total,
    contrapartida: passagem.contrapartida,
    emMoedaEstrangeira: passagem.emMoedaEstrangeira,
    moedaId: passagem.emMoedaEstrangeira ? passagem.moedaEstrangeiraId : undefined,
  };
};

const calcularHospedagemTotal = (hospedagem: HospedagemAlimentacaoProposta) => {
  const total = (hospedagem.quantidade ?? 0) * (hospedagem.custoUnitario ?? 0);
  return {
    total,
    contrapartida: hospedagem.contrapartida,
    emMoedaEstrangeira: hospedagem.emMoedaEstrangeira,
    moedaId: hospedagem.emMoedaEstrangeira ? hospedagem.moedaEstrangeiraId : undefined,
  };
};

const calcularServicoTerceiroTotal = (servico: ServicoTerceiroProposta) => {
  return {
    total: servico.custoUnitario ?? 0,
    contrapartida: servico.contrapartida,
    emMoedaEstrangeira: servico.emMoedaEstrangeira,
    moedaId: servico.emMoedaEstrangeira ? servico.moedaEstrangeiraId : undefined,
  };
};

const calcularEncargoTotal = (encargo: EncargoProposta) => {
  return {
    total: encargo.custoUnitario ?? 0,
    contrapartida: encargo.contrapartida,
    emMoedaEstrangeira: encargo.emMoedaEstrangeira,
    moedaId: encargo.emMoedaEstrangeira ? encargo.moedaEstrangeiraId : undefined,
  };
};

const calcularPessoal = (pessoal: PessoalProposta) => {
  return {
    total: pessoal.valor ?? 0,
    contrapartida: pessoal.contrapartida,
    emMoedaEstrangeira: pessoal.emMoedaEstrangeira,
    moedaId: pessoal.emMoedaEstrangeira ? pessoal.moedaEstrangeiraId : undefined,
  };
};

const calcularBolsas = (bolsa: BolsaProposta) => {
  return {
    total: bolsa.valorTotal ?? 0,
    contrapartida: bolsa.contrapartida,
    emMoedaEstrangeira: undefined,
    moedaId: undefined,
  };
};

export const calcularRubricas = (proposta: Proposta) => {
  let columns: { rubrica: string; calculo: CalculoRubricaResult | null }[] = [];

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.diarias,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.diarias,
      calculo:
        proposta?.diariaProposta?.length > 0
          ? calcularTotais(proposta?.diariaProposta, calcularDiariaTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.materialDeConsumo,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.materialDeConsumo,
      calculo:
        proposta?.materialConsumoProposta?.length > 0
          ? calcularTotais(proposta?.materialConsumoProposta, calcularMaterialConsumoTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.materialPermanenteEquipamentos,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.materialPermanenteEquipamentos,
      calculo:
        proposta?.materialPermanenteProposta?.length > 0
          ? calcularTotais(proposta?.materialPermanenteProposta, calcularMaterialPermanenteTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.passagens,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.passagens,
      calculo:
        proposta?.passagemProposta?.length > 0
          ? calcularTotais(proposta?.passagemProposta, calcularPassagemTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.hospedagemAlimentacao,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.hospedagemAlimentacao,
      calculo:
        proposta?.hospedagemAlimentacaoProposta?.length > 0
          ? calcularTotais(proposta?.hospedagemAlimentacaoProposta, calcularHospedagemTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.encargos,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.encargos,
      calculo:
        proposta?.encargoProposta?.length > 0
          ? calcularTotais(proposta?.encargoProposta, calcularEncargoTotal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.pessoal,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.pessoal,
      calculo:
        proposta?.pessoalProposta?.length > 0
          ? calcularTotais(proposta?.pessoalProposta, calcularPessoal)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.bolsa,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.bolsa,
      calculo:
        proposta?.bolsaProposta?.length > 0
          ? calcularTotais(proposta?.bolsaProposta, calcularBolsas)
          : null,
    });
  }

  if (
    proposta?.edital?.editalRubrica?.some(
      (rubrica) => rubrica.tipoEditalRubrica === TipoEditalRubrica?.servicosDeTerceiros,
    )
  ) {
    columns.push({
      rubrica: tipoEditalRubricaI18N.servicosDeTerceiros,
      calculo:
        proposta?.servicoTerceiroProposta?.length > 0
          ? calcularTotais(proposta?.servicoTerceiroProposta, calcularServicoTerceiroTotal)
          : null,
    });
  }

  return columns;
};

export const getOrcamentoConsolidadoTable = (formData: Proposta) => {
  if (formData.edital?.editalRubrica) {
    const headers: HeaderModel[] = [
      { value: 'Nome', texto: 'Nome', colspan: 1 },
      { value: 'R$', texto: 'R$', colspan: 1, customSx: { textAlign: 'center' } },
    ];

    const rubricaResult = calcularRubricas(formData);
    let uniqueMoedaIds: Set<number> = new Set();
    let contrapartidaMoedaIds: Set<number> = new Set();
    let hasContrapartida = false;
    let contrapartidaColspan = 1;
    let rubricaColspan = 2;

    let totalReais = 0;
    let totalContrapartidaReais = 0;
    const totalMoedaEstrangeira: Record<number, number> = {};
    const totalContrapartidaMoedaEstrangeira: Record<number, number> = {};

    const cotacoes = getCotacoes(formData);

    rubricaResult.forEach(({ rubrica, calculo }) => {
      if (calculo) {
        Object.keys(calculo.moedaEstrangeira).forEach((moedaId) => {
          uniqueMoedaIds.add(Number(moedaId));
        });

        if (calculo.contrapartida > 0 || Object.keys(calculo.contrapartidaEstrangeira).length > 0) {
          hasContrapartida = true;
        }

        Object.keys(calculo.contrapartidaEstrangeira).forEach((moedaId) => {
          contrapartidaMoedaIds.add(Number(moedaId));
        });
      }
    });

    const sortedUniqueMoedaIds = Array.from(uniqueMoedaIds).sort((a, b) => a - b);
    const sortedContrapartidaMoedaIds = Array.from(contrapartidaMoedaIds).sort((a, b) => a - b);

    const moedaEstrangeiraRubrica = Array.from(
      formData.edital?.editalRubrica
        .reduce((map, editalRubrica: EditalRubrica) => {
          editalRubrica?.moedaEstrangeira.forEach((moeda) => {
            if (sortedUniqueMoedaIds.includes(moeda.id) && !map.has(moeda.id)) {
              map.set(moeda.id, moeda);
            }
          });
          return map;
        }, new Map<number, MoedaEstrangeira>())
        .values(),
    );

    moedaEstrangeiraRubrica.forEach((moeda) => {
      headers.push({
        value: moeda.simbolo,
        texto: moeda.simbolo,
        colspan: 1,
        customSx: { textAlign: 'center' },
      });
      totalMoedaEstrangeira[moeda.id] = 0;
    });

    if (hasContrapartida) {
      headers.push({
        value: 'Contrapartida R$',
        texto: 'R$',
        colspan: 1,
        customSx: { textAlign: 'center' },
      });
    }

    const moedaEstrangeiraContrapartida = Array.from(
      formData.edital?.editalRubrica
        .reduce((map, editalRubrica: EditalRubrica) => {
          editalRubrica?.moedaEstrangeira.forEach((moeda) => {
            if (sortedContrapartidaMoedaIds.includes(moeda.id) && !map.has(moeda.id)) {
              map.set(moeda.id, moeda);
            }
          });
          return map;
        }, new Map<number, MoedaEstrangeira>())
        .values(),
    );

    moedaEstrangeiraContrapartida.forEach((moeda) => {
      headers.push({
        value: `Contrapartida Moeda ${moeda.simbolo}`,
        texto: moeda.simbolo,
        colspan: 1,
        customSx: { textAlign: 'center' },
      });
      totalContrapartidaMoedaEstrangeira[moeda.id] = 0;
    });

    const columns: RowsModel[] = rubricaResult.map(({ rubrica, calculo }) => {
      const row: RowsModel = {
        value: [
          { header: 'Nome', textValue: rubrica, customSx: { fontWeight: 700 } },
          {
            header: 'R$',
            textValue: formatCurrency(calculo?.reais ?? 0),
            customSx: { textAlign: 'center' },
          },
        ],
      };

      totalReais += calculo?.reais || 0;

      moedaEstrangeiraRubrica.forEach((moeda) => {
        const valorMoeda = calculo?.moedaEstrangeira[moeda.id] || 0;
        row.value.push({
          header: moeda.simbolo,
          textValue: formatCurrency(valorMoeda, moeda.sigla),
          customSx: { textAlign: 'center' },
        });
        totalMoedaEstrangeira[moeda.id] += valorMoeda;
      });

      if (hasContrapartida) {
        row.value.push({
          header: 'Contrapartida R$',
          textValue: formatCurrency(calculo?.contrapartida ?? 0),
          customSx: { textAlign: 'center' },
        });
        totalContrapartidaReais += calculo?.contrapartida || 0;
      }

      moedaEstrangeiraContrapartida.forEach((moeda) => {
        const valorContrapartidaMoeda = calculo?.contrapartidaEstrangeira[moeda.id] || 0;
        row.value.push({
          header: `Contrapartida Moeda ${moeda.simbolo}`,
          textValue: formatCurrency(valorContrapartidaMoeda, moeda.sigla),
          customSx: { textAlign: 'center' },
        });
        totalContrapartidaMoedaEstrangeira[moeda.id] += valorContrapartidaMoeda;
      });

      const total =
        calculo?.reais +
        (calculo?.contrapartida || 0) +
        moedaEstrangeiraRubrica.reduce((sum, moeda) => {
          const valorMoeda = calculo?.moedaEstrangeira[moeda.id] || 0;
          const cotacaoMoeda = insertDecimals(cotacoes[moeda.id] ?? '');
          const valorComCotacao = cotacaoMoeda ? valorMoeda * cotacaoMoeda : valorMoeda;
          return sum + valorComCotacao;
        }, 0) +
        moedaEstrangeiraContrapartida.reduce((sum, moeda) => {
          const valorContrapartidaMoeda = calculo?.contrapartidaEstrangeira[moeda.id] || 0;
          const cotacaoContrapartidaMoeda = insertDecimals(cotacoes[moeda.id] ?? '');
          const valorContrapartidaComCotacao = cotacaoContrapartidaMoeda
            ? valorContrapartidaMoeda * cotacaoContrapartidaMoeda
            : valorContrapartidaMoeda;
          return sum + valorContrapartidaComCotacao;
        }, 0);

      row.value.push({
        header: 'Total R$',
        textValue: formatCurrency(isNaN(total) ? 0 : total),
        customSx: { textAlign: 'center', fontWeight: 700 },
      });

      return row;
    });

    const totalRow: RowsModel = {
      value: [
        { header: 'Nome', textValue: 'Valor Total', customSx: { fontWeight: 700 } },
        {
          header: 'R$',
          textValue: formatCurrency(totalReais),
          customSx: { textAlign: 'center', fontWeight: 700 },
        },
      ],
    };

    moedaEstrangeiraRubrica.forEach((moeda) => {
      totalRow.value.push({
        header: moeda.simbolo,
        textValue: formatCurrency(totalMoedaEstrangeira[moeda.id], moeda.sigla),
        customSx: { textAlign: 'center', fontWeight: 700 },
      });
      rubricaColspan++;
    });

    totalRow.value.push({
      header: 'Contrapartida R$',
      textValue: formatCurrency(totalContrapartidaReais),
      customSx: { textAlign: 'center', fontWeight: 700 },
    });

    moedaEstrangeiraContrapartida.forEach((moeda) => {
      totalRow.value.push({
        header: `Contrapartida Moeda ${moeda.simbolo}`,
        textValue: formatCurrency(totalContrapartidaMoedaEstrangeira[moeda.id], moeda.sigla),
        customSx: { textAlign: 'center', fontWeight: 700 },
      });
      contrapartidaColspan++;
    });

    if (formData.edital?.editalRubrica?.length > 1) {
      const calculoTotal =
        totalReais +
        Object.keys(totalMoedaEstrangeira).reduce((acc, moedaId) => {
          const valorMoeda = totalMoedaEstrangeira[moedaId] || 0;
          const cotacaoMoeda = insertDecimals(cotacoes[moedaId] ?? '');
          const valorComCotacao = cotacaoMoeda ? valorMoeda * cotacaoMoeda : valorMoeda;
          return acc + valorComCotacao;
        }, 0) +
        Object.keys(totalContrapartidaMoedaEstrangeira).reduce((acc, moedaId) => {
          const valorContrapartidaMoeda = totalContrapartidaMoedaEstrangeira[moedaId] || 0;
          const cotacaoContrapartidaMoeda = insertDecimals(cotacoes[moedaId] ?? '');
          const valorContrapartidaComCotacao = cotacaoContrapartidaMoeda
            ? valorContrapartidaMoeda * cotacaoContrapartidaMoeda
            : valorContrapartidaMoeda;
          return acc + valorContrapartidaComCotacao;
        }, 0) +
        totalContrapartidaReais;

      totalRow.value.push({
        header: 'Total R$',
        textValue: formatCurrency(isNaN(calculoTotal) ? 0 : calculoTotal),
        customSx: { textAlign: 'center', fontWeight: 700 },
      });

      columns.push(totalRow);
    }
    let subtitles: SubtitleModel[] = [
      {
        texto: 'Rubrica',
        colspan: rubricaColspan,
        customSx: { textAlign: 'center' },
      },
    ];

    if (hasContrapartida) {
      subtitles.push({
        texto: 'Contrapartida',
        colspan: contrapartidaColspan,
        customSx: { textAlign: 'center' },
      });
    }

    if (
      hasContrapartida ||
      moedaEstrangeiraRubrica.length > 0 ||
      moedaEstrangeiraContrapartida.length > 0
    ) {
      headers.push({
        value: 'Total R$',
        texto: 'R$',
        colspan: 1,
        customSx: { textAlign: 'center' },
      });
      subtitles.push({
        texto: 'Total',
        colspan: 1,
        customSx: { textAlign: 'center' },
      });
    }

    return {
      headers: headers,
      columns: columns,
      subtitle: subtitles,
    };
  } else {
    return {
      headers: [],
      columns: [],
      subtitle: [],
    };
  }
};

export const getMoedasEstrangeirasUnificadas = (formData: Proposta) => {
  if (formData?.edital?.editalRubrica) {
    const rubricaResult = calcularRubricas(formData);
    const uniqueMoedaIds = new Set<number>();
    const contrapartidaMoedaIds = new Set<number>();

    rubricaResult.forEach(({ rubrica, calculo }) => {
      if (calculo) {
        Object.keys(calculo.moedaEstrangeira).forEach((moedaId) => {
          uniqueMoedaIds.add(Number(moedaId));
        });
        Object.keys(calculo.contrapartidaEstrangeira).forEach((moedaId) => {
          contrapartidaMoedaIds.add(Number(moedaId));
        });
      }
    });

    const combinedMoedaIds = new Set([...uniqueMoedaIds, ...contrapartidaMoedaIds]);
    return Array.from(
      formData?.edital?.editalRubrica
        .reduce((map, editalRubrica) => {
          editalRubrica?.moedaEstrangeira.forEach((moeda) => {
            if (combinedMoedaIds.has(moeda.id) && !map.has(moeda.id)) {
              map.set(moeda.id, moeda);
            }
          });
          return map;
        }, new Map<number, MoedaEstrangeira>())
        .values(),
    );
  } else {
    return null;
  }
};

const getCotacoes = (formData: Proposta) => {
  if (formData.propostaCotacaoMoedaEstrangeira) {
    return formData?.propostaCotacaoMoedaEstrangeira.reduce(
      (acc, { moedaEstrangeiraId, valor }) => {
        acc[moedaEstrangeiraId] = valor;
        return acc;
      },
      {} as Record<number, number>,
    );
  } else {
    return [];
  }
};
