import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { TipoEvento as TipoEventoModel } from '../../../models/tipo-evento';
import { headCells } from './tipo-evento.headcell';
import tipoEventoUiSchema from './tipo-evento.uischema';

function TipoEvento() {
  const customErrors = [whiteSpaces('nome')];

  return (
    <Crud<TipoEventoModel>
      headCells={headCells}
      titleConfig={{ value: 'Tipos de Eventos', show: true }}
      apiUrl={'/tipo-evento'}
      uischema={tipoEventoUiSchema}
      customErrors={customErrors}
      hideView
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default TipoEvento;
