import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import AvatarEditor, { Position } from 'react-avatar-editor';
import Webcam from 'react-webcam';
import { Camera, CloudUpload } from 'styled-icons/bootstrap';
import { CameraSwitch, Edit } from 'styled-icons/fluentui-system-filled';
import { MinusSmall, PlusSmall } from 'styled-icons/heroicons-outline';
import Loading from '../crud/loading';
import './style/custom-avatar-editor.css';

type State = {
  image: string;
  position: Position;
  scale: number;
};

type ProfilePictureEditorProps = {
  username?: string;
  image: string;
  saveHandler: Function;
};

export default function CustomAvatarEditor(props: ProfilePictureEditorProps) {
  const { username, image, saveHandler } = props;
  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';

  const cameraRef = useRef<Webcam>(null);
  const editor = useRef<AvatarEditor>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [open, setOpen] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [loadingCam, setLoadingCam] = useState(false);
  const [toggle, setToggle] = useState('upload');
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [cropState, setCropState] = useState<State>({
    image: image,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
  });

  const resetCrop = {
    position: { x: 0.5, y: 0.5 },
    scale: 1,
  };

  useEffect(() => {
    setCropState({ ...cropState, image: image });
  }, [image]);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER,
    );
  }, []);

  const openHandler = () => {
    setOpen(!open);
    setCropState({ ...resetCrop, image: image });
    setToggle('upload');
    setOpenCamera(false);
  };

  const toggleButtonHandler = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment != null) {
      setToggle(newAlignment);
    }
  };

  const scaleHandler = (_: Event, scale: number | number[]) => {
    typeof scale === 'number' && setCropState({ ...cropState, scale });
  };

  const positionChangeHandler = (position: Position) => {
    setCropState({ ...cropState, position });
  };

  const clickUploadHandler = () => {
    setOpenCamera(false);
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const openCameraHandler = () => {
    setOpenCamera(true);
    if (!openCamera) setLoadingCam(true);
  };

  const fileChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files;
    if (uploadedFile && uploadedFile[0]) {
      setCropState({ ...resetCrop, image: URL.createObjectURL(uploadedFile[0]) });
    }
  };

  const takePictureHandler = () => {
    const camComponente = cameraRef.current;
    if (camComponente) {
      const image = camComponente.getScreenshot();
      image != null && setCropState({ ...resetCrop, image });
    }
    setToggle('upload');
    setOpenCamera(false);
  };

  const handleNameAvatar = () => {
    if (!username) return '';

    return username
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  };

  const cropAndSaveHandler = () => {
    const image = editor.current?.getImageScaledToCanvas().toDataURL();
    if (!image) return;
    saveHandler(image);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={openHandler}>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Box className='badge-icon'>
              <Edit size={16} />
            </Box>
          }
        >
          <Avatar className='avatar-icon' src={typeof image === 'string' ? image : ''}>
            {handleNameAvatar()}
          </Avatar>
        </Badge>
      </IconButton>
      <Dialog className='avatar-editor-dialog' open={open} onClose={openHandler} fullWidth>
        <DialogTitle className='avatar-editor-dialog-tittle'>
          Escolha sua foto de perfil
        </DialogTitle>
        <DialogContent className='avatar-editor-dialog-content'>
          <ToggleButtonGroup
            className='avatar-editor-toggle-group'
            value={toggle}
            exclusive
            onChange={toggleButtonHandler}
          >
            <ToggleButton value='upload' onClick={clickUploadHandler}>
              {toggle === 'upload' && <CloudUpload size={18} className='avatar-editor-icons' />}
              Upload
            </ToggleButton>
            <ToggleButton value='camera' onClick={openCameraHandler}>
              {toggle === 'camera' && <Camera size={18} className='avatar-editor-icons' />}
              Câmera
            </ToggleButton>
          </ToggleButtonGroup>
          {!openCamera && (
            <>
              <Box className='avatar-editor-container'>
                <AvatarEditor
                  className='avatar-editor-canvas'
                  image={typeof cropState.image === 'string' ? cropState.image : ''}
                  ref={editor}
                  scale={cropState.scale}
                  position={cropState.position}
                  onPositionChange={positionChangeHandler}
                  color={[255, 255, 255, 1]}
                  border={0}
                  borderRadius={150}
                />
                <Box className='avatar-editor-slider'>
                  <MinusSmall width={36} />
                  <Slider
                    size='small'
                    onChange={scaleHandler}
                    max={2}
                    min={1}
                    value={cropState.scale}
                    step={0.01}
                    defaultValue={1}
                  />
                  <PlusSmall width={36} />
                </Box>
              </Box>
            </>
          )}
          {openCamera && (
            <>
              <Box className='camera-container'>
                <IconButton className='switch-camera-button' onClick={handleClick}>
                  <CameraSwitch size={24} />
                </IconButton>
                {loadingCam && <Loading isLoading={loadingCam} />}
                <Webcam
                  ref={cameraRef}
                  className='camera'
                  onUserMedia={() => {
                    setLoadingCam(false);
                  }}
                  videoConstraints={{ facingMode: facingMode }}
                />
                <IconButton className='capture-camera-button' onClick={takePictureHandler}>
                  <Camera size={24} />
                </IconButton>
              </Box>
            </>
          )}
          <input
            type='file'
            ref={inputFileRef}
            id='inputFile'
            hidden
            size={100 * 1024} //100kb
            accept='.jpg,.png'
            onChange={fileChangeHandler}
          />
        </DialogContent>
        <DialogActions className='avatar-editor-dialog-actions'>
          <Button variant='outlined' onClick={openHandler}>
            Cancelar
          </Button>
          <Button variant='contained' onClick={cropAndSaveHandler} disableElevation>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
