import { useNavigate, useParams } from 'react-router-dom';
import { Money } from 'styled-icons/fluentui-system-filled';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { EdicaoLinhaDeAcao as EdicaoLinhaDeAcaoModel } from '../../../models/edicao-linha-de-acao';
import { headCells } from './edicao-linha-de-acao.headcell';
import edicaoLinhaDeAcaoUiSchema from './edicao-linha-de-acao.uischema';

function EdicaoLinhaDeAcao() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const { linhaDeAcaoId } = useParams();
  const linhaDeAcaoIdNumber = Number(linhaDeAcaoId);

  const edicaoRecursoLinhaDeAcao = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('edicao-recurso-linha-de-acao'),
    icon: <Money size='24' color='#009900' />,
    title: 'Edição de Recurso da Linha de Ação',
    handler: (row: EdicaoLinhaDeAcaoModel) => {
      setParameterFilters({
        ...parameterFilters,
        edicaoLinhaDeAcao: row,
      });
      navigate(`/edicao-linha-de-acao/${row?.id}/edicao-recurso-linha-de-acao`);
    },
  };

  const defaultForm = {
    linhaDeAcaoId: linhaDeAcaoIdNumber,
    valorTotal: 0,
    ano: new Date().getFullYear().toString(),
  };

  return (
    <Crud<EdicaoLinhaDeAcaoModel>
      headCells={headCells}
      uischema={edicaoLinhaDeAcaoUiSchema}
      titleConfig={{ value: 'Edições da Linha de Ação', show: true }}
      apiUrl={'/edicao-linha-de-acao'}
      defaultForm={defaultForm}
      queryFilters={{ linhaDeAcaoId: linhaDeAcaoIdNumber }}
      hideDelete
      hideUpdate
      customActions={[() => edicaoRecursoLinhaDeAcao]}
    ></Crud>
  );
}

export default EdicaoLinhaDeAcao;

export const rootViews = ['linha-de-acao'];
