// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.array-panel {
  box-shadow: 2px 3px 10px 0px #0000001A;
  border-radius: 4px;
  padding: 0 1rem;
  margin-bottom: 0.75rem;

  .array-item-number {
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 1.35rem;
    background-color: #003D92;
    height: 32px;
    width: 32px;
    margin-right: 1.5rem;
  }

  .array-item-container {
    padding: 0.5rem 0 0.25rem 0;
    flex-grow: 1
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/style/simple-item-layout.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,eAAe;EACf,sBAAsB;;EAEtB;IACE,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,oBAAoB;EACtB;;EAEA;IACE,2BAA2B;IAC3B;EACF;AACF","sourcesContent":[".array-panel {\n  box-shadow: 2px 3px 10px 0px #0000001A;\n  border-radius: 4px;\n  padding: 0 1rem;\n  margin-bottom: 0.75rem;\n\n  .array-item-number {\n    font-family: 'Raleway';\n    font-weight: 600;\n    font-size: 1.35rem;\n    background-color: #003D92;\n    height: 32px;\n    width: 32px;\n    margin-right: 1.5rem;\n  }\n\n  .array-item-container {\n    padding: 0.5rem 0 0.25rem 0;\n    flex-grow: 1\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
