export enum TipoTermoAditivo {
  aditivoPrazo = 'ADITIVO_DE_PRAZO',
  aditivoParticipantes = 'ADITIVO_DE_PARTICIPANTES',
  aditivoRecurso = 'ADITIVO_DE_RECURSO',
}

export type TipoTermoAditivoI18N = {
  [key in TipoTermoAditivo]: string;
};

export const tipoTermoAditivoI18N: TipoTermoAditivoI18N = {
  [TipoTermoAditivo.aditivoPrazo]: 'Aditivo de Prazo',
  [TipoTermoAditivo.aditivoParticipantes]: 'Aditivo de Participantes',
  [TipoTermoAditivo.aditivoRecurso]: 'Aditivo de Recurso',
};
