import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { TipoFuncaoParticipante } from '../../models/funcao-participante';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta';
import {
  CustomAutocomplete,
  CustomListImageBox,
  CustomAvatar,
  CustomSmallAvatar,
  CustomListImg,
  CustomSmallListImg,
  CustomStack,
} from './styled/custom-select-membro';

const CustomSelectMembroTester = rankWith(5, uiTypeIs('CustomSelectMembro'));

const handleNameAvatar = (name: string | undefined) => {
  if (!name) return '';

  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');
};

export const CustomSelectMembroRenderer = {
  tester: CustomSelectMembroTester,
  renderer: withJsonFormsControlProps(
    ({ schema, uischema, data, path, visible, enabled, handleChange, label, ...props }) => {
      const { formData } = useCrudContext();

      const filters = {
        situacao: TipoSituacao.aceito,
        propostaId: formData.id,
      };

      const apiSearchInstance = makePropostaConviteMembroApi();

      const [propostaConviteMembro, setPropostaConviteMembro] = useState<PropostaConviteMembro[]>(
        [],
      );
      const [selectedPropostaConviteMembro, setSelectedPropostaConviteMembro] =
        useState<PropostaConviteMembro | null>(null);

      const handleAdd = (value: PropostaConviteMembro | null) => {
        if (value) {
          handleChange(path, value.id);
        } else {
          handleChange(path, null);
        }
      };

      useEffect(() => {
        apiSearchInstance?.getAll?.(filters).then((apiData: PropostaConviteMembro[]) => {
          setPropostaConviteMembro(apiData);
        });
      }, [path]);

      useEffect(() => {
        if (isEmpty(data)) {
          const defaultMembro = propostaConviteMembro.find(
            (membro) => membro.funcaoParticipante?.codigo === TipoFuncaoParticipante.coordenador,
          );
          if (defaultMembro) {
            setSelectedPropostaConviteMembro(defaultMembro);
            handleAdd(defaultMembro);
          }
        }
      }, [propostaConviteMembro]);

      return (
        <Hidden xsUp={!visible}>
          <CustomStack>
            <CustomAutocomplete
              id='tags-outlined'
              options={propostaConviteMembro}
              disabled={!enabled}
              getOptionLabel={(option: PropostaConviteMembro) => option?.usuario?.nome}
              value={selectedPropostaConviteMembro}
              onChange={(e, value: PropostaConviteMembro | null) => {
                setSelectedPropostaConviteMembro(value);
                handleAdd(value);
              }}
              renderOption={(props, option: PropostaConviteMembro) => (
                <CustomListImageBox {...props}>
                  {option.usuario?.fotoPerfil ? (
                    <CustomListImg src={option.usuario?.fotoPerfil} />
                  ) : (
                    <CustomAvatar>{handleNameAvatar(option.usuario?.nome)}</CustomAvatar>
                  )}
                  {option.usuario?.nome}
                </CustomListImageBox>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label={label}
                  placeholder={`Digite o nome do ${label}`}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedPropostaConviteMembro?.usuario ? (
                      selectedPropostaConviteMembro?.usuario?.fotoPerfil ? (
                        <CustomSmallListImg
                          src={selectedPropostaConviteMembro.usuario?.fotoPerfil}
                        />
                      ) : (
                        <CustomSmallAvatar>
                          {handleNameAvatar(selectedPropostaConviteMembro?.usuario?.nome)}
                        </CustomSmallAvatar>
                      )
                    ) : null,
                  }}
                />
              )}
            />
          </CustomStack>
        </Hidden>
      );
    },
  ),
};
