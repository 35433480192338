import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { formatReal } from '../../helpers/format-real';
import { MoedaEstrangeira } from '../../models/moeda-estrangeira';
import { PropostaCotacaoMoedaEstrangeira } from '../../models/proposta';
import { getFirstError } from '../../utils/get-first-error';
import { getMoedasEstrangeirasUnificadas } from '../../views/proposta/util/calcular-rubricas';
import { IControlElement } from '../uischema';
import './renderer.css';
import { StyledTextField } from './styled/money-exchange-rate';

const moneyTester = rankWith(5, uiTypeIs('MoneyExchangeRate'));

export const moneyRender = {
  tester: moneyTester,
  renderer: withJsonFormsControlProps(({ visible = true, schema, enabled, data, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const ctx = useCrudContext();
    const { validationMode, formData, crudStates, disabledFields, isRequiredField } = ctx;
    const [moedasEstrangeiras, setMoedasEstrangeiras] = useState<MoedaEstrangeira[]>([]);
    const moedasUnificadas = useMemo(() => getMoedasEstrangeirasUnificadas(formData), [formData]);

    useEffect(() => {
      setMoedasEstrangeiras(moedasUnificadas ?? []);

      if ((!data || data.length === 0) && moedasUnificadas) {
        const defaultCotacoes = moedasUnificadas.map((moeda) => ({
          moedaEstrangeiraId: moeda.id,
          valor: null,
        }));
        props.handleChange(props.path, defaultCotacoes);
      }
    }, [formData, moedasUnificadas]);

    const isDisabled: boolean =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema.options?.disabled ||
      disabledFields.includes(props.path);

    const handleValueChange = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      moedaId: number,
    ) => {
      const originalCursorPosition = event.target.selectionStart ?? 0;
      const originalLength = event.target.value.length;
      const inputValue = event.target.value.replace(/[^0-9]/g, '');

      const formattedValue = formatReal(inputValue);

      const lengthDifference = formattedValue.length - originalLength;
      let newCursorPosition = originalCursorPosition + lengthDifference;
      newCursorPosition = Math.max(0, Math.min(newCursorPosition, formattedValue.length));

      const updatedCotacoes = (data || []).map((cotacao: PropostaCotacaoMoedaEstrangeira) =>
        cotacao.moedaEstrangeiraId === moedaId
          ? { ...cotacao, valor: Number(inputValue) }
          : cotacao,
      );

      if (
        !updatedCotacoes.some(
          (cotacao: PropostaCotacaoMoedaEstrangeira) => cotacao.moedaEstrangeiraId === moedaId,
        )
      ) {
        updatedCotacoes.push({
          moedaEstrangeiraId: moedaId,
          valor: Number(inputValue),
        });
      }

      props.handleChange(
        props.path,
        updatedCotacoes.map(({ moedaEstrangeiraId, valor }) => ({ moedaEstrangeiraId, valor })),
      );

      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container'>
          {moedasEstrangeiras.map((moeda) => {
            const cotacao = data?.find(
              (c: PropostaCotacaoMoedaEstrangeira) => c.moedaEstrangeiraId === moeda.id,
            );
            return (
              <StyledTextField
                {...props}
                size='small'
                className={`${
                  (isDisabled && 'disabled-field') ||
                  (props.errors && validationMode === 'ValidateAndShow' && 'has-error')
                }`}
                key={moeda.id}
                label={`Valor da cotação da moeda em ${moeda.nome}`}
                value={cotacao?.valor ? formatReal(String(cotacao.valor)) : ''}
                onChange={(e) => handleValueChange(e, moeda.id)}
                disabled={isDisabled}
                required={isRequiredField(true, ctx)}
                InputProps={
                  {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>
                }
              />
            );
          })}
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
