import { Condition, Rule, RuleEffect } from '@jsonforms/core';

export const hideIfBrasil: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  } as Condition,
};
