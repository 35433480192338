import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { Crud } from '../../../components/crud/crud';
import { FormularioConsultoria } from '../../../models/formulario-consultoria';
import { headCells } from './gerenciar-formulario-consultoria.headcell';
import formularioConsultoriaUiSchema from './gerenciar-formulario-consultoria.uischema';

function FormularioConsultoriaView() {
  const { formularioConsultoriaId: editalId } = useParams();
  const defaultForm = { perguntasCategorizadas: false, editalId: Number(editalId) };

  const api = useMemo(() => makeApi<FormularioConsultoria>('/formulario-consultoria'), [makeApi]);

  return (
    <Crud<FormularioConsultoria>
      headCells={headCells}
      titleConfig={{ value: 'Gerenciar Formulários', show: true }}
      queryFilters={{ withDeleted: true, editalId: Number(editalId) }}
      apiUrl={'/gerenciar-formulario-consultoria'}
      uischema={formularioConsultoriaUiSchema}
      apiClient={api}
      defaultForm={defaultForm}
      customSave={{ show: false }}
    ></Crud>
  );
}

export default FormularioConsultoriaView;

export const rootViews = ['formulario-consultoria'];
