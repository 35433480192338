import { AxiosResponse } from 'axios';
import client from '../config/axios-config';

export interface ILoginApi {
  login: (data: any) => Promise<any>;
  obterConfiguracoesFundacao: () => Promise<any>;
  confirmarEmail: (token: string) => Promise<any>;
  reenviarEmailConfirmacao: (email: string) => Promise<any>;
  recuperarSenha: (email: string) => Promise<AxiosResponse<any, any>>;
  alterarSenha: (token: string, senha: string) => Promise<AxiosResponse<any, any>>;
}

export class LoginApi implements ILoginApi {
  async login(data: any): Promise<any> {
    const response = await client.post<any>('login', data);
    return response;
  }

  async confirmarEmail(token: string) {
    const response = await client.post<any>(`usuario/confirmar-email?token=${token}`);
    return response;
  }

  async reenviarEmailConfirmacao(email: string) {
    const response = await client.post<any>('usuario/reenviar-email-confirmacao', { email });
    return response;
  }

  async recuperarSenha(email: string) {
    const response = await client.post<any>('usuario/recuperar-senha', { email });
    return response;
  }

  async alterarSenha(token: string, senha: string) {
    const response = await client.post<any>('usuario/alterar-senha', { token, senha });
    return response;
  }

  async obterConfiguracoesFundacao(): Promise<any> {
    return await client.get<any>('configuracoes-fundacao');
  }
}

export const makeLoginApi = () => {
  return new LoginApi();
};
