import { AxiosResponse } from 'axios';
import { GenericApi } from '../../api/generic-api';
import client from '../../config/axios-config';
import { Usuario } from '../../models/usuario';

export class UsuarioApi extends GenericApi<Usuario> {
  constructor(url: string) {
    super(url);
  }

  async get(_: number): Promise<Usuario> {
    const response = await client.get<Usuario>(`${this.url}/autenticado`);
    return response.data;
  }

  async getAll(): Promise<any> {
    const response = await client.get<Usuario>(`${this.url}/autenticado`);
    return response.data;
  }

  async atualizarPerfil(usuario: Partial<Usuario>): Promise<AxiosResponse<Usuario, any>> {
    try {
      const response = await client.put<Usuario>(`${this.url}/editar-perfil`, usuario);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }

  async atualizarFotoPerfil(fotoPerfil: any): Promise<AxiosResponse<Usuario, any>> {
    try {
      const response = await client.put<Usuario>(`${this.url}/atualizar-foto-perfil`, fotoPerfil);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
}

export const makeUsuarioApi = () => {
  return new UsuarioApi('usuario');
};
