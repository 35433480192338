export const BaseButton = `
  width: 100px;
  height: 36px;
  letter-spacing: 0.5px;
  line-height: 15px;
  font-family: 'Raleway', sans-serif;
  text-transform: none;
  font-weight: 500;
  background-color: var(--color-button);
  gap: 10px;
`;

export const DisabledButton = `
  border: none;
  color: white;
  background-color: var(--color-button-disabled);
`;

export const IconStyles = `
  width: 20px;
  height: 20px;
`;
