import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { BaseButton, DisabledButton, IconStyles } from '../../layout/components/button/base-button';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

export const CustomDoneIcon = styled(DoneIcon)`
  ${IconStyles}
`;

export const CustomEditIcon = styled(EditIcon)`
  ${IconStyles}
`;

export const EditButton = styled(Button)`
  ${BaseButton}
  color: white;

  &:hover {
    background-color: var(--color-button);
    filter: brightness(0.9);
  }

  &.Mui-disabled {
    ${DisabledButton}
  }
`;

export const SaveButton = styled(Button)`
  ${BaseButton}
  color: white;

  &:hover {
    background-color: var(--color-button);
    filter: brightness(0.9);
  }

  &.Mui-disabled {
    ${DisabledButton}
  }
`;

export const Container = styled.div`
  margin: auto;
  padding: 0.4rem;
  display: flex;
  justify-content: flex-end;
  width: 95%;
  max-width: 1750px;

  @media (max-width: 1920px) {
    width: 85%;
  }

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

export const TitleField = styled.h2`
  font-family: 'Railway', sans-serif;
  font-weight: 600;
  margin: 0;
`;
