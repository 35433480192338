import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { DepartamentoFundacao as DepartamentoFundacaoModel } from '../../../models/departamento-fundacao';
import { headCells } from './departamento-fundacao.headcell';
import DepartamentoFundacaoUiSchema from './departamento-fundacao.uischema';

function DepartamentoFundacao() {
  const customErrors = [whiteSpaces('sigla'), whiteSpaces('nome'), whiteSpaces('descricao')];
  const defaultForm = { nome: '', sigla: '' };

  return (
    <Crud<DepartamentoFundacaoModel>
      headCells={headCells}
      titleConfig={{ value: 'Departamentos', show: true }}
      uischema={DepartamentoFundacaoUiSchema}
      apiUrl={'/departamento-fundacao'}
      customErrors={customErrors}
      defaultForm={defaultForm}
      hideView
      queryFilters={{ withDeleted: true, orderBy: 'sigla', orderDirection: 'ASC' }}
    ></Crud>
  );
}

export default DepartamentoFundacao;
