import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import ListaPropostas from '../../../components/proposta/lista-propostas';
import { Proposta } from '../../../models/proposta';
import { useAuthContext } from '../../login/auth/auth-context';
import { ComponentBox, Header, TituloLabel } from './style/todas-propostas';
import { useCrudContext } from '../../../components/crud/crud-context';

function TodasPropostas() {
  const { loggedInUser } = useAuthContext();
  const { setCurrentTitle } = useCrudContext();
  setCurrentTitle('Propostas');
  const [propostas, setPropostas] = useState<Proposta[]>([]);
  const propostaApi = makeApi<Proposta>('/proposta', {
    orderRelationBy: {
      orderCreatedAtBy: (a, b) => b.getTime() - a.getTime(),
    },
    customPaths: { getAll: '/minhas-propostas' },
  });

  useEffect(() => {
    loadPropostas();
  }, [loggedInUser]);

  function loadPropostas() {
    if (loggedInUser) {
      propostaApi.getAll({ orderBy: 'createdAt', orderDirection: 'DESC' }).then((x) => {
        setPropostas(x);
      });
    }
  }

  return (
    <ComponentBox>
      <Header>
        <TituloLabel>Propostas</TituloLabel>
      </Header>
      <ListaPropostas splitLength={1} propostas={propostas} />
    </ComponentBox>
  );
}

export default TodasPropostas;
