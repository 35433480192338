import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';

export interface Data extends Record<string, any> {
  nome: string;
}

export const displayProperties = ['grandeAreaNome'];

export const moduleLabel = 'Áreas de Conhecimento';

export const moduleName = 'CONFIGURACOES_INICIAIS.AREA_DE_CONHECIMENTO';

export const rowsLength = 12;

export const headCells: readonly HeadCell<AreaDeConhecimento>[] = [
  {
    id: 'grandeAreaNome',
    numeric: false,
    disablePadding: false,
    label: 'Nome da Grande Área',
  },
];
