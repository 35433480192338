import { makeApi } from '../../../api/generic-api';
import { CrudContextData } from '../../../jsonforms/uischema/uischema.type';
import { IUiSchema, showIfBrasil, hideIfBrasil } from '../../../jsonforms/uischema';

const getCodigoPaisInstituicao = async (
  instituicao: any,
  updater: (path: string, value: any) => void,
) => {
  const instSchema = await makeApi('instituicao').getSchema();
  const paisIdSchema = instSchema?.properties?.paisId;
  const country = instituicao?.pais;
  let foreignEnum;
  if ('codigo' in country && (foreignEnum = (paisIdSchema as any)?.foreignEnum)) {
    const [codeKey] =
      Object.entries(foreignEnum).find(([, value]) => value === country.codigo) ?? [];
    setTimeout(() => updater('paisId-codigo', codeKey), 200);
  }
};

const requiredIfBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] === 'brasil';
};

const requiredIfNotBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] !== 'brasil';
};

const unidadeUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Select',
          scope: '#/properties/instituicaoId',
          label: 'Instituição',
          options: {
            required: true,
            handleChange: getCodigoPaisInstituicao,
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/nome',
              label: 'Nome',
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/sigla',
              label: 'Sigla',
              options: {
                required: true,
              },
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/cnpj',
          label: 'CNPJ',
          rule: showIfBrasil,
          options: {
            mask: '99.999.999/9999-99',
            required: requiredIfBrasil,
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Cep',
              scope: '#/properties/endereco/properties/cep',
              label: 'CEP',
              rule: showIfBrasil,
              options: {
                mask: '99999-999',
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Text',
              scope: '#/properties/endereco/properties/cep',
              label: 'ZipCode',
              rule: hideIfBrasil,
              options: {
                maxLength: 9,
                required: requiredIfNotBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: hideIfBrasil,
              options: {
                required: requiredIfNotBrasil,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              label: 'Estado/Região',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: showIfBrasil,
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/endereco/properties/numero',
                      label: 'Número',
                      rule: showIfBrasil,
                      options: {
                        required: requiredIfBrasil,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/endereco/properties/complemento',
                      rule: showIfBrasil,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default unidadeUiSchema;
