import { pascalCase } from 'change-case';
import { makeApi } from '../api/generic-api';
import { CrudContextData } from '../jsonforms/uischema';

export const createResource = async (ctx: CrudContextData, apiUrl: string) => {
  const { formData, setFormData, crudStates, parameterFilters, setParameterFilters, showError } =
    ctx;
  const api = makeApi<any>(apiUrl);
  const urlVar = `step${pascalCase(apiUrl?.replace?.('/', ''))}Id`;
  const isCreated = !!(
    formData.id &&
    parameterFilters?.[urlVar] &&
    Number(parameterFilters?.[urlVar]) === formData?.id
  );
  if (crudStates.add && !isCreated) {
    try {
      const postResponse = await api?.post?.(formData);
      if (postResponse.status === 201) {
        setParameterFilters({ ...parameterFilters, [urlVar]: postResponse?.data?.id });
        setFormData({ ...formData, ...postResponse.data });
        return true;
      }
    } catch (error: any) {
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
      return false;
    }
  }
  if (crudStates.edit) {
    try {
      const putResponse = await api?.put?.(formData?.id, formData);
      if (putResponse.status === 200) {
        return true;
      } else {
        console.error(putResponse);
        return false;
      }
    } catch (error: any) {
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      showError(errorMessage || 'Ocorreu um erro.');
      console.error(errorMessage);
      return false;
    }
  }
  return true;
};
