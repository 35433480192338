import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { FluentDocumentFolder24Filled } from '../../../../components/validity-checker/fluent-document-folder-24-filled';

export const ComponentBox = styled(Box)({
  minWidth: '536px',
  fontFamily: 'Raleway',
  borderRadius: '4px',
  border: 'solid 1px #C5C6D0',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const EditalIcon = styled(FluentDocumentFolder24Filled)({
  width: '18px',
  height: '18px',
  color: '#003D92',
  marginLeft: '16px',
});

export const EditalHeaderLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
});

export const EditalList = styled(Box)({
  color: '#454545 !important',
  fontSize: '12px',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  fontFamily: 'Raleway',
  fontWeight: 600,
});

export const StyledBackgroundList = styled(List)({
  minHeight: '414px',
  maxHeight: '414px',
  padding: '0px',
});

export const StyledBackgroundListItem = styled(ListItem)({
  paddingTop: '6px',
  paddingBottom: '6px',
  borderStyle: 'solid',
  borderWidth: '0px 0px 0.05px 0px',
  borderColor: '#C5C6D0',
});

export const StyledBackgroundListItemButton = styled(ListItemButton)({
  minHeight: '57px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '16px',
  borderTop: 'solid 1px #C5C6D0',
});

export const FooterButton = styled(Typography)({
  fontSize: '12px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
});

export const EditalEmpty = styled(Typography)({
  marginLeft: '20px',
});

export const TodosEditaisAnchor = styled('a')({
  cursor: 'pointer',
});
