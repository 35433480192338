import styled from '@emotion/styled';
import { TableContainer } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

export const StyledTableHeader = styled(TableHead)({});

export const StyledTableTitle = styled(TableCell)({
  fontWeight: 700,
  fontSize: '16px',
  textAlign: 'center',
});

export const StyledTableSubTitle = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  fontWeight: 700,
}));

export const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  fontWeight: 700,
  border: `1px solid ${theme.palette.divider}`,
}));

export const StyledTableData = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const StyledTableContainer = styled(TableContainer)({
  marginBottom: '35px',
});
