import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AreaDeConhecimentoDeleteDialog(props: AreaDeConhecimentoDeleteDialogProps) {
  const { openDialog, body, handleCloseDelete, destroyHandler } = props;

  return (
    <Dialog
      open={openDialog}
      keepMounted
      onClose={() => handleCloseDelete()}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>{'Deseja realmente desativar este item?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{body}</DialogContentText>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button className='btn-cancel-delete' onClick={() => handleCloseDelete()}>
          Cancelar
        </Button>
        <Button className='btn-confirm-delete' onClick={() => destroyHandler()}>
          Sim, tenho certeza
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface AreaDeConhecimentoDeleteDialogProps {
  openDialog: boolean;
  handleCloseDelete: Function;
  destroyHandler: Function;
  body: React.ReactNode;
}
