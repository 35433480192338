import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { NavigateFunction } from 'react-router-dom';
import { FormApi, GenericApi } from '../../api/generic-api';
import { circleFlagBuilder } from '../../components/circle-flag';
import { formatCurrency } from '../../helpers/format-currency';
import { formatFloat } from '../../helpers/format-float';
import { CrudContextData, hideIfBrasil, IUiSchema, showIfBrasil } from '../../jsonforms/uischema';
import { AreaDeConhecimento } from '../../models/area-de-conhecimento';
import { DiariaProposta } from '../../models/diaria-proposta';
import { BolsaEdital, FaixaFinanciamentoEdital } from '../../models/edital';
import { EditalRubrica, TipoEditalRubrica } from '../../models/edital-rubrica';
import { Estado } from '../../models/estado';
import { ModalidadeBolsa } from '../../models/modalidade-bolsa';
import { MoedaEstrangeira } from '../../models/moeda-estrangeira';
import { NivelBolsa } from '../../models/nivel-bolsa';
import { CodigoPais, Pais } from '../../models/pais';
import { PessoalProposta } from '../../models/pessoal-proposta';
import {
  HospedagemAlimentacaoProposta,
  MaterialConsumoProposta,
  MaterialPermanenteProposta,
  PassagemProposta,
  Proposta,
  ServicoTerceiroProposta,
  tipoOrigemI18N,
  tipoPassagemPropostaI18N,
  tipoPessoaI18N,
  TrechoPassagemProposta,
  trechoPassagemPropostaI18N,
} from '../../models/proposta';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { formatLabel } from '../edital/edital.uischema';
import {
  setLabelWhenChangeDateFuncao,
  setLabelWhenChangeDateServico,
} from '../editar-perfil/handlers/set-label-when-change-date';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { requiredIfBrasil, requiredIfNotBrasil } from '../instituicao/instituicao.uischema';
import { stepHandlerAbrangencia } from './handlers/step-handler-abrangencia';
import { stepHandlerDescricao } from './handlers/step-handler-descricao';
import { stepHandlerProposta } from './handlers/step-handler-proposta';
import { IbgeApi, makeIbgeApi } from './ibge.api';
import { buildTableLayoutHeader } from './table-layout-header';
import { getOrcamentoConsolidadoTable } from './util/calcular-rubricas';

const formatLabelFaixaFinanciamento = (item: any) => {
  const valorMinimoInReal = item.valorMinimo / 100;
  const valormaximoInReal = item.valorMaximo / 100;

  const formattedValorMinimo = formatCurrency(valorMinimoInReal);
  const formattedValorMaximo = formatCurrency(valormaximoInReal);

  return `${item.nome} (${formattedValorMinimo} - ${formattedValorMaximo})`;
};

const disableTituloDoEdital = {
  effect: 'DISABLE',
  condition: {},
} as Rule;

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const areaDeConhecimentoHandleChange = async (
  item: AreaDeConhecimento,
  handler: (path: string, value: any) => void,
  formData: Proposta,
  ctx?: CrudContextData,
  path?: string,
) => {
  const pathToSet = path?.split('.').slice(0, -1).join('.') || '';

  if (pathToSet && item?.id) handler(`${pathToSet}.id`, item?.id);
};

const showIfVinculoInstitucionalObrigatorio: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/edital/properties/vinculoInstitucionalObrigatorio',
    schema: {
      const: true,
    },
  } as Condition,
};

const showIfNationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'NACIONAL',
    },
  },
} as Rule;

const showIfInternationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'INTERNACIONAL',
    },
  },
} as Rule;

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const apiInstanceIbge = makeIbgeApi();

const showIfContrapartida = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/contrapartida',
    schema: {
      const: true,
    },
  },
} as Rule;

const showIfForeignCurrency = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/emMoedaEstrangeira',
    schema: {
      const: true,
    },
  },
} as Rule;

const createShowRuleForEditalRubrica = (tipoEditalRubricaValue: TipoEditalRubrica): Rule => {
  return {
    effect: 'SHOW',
    condition: {
      scope: '#/properties/edital',
      schema: {
        properties: {
          editalRubrica: {
            type: 'array',
            contains: {
              properties: {
                tipoEditalRubrica: {
                  enum: [tipoEditalRubricaValue],
                },
              },
            },
          },
        },
      },
    },
  } as Rule;
};

const propostaUiSchema: IUiSchema = {
  type: 'CategorizationMenu',
  elements: [
    {
      type: 'Category',
      label: 'Caracterização da Proposta',
      elements: [
        {
          type: 'Category',
          label: 'Informações Iniciais',
          options: {
            stepHandler: {
              handler: stepHandlerProposta,
              callback: (ctx: CrudContextData) => createResource(ctx, '/proposta'),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/edital/properties/nome',
              label: 'Título do Edital',
              rule: disableTituloDoEdital,
            },
            {
              type: 'Control',
              scope: '#/properties/tituloDoProjeto',
              label: 'Título do Projeto',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/duracao',
              label: 'Duração do projeto em meses',
              options: {
                isPositiveNumber: true,
              },
            },
            {
              type: 'Select',
              scope: '#/properties/instituicaoExecutoraId',
              label: 'Instituição',
            },
            {
              type: 'Select',
              scope: '#/properties/unidadeExecutoraId',
              label: 'Unidade',
              options: {
                filter: {
                  formFields: ['instituicaoExecutoraId'],
                  handler: (formData: Proposta, listData: any[], api: FormApi) => {
                    if (!formData.instituicaoExecutoraId) return Promise.resolve(listData);
                    return api.getAll({ instituicaoId: formData?.instituicaoExecutoraId });
                  },
                },
              },
            },
            {
              type: 'Control',
              scope: '#/properties/areaDeConhecimento',
              label: 'Suas Áreas de Conhecimento',
              options: {
                label: 'Área de Conhecimento',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/grandeAreaId',
                      label: 'Grande área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['grandeAreaNome'],
                        filter: {
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                          ) => {
                            return api.buscarGrandesAreas();
                          },
                        },
                        disableHandler: disableIfNoOptions,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/areaId',
                      label: 'Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['areaNome'],
                        filter: {
                          formFields: ['grandeAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/subAreaId',
                      label: 'Sub-área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['subAreaNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['areaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/especialidadeId',
                      label: 'Especialidade',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['especialidadeNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['subAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarEspecialidadePorSubAreaId(
                              queryFilterValues?.[0].value,
                            );
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              },
            },
            {
              type: 'VerticalLayout',
              rule: showIfVinculoInstitucionalObrigatorio,
              elements: [
                {
                  type: 'Boolean',
                  scope: '#/properties/edital/properties/vinculoInstitucionalObrigatorio',
                  label: 'Vínculo institucional obrigatório',
                  options: {
                    disabled: true,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/vinculoInstitucional/properties/tipoVinculoInstitucionalId',
                  label: 'Tipo de vínculo',
                  options: {
                    required: (ctx: CrudContextData) => {
                      const { formData } = ctx;
                      return formData?.edital?.vinculoInstitucionalObrigatorio;
                    },
                  },
                },
                {
                  type: 'Boolean',
                  scope: '#/properties/edital/properties/vinculoEmpregaticioObrigatorio',
                  label: 'Vínculo empregatício obrigatório',
                  options: {
                    disabled: true,
                  },
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/vinculoInstitucional/properties/inicioServico',
                      label: 'Início de Serviço',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData?.edital?.vinculoEmpregaticioObrigatorio;
                        },
                      },
                    },
                    {
                      type: 'Label',
                      scope: '#/properties/vinculoInstitucional/properties/tempoServico',
                      label: 'Tempo de Serviço',
                      options: {
                        formatOptionLabel: setLabelWhenChangeDateServico,
                        componentProps: {
                          variant: 'h5',
                          align: 'left',
                          sx: {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'Select',
                  scope: '#/properties/vinculoInstitucional/properties/regimeTrabalhoId',
                  label: 'Regime de Trabalho',
                  options: {
                    required: (ctx: CrudContextData) => {
                      const { formData } = ctx;
                      return formData?.edital?.vinculoEmpregaticioObrigatorio;
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/vinculoInstitucional/properties/funcao',
                  label: 'Função/Cargo',
                  options: {
                    required: (cxt: CrudContextData) => {
                      const { formData } = cxt;
                      return formData?.edital?.vinculoEmpregaticioObrigatorio;
                    },
                  },
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/vinculoInstitucional/properties/inicioFuncao',
                      label: 'Início de Função/Cargo',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: (cxt: CrudContextData) => {
                          const { formData } = cxt;
                          return formData?.edital?.vinculoEmpregaticioObrigatorio;
                        },
                      },
                    },
                    {
                      type: 'Label',
                      scope: '#/properties/vinculoInstitucional/properties/tempoFuncao',
                      label: 'Tempo de Função/Cargo',
                      options: {
                        formatOptionLabel: setLabelWhenChangeDateFuncao,
                        componentProps: {
                          variant: 'h5',
                          align: 'left',
                          sx: {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          },
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Informações Complementares',
          options: {
            stepHandler: {
              handler: stepHandlerDescricao,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaInformacaoComplementar',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/informacaoComplementarEditalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Abrangência',
          options: {
            stepHandler: {
              handler: stepHandlerAbrangencia,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/abrangencia',
              label: 'Abrangência',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/estadoId',
                          label: 'Estado',
                          options: {
                            displayProperties: ['nome'],
                            filter: {
                              handler(formData, listData, api, queryFilterValues, path) {
                                const editalId = formData?.editalId;
                                if (!editalId) return Promise.resolve([]);

                                const index = Number(path?.split?.('.')?.at?.(-2));
                                if (Number.isNaN(index)) return Promise.resolve([]);

                                const { abrangencia } = formData;

                                return api.getAll({ editalId: editalId }).then((response: any) => {
                                  return response?.filter((x: any) => {
                                    if (!abrangencia) return true;
                                    return abrangencia
                                      ?.filter((_: any, i: number) => i !== index)
                                      ?.every((y: any) => y.estadoId !== x.id);
                                  });
                                });
                              },
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/abrangenciaMunicipio',
                          options: {
                            selectMultiple: true,
                            displayProperties: ['nome'],
                            apiInstance: apiInstanceIbge,
                            filter: {
                              formFields: ['estadoId'],
                              handler: (
                                formData: Proposta,
                                listData: any[],
                                api: IbgeApi,
                                queryFilterValues: any,
                              ) => {
                                if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                              },
                            },
                            handleChange: async (values: any, handler, formData, ctx, path) => {
                              const mappedValues = values?.map((value: any) => {
                                return {
                                  municipio: value?.data?.nome,
                                };
                              });
                              handler(`${path}`, mappedValues);
                            },
                            optionsComparator: (option: any, item: any) =>
                              option?.data?.nome === item?.municipio,
                            visibleHandler: visibleIfHasFilter,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexos',
      elements: [
        {
          type: 'Category',
          label: 'Documentos Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos Pessoais',
                  type: 'File',
                  options: {
                    categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                    route: 'usuario/usuario-anexo',
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                    withoutFk: true,
                    filterByCategories: true,
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Documentos da Proposta',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos da Proposta',
                  type: 'File',
                  scope: '#/properties/documentoPropostaAnexo',
                  options: {
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Plano de Trabalho',
      elements: [
        {
          type: 'Category',
          label: 'Descrição',
          options: {
            stepHandler: {
              handler: stepHandlerDescricao,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaDescritiva',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/editalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Membros',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CustomDataTableUserSelect',
                  scope: '#/properties/propostaConviteMembro',
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Atividades',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/propostaAtividade',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/titulo',
                      label: 'Título',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/mesInicio',
                          label: 'Mês de Início',
                          options: {
                            oneOf: (formData: Proposta) => {
                              return Array.from({ length: formData?.duracao }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1,
                              }));
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/duracao',
                          label: 'Duração (Em meses)',
                          options: {
                            formFields: (formData, path) => {
                              return [
                                formData?.propostaAtividade?.at(path.split('.').at(-2))?.mesInicio,
                              ];
                            },
                            oneOf: (formData: Proposta, path: string) => {
                              const { duracao } = formData;
                              const mesInicio = formData?.propostaAtividade?.at(
                                parseInt(path.split('.').at(-2)),
                              )?.mesInicio;
                              if (!duracao || !mesInicio) return [];

                              const mesesRestantes = duracao - mesInicio + 1;

                              return Array.from({ length: mesesRestantes }, (_, i) => {
                                const quantidadeMeses = i + 1;
                                return {
                                  title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                  const: quantidadeMeses,
                                };
                              });
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/cargaHorariaSemanal',
                          label: 'Carga Horária Semanal',
                          options: {
                            oneOf: () => {
                              return Array.from({ length: 60 }, (_, i) => {
                                const horas = i + 1;
                                return {
                                  title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                  const: horas,
                                };
                              });
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'CustomSelectMembro',
                      scope: '#/properties/membroResponsavelId',
                      label: 'Responsável',
                    },
                    {
                      type: 'CustomMultipleSelectMembro',
                      scope: '#/properties/propostaAtividadeMembro',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Orçamento',
          elements: [
            {
              type: 'Category',
              label: 'Faixa de Financiamento',
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/faixaFinanciamentoEditalId',
                      label: '',
                      options: {
                        required: true,
                        displayProperties: ['nome', 'valorMinimo', 'valorMaximo'],
                        filter: {
                          handler: (
                            formData: FaixaFinanciamentoEdital,
                            listData: FaixaFinanciamentoEdital[],
                            api: GenericApi<FaixaFinanciamentoEdital>,
                          ) => {
                            if (!formData.editalId) return Promise.resolve(listData);
                            return api.getAll({
                              editalId: formData?.editalId,
                            });
                          },
                        },
                        formatOptionLabel: formatLabelFaixaFinanciamento,
                      },
                    },
                  ],
                },
              ],
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital/properties/faixaFinanciamento',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                } as Condition,
              } as Rule,
            },
            {
              type: 'Category',
              label: 'Diárias',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.diarias),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/diariaProposta',
                  label: '',
                  options: {
                    label: 'Diária',
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    defaultValue: (formData: DiariaProposta) => ({ paisId: 1 }),
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Localidade',
                        field: (row: DiariaProposta) => {
                          return row.pais.codigo === CodigoPais.brasil
                            ? `${row.pais.nomePt}-${row.estado?.nome}/${row.municipio}`
                            : `${row.pais?.nomePt}-${row.estadoRegiao}`;
                        },
                      },
                      {
                        label: 'Número de Diárias	',
                        field: (row: DiariaProposta) =>
                          row.numeroDiaria ? formatFloat(row.numeroDiaria, 'pt-BR', 0, 1) : '-',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: DiariaProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: DiariaProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: DiariaProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: DiariaProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: DiariaProposta) =>
                          row.valorTotal ? formatCurrency(row.valorTotal) : '-',
                      },
                    ],
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('paisId', 'estado'), null);
                                  handler(`${path}`.replace('paisId', 'estadoId'), null);
                                  handler(`${path}`.replace('paisId', 'municipio'), null);
                                  handler(`${path}`.replace('paisId', 'estadoRegiao'), null);

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('diariaPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/estadoId',
                              label: 'Estado',
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfBrasil,
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('diariaPropostaUnsaved.estado', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/municipio',
                              label: 'Município',
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                required: requiredIfBrasil,
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                          rule: showIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('paisId', 'estado'), null);
                                  handler(`${path}`.replace('paisId', 'estadoId'), null);
                                  handler(`${path}`.replace('paisId', 'municipio'), null);
                                  handler(`${path}`.replace('paisId', 'estadoRegiao'), null);

                                  handler('diariaPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/estadoRegiao',
                              label: 'Estado/Região',
                              options: {
                                required: requiredIfNotBrasil,
                              },
                            },
                          ],
                          rule: hideIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Float',
                              scope: '#/properties/numeroDiaria',
                              label: 'Número de Diárias',
                              options: {
                                required: true,
                                numberStep: 0.5,
                                isPositiveNumber: true,
                                handleChange: (
                                  numeroDiaria: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const custoUnitario =
                                    formData?.['diariaPropostaUnsaved']?.custoUnitario;

                                  if (!numeroDiaria && !custoUnitario) {
                                    handler(`${path}`.replace('numeroDiaria', 'valorTotal'), null);
                                    return;
                                  }

                                  handler(
                                    `${path}`.replace('numeroDiaria', 'valorTotal'),
                                    (custoUnitario * numeroDiaria) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const numeroDiaria =
                                    formData?.['diariaPropostaUnsaved']?.numeroDiaria;

                                  if (!numeroDiaria && !custoUnitario) {
                                    handler(`${path}`.replace('numeroDiaria', 'valorTotal'), null);
                                    return;
                                  }

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    (custoUnitario * numeroDiaria) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.diarias &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.diarias,
                                    );
                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('diariaPropostaUnsaved.moedaEstrangeira', item);
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Material de Consumo',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.materialDeConsumo),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/materialConsumoProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Quantidade',
                        field: (row: MaterialConsumoProposta) =>
                          row.quantidade ? formatFloat(row.quantidade, 'pt-BR', 0, 0) : '-',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: MaterialConsumoProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: MaterialConsumoProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: MaterialConsumoProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: DiariaProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: MaterialConsumoProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Material de Consumo',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/unidadeMedida',
                              label: 'Unidade de Medida',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'Float',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['materialConsumoPropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;
                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    (custoUnitario * quantidade) / 100,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['materialConsumoPropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    (custoUnitario * quantidade) / 100,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.materialDeConsumo &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('materialConsumoPropostaUnsaved.moedaEstrangeira', item);
                                },
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.materialDeConsumo,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/entidade',
                          label: 'Entidade',
                          rule: showIfContrapartida,
                          options: {
                            required: true,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Material Permanente',
              rule: createShowRuleForEditalRubrica(
                TipoEditalRubrica.materialPermanenteEquipamentos,
              ),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/materialPermanenteProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Tipo',
                        field: (row: MaterialPermanenteProposta) =>
                          tipoOrigemI18N[row.tipoOrigem.toUpperCase()],
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: MaterialPermanenteProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Mês',
                        field: (row: MaterialPermanenteProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: MaterialPermanenteProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: MaterialPermanenteProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: MaterialPermanenteProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Material Permanente',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipoOrigem',
                              label: 'Tipo',
                            },
                            {
                              type: 'Number',
                              label: 'Quantidade',
                              scope: '#/properties/quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['materialPermanentePropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;
                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              label: 'Custo Unitário',
                              scope: '#/properties/custoUnitario',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['materialPermanentePropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.materialPermanenteEquipamentos &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler(
                                    'materialPermanentePropostaUnsaved.moedaEstrangeira',
                                    item,
                                  );
                                },
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.materialPermanenteEquipamentos,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/entidade',
                          label: 'Entidade',
                          rule: showIfContrapartida,
                          options: {
                            required: true,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Passagens',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.passagens),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/passagemProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    defaultValue: (formData: PassagemProposta) => ({ paisId: 1 }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Trecho',
                        field: (row: PassagemProposta) =>
                          trechoPassagemPropostaI18N[row.trecho.toUpperCase()],
                      },
                      {
                        label: 'Ida e Volta',
                        field: (row: PassagemProposta) => (row.idaVolta ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Origem',
                        field: (row: PassagemProposta) =>
                          row.trecho === TrechoPassagemProposta.nacional
                            ? `Brasil - ${row.estadoOrigem?.nome}-${row.municipioOrigem}`
                            : `${row.paisOrigem?.nomePt}-${row.estadoRegiaoOrigem}`,
                      },
                      {
                        label: 'Destino',
                        field: (row: PassagemProposta) =>
                          row.trecho === TrechoPassagemProposta.nacional
                            ? `Brasil-${row.estadoDestino?.nome}-${row.municipioDestino}`
                            : `${row.paisDestino?.nomePt}-${row.estadoRegiaoDestino}`,
                      },
                      {
                        label: 'Tipo',
                        field: (row: PassagemProposta) =>
                          tipoPassagemPropostaI18N[row.tipo.toUpperCase()],
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: PassagemProposta) =>
                          row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-',
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Mês',
                        field: (row: PassagemProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: PassagemProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: PassagemProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: PassagemProposta) =>
                          row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-',
                      },
                    ],
                    label: 'Passagem',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Trecho',
                              scope: '#/properties/trecho',
                              options: {
                                required: true,
                                handleChange: (
                                  trecho: TrechoPassagemProposta,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('trecho', 'estadoOrigemId'), null);
                                  handler(`${path}`.replace('trecho', 'estadoOrigem'), null);
                                  handler(`${path}`.replace('trecho', 'municipioOrigem'), null);
                                  handler(`${path}`.replace('trecho', 'paisOrigemId'), null);
                                  handler(`${path}`.replace('trecho', 'estadoRegiaoOrigem'), null);
                                  handler(`${path}`.replace('trecho', 'estadoDestinoId'), null);
                                  handler(`${path}`.replace('trecho', 'estadoDestino'), null);
                                  handler(`${path}`.replace('trecho', 'municipioDestino'), null);
                                  handler(`${path}`.replace('trecho', 'paisDestinoId'), null);
                                  handler(`${path}`.replace('trecho', 'estadoRegiaoDestino'), null);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Ida e Volta',
                              scope: '#/properties/idaVolta',
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Estado de Origem',
                              scope: '#/properties/estadoOrigemId',
                              rule: showIfNationalTicket,
                              options: {
                                displayProperties: ['nome'],
                                required: true,
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('passagemPropostaUnsaved.estadoOrigem', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Município de Origem',
                              scope: '#/properties/municipioOrigem',
                              rule: showIfNationalTicket,
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                defaultValue: 'municipioOrigem',
                                required: true,
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoOrigemId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'País de Origem',
                              scope: '#/properties/paisOrigemId',
                              rule: showIfInternationalTicket,
                              options: {
                                displayProperties: ['nomePt'],
                                required: true,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('passagemPropostaUnsaved.paisOrigem', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Estado/Região de Origem',
                              rule: showIfInternationalTicket,
                              scope: '#/properties/estadoRegiaoOrigem',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Estado de Destino',
                              scope: '#/properties/estadoDestinoId',
                              rule: showIfNationalTicket,
                              options: {
                                displayProperties: ['nome'],
                                required: true,
                                handleChange: (
                                  item: Estado,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('passagemPropostaUnsaved.estadoDestino', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Município de Destino',
                              scope: '#/properties/municipioDestino',
                              rule: showIfNationalTicket,
                              options: {
                                targetFieldValue: 'nome',
                                optionsComparator: (option: any, item: string) =>
                                  option?.nome === item,
                                defaultValue: 'municipioOrigem',
                                displayProperties: ['nome'],
                                required: true,
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoDestinoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'País de Destino',
                              scope: '#/properties/paisDestinoId',
                              rule: showIfInternationalTicket,
                              options: {
                                displayProperties: ['nomePt'],
                                required: true,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('passagemPropostaUnsaved.paisDestino', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              label: 'Estado/Região de Destino',
                              rule: showIfInternationalTicket,
                              scope: '#/properties/estadoRegiaoDestino',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              label: 'Tipo',
                              scope: '#/properties/tipo',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              label: 'Custo Unitário',
                              scope: '#/properties/custoUnitario',
                              options: {
                                required: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade =
                                    formData?.['passagemPropostaUnsaved']?.quantidade;

                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Number',
                              label: 'Quantidade',
                              scope: '#/properties/quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const custoUnitario =
                                    formData?.['passagemPropostaUnsaved']?.custoUnitario;

                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              label: 'Valor Total',
                              scope: '#/properties/valorTotal',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Mês Previsto',
                              scope: '#/properties/mesPrevisto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.passagens &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Moeda estrangeira',
                              scope: '#/properties/moedaEstrangeiraId',
                              options: {
                                displayProperties: ['nome'],
                                required: true,
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica.tipoEditalRubrica ===
                                        TipoEditalRubrica.passagens,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('passagemPropostaUnsaved.moedaEstrangeira', item);
                                },
                              },
                              rule: showIfForeignCurrency,
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              label: 'Contrapartida',
                              scope: '#/properties/contrapartida',
                              options: {
                                handleChange: async (
                                  value: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx: CrudContextData,
                                  path: string,
                                ) => {
                                  if (!value) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoEntidade'),
                                      null,
                                    );
                                    handler(`${path}`.replace('contrapartida', 'entidade'), null);
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              label: 'Tipo de Entidade',
                              scope: '#/properties/tipoEntidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              scope: '#/properties/entidade',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Hospedagem e Alimentação',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.hospedagemAlimentacao),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/hospedagemAlimentacaoProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Localidade',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.pais.codigo === CodigoPais.brasil
                            ? `${row.pais.nomePt}-${row.estado?.nome}-${row.municipio}`
                            : `${row.pais?.nomePt}-${row.estadoRegiao}`,
                      },
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Custo Unitário',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                      {
                        label: 'Quantidade',
                        field: 'quantidade',
                      },
                      {
                        label: 'Mês',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor Total',
                        field: (row: HospedagemAlimentacaoProposta) =>
                          row.valorTotal ? formatFloat(row.valorTotal) : '-',
                      },
                    ],
                    label: 'Hospedagem e Alimentação',
                    defaultValue: (formData: HospedagemAlimentacaoProposta) => ({ paisId: 1 }),
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('paisId', 'estado'), null);
                                  handler(`${path}`.replace('paisId', 'estadoId'), null);
                                  handler(`${path}`.replace('paisId', 'municipio'), null);
                                  handler(`${path}`.replace('paisId', 'estadoRegiao'), null);

                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('hospedagemAlimentacaoPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/estadoId',
                              label: 'Estado',
                              options: {
                                displayProperties: ['nome'],
                                required: requiredIfBrasil,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('hospedagemAlimentacaoPropostaUnsaved.estado', item);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/municipio',
                              label: 'Município',
                              options: {
                                required: requiredIfBrasil,
                                displayProperties: ['nome'],
                                apiInstance: apiInstanceIbge,
                                filter: {
                                  formFields: ['estadoId'],
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: IbgeApi,
                                    queryFilterValues: any,
                                  ) => {
                                    if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                    return api.buscarMunicipioPorEstadoId(
                                      queryFilterValues[0].value,
                                    );
                                  },
                                },
                              },
                            },
                          ],
                          rule: showIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/paisId',
                              label: 'País',
                              options: {
                                required: true,
                                iconBuilder: circleFlagBuilder,
                                handleChange: (
                                  item: Pais,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`${path}`.replace('paisId', 'estado'), null);
                                  handler(`${path}`.replace('paisId', 'estadoId'), null);
                                  handler(`${path}`.replace('paisId', 'municipio'), null);
                                  handler(`${path}`.replace('paisId', 'estadoRegiao'), null);

                                  handler('hospedagemAlimentacaoPropostaUnsaved.pais', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/estadoRegiao',
                              label: 'Estado/Região',
                              options: {
                                required: requiredIfNotBrasil,
                              },
                            },
                          ],
                          rule: hideIfBrasil,
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Number',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                required: true,
                                numberStep: 1,
                                isPositiveNumber: true,
                                handleChange: (
                                  quantidade: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const custoUnitario =
                                    formData?.['hospedagemAlimentacaoPropostaUnsaved']
                                      ?.custoUnitario;

                                  if (!quantidade && !custoUnitario) return;

                                  handler(
                                    `${path}`.replace('quantidade', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  custoUnitario: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: String,
                                ) => {
                                  const quantidade =
                                    formData?.['hospedagemAlimentacaoPropostaUnsaved']?.quantidade;
                                  if (!custoUnitario && !quantidade) return;

                                  handler(
                                    `${path}`.replace('custoUnitario', 'valorTotal'),
                                    custoUnitario * quantidade,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                disabled: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.hospedagemAlimentacao &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.hospedagemAlimentacao,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(
                                    'hospedagemAlimentacaoPropostaUnsaved.moedaEstrangeira',
                                    item,
                                  );
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Serviço de Terceiros',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.servicosDeTerceiros),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/servicoTerceiroProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader, field: 'custoUnitario' },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Especificação',
                        field: 'especificacao',
                      },
                      {
                        label: 'Tipo',
                        field: (row: ServicoTerceiroProposta) =>
                          tipoPessoaI18N[row.tipo.toUpperCase()],
                      },
                      {
                        label: 'Mês',
                        field: (row: ServicoTerceiroProposta) =>
                          row.mesPrevisto ? `${row.mesPrevisto}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: ServicoTerceiroProposta) =>
                          row.contrapartida ? 'Sim' : 'Não',
                      },
                      {
                        label: 'Moeda',
                        field: (row: ServicoTerceiroProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor',
                        field: (row: ServicoTerceiroProposta) =>
                          row.custoUnitario ? formatFloat(row.custoUnitario) : '-',
                      },
                    ],
                    label: 'Serviço de Terceiros',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipo',
                              label: 'Tipo',
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.servicosDeTerceiros &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: true,
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: Proposta,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.servicosDeTerceiros,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                                handleChange: (
                                  item: MoedaEstrangeira,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  //Adiciona o objeto inteiro no formulário provisório
                                  handler('servicoTerceiroPropostaUnsaved.moedaEstrangeira', item);
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Pessoal',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.pessoal),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'ArrayTableLayout',
                  scope: '#/properties/pessoalProposta',
                  label: '',
                  options: {
                    additionalData: (formData: Proposta) => ({ editalId: formData?.editalId }),
                    buildHeader: { builder: buildTableLayoutHeader, field: 'valor' },
                    route: '/proposta',
                    headCells: [
                      {
                        label: 'Função',
                        field: 'funcao',
                      },
                      {
                        label: 'Formação',
                        field: 'formacaoProfissional',
                      },
                      {
                        label: 'Perfil Desejado',
                        field: 'perfilDesejado',
                      },
                      {
                        label: 'Mês',
                        field: (row: PessoalProposta) =>
                          row.mesInicio ? `${row.mesInicio}°` : '-',
                      },
                      {
                        label: 'Contrapartida',
                        field: (row: PessoalProposta) => (row.contrapartida ? 'Sim' : 'Não'),
                      },
                      {
                        label: 'Moeda',
                        field: (row: PessoalProposta) =>
                          row.moedaEstrangeira
                            ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})`
                            : 'Real (R$)',
                      },
                      {
                        label: 'Valor',
                        field: (row: PessoalProposta) => (row.valor ? formatFloat(row.valor) : '-'),
                      },
                    ],
                    label: 'Pessoal',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/funcao',
                              label: 'Função',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/formacaoProfissional',
                              label: 'Formação Profissional',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/perfilDesejado',
                              label: 'Perfil Desejado',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesInicio',
                              label: 'Mês de Início',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                                handleChange: (
                                  item: number,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  handler(`${path}`.replace('mesInicio', 'duracao'), null);
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/duracao',
                              label: 'Duração (Em meses)',
                              options: {
                                formFields: (formData, path) => {
                                  return [formData?.['pessoalPropostaUnsaved']?.mesInicio];
                                },
                                oneOf: (formData: Proposta, path: string) => {
                                  const { duracao } = formData;
                                  const mesInicio = formData?.['pessoalPropostaUnsaved']?.mesInicio;
                                  if (!duracao || !mesInicio) return [];

                                  const mesesRestantes = duracao - mesInicio + 1;

                                  return Array.from({ length: mesesRestantes }, (_, i) => {
                                    const quantidadeMeses = i + 1;
                                    return {
                                      title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                      const: quantidadeMeses,
                                    };
                                  });
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/cargaHorariaSemanal',
                              label: 'Carga Horária Semanal',
                              options: {
                                required: true,
                                oneOf: () => {
                                  return Array.from({ length: 60 }, (_, i) => {
                                    const horas = i + 1;
                                    return {
                                      title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                      const: horas,
                                    };
                                  });
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoHoraCustoMes',
                              label: 'Custo Hora ou Custo Mês',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valor',
                              label: 'Valor',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'HorizontalLayout',
                              elements: [
                                {
                                  type: 'Select',
                                  scope: '#/properties/tipoPessoa',
                                  label: 'Tipo',
                                  rule: showIfContrapartida,
                                },
                                {
                                  type: 'Control',
                                  scope: '#/properties/entidade',
                                  label: 'Entidade',
                                  rule: showIfContrapartida,
                                  options: {
                                    required: true,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.pessoal &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },
                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: true,
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: Proposta,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.pessoal,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Encargos',
              rule: createShowRuleForEditalRubrica(TipoEditalRubrica.encargos),
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/encargoProposta',
                  label: '',
                  options: {
                    label: 'Encargos',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/especificacao',
                              label: 'Especificação',
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/custoUnitario',
                              label: 'Custo Unitário',
                              options: {
                                required: true,
                                isPositiveNumber: true,
                              },
                            },
                            {
                              type: 'Boolean',
                              scope: '#/properties/emMoedaEstrangeira',
                              label: 'Moeda estrangeira',
                              options: {
                                visibleHandler: (ctx: CrudContextData) => {
                                  const { formData } = ctx;

                                  return formData?.edital?.editalRubrica?.some(
                                    (editalRubrica: EditalRubrica) =>
                                      editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica.encargos &&
                                      editalRubrica?.editalRubricaMoedaEstrangeira?.length > 0,
                                  );
                                },

                                handleChange: (
                                  emMoedaEstrangeira: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!emMoedaEstrangeira) {
                                    handler(
                                      `${path}`.replace('emMoedaEstrangeira', 'moedaEstrangeiraId'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/moedaEstrangeiraId',
                              label: 'Moeda estrangeira',
                              rule: showIfForeignCurrency,
                              options: {
                                required: true,
                                displayProperties: ['nome'],
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: MoedaEstrangeira[],
                                    api: GenericApi<MoedaEstrangeira>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<MoedaEstrangeira[]> => {
                                    let editalRubrica = formData?.edital?.editalRubrica?.find(
                                      (editalRubrica: EditalRubrica) =>
                                        editalRubrica?.tipoEditalRubrica ===
                                        TipoEditalRubrica?.encargos,
                                    );

                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((moedaEstrangeira: MoedaEstrangeira) =>
                                          editalRubrica?.editalRubricaMoedaEstrangeira?.find(
                                            (editalRubricaMoedaEstrangeira) =>
                                              editalRubricaMoedaEstrangeira?.moedaEstrangeiraId ===
                                              moedaEstrangeira?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/mesPrevisto',
                              label: 'Mês Previsto',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}°`,
                                    const: i + 1,
                                  }));
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            },
            {
              type: 'Category',
              label: 'Bolsas',
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/bolsaProposta',
                  label: '',
                  options: {
                    label: 'Bolsa',
                    detail: {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Select',
                              scope: '#/properties/modalidadeBolsaId',
                              label: 'Modalidade da Bolsa',
                              options: {
                                filter: {
                                  handler: (
                                    formData: Proposta,
                                    listData: any[],
                                    api: GenericApi<ModalidadeBolsa>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<ModalidadeBolsa[]> => {
                                    const bolsaEdital = formData?.edital?.bolsaEdital?.map(
                                      (bolsaEdital: BolsaEdital) => bolsaEdital?.modalidadeBolsaId,
                                    );
                                    return api
                                      .getAll()
                                      .then((response) =>
                                        response.filter((modalidadeBolsa: ModalidadeBolsa) =>
                                          bolsaEdital?.find(
                                            (modalidadeBolsaEdital: number) =>
                                              modalidadeBolsaEdital === modalidadeBolsa?.id,
                                          ),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/nivelBolsaId',
                              label: 'Nível da Bolsa',
                              options: {
                                formatOptionLabel: formatLabel,

                                handleChange: (
                                  values: NivelBolsa,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  handler(`nivelBolsaId`, values?.id);
                                  handler(`valorAtualBolsa`, values?.valorAtualBolsa);
                                },

                                filter: {
                                  formFields: ['modalidadeBolsaId'],
                                  handler: (
                                    { bolsaProposta }: Proposta,
                                    listData: NivelBolsa[],
                                    api: GenericApi<NivelBolsa>,
                                    queryFilterValues: any,
                                    path: string,
                                  ): Promise<NivelBolsa[]> => {
                                    if (!queryFilterValues?.[0]?.value) return Promise.resolve([]);
                                    const index = Number(path?.split?.('.')?.at?.(-2));
                                    if (Number.isNaN(index)) return Promise.resolve([]);
                                    return api
                                      .getAll({ modalidadeBolsaId: queryFilterValues?.[0]?.value })
                                      .then((response) =>
                                        response.filter((x: any) =>
                                          bolsaProposta
                                            .filter((_: any, i: number) => i !== index)
                                            .every((y: any) => y.nivelBolsaId !== x.id),
                                        ),
                                      );
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Number',
                              scope: '#/properties/quantidade',
                              label: 'Quantidade',
                              options: {
                                numberStep: 1,
                                required: true,
                                isPositiveNumber: true,
                                handleChange: (
                                  value: any,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const quantidade = Number(value);
                                  const ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                                  const duracao =
                                    Number(formData?.bolsaProposta?.[ticketIndex]?.duracao) ?? 0;
                                  const valorAtualBolsa = (formData as any)?.valorAtualBolsa ?? 0;

                                  handler(`quantidade`, quantidade);
                                  handler(
                                    path.replace('quantidade', 'valorTotal'),
                                    duracao * Number(quantidade) * valorAtualBolsa,
                                  );
                                },
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/duracao',
                              label: 'Duração (Em meses)',
                              options: {
                                required: true,
                                oneOf: (formData: Proposta) => {
                                  return Array.from({ length: formData?.duracao }, (_, i) => ({
                                    title: `${i + 1}`,
                                    const: i + 1,
                                  }));
                                },

                                handleChange: (
                                  value: any,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path: string,
                                ) => {
                                  const duracao = Number(value);
                                  const ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                                  const quantidade =
                                    formData?.bolsaProposta?.[ticketIndex]?.quantidade;
                                  const valorAtualBolsa = (formData as any)?.valorAtualBolsa ?? 1;

                                  handler(
                                    path.replace('duracao', 'valorTotal'),
                                    duracao * Number(quantidade) * valorAtualBolsa,
                                  );
                                },
                              },
                            },
                            {
                              type: 'MonetaryInput',
                              scope: '#/properties/valorTotal',
                              label: 'Valor Total',
                              options: {
                                required: true,
                                disabled: true,
                              },
                            },
                          ],
                        },
                        {
                          type: 'HorizontalLayout',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/contrapartida',
                              options: {
                                defaultValue: false,
                                handleChange: (
                                  contrapartida: boolean,
                                  handler,
                                  formData: Proposta,
                                  ctx,
                                  path,
                                ) => {
                                  if (!contrapartida) {
                                    handler(
                                      `${path}`.replace('contrapartida', 'tipoPessoa'),
                                      undefined,
                                    );
                                    handler(
                                      `${path}`.replace('contrapartida', 'entidade'),
                                      undefined,
                                    );
                                  }
                                },
                              },
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/entidade',
                              label: 'Entidade',
                              rule: showIfContrapartida,
                              options: {
                                required: true,
                              },
                            },
                            {
                              type: 'Select',
                              scope: '#/properties/tipoPessoa',
                              label: 'Tipo',
                              rule: showIfContrapartida,
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital/properties/bolsaEdital',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                } as Condition,
              } as Rule,
            },
            {
              type: 'Category',
              label: 'Resumo Orçamento Consolidado',
              rule: {
                effect: RuleEffect.SHOW,
                condition: {
                  scope: '#/properties/edital',
                  schema: {
                    properties: {
                      editalRubrica: {
                        type: 'array',
                        minItems: 1,
                      },
                    },
                  },
                } as Condition,
              } as Rule,
              options: {
                stepHandler: {
                  handler: stepHandlerErrors,
                },
              },
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'MoneyExchangeRate',
                      scope: '#/properties/propostaCotacaoMoedaEstrangeira',
                      label: 'Cotação Moeda Estrangeira',
                      options: {
                        isPositiveNumber: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'CustomTableView',
                      scope: '#/properties/orcamentoConsolidado',
                      options: {
                        label: 'Resumo Orçamento Consolidado',
                        tableOf: (formData: Proposta) => {
                          return getOrcamentoConsolidadoTable(formData);
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Termos',
      elements: [
        {
          type: 'Category',
          label: 'Termo de Aceite',
          options: {
            stepHandler: {
              handler: stepHandlerAbrangencia,
            },
            nextButtonTitle: 'Submeter Proposta',
            nextButtonDisabled: (data: any) => {
              return !data?.termoDeAceiteAceito;
            },
            onFinish: (navigate: NavigateFunction, ctx: CrudContextData) => {
              navigate('/');
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CkEditor',
                  scope: '#/properties/edital/properties/termoDeAceite',
                  options: {
                    ckeditorSize: { height: 400 },
                    toolbar: [],
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/termoDeAceiteAceito',
              label: 'Li e estou de acordo com o Termo de Aceite',
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    showProgress: true,
    route: '/proposta',
  },
};

export default propostaUiSchema;
