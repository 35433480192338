const funcaoParticipanteUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
      label: 'Nome da Função de Participante',
      options: {
        required: true,
      },
    },
  ],
};

export default funcaoParticipanteUiSchema;
