import { ControlProps } from '@jsonforms/core';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { GenericApi } from '../../../api/generic-api';
import { displayProperties, moduleLabels } from '../../../views';
import { CrudContextData, IControlElement } from '../../uischema';

export interface CustomSelectProps extends ControlProps {
  multiple: boolean;
  selectedItem: any;
  setSelectedItem: (value: any) => void;
  options: any[];
  oneOf?: any[];
  isVisible: boolean;
  isDisabled: boolean;
  apiUrl: string;
}

export type FilterProps = {
  uischema: IControlElement;
  customProps: CustomSelectProps;
  path: string;
  ctx: CrudContextData;
  apiListData: any[];
  setApiListData: (data: any) => void;
  lastFilterValues: any;
  setLastFilterValues: (values: any) => void;
  api: GenericApi<any> | null;
  apiUrl: string;
  setSelectedItem: (item: any) => void;
};

export const disableHandler = (
  uischema: IControlElement,
  ctx: CrudContextData,
  apiListData: any[],
  setDisabled: (value: boolean) => void,
) => {
  const disableHandler = uischema?.options?.disableHandler;
  if (!disableHandler) return;

  const handlerResult = disableHandler?.(ctx, apiListData);
  setDisabled(handlerResult);
};

export const filterHandler = (props: FilterProps) => {
  const {
    uischema,
    path,
    ctx,
    apiListData,
    setApiListData,
    lastFilterValues,
    setLastFilterValues,
    api,
    apiUrl,
    setSelectedItem,
  } = props;

  const { formData, showError } = ctx;

  const filter = uischema?.options?.filter;
  if (!filter) return;

  const { formFields, handler } = filter;
  if (!formFields) return;

  let hasNullFilterValue = false;

  const queryFilterValues =
    formFields?.map?.((filter: any) => {
      const filterValue = path
        ?.split('.')
        .slice(0, -1)
        .concat(filter)
        .reduce((acc: any, key: any) => acc && acc[key], formData);

      if (!filterValue) hasNullFilterValue = true;

      return {
        filter,
        value: filterValue,
      };
    }) ?? [];

  if (handler && !isEqual(queryFilterValues, lastFilterValues)) {
    if (formFields) {
      handler?.(formData, apiListData, api, queryFilterValues, path)
        ?.then?.((dataFromApi: any) => {
          setApiListData(dataFromApi);
        })
        .catch((error: any) => {
          showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
        });
    }

    if (hasNullFilterValue) {
      //Limpa a lista quando o filtro observado for null
      setApiListData([]);
    }

    if (!(queryFilterValues.length && !lastFilterValues?.length)) {
      // Alterou os filtros
      // Limpa o select
      setTimeout(
        () => handleChange(null, uischema, apiUrl, props.customProps, ctx, setSelectedItem),
        100,
      );
    }

    setLastFilterValues(queryFilterValues);
  }
};

export const loadSavedItem = (
  uischema: IControlElement,
  props: CustomSelectProps,
  dataFromApi: any[],
  setSelectedItem: (item: any) => void,
  formData: any,
  apiUrl: string,
) => {
  if (props?.data) {
    const targetFieldValue = uischema?.options?.targetFieldValue ?? 'id';
    const comparator = uischema?.options?.optionsComparator;

    const item = dataFromApi?.find?.((item: any) => {
      const isMatch = comparator
        ? comparator?.(item, props?.data)
        : item?.id === Number(props?.data);

      return isMatch;
    });
    if (item) {
      const option = {
        label: displayPropertiesComputed(uischema, item, apiUrl),
        value: item?.[targetFieldValue],
        codigo: item?.codigo,
      };
      setSelectedItem(option);
      if (uischema.options?.handleChange) {
        uischema.options?.handleChange?.(item, props.handleChange, formData);
      }
    }
  }
};

export const displayPropertiesComputed = (uischema: IControlElement, item: any, apiUrl: string) => {
  if (isEmpty(item)) return '';
  const formatOptionLabel = uischema?.options?.formatOptionLabel;

  if (formatOptionLabel && typeof formatOptionLabel === 'function') {
    return formatOptionLabel(item);
  }

  // Formatação padrão
  const properties = displayProperties?.find((option) => option?.id === apiUrl);
  const values: string[] = [];

  if (typeof properties?.value === 'function') return properties?.value(item);

  let propValues = uischema?.options?.displayProperties ?? (properties?.value as string[]);
  propValues?.forEach?.((prop: any) =>
    values?.push?.(prop.split('.').reduce((acc: any, key: any) => acc && acc[key], item)),
  );
  return values?.join?.(', ');
};

export const getLabel = (uischema: IControlElement, apiUrl: string) => {
  const uiSchemaLabel = uischema.label;
  const moduleLabel = moduleLabels?.find((headCell) => headCell?.id === apiUrl)?.value;
  return uiSchemaLabel ?? moduleLabel;
};

export const handleChange = (
  content: any,
  uischema: IControlElement,
  apiUrl: string,
  props: CustomSelectProps,
  ctx: CrudContextData,
  setSelectedItem: (item: any) => void,
) => {
  const { formData } = ctx;

  const option = {
    label: displayPropertiesComputed(uischema, content?.data, apiUrl) ?? '',
    value: content?.value,
    codigo: content?.codigo,
  };

  if (uischema.options?.handleChange) {
    uischema.options?.handleChange?.(
      content?.data ?? content?.value ?? null,
      props.handleChange,
      formData,
      ctx,
      props.path,
    );
  }

  props.handleChange(props.path, content?.value ?? null);
  setSelectedItem(content?.value ? option : null);
};

export const loadEnumDefault = (
  listData: any,
  props: CustomSelectProps,
  ctx: CrudContextData,
  schema: any,
) => {
  const { formData } = ctx;

  if (!props.data && formData?.[`${props.path}-codigo`]) {
    const foreignEnum = (schema as any)?.foreignEnum;
    const enumCode = foreignEnum[formData[`${props.path}-codigo`]];
    const item = listData?.find?.((item: any) => item.codigo === enumCode);
    props.handleChange(props.path, item?.id);
  }
};
