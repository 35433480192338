import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { headCells } from './aviso.headcell';
import avisoUiSchema from './aviso.uischema';
import { Aviso as AvisoModel } from '../../../models/aviso';

function Aviso() {
  const customErrors = [whiteSpaces('aviso')];

  const defaultForm = {
    possuiDataEncerramento: false,
  };

  return (
    <Crud<AvisoModel>
      headCells={headCells}
      titleConfig={{ value: 'Avisos', show: true }}
      uischema={avisoUiSchema}
      apiUrl={'/aviso'}
      hideView
      queryFilters={{ withDeleted: true }}
      customErrors={customErrors}
      defaultForm={defaultForm}
    />
  );
}

export default Aviso;
