import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { DocumentoPessoal } from '../../../models/documento-pessoal';

export const displayProperties = ['nome'];

export const moduleName = 'PESSOA.DOCUMENTO_PESSOAL.DOCUMENTO_PESSOAL';

export const headCells: readonly HeadCell<DocumentoPessoal>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Documentos Pessoais',
  },
];
