import { styled, Typography } from '@mui/material';
import { formatFloat } from '../../helpers/format-float';
import { TableLayoutHeaderData } from '../../jsonforms/uischema/uischema.type';

export const getSumByMoeda = (listData: TableLayoutHeaderData[], field: string) => {
  return listData?.reduce(
    (acc, data) => {
      const { moedaEstrangeira } = data;
      const moeda = moedaEstrangeira
        ? `${moedaEstrangeira.nome} (${moedaEstrangeira.simbolo})`
        : 'Real (R$)';

      if (acc[moeda]) {
        acc[moeda] += data[field] ? data[field] : 0;
      } else {
        acc[moeda] = data[field];
      }

      return acc;
    },
    {} as Record<string, number>,
  );
};

// Função para construir o layout do cabeçalho da tabela
export const buildTableLayoutHeader = (data: any[], field: string = 'valorTotal') => {
  const totalSums = getSumByMoeda(data, field);

  return (
    <>
      <Typography>Total gasto em cada moeda: </Typography>
      <TotalSumText>
        {totalSums &&
          Object.entries(totalSums).map(([moeda, total], index) => (
            <>
              {moeda} {total ? formatFloat(total) : '0,00'}
              {index < Object.entries(totalSums).length - 1 && ' | '}
            </>
          ))}
      </TotalSumText>
    </>
  );
};

const TotalSumText = styled(Typography)({
  fontFamily: 'Roboto, sans-serif !important',
  color: '#1351B4 !important',
});
