// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.array-toolbar {
  margin-bottom: 2rem;

  .array-toolbar-container {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .left-grid-container {
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      .array-toolbar-label {
        font-family: 'Raleway';
        font-weight: 600;
        padding: 0.35rem 0;
      }
    }

    .add-button {
      font-family: 'Raleway';
      background-color: #003d92;
      font-size: 0.75rem;

      &:hover {
        background-color: #002b66;
      }
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/style/array-toolbar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;;EAEnB;IACE,WAAW;IACX,mBAAmB;IACnB,8BAA8B;;IAE9B;MACE,mBAAmB;MACnB,8BAA8B;MAC9B,QAAQ;;MAER;QACE,sBAAsB;QACtB,gBAAgB;QAChB,kBAAkB;MACpB;IACF;;IAEA;MACE,sBAAsB;MACtB,yBAAyB;MACzB,kBAAkB;;MAElB;QACE,yBAAyB;MAC3B;IACF;EACF;AACF","sourcesContent":[".array-toolbar {\n  margin-bottom: 2rem;\n\n  .array-toolbar-container {\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n\n    .left-grid-container {\n      align-items: center;\n      justify-content: space-between;\n      gap: 5px;\n\n      .array-toolbar-label {\n        font-family: 'Raleway';\n        font-weight: 600;\n        padding: 0.35rem 0;\n      }\n    }\n\n    .add-button {\n      font-family: 'Raleway';\n      background-color: #003d92;\n      font-size: 0.75rem;\n\n      &:hover {\n        background-color: #002b66;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
