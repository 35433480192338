import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

export const StyledDrawer = styled(Drawer)`
  background-color: var(--color-background) !important;
  color: var(--color-foreground) !important;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 10 !important;
  font-family: 'Raleway', sans-serif;
  font-weight: medium;
  font-size: 14px;

  .MuiDrawer-paper {
    box-sizing: border-box;
    overflow-x: hidden;
    transition: width 0.5s;
  }
`;

export const SidebarBox = styled(Box)`
  width: 100%;
`;

export const SidebarToolbar = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;

  div {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SidebarBoxContent = styled(Box)`
  cursor: pointer;
  padding: 5px;
  height: 50px;
`;

export const NavIcon = styled(Box)`
  min-width: 30px !important;
  color: var(--color-icon) !important;
  display: flex;
`;

export const SidebarBoxContentNavIcon = styled(Box)`
  display: flex;
  justify-content: left;
  align-self: center;
  padding: 15px 0px;
  padding-left: 15px;
`;

export const SidebarBoxContentDiv = styled(Box)`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
`;

export const NavContainer = styled(List)`
  background-color: var(--color-background) !important;
  color: var(--color-foreground) !important;
  height: 100%;
`;
