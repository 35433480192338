import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

export const HomeLink = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomeIcon = styled(Home)<{ size: number }>`
  color: var(--color-icon);
  padding-bottom: 1.5;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const BreadcrumbContent = styled(Typography)<{ isLast: boolean }>`
  padding: 0.2;
  font-size: 0.9rem !important;
  padding-left: 1.5;
  padding-right: 1.5;
  border-radius: 12;
  color: var(--color-foreground);

  &:hover {
    background-color: var(--color-background);
  }

  ${({ isLast }) =>
    isLast &&
    `
    color: var(--color-foreground);
    font-weight: 700 !important;
    cursor: default;
  `}
`;
