import {
  JsonFormsCore,
  JsonSchema,
  UISchemaElement,
  ValidationMode,
  createAjv,
} from '@jsonforms/core';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Fragment } from 'react';
import { customRenderers } from '../../jsonforms/renderers';
import { translateErrors } from './i18n';
import { Form, FormContainer, StyledLoading } from './style/json-form.styled';

export interface JsonFormProps {
  uischema: UISchemaElement;
  schema: JsonSchema;
  data: any;
  load?: boolean;
  onChange?: (state: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
  readonly?: boolean;
  validationMode?: ValidationMode;
  additionalErrors?: any[];
  width?: number;
  height?: number;
  horizontalScroll?: boolean;
  verticalScroll?: boolean;
}

const JsonForm = ({
  uischema,
  schema,
  data,
  load,
  onChange,
  readonly,
  validationMode = 'ValidateAndShow',
  additionalErrors,
  width,
  height,
  horizontalScroll,
  verticalScroll,
}: JsonFormProps) => {
  const handleDefaultsAjv = createAjv({ useDefaults: true });
  const translateError = (error: any): string => translateErrors(error) ?? error?.message ?? '';

  const getSizeConfig = () => {
    let sizeConfig: {
      width?: string;
      height?: string;
      overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
      overflowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
    } = {};

    if (width) sizeConfig.width = `${width}px`;
    if (height) sizeConfig.height = `${height}px`;

    if (horizontalScroll) sizeConfig.overflowX = 'scroll';
    if (verticalScroll) sizeConfig.overflowY = 'scroll';

    return sizeConfig;
  };

  return (
    <Fragment>
      <Form style={getSizeConfig()}>
        {load && <StyledLoading isLoading={load} />}
        {!load && (
          <FormContainer>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={[...materialRenderers, ...customRenderers]}
              cells={materialCells}
              onChange={onChange}
              readonly={readonly}
              validationMode={validationMode}
              i18n={{ locale: 'pt', translateError }}
              additionalErrors={additionalErrors}
              ajv={handleDefaultsAjv}
            />
          </FormContainer>
        )}
      </Form>
    </Fragment>
  );
};

export default JsonForm;
