import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { FormApi } from '../../../api/generic-api';
import { IUiSchema } from '../../../jsonforms/uischema';
import { EdicaoRecursoLinhaDeAcao } from '../../../models/edicao-recurso-linha-de-acao';
import {
  TipoRecursoLinhaDeAcao,
  tipoRecursoLinhaDeAcaoI18N,
} from '../../../models/tipo-recurso-linha-de-acao';

const hideIfInstrumentoJuridico: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/tipoRecursoLinhaDeAcao',
    schema: {
      not: {
        const: 'TESOURO',
      },
    },
  } as Condition,
};

const hideIfTesouro: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/tipoRecursoLinhaDeAcao',
    schema: {
      not: {
        const: 'INSTRUMENTO_JURIDICO',
      },
    },
  } as Condition,
};

const edicaoRecursoLinhaDeAcaoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Select',
      scope: '#/properties/tipoRecursoLinhaDeAcao',
      label: 'Tipo de Recurso',
      options: {
        onlyCreate: true,
        oneOf: (formData: EdicaoRecursoLinhaDeAcao) => {
          return Array.from(Object.values(TipoRecursoLinhaDeAcao), (value, _) => ({
            title: tipoRecursoLinhaDeAcaoI18N[value],
            const: value,
          }));
        },
        handleChange: (
          tipoRecursoLinhaDeAcao: TipoRecursoLinhaDeAcao,
          handler,
          formData: EdicaoRecursoLinhaDeAcao,
          ctx,
          path: string,
        ) => {
          handler('instrumentoJuridicoId', null);
          handler('tesouroId', null);
        },
      },
    },
    {
      type: 'Select',
      scope: '#/properties/instrumentoJuridicoId',
      rule: hideIfTesouro,
    },
    {
      type: 'Select',
      scope: '#/properties/tesouroId',
      rule: hideIfInstrumentoJuridico,
      options: {
        filter: {
          handler: (formData: any, listData: any[], api: FormApi) => {
            return api.getAll({
              withDeleted: 'false',
              orderBy: 'ano',
              orderDirection: 'DESC',
            });
          },
        },
      },
    },
    {
      type: 'MonetaryInput',
      scope: '#/properties/valor',
    },
  ],
};

export default edicaoRecursoLinhaDeAcaoUiSchema;
