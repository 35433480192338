import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { EdicaoRecursoPrograma } from '../../../models/edicao-recurso-programa';

export const displayProperties = ['linhaDeAcao.titulo', 'ano'];

export const moduleName =
  'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_RECURSO_PROGRAMA.EDICAO_RECURSO_PROGRAMA';

export const headCells: readonly HeadCell<EdicaoRecursoPrograma>[] = [
  {
    id: 'edicaoProgramaId',
    numeric: false,
    disablePadding: false,
    label: 'Edição do Programa',
    resource: {
      name: 'edicaoPrograma',
      target: 'ano',
    },
  },
  {
    id: 'linhaDeAcaoId',
    numeric: false,
    disablePadding: false,
    label: 'Linha de Ação',
    resource: {
      target: (data) => data.edicaoLinhaDeAcao?.linhaDeAcao?.titulo,
    },
  },
  {
    id: 'funcionalProgramaticaId',
    numeric: false,
    disablePadding: false,
    label: 'Funcional Programática',
    resource: {
      name: 'funcionalProgramatica',
      target: 'nome',
    },
  },

  {
    id: 'edicaoRecursoProgramaNaturezaDespesa',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
    resource: {
      target: (data) => {
        let newValue = data.edicaoRecursoProgramaNaturezaDespesa?.reduce(
          (acc: number, curr: any) => acc + curr.valor,
          0,
        );
        newValue = parseInt(`${newValue}`) / 100;
        if (Number.isNaN(newValue)) newValue = 0;
        let newValueStr =
          'R$ ' +
          newValue.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return newValueStr;
      },
    },
    mask: 'money',
  },
];
