import { CircleFlag } from 'react-circle-flags';

export const circleFlagBuilder = (option: any) => {
  return (
    <CircleFlag
      className='country-flag'
      countryCode={option?.data?.sigla?.toLowerCase?.()}
      height='16'
    />
  );
};
