import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { PropostaConviteMembro, tipoSituacaoI18N } from '../../../models/proposta';

export const displayProperties = ['usuario.nome'];

export const moduleLabel = 'Convite de Membros';

export const moduleName = 'PROPOSTA.PROPOSTA_CONVITE_MEMBRO.PROPOSTA_CONVITE_MEMBRO';

export const headCells: readonly HeadCell<PropostaConviteMembro>[] = [
  {
    id: 'usuario',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
    resource: {
      target: (row: PropostaConviteMembro): string => row?.usuario?.nome,
    },
  },
  {
    id: 'usuario',
    numeric: false,
    disablePadding: false,
    label: 'Instituição',
    resource: {
      target: (row: PropostaConviteMembro): string => row?.usuario?.instituicao?.nome,
    },
  },
  {
    id: 'situacao',
    numeric: true,
    disablePadding: false,
    label: 'Convite',
    resource: {
      target: (row: PropostaConviteMembro): string => {
        return tipoSituacaoI18N[row?.situacao];
      },
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data de Convite',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataAceite',
    numeric: false,
    disablePadding: false,
    label: 'Data de Aceite',
    dateFormat: 'DD/MM/YYYY',
  },
];
