export enum TipoFuncaoParticipante {
  coordenador = 'COORDENADOR',
  bolsista = 'BOLSISTA',
}

export interface FuncaoParticipante {
  id?: number;
  nome: string;
  codigo?: TipoFuncaoParticipante;
  deletedAt: Date;
}
