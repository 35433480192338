import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { CronogramaDesembolso } from '../../../models/cronograma-desembolso';

export const moduleName =
  'ORCAMENTO.INSTRUMENTO_JURIDICO.INSTITUICAO_INSTRUMENTO_JURIDICO.CRONOGRAMA_DESEMBOLSO.CRONOGRAMA_DESEMBOLSO';

export const headCells: readonly HeadCell<CronogramaDesembolso>[] = [
  {
    id: 'parcela',
    numeric: true,
    disablePadding: false,
    label: 'Parcela',
  },
  {
    id: 'dataPrevista',
    numeric: false,
    disablePadding: false,
    label: 'Data Prevista',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataEfetiva',
    numeric: false,
    disablePadding: false,
    label: 'Data Efetiva',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: false,
    label: 'Valor da Parcela',
    mask: 'money',
  },
];
