import styled from '@emotion/styled';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export const CustomStack = styled(Stack)({
  width: '100%',
  paddingTop: '16px',
  paddingBottom: '16px',
});

export const CustomAutocomplete = styled(Autocomplete)({
  width: '100%',
  paddingTop: '16px',
  paddingBottom: '16px',
});

export const CustomListImageBox = styled('li')({
  borderRadius: '8px',
  margin: '5px',
  padding: '8px',
  [`&.${autocompleteClasses.option}`]: {
    padding: '8px',
  },
});

export const CustomAvatar = styled(Avatar)({
  width: '45px',
  height: '45px',
  fontSize: '13pt',
  bgcolor: '#ffdddd',
  color: '#5d0000',
  marginRight: '10pt',
});

export const CustomSmallAvatar = styled(Avatar)({
  width: '25px',
  height: '25px',
  fontSize: '10pt',
  bgcolor: '#ffdddd',
  color: '#5d0000',
  marginRight: '5pt',
});

export const CustomListImg = styled('img')({
  borderRadius: '50%',
  width: '45px',
  height: '45px',
  marginRight: '10pt',
});

export const CustomSmallListImg = styled('img')({
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  marginRight: '5pt',
});
