import dayjs from '../components/dayjs/sig-dayjs';

const hasFormat = (obj: any): obj is { format: unknown } =>
  obj && typeof obj === 'object' && 'format' in obj;

// Arruma o formato das datas ao carregar uma row
export const fixDate = (data: any, schema: any) => {
  for (const [key, value] of Object.entries(schema?.properties ?? [])) {
    if (hasFormat(value) && value?.format === 'date') {
      if (data?.[key]) {
        data[key] =
          dayjs?.(data?.[key], 'YYYY-MM-DD', 'UTC')?.format?.('YYYY-MM-DD') ?? data?.[key];
      }
    }
  }
};
