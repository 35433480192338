import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { headCells } from './edicao-recurso-programa.headcell';
import edicaoRecursoProgramaUiSchema from './edicao-recurso-programa.uischema';
import { EdicaoPrograma } from '../../../models/edicao-programa';
import { EdicaoRecursoPrograma as EdicaoRecursoProgramaModel } from '../../../models/edicao-recurso-programa';

function EdicaoRecursoPrograma() {
  const { edicaoProgramaId } = useParams();
  const { apiListData } = useCrudContext();
  const [ano, setAno] = React.useState(null);
  const [edicoesRecurso, setEdicoesRecurso] = React.useState(null);
  const edicaoProgramaIdNumber = Number(edicaoProgramaId);
  const apiEdicaoRecursoPrograma = makeApi(`/edicao-recurso-programa`);

  useEffect(() => {
    apiEdicaoRecursoPrograma.getAll({ edicaoProgramaId: edicaoProgramaIdNumber }).then((x) => {
      setEdicoesRecurso(x);
    });
  }, [apiListData]);

  useEffect(() => {
    const api = makeApi<EdicaoPrograma>(`/edicao-programa`);
    api.get(edicaoProgramaIdNumber).then((x) => setAno(x.ano));
  }, []);

  const defaultForm = {
    edicaoProgramaId: edicaoProgramaIdNumber,
    valor: 0,
    ano: ano || null,
    edicoesRecurso,
  };

  return (
    <Crud<EdicaoRecursoProgramaModel>
      headCells={headCells}
      uischema={edicaoRecursoProgramaUiSchema}
      titleConfig={{ value: 'Recursos do Programa', show: true }}
      apiUrl={'/edicao-recurso-programa'}
      apiClient={apiEdicaoRecursoPrograma}
      defaultForm={defaultForm}
      queryFilters={{ edicaoProgramaId: edicaoProgramaIdNumber }}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export default EdicaoRecursoPrograma;

export const rootViews = ['edicao-programa'];
