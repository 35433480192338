// styledComponents.js
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import CustomModal from '../../../components/layout/custom-modal';

// Define and export the styled components
export const CustomButton = styled(Button)({
  width: '180px',
  height: '36px',
  borderRadius: '4px',
  padding: '10px, 16px, 10px, 16px',
  backgroundColor: '#003D92',
  color: 'white',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const ComponentBox = styled(Box)({
  width: '100%',
  fontFamily: 'Raleway',
});

export const Header = styled('div')({
  marginLeft: '20px',
});

export const Search = styled('div')({
  padding: '20px',
  paddingTop: 0,
  marginBottom: '20px',
});

export const WidthFull = styled(List)({
  width: '100%',
});

export const ListItemAviso = styled(List)({
  width: '100%',
  marginBottom: '12px',
  marginTop: '10px',
});

export const WidthFullBox = styled(Box)({
  width: '100%',
});

export const WidthFullListItem = styled(ListItem)({
  width: '100%',
});

export const ListItemAvisoName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'Raleway',
});

export const BoxFlex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
});

export const ButtonLabel = styled('span')({
  marginLeft: 4,
});

export const AvisoDescricao = styled(Typography)({
  fontFamily: 'Raleway',
  color: '#737784',
});

export const StyledCustomModal = styled(CustomModal)({
  border: 'none',
  borderRadius: '4px',
  '.modal_modal_description': {
    backgroundColor: 'red',
  },
});
