import { HeadCell } from '../../components/crud/protocols/head-cell';
import { TipoVinculoInstitucional } from '../../models/tipo-vinculo-institucional';

export interface Data extends Record<string, any> {
  nome: string;
}

export const displayProperties = ['nome'];

export const moduleLabel = 'Tipo de Vínculo Institucional';

export const headCells: readonly HeadCell<TipoVinculoInstitucional>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
