import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import cloneDeep from 'lodash/cloneDeep';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import { useNotificacaoContext } from '../../views/fundacao/notificacao/notificacao-context';
import { useAuthContext } from '../../views/login/auth/auth-context';
import BreadcrumbsCustom from '../breadcrumb/breadcrumb';
import { BreadcrumbsContext } from '../breadcrumb/breadcrumb-context';
import { useCrudContext } from '../crud/crud-context';
import { makeErrorsManager } from '../crud/errors-manager';
import { NavDrawer } from './components/nav-drawer';
import { DarkModeToggle } from './dark-mode-toggle/dark-mode-toggle';
import { useThemeContext } from './dark-mode-toggle/theme-context';
import './style/layout.css';
import { ContentWrapper } from './style/layout.styled';
import LogoFundacao from './logo-fundacao';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(() => ({
  boxShadow: 'none',
  backgroundColor: '#FFFFFF',
}));

const defaultTheme = createTheme();

export default function Layout({ children }: { children: ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { loadNotificationCount, notificationCount, borderColor } = useNotificacaoContext();
  const { logout, loggedInUser } = useAuthContext();
  const userHasProfile = loggedInUser?.perfil?.length > 0;
  const navigate = useNavigate();
  const {
    setCurrentTitle,
    currentTitle,
    crudStates,
    setDisabledFields,
    updateCrudStates,
    setFormData,
    setId,
    id,
  } = useCrudContext();
  const { breadcrumb, setBreadcrumb } = useContext(BreadcrumbsContext);
  const [mainContentMarginLeft, setMainContentMarginLeft] = useState('240px');
  const { isDarkMode } = useThemeContext();

  const errorsManager = makeErrorsManager(useCrudContext());
  const { clearErrors } = errorsManager;

  let location = useLocation();

  useEffect(() => {
    // Configurações do Breadcrumb
    if (location.pathname !== '/home') {
      const current = {
        name: currentTitle,
        pathname: location.pathname,
        defaultState: crudStates,
      };
      const index = breadcrumb?.findIndex((i) => i.pathname === location.pathname);
      const targetBreadcrumb = breadcrumb?.[index];
      if (currentTitle && targetBreadcrumb?.name !== currentTitle) {
        if (breadcrumb[index]) {
          breadcrumb[index].name = currentTitle;
        }
      }
      if (index === -1) {
        const newBreadcrumb = [...breadcrumb, current];
        setBreadcrumb(newBreadcrumb);
      } else {
        const breadcrumbCopy = cloneDeep(breadcrumb);
        breadcrumbCopy.splice(index + 1);
        setBreadcrumb(breadcrumbCopy);
      }
    } else {
      setCurrentTitle('');
      setBreadcrumb([]);
    }

    loadNotificationCount();
  }, [currentTitle]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const back = (state = CrudStatesOptions.LIST) => {
    clearErrors();
    if (state === CrudStatesOptions.VIEW) {
      updateCrudStates(state);
    } else if (state === CrudStatesOptions.LIST) {
      updateCrudStates(state);
      setFormData({});
      setDisabledFields([]);
      const idToRemoveRegex = new RegExp(`/${id}$`);
      setId(-1);
      if (idToRemoveRegex.test(location.pathname)) {
        const newPath = location.pathname.replace(idToRemoveRegex, '');
        navigate(newPath);
      }
    }
  };

  const handleDrawerOpenMainContent = (open: boolean) => {
    setMainContentMarginLeft(open ? '240px' : '60px');
  };

  const listItemIconCss = {
    '& .MuiSvgIcon-root': {
      minWidth: '40px',
      textAlign: 'center',
      justifyContent: 'center',
      height: '20px',
    },
  };

  const menuItemCss = {
    '.MuiListItemIcon-root': {
      //padding: '10px',
    },
  };

  const handleNameAvatar = useCallback(() => {
    const name = loggedInUser?.nome;
    if (!name) return '';

    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  }, [loggedInUser]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        {userHasProfile ? <NavDrawer handleMainContent={handleDrawerOpenMainContent} /> : null}

        <AppBar position='fixed' className='appbar' sx={{ zIndex: 1 }}>
          <Toolbar disableGutters className='toolbar-layout-topside'>
            {!userHasProfile ? <LogoFundacao width={60} className='logo-no-profile' /> : null}
            <div
              className='back'
              style={{
                marginLeft: userHasProfile ? mainContentMarginLeft : '0',
                transition: 'margin-left 0.5s',
              }}
            >
              <Button
                className='btn-back'
                onClick={() => {
                  return back();
                }}
              >
                <ArrowBackIcon></ArrowBackIcon>
                Voltar
              </Button>
              <span style={{ color: '#BBBBBB' }}>|</span>
              <BreadcrumbsCustom />
            </div>
            <div className='notification-menu'>
              <Link to='/notificacao'>
                <Button type='button'>
                  <Badge
                    badgeContent={notificationCount}
                    max={99}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: borderColor,
                        color: 'white',
                      },
                    }}
                  >
                    <NotificationsIcon></NotificationsIcon>
                  </Badge>
                </Button>
              </Link>
            </div>
            <div className='user-menu'>
              <Button onClick={handleClick}>
                <Avatar
                  src={loggedInUser?.fotoPerfil}
                  sx={{
                    width: 30,
                    height: 30,
                    fontSize: '14px',
                    backgroundColor: '#FFB4AB',
                  }}
                >
                  {handleNameAvatar()}
                </Avatar>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  '& .MuiPaper-root': {
                    width: '170px',
                  },
                }}
              >
                <Link to='/editar-perfil'>
                  <MenuItem sx={menuItemCss} onClick={handleClose}>
                    <ListItemIcon sx={listItemIconCss}>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary='Perfil' />
                  </MenuItem>
                </Link>

                <MenuItem sx={menuItemCss}>
                  <ListItemIcon sx={listItemIconCss}>
                    <DarkModeToggle />
                  </ListItemIcon>
                  <ListItemText primary={!isDarkMode ? 'Modo Claro' : 'Modo Escuro'} />
                </MenuItem>

                <Divider />

                <MenuItem sx={menuItemCss} onClick={logout}>
                  <ListItemIcon sx={listItemIconCss}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary='Sair' />
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <div
          className='main-content'
          style={{
            marginLeft: userHasProfile ? mainContentMarginLeft : '20px',
            transition: 'margin 0.5s',
          }}
        >
          <ContentWrapper>{children}</ContentWrapper>
        </div>
      </Box>
    </ThemeProvider>
  );
}
