import styled from '@emotion/styled';
import ListItemText from '@mui/material/ListItemText';
import { Calendar2RangeFill } from 'styled-icons/bootstrap';
import { BackgroundColor } from 'styled-icons/foundation';

export const Vigencia = styled('span')<{ colorValue: string }>(({ colorValue }) => ({
  color: colorValue,
  marginLeft: '9px',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  fontFamily: 'Raleway',
}));

export const VigenciaLabel = styled('span')({
  fontFamily: 'Raleway',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#434653',
  marginLeft: '9px',
});

export const CallendarRangeIcon = styled(Calendar2RangeFill)({
  width: '16px',
  height: '16px',
  color: '#1351B4',
});

export const ComponentContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledListItemText = styled(ListItemText)({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});
