import { EdicaoLinhaDeAcao } from './edicao-linha-de-acao';
import { Tesouro } from './tesouro';
import { InstrumentoJuridico } from './instrumento-juridico';

export enum TipoCodigo {
  corte = 'CORTE',
  aditivo = 'ADITIVO',
}

export interface EdicaoRecursoLinhaDeAcao {
  id: number;
  edicaoLinhaDeAcaoId: number;
  instrumentoJuridicoId: number;
  tesouroId: number;
  valor: number;
  edicaoLinhaDeAcao: EdicaoLinhaDeAcao;
  tesouro: Tesouro;
  instrumentoJuridico: InstrumentoJuridico;
  deletedAt: Date;
  createdAt: Date;
}
