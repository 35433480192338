import Link from '@mui/material/Link';

export type ShowMoreType = {
  linkText: string;
  callback: () => void;
};

export const truncateByCharacters = (
  textString: string,
  charLimit: number,
  showMore?: ShowMoreType,
) => {
  if (!textString) return '';
  if (textString.length <= charLimit) return textString;
  if (showMore) {
    return (
      <>
        {textString.slice(0, charLimit)}
        <Link onClick={showMore.callback} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {showMore.linkText}
        </Link>
      </>
    );
  }

  return textString.slice(0, charLimit) + '...';
};
