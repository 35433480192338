import { Rule } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';
import { ValorDiaria } from '../../../models/valor-diaria';

const showIfForeignCurrency = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/emMoedaEstrangeira',
    schema: {
      const: true,
    },
  },
} as Rule;

const diariasUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'LongText',
      scope: '#/properties/descricao',
      label: 'Descrição',
      options: {
        required: true,
      },
    },
    {
      type: 'Select',
      scope: '#/properties/nivelAcademicoId',
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorDiaria',
          label: 'Valor Diária',
          options: {
            required: true,
            isPositiveNumber: true,
          },
        },
        {
          type: 'Boolean',
          label: 'Moeda estrangeira',
          scope: '#/properties/emMoedaEstrangeira',
          options: {
            handleChange: (
              emMoedaEstrangeira: boolean,
              handler,
              formData: ValorDiaria,
              ctx,
              path: string,
            ) => {
              handler('moedaEstrangeiraId', null);
            },
          },
        },
        {
          type: 'Select',
          label: 'Moeda estrangeira',
          scope: '#/properties/moedaEstrangeiraId',
          rule: showIfForeignCurrency,
          options: {
            required: (ctx) => !!ctx?.formData?.emMoedaEstrangeira,
          },
        },
      ],
    },
  ],
};

export default diariasUiSchema;
