// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index {
  margin-right: 1.5rem;

  .avatar {
      font-family: 'Raleway';
      font-weight: 600;
      background-color: #003D92; 
      height: 32px; 
      width: 32px;
  }
}

.item-label{
  font-family: 'Raleway';
  font-weight: 600;
  size: 16px;
  color: #333333;
}

.arrow-icon{
  margin-left: 24px;
}

.accordion-margin-bottom {
  margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/style/expandable-item-layout.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;;EAEpB;MACI,sBAAsB;MACtB,gBAAgB;MAChB,yBAAyB;MACzB,YAAY;MACZ,WAAW;EACf;AACF;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".index {\n  margin-right: 1.5rem;\n\n  .avatar {\n      font-family: 'Raleway';\n      font-weight: 600;\n      background-color: #003D92; \n      height: 32px; \n      width: 32px;\n  }\n}\n\n.item-label{\n  font-family: 'Raleway';\n  font-weight: 600;\n  size: 16px;\n  color: #333333;\n}\n\n.arrow-icon{\n  margin-left: 24px;\n}\n\n.accordion-margin-bottom {\n  margin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
