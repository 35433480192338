import isEmpty from 'lodash/isEmpty';
import { IControlElement } from '../uischema';
import { translateErrors } from '../../components/crud/i18n';
import { displayProperties, moduleLabels } from '../../views';

export const getError = (errorsJsonForms: any[], errorsCustom: any[], path: string) => {
  const requiredError = errorsJsonForms?.find?.((error) => error?.params?.missingProperty === path);
  const customRequiredError = errorsCustom?.find?.(
    (error) => error?.params?.missingProperty === path,
  );
  return requiredError ?? customRequiredError;
};

export const getLabel = (uischema: IControlElement, apiUrl: string) => {
  const uiSchemaLabel = uischema.label;
  const moduleLabel = moduleLabels?.find((headCell) => headCell?.id === apiUrl)?.value;
  return uiSchemaLabel ?? moduleLabel;
};

export const displayPropertiesComputed = (uischema: IControlElement, item: any, apiUrl: string) => {
  if (isEmpty(item)) return '';
  const formatOptionLabel = uischema?.options?.formatOptionLabel;

  if (formatOptionLabel && typeof formatOptionLabel === 'function') {
    return formatOptionLabel(item);
  }

  // Formatação padrão
  const properties = displayProperties?.find((option) => option?.id === apiUrl);
  const values: string[] = [];

  if (typeof properties?.value === 'function') return properties?.value(item);

  let propValues = uischema?.options?.displayProperties ?? (properties?.value as string[]);
  propValues?.forEach?.((prop: any) =>
    values?.push?.(prop.split('.').reduce((acc: any, key: any) => acc && acc[key], item)),
  );
  return values?.join?.(', ');
};

export const getErrorMessage = (errorsJsonForms: any[], errorsCustom: any[], path: string) => {
  const error = getError(errorsJsonForms, errorsCustom, path);
  return translateErrors?.(error) ?? error?.message ?? '';
};
