import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export const Main = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%',
});

export const Container = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
});

export const LeftContent = styled(Box)({
  flexBasis: '100%',
  height: '100%',
  width: '100%',
  marginTop: '20px',
  marginRight: '5rem',
});

export const RightMenu = styled(Box)({
  flexbasis: '300px',
  width: '300px',
  flexShrink: 0,
});

export const Header = styled('div')({
  width: '100%',
  minHeight: '500px',
  height: '100%',
});

export const TitleCategory = styled(Typography)({
  fontFamily: 'Raleway, sans-serif',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18.78px',
  textAlign: 'left',
  color: '#333333',
  marginBottom: '20px',
});

export const MenuItem = styled('div')<{ depth: number }>(({ depth }) => ({
  borderLeft: depth === 0 ? '2px solid #1351B4' : '2px solid #B0C6FF',
  marginLeft: `${depth * 10}px`,
}));

export const StyledList = styled(List)({
  padding: 0,
});

export const StyledListItemButton = styled(ListItemButton)({
  padding: 0,
  paddingLeft: '8px',
  marginTop: '12px',
  marginBottom: '4px',
});

export const StyledListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14.09px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#001945',
  },
});

export const StyledListSubItemButton = styled(ListItemButton)<{ depth: number }>(({ depth }) => ({
  padding: 0,
  paddingLeft: `${((depth + 1) * 10) / depth}px`,
  marginTop: '4px',
  marginBottom: '4px',
}));

export const StyledListSubItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '11.74px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    color: '#001945',
  },
});

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 28,
});

export const ButtonContainer = styled(Box)({
  marginBottom: '16px',
});

export const StyledButtonOutlined = styled(Button)<{
  fullWidth?: boolean;
  marginBottom?: string;
  marginRight?: string;
}>(({ fullWidth, marginBottom, marginRight }) => ({
  letterSpacing: '0.5px',
  color: 'white',
  height: '30.75px',
  lineHeight: '15px',
  fontWeight: '500',
  backgroundColor: 'var(--color-button)',
  gap: '6px',
  '&:hover': {
    backgroundColor: 'var(--color-button)',
    filter: 'brightness(0.9)',
  },
  width: fullWidth ? '100%' : 'auto',
  marginBottom,
  marginRight,
}));

export const StyledButtonContained = styled(Button)<{
  fullWidth?: boolean;
  marginBottom?: string;
  marginRight?: string;
}>(({ fullWidth }) => ({
  float: 'right',
  backgroundColor: 'var(--color-button)',
  '&:hover': {
    backgroundColor: 'var(--color-button)',
    filter: 'brightness(0.9)',
  },
  width: fullWidth ? '100%' : 'auto',
}));

export const CounterText = styled('span')({
  fontFamily: 'Raleway, sans-serif',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
  textAlign: 'right',
  color: '#757780 !important',
});

export const StepsCompletedContainer = styled(Box)({
  marginBottom: '7px',
});

export const StepsCompletedText = styled(Typography)({
  fontFamily: 'Raleway, sans-serif',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  marginTop: '5px',
  color: '#001945',
  marginBottom: '8px',
});

export const StyledLinearProgress = styled(LinearProgress)({
  height: '12px',
  borderRadius: 12,
  backgroundColor: 'rgba(0, 25, 69, 0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#1351B4',
  },
  marginBottom: '15px',
});

const IconBase = ({ fontSize }: { fontSize?: string }) => ({
  fontSize: fontSize || '16px !important',
  color: '#C5C6D0 !important',
});

export const StyledCheckCheckIcon = styled(CheckCircleIcon)(IconBase);

export const StyledCircleIcon = styled(CircleIcon)(({ fontSize = '14px' }) => ({
  ...IconBase({ fontSize }),
}));

export const StyledHorizontalRuleIcon = styled(HorizontalRuleIcon)(({ fontSize = '18px' }) => ({
  ...IconBase({ fontSize }),
  transform: 'scaleY(2.5)',
}));
