import { HeadCell } from '../../../../components/crud/protocols/head-cell';
import { TipoCodigo } from '../../../../models/edicao-recurso-linha-de-acao';

export interface Data extends Record<string, any> {
  id: number;
  edicaoRecursoLinhaDeAcaoId: number;
  createdAt: Date;
}

export const moduleName =
  'ORCAMENTO.EDICAO_RECURSO_LINHA_DE_ACAO.HISTORICO_AJUSTE_EDICAO_RECURSO_LINHA_DE_ACAO.HISTORICO_AJUSTE_EDICAO_RECURSO_LINHA_DE_ACAO';

export const displayProperties = ['edicaoRecursoLinhaDeAcaoId'];

export const moduleLabel = 'Ajustar Edição do Recurso da Linha de Ação';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'edicaoRecursoLinhaDeAcaoId',
    numeric: true,
    disablePadding: false,
    label: 'Ano',
    resource: {
      target: (data) => data.edicaoRecursoLinhaDeAcao?.edicaoLinhaDeAcao?.ano,
    },
  },
  {
    id: 'valorInicial',
    numeric: true,
    disablePadding: false,
    label: 'Valor Inicial',
    mask: 'money',
    computedRowLabel: (row) => {
      let valorAnterior;
      const tipoCodigo = row.tipo;
      const valorAjuste = row.valorAjuste;
      const valorFinal = row.valorFinal;

      if (tipoCodigo === TipoCodigo.corte) {
        valorAnterior = valorFinal + valorAjuste;
      } else if (tipoCodigo === TipoCodigo.aditivo) {
        valorAnterior = valorFinal - valorAjuste;
      }

      valorAnterior = parseInt(valorAnterior) / 100;
      valorAnterior =
        'R$ ' +
        valorAnterior.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      return valorAnterior;
    },
  },
  {
    id: 'valorAjuste',
    numeric: true,
    disablePadding: false,
    label: 'Valor do Ajuste',
    mask: 'money',
    computedRowLabel: (row) => {
      const tipoCodigo = row.tipo;
      let valorAjuste = row.valorAjuste;

      valorAjuste = parseInt(valorAjuste) / 100;
      valorAjuste =
        'R$ ' +
        valorAjuste.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      if (tipoCodigo === TipoCodigo.corte) valorAjuste = '-' + valorAjuste;
      if (tipoCodigo === TipoCodigo.aditivo) valorAjuste = '+' + valorAjuste;

      return valorAjuste;
    },
  },
  {
    id: 'valorAjustado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Ajustado',
    mask: 'money',
    computedRowLabel: (row) => {
      let valorFinal = row.valorFinal;

      valorFinal = parseInt(valorFinal) / 100;
      valorFinal =
        'R$ ' +
        valorFinal.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      return valorFinal;
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data do Ajuste',
    dateFormat: 'DD/MM/YYYY',
  },
];
