import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledDialogActions, BtnCancelDelete, BtnConfirmDelete } from './style/data-table.styled';

export default function DeleteDialog(props: DeleteDialogProps) {
  const { openDialog, body, handleCloseDelete, destroy, customHeaderText } = props;

  return (
    <Dialog
      open={openDialog}
      keepMounted
      onClose={() => handleCloseDelete()}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>
        {customHeaderText ? customHeaderText : 'Deseja realmente desativar este item?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{body}</DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <BtnCancelDelete onClick={() => handleCloseDelete()}>Cancelar</BtnCancelDelete>
        <BtnConfirmDelete onClick={() => destroy()}>Sim, tenho certeza</BtnConfirmDelete>
      </StyledDialogActions>
    </Dialog>
  );
}

export interface DeleteDialogProps {
  openDialog: boolean;
  handleCloseDelete: Function;
  destroy: Function;
  body: React.ReactNode;
  customHeaderText?: string;
}
