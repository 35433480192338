import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f1efef;
`;

export const Background = styled.div`
  width: 100%;
  background-color: #1351b4;
  padding-right: 16rem;
`;

export const LoginContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: 'Raleway' !important;
  height: 100vh;
  width: 100vw;
  background-color: #faf8ff;

  input,
  p,
  ul,
  li {
    background: transparent !important;
    color: black;
  }
`;

export const Paper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  align-items: flex-start;
  background-color: #faf8ff;
  margin: 2rem 5.75rem 5.31rem 6.25rem;
`;

export const LoginForm = styled.form`
  width: 100%;
  margin-top: 8px;
  max-width: 27.438rem;
`;

export const RememberMe = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  margin-top: 24px;
  color: #939495;
`;

export const LinkStyled = styled.a`
  color: #003d92;
  float: left;
  padding-top: 8px;
  padding-left: 4px;
  text-decoration: none;
`;

export const CompleteLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 2% 0;
`;

export const GovImage = styled.img`
  width: 68px;
  margin-left: 2%;
  padding-bottom: 3px;
`;

export const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9f9f9f;
`;

export const CreateLink = styled(Link)`
  padding-left: 4px;
  color: #003d92;
`;

export const LogoRegister = styled.div`
  width: 6.12rem;
  margin-top: 1.38rem;
  margin-bottom: 0.75rem;
`;

export const LogoLogin = styled.div`
  width: 158.61px;
  margintop: 2rem;
`;

export const CustomDatePicker = styled.div`
  .MuiFilledInput-root {
    overflow: hidden;
    font-family: Raleway;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid #2d3843;
    background-color: transparent;
  }

  .MuiFilledInput-root .MuiInputBase-input {
    color: #052f54 !important;
  }

  .MuiFilledInput-root:hover {
    background-color: transparent;
  }

  .MuiFilledInput-root::before,
  .MuiFilledInput-root::after,
  .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error)::before,
  .MuiFilledInput-root.Mui-focused::after {
    border-bottom: none;
  }

  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: inherit;
  }
`;

export const RecoverPassword = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  flex-direction: column;
  font-family: 'Raleway' !important;
  height: 100vh;
  width: 100vw;
`;

export const BackButton = styled(Button)`
  margin-bottom: 16px !important;
`;

export const LoaderConfirmEmail = styled.div`
  margin-top: 20px;
`;

export const ChangePassword = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: 'Raleway' !important;
  background-color: #faf8ff;
  height: 100vh;
  width: 100vw;

  input,
  p,
  ul,
  li {
    background: transparent !important;
    color: black !important;
  }
`;

export const Lines = styled.div`
  height: 1px;
  width: 100%;
  background-color: #9f9f9f;
`;

export const LineCenter = styled.div`
  height: 100%;
  padding: 0 2.5%;
  color: #9f9f9f;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;
