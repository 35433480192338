import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import {
  BaseButton,
  DisabledButton,
} from '../../../components/layout/components/button/base-button';

export const ArrayTableLayoutContainer = styled(Box)({
  '& button, & p, & button': {
    fontFamily: 'Raleway, sans-serif',
  },
  '& td, & th': {
    fontSize: '0.875rem',
  },
});

export const TotalContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  '& p': {
    fontWeight: '600',
    fontSize: '0.875rem',
  },
});

export const TableLayoutContainer = styled(Box)({
  overflow: 'hidden',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
});

export const StyledTable = styled(Table)({
  maxWidth: '100%',
  border: '1px solid #C5C6D0',
});

export const TableLayoutHeader = styled(TableHead)({
  border: '1px solid #1351B4',
  backgroundColor: '#1351B4',
});

export const TableLayoutHeaderCell = styled(TableCell)({
  border: 'none',
  padding: '0.5rem 0.5rem',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  color: '#fff !important',
});

export const TableLayoutRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: '1rem',
  },
});

export const TableLayoutCell = styled(TableCell)({
  maxWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderColor: '#C5C6D0',
  padding: '0.5rem 0.5rem',
  whiteSpace: 'nowrap',
  wordBreak: 'break-word',
});

export const TableLayoutActionCell = styled(TableCell)({
  borderColor: '#C5C6D0',
  padding: '0.25rem 1rem',
  width: 0,
  whiteSpace: 'nowrap',
});

export const TableLayoutIconButton = styled(IconButton)({
  padding: '0.5rem',
});

export const ActionsContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  marginBottom: '0.75rem',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const ActionButton = styled(Button)`
  ${BaseButton}
  gap: 0.25rem;
  color: white;

  &:hover {
    background-color: var(--color-button);
    filter: brightness(0.9);
  }

  &.Mui-disabled {
    ${DisabledButton}
  }

  &.MuiButton-outlined {
    color: #d32f2f;
    border-color: #d32f2f;
    background-color: transparent;

    &:hover {
      background-color: #d32f2f;
      color: white;
    }
  }
`;

export const TableLayoutBody = styled(Box)({
  overflow: 'auto',
  maxHeight: '20rem',
});

export const TableLayoutPagination = styled(TablePagination)({
  padding: '0',
  border: 'none',
  '& .MuiTablePagination-root, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-menuItem':
    {
      fontFamily: 'Raleway, sans-serif',
    },
  '& .MuiTablePagination-select': {
    color: '#939495',
    backgroundColor: '#fff',
  },
});

export const PaginationContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledAddIcon = styled(AddIcon)({
  width: '1rem',
  height: '1rem',
});

export const StyledCheckIcon = styled(Check)({
  width: '1rem',
  height: '1rem',
});

export const FormActionsContainer = styled(Box)({
  marginTop: '0.5rem',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
});

export const LayoutStateContainer = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  marginBottom: '0.75rem',
});

export const LayoutStateText = styled(Typography)({
  fontWeight: '600',
  fontSize: '0.875rem',
});
