import { useTheme } from '@mui/material/styles';
import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import {
  StyledTableCell,
  StyledTableHeader,
  StyledTableTitle,
  StyledTableData,
  StyledTableSubTitle,
} from './styled/custom-table-view';

const tableRenderTester = rankWith(5, uiTypeIs('CustomTableView'));

export interface HeaderModel {
  value: string;
  texto: string;
  colspan: number;
  customSx?: SxProps<Theme>;
}

export interface SubtitleModel {
  texto: string;
  colspan: number;
  customSx?: SxProps<Theme>;
}

export interface CellModel {
  header: string;
  textValue: string;
  customSx?: SxProps<Theme>;
}

export interface RowsModel {
  value: CellModel[];
  customSx?: SxProps<Theme>;
}

export const TableRenderer = {
  tester: tableRenderTester,
  renderer: withJsonFormsControlProps(({ data, visible, uischema }) => {
    const theme = useTheme();
    const [headers, setHeaders] = useState<HeaderModel[]>([]);
    const [rows, setRows] = useState<RowsModel[]>([]);
    const [subtitles, setSubtitles] = useState<SubtitleModel[]>([]);
    const ctx = useCrudContext();
    const { formData } = ctx;
    const { tableOf, label } = uischema.options || {};

    useEffect(() => {
      if (typeof tableOf === 'function') {
        const tableData = tableOf(formData);
        setHeaders(tableData.headers);
        setRows(tableData.columns);
        setSubtitles(tableData.subtitle);
      }
    }, [formData, tableOf]);

    return (
      <TableContainer component={Paper} hidden={!visible}>
        <Table>
          <StyledTableHeader>
            {label ? (
              <TableRow>
                <StyledTableTitle
                  colSpan={headers.reduce((sum, header) => sum + header.colspan, 0)}
                  key={label}
                  theme={theme}
                >
                  {label}
                </StyledTableTitle>
              </TableRow>
            ) : null}

            {subtitles.length > 0 && (
              <TableRow>
                {subtitles.map((subtitle, index) => (
                  <StyledTableSubTitle
                    key={`subtitle-${index}`}
                    colSpan={subtitle.colspan}
                    sx={subtitle?.customSx}
                    theme={theme}
                  >
                    {subtitle.texto}
                  </StyledTableSubTitle>
                ))}
              </TableRow>
            )}

            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell
                  key={index}
                  colSpan={header.colspan}
                  sx={header?.customSx}
                  theme={theme}
                >
                  {header.texto}
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={row.customSx}>
                {headers.map((header, cellIndex) => {
                  const cellData = row.value.find((cell) => cell.header === header.value);
                  return (
                    <StyledTableData key={cellIndex} sx={cellData?.customSx || {}} theme={theme}>
                      {cellData?.textValue || ''}
                    </StyledTableData>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }),
};
