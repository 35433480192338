import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';

export interface IPerguntaUiAvaliacaoSchemaOptions {
  withoutMinMaxDescritiva: boolean;
}

const perguntaAvaliacaoUiSchema = (options?: IPerguntaUiAvaliacaoSchemaOptions): IUiSchema => ({
  type: 'VerticalLayout',
  elements: [
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'LongText',
          scope: '#/properties/pergunta',
          label: 'Texto da Pergunta',
          options: {
            required: true,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/tipoResposta',
          label: 'Tipo da Resposta',
          options: {
            required: true,
          },
        },
      ],
    },
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/multiplaEscolha',
          label: 'Adicionar Alternativa',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/alternativa',
                  label: 'Texto Alternativa',
                },
              ],
            },
            showSortButtons: true,
          },
        },
      ],
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          type: 'AND',
          conditions: [
            {
              scope: '#/properties/tipoResposta',
              schema: { enum: ['MULTIPLA_ESCOLHA', null] },
            },
            {},
          ],
        } as Condition,
      } as Rule,
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/nota/properties/notaMinima',
          label: 'Nota Mínima',
          options: {
            isPositiveNumber: true,
          },
        },
        {
          type: 'Control',
          scope: '#/properties/nota/properties/notaMaxima',
          label: 'Nota Máxima',
          options: {
            isPositiveNumber: true,
          },
        },
      ],
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          type: 'AND',
          conditions: [
            {
              scope: '#/properties/tipoResposta',
              schema: { enum: ['NOTA', null] },
            },
            {},
          ],
        } as Condition,
      } as Rule,
    },
  ],
});

export default perguntaAvaliacaoUiSchema;
