import { camelCase } from 'change-case';
import { createElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import getRouteParam from './helpers/get-route-param';

const componentContext = require.context('./views', true, /\.view.tsx$/);
const componentKeys = componentContext.keys();

const routes = componentKeys
  .map((componentPath) => {
    const componentModule = componentContext(componentPath);
    if (!componentModule.disableAutoRoute) {
      const componentName = componentPath
        .split('/')
        .pop()
        ?.replace(/\.view.tsx$/, '');

      const rootViews: string[] = componentModule.rootViews || [];

      const pathPart = rootViews.reduce(
        (acc: string, rootView: string) => `${acc}/${rootView}/:${camelCase(rootView)}Id`,
        '',
      );

      const path = `${pathPart}/${componentName}/:${getRouteParam(componentName)}?`;

      return {
        path,
        element: createElement(componentModule.default),
      };
    }
  })
  .filter((entry) => entry !== undefined);

const AppRoutes = () => (
  <Routes>
    {routes.map((route, index) => (
      <Route key={index} {...route} />
    ))}
    <Route path='*' element={<Navigate to='/not-found' replace />} />
  </Routes>
);

export default AppRoutes;
