import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { EdicaoPrograma } from '../../../models/edicao-programa';

export const moduleName = 'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_PROGRAMA';

export const displayProperties = ['ano'];

export const headCells: readonly HeadCell<EdicaoPrograma>[] = [
  {
    id: 'ano',
    numeric: false,
    disablePadding: false,
    label: 'Ano',
  },
  {
    id: 'valorTotal',
    numeric: false,
    disablePadding: false,
    label: 'Soma de Recursos',
    mask: 'money',
  },
  {
    id: 'somaRecursoAlocado',
    numeric: true,
    disablePadding: false,
    label: 'Soma dos Recursos Alocados',
    mask: 'money',
  },
];
