import { useMemo } from 'react';
import { Eye } from '../../../components/styled-icons';
import { Crud } from '../../../components/crud/crud';
import { headCells } from './gerenciar-formularios.headcell';
import { FormularioEnquadramento } from '../../../models/formulario-enquadramento';
import formularioEnquadramentoUiSchema from './gerenciar-formularios.uischema';
import { makeApi } from '../../../api/generic-api';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { moduleNameFor } from '../../../helpers/module-name-for';

function FormularioEnquadramentoView() {
  const navigate = useNavigate();
  const { formularioEnquadramentoId: editalId } = useParams();
  const defaultForm = { perguntasCategorizadas: false, editalId: Number(editalId) };

  const api = useMemo(() => makeApi<FormularioEnquadramento>('/formulario-enquadramento'), []);

  const gerenciarFormularioDetalhes = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('formulario-enquadramento'),
    icon: <Eye size='24' />,
    title: 'Visualizar',
    handler: (row: any) => {
      navigate(`/gerenciar-formulario-detalhes/${row?.id}`, {
        state: { crudState: CrudStatesOptions.EDIT },
      });
    },
  };

  return (
    <Crud<FormularioEnquadramento>
      headCells={headCells}
      titleConfig={{ value: 'Gerenciar Formulários', show: true }}
      queryFilters={{ withDeleted: true, editalId: Number(editalId) }}
      apiUrl={'/gerenciar-formularios'}
      uischema={formularioEnquadramentoUiSchema}
      apiClient={api}
      defaultForm={defaultForm}
      customSave={{ show: false }}
      hideView
      customActions={[() => gerenciarFormularioDetalhes]}
    ></Crud>
  );
}

export default FormularioEnquadramentoView;

export const rootViews = ['formulario-enquadramento'];
