import { Anexo } from './anexo';
import { DocumentoPessoal } from './documento-pessoal';
import { EdicaoPrograma } from './edicao-programa';
import { EditalRubricaMoedaEstrangeira } from './edital-rubrica-moeda-estrangeira';
import { Estado } from './estado';
import { FuncaoParticipante } from './funcao-participante';
import { ModalidadeBolsa } from './modalidade-bolsa';
import { MoedaEstrangeira } from './moeda-estrangeira';
import { NaturezaDespesa } from './natureza-despesa';
import { NivelAcademico } from './nivel-academico';
import { NivelBolsa } from './nivel-bolsa';
import { Pergunta } from './pergunta';
import { Programa } from './programa';
import { TipoEvento } from './tipo-evento';
import { Usuario } from './usuario';

export interface Edital {
  id: number;
  nome: string;
  duracaoProjetoEmMeses: number;
  pesquisadorSubmeterVariasPropostas: boolean;
  restricaoParticipante: boolean;
  restricaoTipoEvento: boolean;
  restricaoConviteParticipantes: boolean;
  coordenadorPodeReceberBolsa: boolean;
  vinculoInstitucionalObrigatorio: boolean;
  vinculoEmpregaticioObrigatorio: boolean;
  possuiNivelAcademicoMinimoCoordenador: boolean;
  definirDuracaoProjetoEmMeses: boolean;
  coordenadorPodeParticiparOutroProjeto: boolean;
  obrigatorioLinkLattesCoordenador: boolean;
  obrigatorioLinkLattesMembros: boolean;
  nivelAcademicoMinimoCoordenadorId: number;
  nivelAcademicoMinimoCoordenador: NivelAcademico;
  conviteParticipantes: Usuario[];
  programaId: number;
  programa: Programa;
  chamadaEdital: ChamadaEdital[];
  editalEstado: Estado[];
  chamadaVigente: ChamadaEdital;
  editalAnexo: Anexo[];
  documentoPropostaEdital: DocumentoPropostaEdital[];
  naturezaDespesaEdital: NaturezaDespesaEdital[];
  bolsaEdital?: BolsaEdital[];
  documentoPessoalEdital?: DocumentoPessoalEdital[];
  termoDeAceite?: string;
  texto?: string;
  tipoEvento: TipoEvento[];
  funcaoParticipante: FuncaoParticipante[];
  editalRubrica: EditalRubrica[];
  faixaFinanciamento?: FaixaFinanciamentoEdital[];
  indicadorProducaoEdital?: IndicadorProducaoEdital[];
  editalPergunta: Pergunta[];
  informacaoComplementarEditalPergunta?: Pergunta[];
  deletedAt: Date;
}

export interface BolsaEdital {
  id: number;
  modalidadeBolsaId: number;
  modalidadeBolsa: ModalidadeBolsa;
  nivelBolsaId: number;
  nivelBolsa: NivelBolsa;
  possuiQuantidadeBolsaPorProposta: boolean;
  quantidadeBolsaPorProposta: number;
  editalId: number;
  edital: Edital;
}

export interface ChamadaEdital {
  id: number;
  editalId: number;
  edital: Edital;
  inicio: Date;
  termino: Date;
  chamadaSuspensa: boolean;
  deletedAt: Date;
}

export interface DocumentoPessoalEdital {
  id?: number;
  obrigatorio?: boolean;
  editalId: number;
  edital: Edital;
  documentoPessoalId: number;
  documentoPessoal: DocumentoPessoal;
}

export interface DocumentoPropostaEdital {
  id: number;
  editalId: number;
  edital: Edital;
  nome: string;
  descricao: string;
  formatoArquivo: string;
  tamanhoArquivo: number;
  arquivoSubmissaoObrigatoria: boolean;
}

export enum TipoEditalRubrica {
  diarias = 'DIARIAS',
  hospedagemAlimentacao = 'HOSPEDAGEM_ALIMENTACAO',
  servicosDeTerceiros = 'SERVICOS_DE_TERCEIROS',
  materialDeConsumo = 'MATERIAL_DE_CONSUMO',
  materialPermanenteEquipamentos = 'MATERIAL_PERMANENTE_EQUIPAMENTOS',
  passagens = 'PASSAGENS',
  pessoal = 'PESSOAL',
  encargos = 'ENCARGOS',
  bolsa = 'BOLSA',
}
export interface EditalRubrica {
  id: number;
  tipoEditalRubrica: TipoEditalRubrica;
  naturezaDespesaId?: number;
  naturezaDespesa: NaturezaDespesa;
  editalId?: number;
  edital: Edital;
  temJustificativaObrigatoria?: boolean;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeira: MoedaEstrangeira[];
  editalRubricaMoedaEstrangeira: EditalRubricaMoedaEstrangeira[];
}

export interface FaixaFinanciamentoEdital {
  id?: number;
  editalId?: number;
  nome: string;
  valorMinimo: number;
  valorMaximo: number;
  observacao?: string;
  edital: Edital;
}

export interface IndicadorProducao {
  id: number;
  nome: string;
  nacionalInternacional: boolean;
  producao: Producao[];
  deletedAt: Date;
}

export interface IndicadorProducaoEdital {
  id?: number;
  editalId?: number;
  indicadorProducaoId?: number;
  indicadorProducao?: IndicadorProducao;
  edital?: Edital;
}

export interface NaturezaDespesaEdital {
  id?: number;
  valor: number;
  editalId: number;
  edital: Edital;
  naturezaDespesaId: number;
  naturezaDespesa: NaturezaDespesa;
  edicaoProgramaId?: number;
  edicaoPrograma: EdicaoPrograma;
}

export interface Producao {
  id: number;
  texto: string;
  indicadorProducaoId: number;
  indicadorProducao: IndicadorProducao;
  deletedAt: Date;
}
