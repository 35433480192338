export const formatFloat = (
  value: number | string,
  locale: string = 'pt-BR',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2,
): string => {
  const intNumber = typeof value === 'string' ? parseInt(value, 10) : value;
  const float = intNumber / 100;

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(float);
};
