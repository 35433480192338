import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { makeApi } from '../../../../api/generic-api';
import { useCrudContext } from '../../../../components/crud/crud-context';
import JsonForm from '../../../../components/crud/json-form';
import { CrudStatesOptions } from '../../../../jsonforms/uischema';
import { FormularioEnquadramento } from '../../../../models/formulario-enquadramento';
import {
  buildFormularioUiSchema,
  buildSchemaFormularioEnquadramento,
} from '../../../../utils/gerenciar-formulario.utils';

const PerguntaAvaliacaoDetalhes = () => {
  const { gerenciarFormularioDetalhesId } = useParams();
  const [isCategorized, setIsCategorized] = useState(false);
  const [formularioEnquadramento, setFormularioEnquadramento] =
    useState<FormularioEnquadramento>(null);
  const formularioEnquadramentoId = Number(gerenciarFormularioDetalhesId ?? null) ?? null;

  const { updateCrudStates } = useCrudContext();
  const location = useLocation();

  useEffect(() => {
    updateCrudStates(location.state?.crudState ?? CrudStatesOptions.VIEW);
    makeApi('/formulario-enquadramento')
      .get(formularioEnquadramentoId)
      .then((x: FormularioEnquadramento) => {
        setFormularioEnquadramento(x);
        setIsCategorized(x?.formularioEnquadramentoCategorizado?.length > 0);
      });
  }, []);

  const uischema = buildFormularioUiSchema(
    isCategorized,
    formularioEnquadramento?.formularioEnquadramentoCategorizado,
  );
  const schema = buildSchemaFormularioEnquadramento(formularioEnquadramento, isCategorized);

  return <JsonForm uischema={uischema} schema={schema} data={{}} />;
};

export default PerguntaAvaliacaoDetalhes;
