import { Crud } from '../../components/crud/crud';
import { Instituicao as InstituicaoModel } from '../../models/instituicao';
import { headCells } from './instituicao.headcell';
import instituicaoUiSchema from './instituicao.uischema';

function Instituicao() {
  const defaultForm = { 'paisId-codigo': 'brasil', endereco: {} };

  return (
    <>
      <Crud<InstituicaoModel>
        titleConfig={{ value: 'Instituição', show: true }}
        headCells={headCells}
        apiUrl={'/instituicao'}
        defaultForm={defaultForm}
        uischema={instituicaoUiSchema}
        queryFilters={{ withDeleted: true }}
      ></Crud>
    </>
  );
}

export default Instituicao;
