import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { NivelAcademico } from '../../../models/nivel-academico';

export const displayProperties = ['tipo'];

export const moduleLabel = 'Níveis Acadêmicos';

export const moduleName = 'CONFIGURACOES_INICIAIS.NIVEL_ACADEMICO';

export const rowsLength = 12;

export const headCells: readonly HeadCell<NivelAcademico>[] = [
  {
    id: 'tipo',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'prioridade',
    numeric: true,
    disablePadding: false,
    label: 'Prioridade',
  },
];
