export const validateCPF = (field?: string) => ({
  field: field,
  hasError: (value: any) => !validatorCPF(value),
  error: {
    keyword: 'validateCPF',
    message: 'CPF inválido.',
    propertyName: field,
    instancePath: `/${field?.replace('.', '/') || field}`,
    dataPath: `/${field}`,
    params: {},
  },
});

const validatorCPF = (cpf: string): boolean => {
  if (cpf) {
    let sum = 0;
    let rest: number;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.match(/^(\d)\1{10}$/)) return false;
    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;
    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(9, 10))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(10, 11))) return false;

    return true;
  }
  return false;
};

export const validators = {
  validateCPF,
};
