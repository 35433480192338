import {
  bootstrap,
  boxiconsRegular,
  boxiconsSolid,
  entypo,
  evaiconsOutline,
  evaiconsSolid,
  evil,
  fluentuiSystemFilled,
  fluentuiSystemRegular,
  foundation,
  heroiconsSolid,
  icomoon,
  ioniconsOutline,
  ioniconsSharp,
  material,
  materialOutlined,
  remixLine,
  simpleIcons,
  typicons,
} from 'styled-icons';

export const {
  CloudUpload,
  FiletypeDoc,
  FiletypeJpg,
  FiletypePdf,
  FiletypeXls,
  Gear,
  BriefcaseFill,
  BagPlusFill,
  Calendar2Week,
  ListCheck,
  Receipt,
  Trash3,
  MegaphoneFill,
  QuestionCircleFill,
} = bootstrap;
export const { Money, Calendar } = boxiconsRegular;
export const { Report, User, CheckSquare, UserDetail, PlaneTakeOff, DownArrow } = boxiconsSolid;
export const { ClipboardPencil, Paperclip } = foundation;
export const { MoneyCalculator, Home, BuildingMultiple, Person, PeopleCommunityAdd, Add } =
  fluentuiSystemFilled;
export const { BuildingBankToolbox, MoneyHand, AttachText, Production } = fluentuiSystemRegular;
export const { Business } = materialOutlined;
export const { Attachment } = typicons;
export const { Star, Info, Settings } = evaiconsSolid;
export const { Profile } = remixLine;
export const { Blocked } = icomoon;
export const { AcademicCap } = heroiconsSolid;
export const { DocumentText, Close } = ioniconsOutline;
export const { DocumentAttach } = ioniconsSharp;
export const { AddLocationAlt, StarRate } = material;
export const { Justeat } = simpleIcons;
export const { Eye } = entypo;
export const { Search } = evil;
export const { EyeOffOutline, EyeOutline } = evaiconsOutline;
