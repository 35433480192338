export enum CodigoPais {
  brasil = 'BRA',
  colombia = 'COL',
  uruguai = 'URY',
  estadosUnidos = 'USA',
  canada = 'CAN',
  mexico = 'MEX',
  russia = 'RUS',
  italia = 'ITA',
  paisesBaixos = 'NLD',
  china = 'CHN',
  espanha = 'ESP',
}
export enum PaisGroups {
  nativo = 'NATIVO',
  estrangeiro = 'ESTRANGEIRO',
}
export interface Pais {
  id: number;
  codigo: CodigoPais;
  nomePt: string;
  nomeEn: string;
  sigla: string;
  continente: string;
  codigoTelefone: number;
}
