import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const EditarPerfilHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  top: 45px;
  background-color: #fafafa;
`;

export const UsuarioInfo = styled(Box)`
  font-family: 'Manrope';
  margin-left: 0.5rem;
`;

export const UsuarioInfoNome = styled(Box)`
  font-weight: 700;
  font-size: 1.5rem;
`;

export const UsuarioInfoPais = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 1rem;

  img {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
