import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormApi, makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';
import { useCrudContext } from '../../../../components/crud/crud-context';
import { whiteSpaces } from '../../../../helpers/custom-errors';
import { HistoricoAjusteEdicaoRecursoLinhaDeAcao } from '../../../../models/historico-ajuste-edicao-recurso-linha-de-acao';
import { Data, headCells } from './historico-ajuste-edicao-recurso-linha-de-acao.headcell';
import historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema from './historico-ajuste-edicao-recurso-linha-de-acao.uischema';

export interface IEdicaoRecursoLinhaDeAcao {
  id: number;
  edicaoRecursoLinhaDeAcaoId: number;
  valor: number;
  deletedAt?: Date;
}

function HistoricoAjusteEdicaoRecursoLinhaDeAcaoView() {
  const [edicaoRecursoLinhaDeAcao, setEdicaoRecursoLinhaDeAcao] = useState<
    Partial<IEdicaoRecursoLinhaDeAcao>
  >({});
  const [apiClient, setApiClient] = useState<FormApi<HistoricoAjusteEdicaoRecursoLinhaDeAcao>>();
  const { edicaoRecursoLinhaDeAcaoId } = useParams();

  const customErrors = [whiteSpaces('justificativa')];
  const edicaoRecursoLinhaDeAcaoIdNumber = Number(edicaoRecursoLinhaDeAcaoId);
  const defaultForm = {
    edicaoRecursoLinhaDeAcaoId: edicaoRecursoLinhaDeAcaoIdNumber,
    valorPrevisto: edicaoRecursoLinhaDeAcao?.valor,
    edicaoRecursoLinhaDeAcao,
  };

  useEffect(() => {
    const api = makeApi<HistoricoAjusteEdicaoRecursoLinhaDeAcao>(
      '/historico-ajuste-edicao-recurso-linha-de-acao',
    );
    const get = api.get.bind(api);
    api.get = async (id: number) => {
      const response = await get(id);
      response.novoValor = response.valorFinal;
      return response;
    };
    setApiClient(api);
  }, []);

  useEffect(() => {
    makeApi(`/edicao-recurso-linha-de-acao`)
      .get(edicaoRecursoLinhaDeAcaoIdNumber)
      .then((data) => setEdicaoRecursoLinhaDeAcao(data));
  }, []);

  return (
    <Crud<Data>
      headCells={headCells}
      queryFilters={{
        withDeleted: true,
        edicaoRecursoLinhaDeAcaoId: edicaoRecursoLinhaDeAcaoIdNumber,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      uischema={historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema}
      titleConfig={{ value: 'Ajuste de Recursos da Linha de Ação', show: true }}
      apiUrl={'/historico-ajuste-edicao-recurso-linha-de-acao'}
      apiClient={apiClient}
      defaultForm={defaultForm}
      customErrors={customErrors}
      hideCreate={!!edicaoRecursoLinhaDeAcao.deletedAt}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export default HistoricoAjusteEdicaoRecursoLinhaDeAcaoView;

export const rootViews = ['edicao-recurso-linha-de-acao'];
