import { IUiSchema } from '../../../jsonforms/uischema';

const avisoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/titulo',
      label: 'Título',
      options: {
        required: true,
      },
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/dataInicio',
          label: 'Data e Hora de Início',
          options: {
            format: 'date-time',
            dateTimeFormat: 'DD-MM-YY HH:mm:ss',
            dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
            ampm: false,
            required: true,
          },
        },
        {
          type: 'Control',
          scope: '#/properties/dataEncerramento',
          label: 'Data e Hora de Encerramento',
          options: {
            format: 'date-time',
            dateTimeFormat: 'DD-MM-YY HH:mm:ss',
            dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
            ampm: false,
          },
        },
      ],
    },
    {
      type: 'CkEditor',
      scope: '#/properties/aviso',
      label: 'Mensagem de aviso',
      options: {
        required: true,
      },
    },
  ],
};

export default avisoUiSchema;
