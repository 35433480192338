const updateNovoValor = (
  tipo: string,
  valorAtual: number,
  valorAjuste: number,
  handler: (path: string, value: any) => void,
) => {
  let novoValor = 0;

  if (tipo === 'CORTE') {
    novoValor = valorAtual - (valorAjuste ?? 0);
  } else if (tipo === 'ADITIVO') {
    novoValor = valorAtual + (valorAjuste ?? 0);
  } else {
    novoValor = valorAtual;
  }

  handler('novoValor', novoValor);
};

const historicoAjusteTesouroUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/tesouro/properties/valorAtual',
          label: 'Valor Atual',
          options: { disabled: true },
        },
        {
          type: 'Select',
          scope: '#/properties/tipo',
          label: 'Tipo',
          options: {
            required: true,
            handleChange: (
              tipo: any,
              handler: (path: string, value: any) => void,
              formData: any,
            ) => {
              updateNovoValor(tipo, formData.tesouro.valorAtual, formData.valorAjuste, handler);
            },
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorAjuste',
          label: 'Valor do Ajuste',
          options: {
            required: true,
            handleChange: (
              valueAjuste: number,
              handler: (path: string, value: any) => void,
              formData: any,
            ) => {
              if (!valueAjuste) return;
              updateNovoValor(formData.tipo, formData.tesouro.valorAtual, valueAjuste, handler);
            },
          },
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/novoValor',
          label: 'Novo Valor',
          options: { disabled: true },
        },
      ],
    },

    {
      type: 'LongText',
      scope: '#/properties/justificativa',
      label: 'Justificativa',
    },
  ],
};

export default historicoAjusteTesouroUiSchema;
