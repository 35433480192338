import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { MouseEvent, useState } from 'react';
import { ActionMenuItem, ActionTitle, StyledMenu } from '../style/actions-table-cell.styled';
import { Action, MAX_ACTIONS } from './protocols/actions';

interface ActionsMenuProps {
  actions: Action[];
  row: any;
}

const ActionsMenu = (props: ActionsMenuProps) => {
  const { actions, row } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeHandler = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={clickHandler}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={closeHandler}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {actions.slice(MAX_ACTIONS - 1).map((action, index) => {
          const { icon, title, handler } = action;

          return (
            <ActionMenuItem
              key={index}
              onClick={() => {
                handler?.(row);
                closeHandler();
              }}
            >
              <Box component='span' mr={1}>
                {icon}
              </Box>
              <ActionTitle>{title}</ActionTitle>
            </ActionMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default ActionsMenu;
