import { formatCurrency } from '../../../helpers/format-currency';
import { insertDecimals } from '../../../helpers/insert-decimals';
import { RowsModel, CellModel } from '../../../jsonforms/uischema';
import { BolsaProposta } from '../../../models/bolsa-proposta';
import { DiariaProposta } from '../../../models/diaria-proposta';
import { TipoEditalRubrica } from '../../../models/edital';
import { tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { PessoalProposta } from '../../../models/pessoal-proposta';
import {
  MaterialConsumoProposta,
  MaterialPermanenteProposta,
  PassagemProposta,
  HospedagemAlimentacaoProposta,
  ServicoTerceiroProposta,
  EncargoProposta,
  Proposta,
  TipoPessoa,
} from '../../../models/proposta';
import { getCotacoes } from './calcular-rubricas';

type CalculoRubricaResult = {
  totaisPorAno: { [ano: number]: { [trimestre: number]: number } };
  totaisPorAnoTotal: { [ano: number]: number };
};

const calcularTotais = <T>(
  items: T[],
  getTotal: (item: T) => {
    total: number;
    emMoedaEstrangeira: boolean;
    mesPrevisto: number;
    moedaId?: number;
  },
  cotacoes: Record<number, number>,
  duracaoProjetoEmMeses: number,
): CalculoRubricaResult => {
  return items.reduce<CalculoRubricaResult>(
    (acc, item) => {
      const { total, emMoedaEstrangeira, mesPrevisto, moedaId } = getTotal(item);

      const cotacao =
        emMoedaEstrangeira && moedaId ? insertDecimals(cotacoes[moedaId] ?? 0) || 1 : 1;
      const valorEmReais = total * cotacao;

      const ano = Math.floor((mesPrevisto - 1) / 12) + 1;
      let trimestre: number;

      if (mesPrevisto <= duracaoProjetoEmMeses) {
        trimestre = Math.floor(((mesPrevisto - 1) % 12) / 3) + 1;
      } else {
        trimestre = 1;
      }

      if (!acc.totaisPorAno[ano]) {
        acc.totaisPorAno[ano] = { 1: 0, 2: 0, 3: 0, 4: 0 };
        acc.totaisPorAnoTotal[ano] = 0;
      }

      acc.totaisPorAno[ano][trimestre] += valorEmReais;
      acc.totaisPorAnoTotal[ano] += valorEmReais;

      return acc;
    },
    {
      totaisPorAno: {},
      totaisPorAnoTotal: {},
    },
  );
};

const calcularDiariaTotal = (diaria: DiariaProposta) => {
  const total = diaria.contrapartida
    ? 0
    : insertDecimals((diaria.numeroDiaria ?? 0) * (diaria.custoUnitario ?? 0));
  return {
    total,
    emMoedaEstrangeira: diaria.emMoedaEstrangeira || false,
    mesPrevisto: diaria.mesPrevisto,
    moedaId: diaria.emMoedaEstrangeira ? diaria.moedaEstrangeiraId : undefined,
  };
};

const calcularMaterialConsumoTotal = (material: MaterialConsumoProposta) => {
  const total = material.contrapartida
    ? 0
    : insertDecimals((material.quantidade ?? 0) * (material.custoUnitario ?? 0));
  return {
    total,
    emMoedaEstrangeira: material.emMoedaEstrangeira,
    mesPrevisto: material.mesPrevisto,
    moedaId: material.emMoedaEstrangeira ? material.moedaEstrangeiraId : undefined,
  };
};

const calcularMaterialPermanenteTotal = (material: MaterialPermanenteProposta) => {
  const total = material.contrapartida
    ? 0
    : (material.quantidade ?? 0) * (material.custoUnitario ?? 0);
  return {
    total,
    emMoedaEstrangeira: material.emMoedaEstrangeira,
    mesPrevisto: material.mesPrevisto,
    moedaId: material.emMoedaEstrangeira ? material.moedaEstrangeiraId : undefined,
  };
};

const calcularPassagemTotal = (passagem: PassagemProposta) => {
  const total = passagem.contrapartida
    ? 0
    : (passagem.quantidade ?? 0) * (passagem.custoUnitario ?? 0);
  return {
    total,
    emMoedaEstrangeira: passagem.emMoedaEstrangeira,
    mesPrevisto: passagem.mesPrevisto,
    moedaId: passagem.emMoedaEstrangeira ? passagem.moedaEstrangeiraId : undefined,
  };
};

const calcularHospedagemTotal = (hospedagem: HospedagemAlimentacaoProposta) => {
  const total = hospedagem.contrapartida
    ? 0
    : (hospedagem.quantidade ?? 0) * (hospedagem.custoUnitario ?? 0);
  return {
    total,
    emMoedaEstrangeira: hospedagem.emMoedaEstrangeira,
    mesPrevisto: hospedagem.mesPrevisto,
    moedaId: hospedagem.emMoedaEstrangeira ? hospedagem.moedaEstrangeiraId : undefined,
  };
};

const calcularServicoTerceiroTotal = (servico: ServicoTerceiroProposta) => {
  const total = servico.contrapartida ? 0 : (servico.custoUnitario ?? 0);
  return {
    total,
    emMoedaEstrangeira: servico.emMoedaEstrangeira,
    mesPrevisto: servico.mesPrevisto,
    moedaId: servico.emMoedaEstrangeira ? servico.moedaEstrangeiraId : undefined,
  };
};

const calcularEncargoTotal = (encargo: EncargoProposta) => {
  const total = encargo.contrapartida ? 0 : (encargo.custoUnitario ?? 0);
  return {
    total,
    emMoedaEstrangeira: encargo.emMoedaEstrangeira,
    mesPrevisto: encargo.mesPrevisto,
    moedaId: encargo.emMoedaEstrangeira ? encargo.moedaEstrangeiraId : undefined,
  };
};

const calcularPessoal = (pessoal: PessoalProposta) => {
  const total = pessoal.contrapartida ? 0 : (pessoal.valor ?? 0);
  return {
    total,
    emMoedaEstrangeira: pessoal.emMoedaEstrangeira,
    mesPrevisto: pessoal.mesInicio,
    moedaId: pessoal.emMoedaEstrangeira ? pessoal.moedaEstrangeiraId : undefined,
  };
};

const calcularBolsas = (bolsa: BolsaProposta) => {
  const total = bolsa.contrapartida ? 0 : (bolsa.valorTotal ?? 0);
  return {
    total,
    emMoedaEstrangeira: false,
    mesPrevisto: 1,
    moedaId: undefined,
  };
};

const calcularServicoTerceiroPorTipoPessoa = (
  servicos: ServicoTerceiroProposta[],
  tipoPessoa: TipoPessoa,
  cotacoes: Record<number, number>,
  duracaoProjetoEmMeses: number,
) => {
  if (servicos) {
    const servicosFiltrados = servicos.filter((servico) => servico.tipo === tipoPessoa);
    const resultado = calcularTotais(
      servicosFiltrados,
      calcularServicoTerceiroTotal,
      cotacoes,
      duracaoProjetoEmMeses,
    );
    return resultado;
  }
};

const calcularRubricasTrimestral = (proposta: Proposta) => {
  let columns: { rubrica: string; calculo: CalculoRubricaResult | null }[] = [];
  const cotacoes = getCotacoes(proposta);

  const calcularSeExisteRubrica = (
    tipoRubrica: TipoEditalRubrica,
    rubricaLabel: string,
    lista: any[],
    calcularTotalFn: (item: any) => any,
    cotacoes: Record<number, number>,
  ) => {
    if (
      proposta?.edital?.editalRubrica?.some((rubrica) => rubrica.tipoEditalRubrica === tipoRubrica)
    ) {
      const calculo =
        lista?.length > 0
          ? calcularTotais(lista, calcularTotalFn, cotacoes, proposta.duracao)
          : {
              totaisPorAno: {},
              totaisPorAnoTotal: {},
            };

      for (let ano = 1; ano <= Math.ceil(proposta.duracao / 12); ano++) {
        if (!calculo.totaisPorAno[ano]) {
          calculo.totaisPorAno[ano] = { 1: 0, 2: 0, 3: 0, 4: 0 };
          calculo.totaisPorAnoTotal[ano] = 0;
        }
      }

      columns.push({
        rubrica: rubricaLabel,
        calculo,
      });

      if (tipoRubrica === TipoEditalRubrica.servicosDeTerceiros) {
        const totalPessoaJuridica = calcularServicoTerceiroPorTipoPessoa(
          proposta.servicoTerceiroProposta,
          TipoPessoa.pessoaJuridica,
          cotacoes,
          proposta.duracao,
        );
        const totalPessoaFisica = calcularServicoTerceiroPorTipoPessoa(
          proposta.servicoTerceiroProposta,
          TipoPessoa.pessoaFisica,
          cotacoes,
          proposta.duracao,
        );
        columns.push({
          rubrica: `- Pessoa Física`,
          calculo: totalPessoaFisica || { totaisPorAno: {}, totaisPorAnoTotal: {} },
        });

        columns.push({
          rubrica: `- Pessoa Jurídica`,
          calculo: totalPessoaJuridica || { totaisPorAno: {}, totaisPorAnoTotal: {} },
        });
      }
    }
  };

  calcularSeExisteRubrica(
    TipoEditalRubrica.diarias,
    tipoEditalRubricaI18N.diarias,
    proposta.diariaProposta,
    calcularDiariaTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.materialDeConsumo,
    tipoEditalRubricaI18N.materialDeConsumo,
    proposta.materialConsumoProposta,
    calcularMaterialConsumoTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.materialPermanenteEquipamentos,
    tipoEditalRubricaI18N.materialPermanenteEquipamentos,
    proposta.materialPermanenteProposta,
    calcularMaterialPermanenteTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.passagens,
    tipoEditalRubricaI18N.passagens,
    proposta.passagemProposta,
    calcularPassagemTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.hospedagemAlimentacao,
    tipoEditalRubricaI18N.hospedagemAlimentacao,
    proposta.hospedagemAlimentacaoProposta,
    calcularHospedagemTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.encargos,
    tipoEditalRubricaI18N.encargos,
    proposta.encargoProposta,
    calcularEncargoTotal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.pessoal,
    tipoEditalRubricaI18N.pessoal,
    proposta.pessoalProposta,
    calcularPessoal,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.bolsa,
    tipoEditalRubricaI18N.bolsa,
    proposta.bolsaProposta,
    calcularBolsas,
    cotacoes,
  );
  calcularSeExisteRubrica(
    TipoEditalRubrica.servicosDeTerceiros,
    tipoEditalRubricaI18N.servicosDeTerceiros,
    proposta.servicoTerceiroProposta,
    calcularServicoTerceiroTotal,
    cotacoes,
  );

  return columns;
};

export const getRubricasTrimestralTable = (proposta: Proposta) => {
  const rubricasTrimestrais = calcularRubricasTrimestral(proposta);
  const tables = [];
  const trimestresPorAno = calcularQuantidadeDeTrimestresPorAno(proposta.duracao);

  Object.keys(trimestresPorAno).forEach((anoStr) => {
    const ano = Number(anoStr);
    const quantidadeDeTrimestres = trimestresPorAno[ano];
    const rows: RowsModel[] = [];
    let totalPorAno: Record<number, Record<number, number>> = {};

    rubricasTrimestrais.forEach((rubricaData) => {
      const totaisPorAnoRubrica = rubricaData.calculo?.totaisPorAno[ano];

      if (
        rubricaData.rubrica !== '- Pessoa Jurídica' &&
        rubricaData.rubrica !== '- Pessoa Física'
      ) {
        Object.entries(totaisPorAnoRubrica ?? {}).forEach(([trimestreStr, valor]) => {
          const trimestre = Number(trimestreStr);

          if (!totalPorAno[ano]) {
            totalPorAno[ano] = {};
          }

          totalPorAno[ano][trimestre] = (totalPorAno[ano][trimestre] || 0) + (valor || 0);
        });
      }
      const row: CellModel[] = [
        {
          header: 'nome',
          textValue: rubricaData.rubrica,
          customSx: { fontWeight: 700 },
        },
        ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map((trimestre) => {
          return {
            header: `trimestre${trimestre}`,
            textValue: formatCurrency(
              totaisPorAnoRubrica ? (totaisPorAnoRubrica[trimestre] ?? 0) : 0,
            ),
            customSx: { textAlign: 'center' },
          };
        }),
        {
          header: 'total',
          textValue: formatCurrency(
            Object.values(totaisPorAnoRubrica ?? {}).reduce((a, b) => a + b, 0) || 0,
          ),
          customSx: { textAlign: 'center', fontWeight: 700 },
        },
      ];

      rows.push({ value: row });
    });

    const totalPorTrimestre = { 1: 0, 2: 0, 3: 0, 4: 0 };
    let totalGeralAno = 0;

    Object.entries(totalPorAno[ano] || {}).forEach(([trimestreStr, valor]) => {
      const trimestre = Number(trimestreStr);
      totalPorTrimestre[trimestre] += valor || 0;
      totalGeralAno += valor || 0;
    });

    const totalRow: CellModel[] = [
      {
        header: 'nome',
        textValue: 'Valor Total',
        customSx: { textAlign: 'center', fontWeight: 700 },
      },
      ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map((trimestre) => {
        return {
          header: `trimestre${trimestre}`,
          textValue: formatCurrency(totalPorTrimestre[trimestre] || 0),
          customSx: { textAlign: 'center', fontWeight: 700 },
        };
      }),
      {
        header: 'total',
        textValue: formatCurrency(totalGeralAno || 0),
        customSx: { textAlign: 'center', fontWeight: 700 },
      },
    ];

    rows.push({ value: totalRow });

    tables.push({
      title: `Ano ${ano}`,
      subtitle: [
        { texto: 'Rubrica', colspan: 1, customSx: { textAlign: 'center' } },
        {
          texto: quantidadeDeTrimestres > 1 ? 'Trimestres' : 'Trimestre',
          colspan: quantidadeDeTrimestres,
          customSx: { textAlign: 'center' },
        },
        { texto: 'Total', colspan: 1, customSx: { textAlign: 'center' } },
      ],
      headers: [
        {
          value: 'nome',
          texto: 'Nome',
          colspan: 1,
          customSx: { textAlign: 'center' },
        },
        ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map((trimestre) => ({
          value: `trimestre${trimestre}`,
          texto: trimestre,
          colspan: 1,
          customSx: { textAlign: 'center' },
        })),
        { value: 'total', texto: 'R$', colspan: 1, customSx: { textAlign: 'center' } },
      ],
      rows,
    });
  });

  return tables;
};

const calcularQuantidadeDeTrimestresPorAno = (duracaoEmMeses: number): Record<number, number> => {
  if (duracaoEmMeses <= 0) {
    return {};
  }
  const trimestresPorAno: Record<number, number> = {};
  let mesesRestantes = duracaoEmMeses;
  let anoAtual = 1;
  while (mesesRestantes > 0) {
    const mesesNoAno = Math.min(12, mesesRestantes);
    const trimestres = Math.ceil(mesesNoAno / 3);
    trimestresPorAno[anoAtual] = trimestres;
    mesesRestantes -= 12;
    anoAtual++;
  }
  return trimestresPorAno;
};
