import { Gear } from '../../../components/styled-icons';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { NivelBolsa as NivelBolsaModel } from '../../../models/nivel-bolsa';
import { headCells } from './nivel-bolsa.headcell';
import nivelBolsaUiSchema from './nivel-bolsa.uischema';
import { onlyNumbers, positiveNumber, whiteSpaces } from '../../../helpers/custom-errors';

function NivelBolsa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters, crudStates, disabledFields, setDisabledFields } =
    useCrudContext();
  const { modalidadeBolsaId } = useParams();
  const modalidadeBolsaIdNumber = Number(modalidadeBolsaId);
  const modalidadeBolsa = parameterFilters?.['modalidadeBolsa'];

  const defaultForm = {
    modalidadeBolsaId: modalidadeBolsaIdNumber,
    modalidadeBolsa: modalidadeBolsa,
  };

  const customErrors = [
    onlyNumbers('cargaHoraria'),
    positiveNumber('cargaHoraria'),
    whiteSpaces('cargaHoraria'),
  ];

  React.useEffect(() => {
    if (crudStates.edit) {
      setDisabledFields([...disabledFields, 'valorAtualBolsa']);
    }
  }, [crudStates.edit]);

  const historicoAjusteValorBolsa = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('historico-ajuste-valor-bolsa'),
    icon: <Gear size='24' color='#009900' />,
    title: 'Histórico de Ajuste de Valor de Bolsa',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        nivelBolsa: row,
      });
      navigate(
        `/modalidade-bolsa/${modalidadeBolsaId}/nivel-bolsa/${row?.id}/historico-ajuste-valor-bolsa`,
      );
    },
  };

  const showHistoricoAjusteValorBolsa = (row: any) => {
    if (row.deletedAt === null) return historicoAjusteValorBolsa;
  };

  return (
    <Crud<NivelBolsaModel>
      titleConfig={{ value: 'Nível de Bolsa', show: true }}
      apiUrl={'/nivel-bolsa'}
      headCells={headCells}
      customErrors={customErrors}
      uischema={nivelBolsaUiSchema}
      queryFilters={{ modalidadeBolsaId: modalidadeBolsaIdNumber, orderBy: 'sigla' }}
      customActions={[showHistoricoAjusteValorBolsa]}
      defaultForm={defaultForm}
    ></Crud>
  );
}

export default NivelBolsa;

export const rootViews = ['modalidade-bolsa'];
