import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FluentDocumentFolder24Filled } from '../../../../components/validity-checker/fluent-document-folder-24-filled';

export const ComponentBoxProposta = styled(Box)({
  minWidth: '536px',
  fontFamily: 'Raleway',
  borderRadius: '4px',
  border: 'solid 1px #C5C6D0',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const PropostaIcon = styled(FluentDocumentFolder24Filled)({
  width: '18px',
  height: '18px',
  color: '#003D92',
  marginLeft: '16px',
});

export const PropostaHeaderLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
});

export const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '16px',
  borderTop: 'solid 1px #C5C6D0',
});

export const FooterButton = styled(Typography)({
  fontSize: '12px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
});

export const PropostaEmpty = styled(Typography)({
  marginLeft: '20px',
});

export const TodasPropostasAnchor = styled('a')({
  cursor: 'pointer',
});
