export const CronogramaDesembolsoCustomTableFooter = (props: any) => {
  const { listData, instituicaoInstrumentoJuridico } = props;
  const calculoSomaTotal = (listaCronogramasDesembolsos: any): number => {
    let total: number = 0;
    listaCronogramasDesembolsos.forEach((cd: any) => {
      total += parseFloat(cd.valor);
    });
    return total;
  };

  const formatReal = (input: number) => {
    const realValue = input / 100;
    return realValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      <div
        style={{
          marginTop: '20px',
          textAlign: 'center',
          fontWeight: 'bold',
          margin: '0 auto',
          width: 'fit-content',
        }}
      >
        Total: R${formatReal(calculoSomaTotal(listData))}
        {instituicaoInstrumentoJuridico &&
          instituicaoInstrumentoJuridico.valorFirmado !== undefined && (
            <p>Valor firmado: R${formatReal(instituicaoInstrumentoJuridico.valorFirmado)}</p>
          )}
      </div>
    </>
  );
};
