export const formatCurrency = (
  value: number | string,
  currency: string = 'BRL',
  locale: string = 'pt-BR',
): string => {
  const cents = typeof value === 'string' ? parseInt(value, 10) : value;
  const reais = cents / 100;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(reais);
};
