import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { EdicaoRecursoLinhaDeAcao } from '../../../models/edicao-recurso-linha-de-acao';
import {
  TipoRecursoLinhaDeAcao,
  tipoRecursoLinhaDeAcaoI18N,
} from '../../../models/tipo-recurso-linha-de-acao';

export interface Data extends Record<string, any> {
  valor: number;
  edicaoLinhaDeAcaoId: number;
  instrumentoJuridicoId: number;
  tesouroId: number;
}

export const moduleName =
  'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'edicaoLinhaDeAcaoId',
    numeric: false,
    disablePadding: false,
    label: 'Edição da Linha de Ação',
    resource: {
      name: 'edicaoLinhaDeAcao',
      target: 'ano',
    },
  },
  {
    id: 'tipoRecursoLinhaDeAcao',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de Recurso',
    resource: {
      target: (row: EdicaoRecursoLinhaDeAcao): string => {
        if (row?.instrumentoJuridicoId) {
          return tipoRecursoLinhaDeAcaoI18N[TipoRecursoLinhaDeAcao.instrumentoJuridico];
        } else if (row?.tesouroId) {
          return tipoRecursoLinhaDeAcaoI18N[TipoRecursoLinhaDeAcao.tesouro];
        }
      },
    },
  },
  {
    id: 'fonte',
    numeric: false,
    disablePadding: false,
    label: 'Fonte',
    resource: {
      target: (data) => data.tesouro?.ano ?? data.instrumentoJuridico?.nome,
    },
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
];
