import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';

export class AreaDeConhecimentoApi extends GenericApi<AreaDeConhecimento> {
  async buscarGrandesAreas(): Promise<AreaDeConhecimento[]> {
    const response = await client.get<AreaDeConhecimento[]>(`${this.url}/grandearea`);
    return response.data;
  }

  async buscarAreaPorGrandeAreaId(id: number): Promise<AreaDeConhecimento> {
    const response = await client.get<AreaDeConhecimento>(`${this.url}/area/${id}`);
    return response.data;
  }

  async buscarSubAreaPorAreaId(id: number): Promise<AreaDeConhecimento> {
    const response = await client.get<AreaDeConhecimento>(`${this.url}/subarea/${id}`);
    return response.data;
  }

  async buscarEspecialidadePorSubAreaId(id: number): Promise<AreaDeConhecimento> {
    const response = await client.get<AreaDeConhecimento>(`${this.url}/especialidade/${id}`);
    return response.data;
  }
}

export const makeAreaDeConhecimentoApi = () => {
  return new AreaDeConhecimentoApi('area-de-conhecimento');
};
