import { HeadCell } from '../../components/crud/protocols/head-cell';
import { Usuario } from '../../models/usuario';

export const moduleName = 'PESSOA.USUARIO.USUARIO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Usuário';

export const headCells: readonly HeadCell<Usuario>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'nomeSocial',
    numeric: false,
    disablePadding: false,
    label: 'Nome Social',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
];
