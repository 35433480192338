import { HeadCell } from '../../components/crud/protocols/head-cell';
import { Pais } from '../../models/pais';

export const displayProperties = ['nomePt'];

export const moduleLabel = 'País';

export const headCells: readonly HeadCell<Pais>[] = [
  {
    id: 'nomePt',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
];
