import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Banco } from '../../../models/banco';

export const moduleName = 'CONFIGURACOES_INICIAIS.BANCO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Banco';

export const headCells: readonly HeadCell<Banco>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
