import { Crud } from '../../../components/crud/crud';
import { headCells } from './gerenciar-formularios.headcell';
import { FormularioEnquadramento } from '../../../models/formulario-enquadramento';
import formularioEnquadramentoUiSchema from './gerenciar-formularios.uischema';
import { customRequired } from '../../../helpers/custom-errors';
import { makeApi } from '../../../api/generic-api';
import { useParams } from 'react-router-dom';

function FormularioEnquadramentoView() {
  const { formularioEnquadramentoId: editalId } = useParams();
  const defaultForm = { perguntasCategorizadas: false, editalId: Number(editalId) };
  const customErrors = [customRequired('titulo', 'O campo Título é obrigatório.')];

  const api = makeApi<FormularioEnquadramento>('/formulario-enquadramento');

  return (
    <Crud<FormularioEnquadramento>
      headCells={headCells}
      titleConfig={{ value: 'Gerenciar Formulários', show: true }}
      queryFilters={{ withDeleted: true, editalId: Number(editalId) }}
      apiUrl={'/gerenciar-formularios'}
      uischema={formularioEnquadramentoUiSchema}
      apiClient={api}
      defaultForm={defaultForm}
      customErrors={customErrors}
      customSave={{ show: false }}
    ></Crud>
  );
}

export default FormularioEnquadramentoView;

export const rootViews = ['formulario-enquadramento'];
