import { CrudContextData, IStepCallback } from '../../../jsonforms/uischema';
import { Usuario } from '../../../models/usuario';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { makeUsuarioApi } from '../../editar-perfil/usuario-api';

export const stepHandlerProposta = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,

  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { crudStates } = ctx;
  const errorsResult = await stepHandlerErrors(fieldsConfig, ctx, data, url);

  if (errorsResult) {
    const usuarioResult =
      data?.edital?.vinculoInstitucionalObrigatorio && crudStates.add
        ? await atualizarUsuario(ctx)
        : true;

    if (callback && usuarioResult) {
      return await callback(ctx);
    }
  }

  return false;
};

const atualizarUsuario = async (ctx: CrudContextData) => {
  const { formData, showError } = ctx;
  const usuarioApi = makeUsuarioApi();
  let valid = false;
  try {
    const usuario: Partial<Usuario> = {
      possuiVinculoInstitucional: formData?.edital?.vinculoInstitucionalObrigatorio,
      possuiVinculoEmpregaticio: formData?.edital?.vinculoEmpregaticioObrigatorio,
      vinculoInstitucional: formData?.vinculoInstitucional,
    };
    await usuarioApi.atualizarPerfil(usuario);
    valid = true;
  } catch (error: any) {
    showError(
      error?.cause?.response?.data?.message?.[0] || 'Erro ao atualizar vínculo do usuário.',
    );
    console.error(error);
  }
  return valid;
};
