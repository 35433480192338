import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { NaturezaDespesa as NaturezaDespesaModel } from '../../../models/natureza-despesa';
import { headCells } from './natureza-despesa.headcell';
import naturezaDespesaUiSchema from './natureza-despesa.uischema';

function NaturezaDespesa() {
  const customErrors = [whiteSpaces('nome')];

  return (
    <Crud<NaturezaDespesaModel>
      headCells={headCells}
      titleConfig={{ value: 'Natureza da Despesa', show: true }}
      apiUrl={'/natureza-despesa'}
      uischema={naturezaDespesaUiSchema}
      customErrors={customErrors}
      hideView
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default NaturezaDespesa;
