import { Crud } from '../../../components/crud/crud';
import { DadosFundacao as DadosFundacaoModel } from '../../../models/dados-fundacao';
import { makeDadosFundacaoApi } from './dados-fundacao-api';
import { headCells } from './dados-fundacao.headcell';
import dadosFundacaoUiSchema from './dados-fundacao.uischema';

function DadosFundacao() {
  const apiClient = makeDadosFundacaoApi('/dados-fundacao');

  return (
    <Crud<DadosFundacaoModel>
      headCells={headCells}
      titleConfig={{ value: 'Perfil Fundação', show: true }}
      apiClient={apiClient}
      apiUrl={'/dados-fundacao'}
      uischema={dadosFundacaoUiSchema}
      initView
      customSave={{ show: false }}
    ></Crud>
  );
}

export default DadosFundacao;
