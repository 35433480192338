import { useEffect, useState } from 'react';
import { Eye } from '../../../components/styled-icons';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { headCells } from './pergunta-avaliacao.headcell';
import perguntaAvaliacaoUiSchema from './pergunta-avaliacao.uischema';
import { GenericApi, makeApi } from '../../../api/generic-api';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { PerguntaAvaliacao as PerguntaAvaliacaoModel } from '../../../models/pergunta-avaliacao';

const defaultForm = {
  obrigatoria: false,
  descritiva: {
    minimo: 0,
    maximo: 0,
  },
  nota: {
    notaMinima: 0,
    notaMaxima: 0,
  },
  perguntaBase: true,
};

function PerguntaAvaliacao() {
  const [api, setApi] = useState<GenericApi<PerguntaAvaliacaoModel>>();

  const navigate = useNavigate();

  const perguntaAvaliacaoDetalhes = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('pergunta-avaliacao'),
    icon: <Eye size='24' />,
    title: 'Visualizar',
    handler: (row: any) => {
      navigate(`/pergunta-avaliacao-detalhes/${row?.id}`, {
        state: { crudState: CrudStatesOptions.EDIT },
      });
    },
  };

  useEffect(() => {
    setApi(
      makeApi<PerguntaAvaliacaoModel>('/pergunta-avaliacao', {
        orderRelationBy: { orderMultiplaEscolhaBy: (a, b) => a.posicao - b.posicao },
      }),
    );
  }, []);

  return (
    <Crud<PerguntaAvaliacaoModel>
      headCells={headCells}
      titleConfig={{ value: 'Perguntas de Avaliação', show: true }}
      queryFilters={{ withDeleted: true, perguntaBase: true }}
      uischema={perguntaAvaliacaoUiSchema}
      apiUrl={'/pergunta-avaliacao'}
      apiClient={api}
      defaultForm={defaultForm}
      hideView
      customActions={[() => perguntaAvaliacaoDetalhes]}
    ></Crud>
  );
}

export default PerguntaAvaliacao;
