import { AxiosResponse } from 'axios';
import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
import { DadosFundacao } from '../../../models/dados-fundacao';

export class DadosFundacaoApi extends GenericApi<DadosFundacao> {
  constructor(url: string) {
    super(url);
  }

  async get(): Promise<DadosFundacao> {
    try {
      const response = await client.get<DadosFundacao>(`${this.url}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async put(_: number, data: any): Promise<AxiosResponse<DadosFundacao, any>> {
    try {
      const response = await client.put<DadosFundacao>(this.url, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
}

export const makeDadosFundacaoApi = (url: string) => {
  return new DadosFundacaoApi(url);
};
