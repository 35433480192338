import { ArrayTranslations } from '@jsonforms/core';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import './style/array-toolbar.css';
import { memo } from 'react';

const StyledBadge = styled(Badge)(({ theme }: any) => ({
  color: theme.palette.error.main,
}));

export interface ArrayLayoutToolbarProps {
  label: string;
  description: string;
  errors: string;
  path: string;
  enabled: boolean;
  addItem(path: string, data: any): () => void;
  createDefault(): any;
  translations: ArrayTranslations;
  disableAdd?: boolean;
}
export const ArrayToolbar = memo(function ArrayLayoutToolbar({
  label,
  description,
  errors,
  addItem,
  path,
  enabled,
  createDefault,
  disableAdd,
}: ArrayLayoutToolbarProps) {
  return (
    <Box className='array-toolbar'>
      <Stack width={'100%'}>
        <Grid container className='array-toolbar-container'>
          <Grid item>
            <Grid container className='left-grid-container'>
              <Grid item>
                <Typography className='array-toolbar-label'>{label}</Typography>
              </Grid>
              <Grid item>
                {errors.length !== 0 && (
                  <Grid item>
                    <Tooltip id='tooltip-validation' title={errors}>
                      <StyledBadge badgeContent={errors.split('\n').length}>
                        <ErrorOutline color='inherit' />
                      </StyledBadge>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {enabled && !disableAdd && (
            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={addItem(path, createDefault())}
                    variant='contained'
                    startIcon={<AddIcon />}
                    className='add-button'
                  >
                    Adicionar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {description && <FormHelperText>{description}</FormHelperText>}
      </Stack>
    </Box>
  );
});
