import {
  ArrayLayoutProps,
  JsonFormsUISchemaRegistryEntry,
  RankedTester,
  createDefaultValue,
  findUISchema,
  rankWith,
  uiTypeIs,
  update,
} from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import { TableBody, TableRow } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import merge from 'lodash/merge';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { EditIcon } from '../../components/icons/edit-icon';
import { PencilSquareIcon } from '../../components/icons/pencil-square-icon';
import { PlaylistAddIcon } from '../../components/icons/playlist-add-icon';
import { TrashIcon } from '../../components/icons/tresh-icon';
import { generateRandomToken } from '../../helpers/generate-random-token';
import { IOptions, TableLayoutHeadCell } from '../uischema';
import './array-layout-render.css';
import {
  ActionButton,
  ActionsContainer,
  ArrayTableLayoutContainer,
  FormActionsContainer,
  LabelTitle,
  LayoutStateContainer,
  LayoutStateText,
  PaginationContainer,
  StyledAddIcon,
  StyledCheckIcon,
  StyledTable,
  TableLayoutActionCell,
  TableLayoutCell,
  TableLayoutContainer,
  TableLayoutHeader,
  TableLayoutHeaderCell,
  TableLayoutIconButton,
  TableLayoutPagination,
  TableLayoutRow,
  TotalContainer,
} from './styled/array-table-layout-render';
import { GenericApi, makeApi } from '../../api/generic-api';

enum TableLayoutState {
  Edit = 'edit',
  Add = 'add',
  View = 'view',
}

const ArrayTableLayoutComponent = (props: ArrayLayoutProps) => {
  const {
    enabled,
    data,
    path,
    schema,
    uischema,
    addItem,
    removeItems,
    renderers,
    cells,
    rootSchema,
    config,
    uischemas,
  } = props;
  const ctx = useCrudContext();
  const { showError, showSuccess } = ctx;
  const { dispatch, core } = useJsonForms();

  const [layoutState, setLayoutState] = useState<TableLayoutState>(TableLayoutState.View);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [paginatedListData, setPaginatedListData] = useState<any[]>([]);
  const [api, setApi] = useState<GenericApi<any>>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const appliedUiSchemaOptions: IOptions = merge({}, config, props.uischema.options);
  const headCells = uischema.options?.headCells as TableLayoutHeadCell[];
  const { builder, field } = uischema.options?.buildHeader || {};
  const additionalData = uischema.options?.additionalData;
  const rowsPerPageOptions = [10, 15, 20];

  useEffect(() => {
    if (uischema.options?.route) {
      const result = makeApi(uischema.options?.route);
      setApi(result);
    }
  }, [uischema]);

  const saveInApi = useCallback(
    (data: any) => {
      api
        ?.put(core.data?.id, data)
        .then(() => {
          showSuccess('Salvo com sucesso!');
        })
        .catch(() => {
          showError('Erro ao salvar!');
        });
    },
    [api, core.data?.id],
  );

  const clearUnsavedItem = useCallback(() => {
    dispatch(update(`${path}Unsaved`, () => {}));
  }, [dispatch, path]);

  useEffect(() => {
    clearUnsavedItem();
  }, [clearUnsavedItem]);

  useEffect(() => {
    setPaginatedListData(
      core.data[path]?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    );
  }, [core.data, path, rowsPerPage, page]);

  const getUnsavedItem = useCallback(() => {
    return core.data[`${path}Unsaved`];
  }, [core.data, path]);

  const exitHandler = useCallback(() => {
    setLayoutState(TableLayoutState.View);
    clearUnsavedItem();
    setEditIndex(null);
  }, [clearUnsavedItem]);

  const innerCreateDefaultValue = useCallback(() => {
    return {
      uniqueArrayKey: generateRandomToken(8),
      ...getUnsavedItem(),
    };
  }, [getUnsavedItem]);

  const saveHandler = useCallback(async () => {
    const { beforeCreate } = appliedUiSchemaOptions;
    const shouldCreate = beforeCreate?.(core.data, path) ?? true;

    if (!shouldCreate) {
      showError('Não foi possível adicionar um novo item');
      return;
    }

    let dataToSave = null;
    if (layoutState === TableLayoutState.Add) {
      dataToSave = innerCreateDefaultValue();
      addItem?.(path, dataToSave)?.();
    } else if (layoutState === TableLayoutState.Edit) {
      dataToSave = getUnsavedItem();
      dispatch(update(`${path}.${editIndex}`, () => dataToSave));
    }

    //TODO: transformar em uma função separada
    const additionalDataToSave = additionalData ? additionalData?.(core.data) : {};
    const currentData = {
      ...additionalDataToSave,
      [path]: (core.data[path] ?? []).filter((_, index) => index !== editIndex).concat(dataToSave),
    };

    saveInApi(currentData);

    exitHandler();
  }, [
    appliedUiSchemaOptions,
    core.data,
    path,
    layoutState,
    additionalData,
    saveInApi,
    exitHandler,
    showError,
    innerCreateDefaultValue,
    addItem,
    getUnsavedItem,
    dispatch,
    editIndex,
  ]);

  const showAddForm = useCallback(() => {
    setLayoutState(TableLayoutState.Add);

    const { defaultValue } = appliedUiSchemaOptions;

    let result: any;
    if (defaultValue) {
      result =
        typeof defaultValue === 'function'
          ? defaultValue(core.data, appliedUiSchemaOptions)
          : defaultValue;
    } else {
      result = createDefaultValue(props.schema, props.rootSchema);
    }

    dispatch(update(`${path}Unsaved`, () => result));
  }, [appliedUiSchemaOptions, core.data, dispatch, path, props.rootSchema, props.schema]);

  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema,
      ),
    [uischemas, schema, path, uischema, rootSchema],
  );

  const getCellContent = (row: unknown, headCell: TableLayoutHeadCell) => {
    const { field } = headCell;
    return typeof field === 'function' ? (field(row) ?? '-') : (row[field] ?? '-');
  };

  const editItem = (index: number) => () => {
    dispatch(update(`${path}Unsaved`, () => core.data[path][index]));
    setTimeout(() => {
      setLayoutState(TableLayoutState.Edit);
      setEditIndex(index);
    }, 100);
  };

  const deleteItem = (path: string, index: number) => () => {
    removeItems(path, [index])?.();
    const updatedData = core.data[path].filter((_, i) => i !== index);
    const additionalDataToSave = additionalData ? additionalData?.(core.data) : {};

    const currentData = {
      ...additionalDataToSave,
      [path]: updatedData,
    };

    saveInApi(currentData);
    setEditIndex(null);
  };

  return (
    <>
      <ArrayTableLayoutContainer>
        <LabelTitle>{props.label}</LabelTitle>
        {layoutState === TableLayoutState.View ? (
          <>
            <ActionsContainer>
              <TotalContainer>{builder ? builder(core.data[path], field) : null}</TotalContainer>
              <ActionButton
                disabled={!enabled}
                onClick={() => showAddForm()}
                variant='contained'
                className='add-button'
              >
                <StyledAddIcon />
                Adicionar
              </ActionButton>
            </ActionsContainer>
            <TableLayoutContainer>
              <StyledTable size='small' aria-label='dense-data-table'>
                <TableLayoutHeader>
                  <TableRow>
                    {headCells?.map((headCell: TableLayoutHeadCell) => (
                      <TableLayoutHeaderCell key={headCell?.label}>
                        {headCell.label}
                      </TableLayoutHeaderCell>
                    ))}
                    {data !== 0 ? (
                      <TableLayoutHeaderCell key={'actions'}>Ações</TableLayoutHeaderCell>
                    ) : null}
                  </TableRow>
                </TableLayoutHeader>
                <TableBody>
                  {data === 0 ? (
                    <TableRow>
                      <TableLayoutCell align='center' colSpan={headCells?.length + 1}>
                        Sem dados
                      </TableLayoutCell>
                    </TableRow>
                  ) : (
                    paginatedListData?.map((row: any, index: number) => (
                      <TableLayoutRow key={row?.uniqueArrayKey}>
                        {headCells?.map((headCell: TableLayoutHeadCell) => (
                          <TableLayoutCell key={headCell?.label}>
                            {getCellContent(row, headCell)}
                          </TableLayoutCell>
                        ))}
                        <TableLayoutActionCell>
                          <TableLayoutIconButton disabled={!enabled} onClick={editItem(index)}>
                            <EditIcon />
                          </TableLayoutIconButton>
                          <TableLayoutIconButton
                            disabled={!enabled}
                            onClick={deleteItem(path, index)}
                          >
                            <TrashIcon />
                          </TableLayoutIconButton>
                        </TableLayoutActionCell>
                      </TableLayoutRow>
                    ))
                  )}
                </TableBody>
              </StyledTable>
            </TableLayoutContainer>
            <PaginationContainer>
              <TableLayoutPagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={core.data[path]?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage='Item por página '
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to ? to : 0} de ${count ? count : 0}`
                }
                onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
                  setPage(page);
                }}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </PaginationContainer>
          </>
        ) : (
          <>
            {layoutState === TableLayoutState.Add && (
              <LayoutStateContainer>
                <PlaylistAddIcon size={30} /> <LayoutStateText>Adicionando Item</LayoutStateText>
              </LayoutStateContainer>
            )}
            {layoutState === TableLayoutState.Edit && (
              <LayoutStateContainer>
                <PencilSquareIcon size={24} /> <LayoutStateText>Edição de Item</LayoutStateText>
              </LayoutStateContainer>
            )}
            <JsonFormsDispatch
              key={'array-layout-form'}
              enabled={enabled}
              schema={schema}
              uischema={foundUISchema}
              path={`${path}Unsaved`}
              renderers={renderers}
              cells={cells}
            />
            <FormActionsContainer>
              <ActionButton
                onClick={() => {
                  exitHandler();
                }}
                variant='outlined'
              >
                Cancelar
              </ActionButton>
              <ActionButton disabled={!enabled} onClick={saveHandler} variant='contained'>
                <StyledCheckIcon />
                Confirmar
              </ActionButton>
            </FormActionsContainer>
          </>
        )}
      </ArrayTableLayoutContainer>
    </>
  );
};

const ArrayTableLayout = memo(ArrayTableLayoutComponent);

const ArrayTableLayoutRenderer = ({ visible, addItem, ...props }: ArrayLayoutProps) => {
  const addItemCb = useCallback((p: string, value: unknown) => addItem(p, value), [addItem]);

  return (
    <Hidden xsUp={!visible}>
      <ArrayTableLayout visible={visible} addItem={addItemCb} {...props} />
    </Hidden>
  );
};

const arrayTableLayoutTester: RankedTester = rankWith(5, uiTypeIs('ArrayTableLayout'));

export const arrayTableLayoutRenderer = {
  tester: arrayTableLayoutTester,
  renderer: withJsonFormsArrayLayoutProps(ArrayTableLayoutRenderer),
};
