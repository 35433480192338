import { CrudContextData, IStepCallback } from '../../../jsonforms/uischema';

export const stepHandlerEditalRubrica = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { formData, showError } = ctx;

  const editalRubrica = formData?.editalRubrica;

  let valid = true;
  let message = '';
  if (editalRubrica?.length > 0) {
    editalRubrica.map((x: any) => {
      if (!x.tipoEditalRubrica) {
        valid = false;
        message = 'Selecione uma Rubrica.';
      } else if (!x.naturezaDespesaId) {
        valid = false;
        message = 'Selecione uma Natureza da Despesa.';
      } else if (x.temMoedaEstrangeira) {
        if (!x.editalRubricaMoedaEstrangeira || x.editalRubricaMoedaEstrangeira.length < 1) {
          valid = false;
          message = 'Selecione ao menos uma moeda estrangeira.';
        }
      }
    });

    if (valid) return true;
  } else {
    return true;
  }

  showError(message);
  return false;
};
