import styled from '@emotion/styled';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export const CustomStack = styled(Stack)({
  flexGrow: 1,
  justifyContent: 'space-around',
});

export const CustomAutoComplete = styled(Autocomplete)({
  maxWidth: 500,
  width: 500,
  marginLeft: 10,
});

export const CustomListItem = styled('li')(({ theme }) => ({
  borderRadius: '8px',
  margin: '5px',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  [`&.${autocompleteClasses.option}`]: {
    padding: '8px',
  },
}));

export const CustomListAvatar = styled(Avatar)({
  width: '45px',
  height: '45px',
  fontSize: '13pt',
  bgcolor: '#ffdddd',
  color: '#5d0000',
  marginRight: '10pt',
});

export const CustomListImg = styled('img')({
  borderRadius: '50%',
  width: '45px',
  height: '45px',
  marginRight: '10pt',
});

export const CustomSelectAvatar = styled(Avatar)({
  width: '25px',
  height: '25px',
  fontSize: '13pt',
  bgcolor: '#ffdddd',
  color: '#5d0000',
  marginRight: '10pt',
});

export const CustomSelectImg = styled('img')({
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  marginRight: '10pt',
});
