import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormApi, makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';
import { whiteSpaces } from '../../../../helpers/custom-errors';
import { HistoricoAjusteTesouro } from '../../../../models/historico-ajuste-tesouro';
import { Data, headCells } from './historico-ajuste-tesouro.headcell';
import historicoAjusteTesouroUiSchema from './historico-ajuste-tesouro.uischema';

export interface ITesouro {
  id: number;
  ano: number;
  valorPrometido: number;
  valorPrevisto: number;
  valorAtual: number;
  deletedAt?: Date;
}

function HistoricoAjusteTesouroView() {
  const [tesouro, setTesouro] = useState<ITesouro>({} as ITesouro);
  const [apiClient, setApiClient] = useState<FormApi<HistoricoAjusteTesouro>>();
  const { tesouroId } = useParams();
  const tesouroIdNumber = Number(tesouroId);
  const customErrors = [whiteSpaces('justificativa')];
  const defaultForm = {
    tesouroId: tesouroIdNumber,
    valorPrevisto: tesouro?.valorPrometido,
    tesouro,
  };

  useEffect(() => {
    const api = makeApi<Data>('/historico-ajuste-tesouro');
    const get = api.get.bind(api);
    api.get = async (id: number) => {
      const response = await get(id);
      response.novoValor = response.valorFinal;
      return response;
    };
    setApiClient(api);
  }, []);

  useEffect(() => {
    makeApi(`/tesouro`)
      .get(tesouroIdNumber)
      .then((data) => setTesouro(data as ITesouro));
  }, []);

  return (
    <Crud<Data>
      headCells={headCells}
      queryFilters={{
        withDeleted: true,
        tesouroId: tesouroIdNumber,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      titleConfig={{ value: 'Ajuste de Tesouro', show: true }}
      apiUrl={'/historico-ajuste-tesouro'}
      apiClient={apiClient}
      uischema={historicoAjusteTesouroUiSchema}
      defaultForm={defaultForm}
      customErrors={customErrors}
      hideCreate={!!tesouro.deletedAt}
      hideUpdate
      hideDelete
    ></Crud>
  );
}

export default HistoricoAjusteTesouroView;

export const rootViews = ['tesouro'];
