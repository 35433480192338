import { ClipboardPencil } from '../../../components/styled-icons';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import dayjs from '../../../components/dayjs/sig-dayjs';
import { onlyNumbers } from '../../../helpers/custom-errors';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Tesouro as TesouroModel } from '../../../models/tesouro';
import { headCells } from './tesouro.headcell';
import tesouroUiSchema from './tesouro.uischema';

function Tesouro() {
  const navigate = useNavigate();

  const customErrors = [onlyNumbers('ano')];
  const defaultForm = {
    ano: dayjs().format('YYYY'),
  };

  const { parameterFilters, setParameterFilters, clearForm } = useCrudContext();
  const deleteMessageHandler = (formData: any) => `Tesouro: ${formData?.ano}`;

  const ajusteTesouro = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('historico-ajuste-tesouro'),
    icon: <ClipboardPencil size='24' color='#009900' />,
    title: 'Histórico de Ajuste do Tesouro',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        tesouro: row,
      });
      clearForm();
      navigate(`/tesouro/${row.id}/historico-ajuste-tesouro`);
    },
  };

  return (
    <Crud<TesouroModel>
      headCells={headCells}
      titleConfig={{ value: 'Tesouro', show: true }}
      uischema={tesouroUiSchema}
      apiUrl={'/tesouro'}
      queryFilters={{
        withDeleted: true,
        orderBy: 'ano',
        orderDirection: 'DESC',
      }}
      hideView
      hideUpdate
      customErrors={customErrors}
      defaultForm={defaultForm}
      deleteMessageHandler={deleteMessageHandler}
      customActions={[() => ajusteTesouro]}
    ></Crud>
  );
}

export default Tesouro;
