import last from 'lodash/last';

export const authStringsFor = (permission: string): string[] => {
  return permission
    .split('.')
    .reduce(
      (acc, curr) => (acc.push(acc.length > 0 ? `${last(acc)}.${curr}` : curr), acc),
      new Array<string>(),
    );
};
