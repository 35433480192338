import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { ValorDiaria } from '../../../models/valor-diaria';
import { headCells } from './valor-diaria.headcell';
import diariasUiSchema from './valor-diaria.uischema';

function Diarias() {
  const customErrors = [whiteSpaces('descricao'), whiteSpaces('localidade')];
  const defaultForm = { descricao: '', valorDiaria: 0 };

  return (
    <Crud<ValorDiaria>
      headCells={headCells}
      titleConfig={{ value: 'Diárias', show: true }}
      apiUrl={'/valor-diaria'}
      uischema={diariasUiSchema}
      customErrors={customErrors}
      defaultForm={defaultForm}
      hideView
      queryFilters={{ withDeleted: true, orderBy: 'nivelAcademico', orderDirection: 'ASC' }}
    ></Crud>
  );
}

export default Diarias;
