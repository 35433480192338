import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { DadosFundacao } from '../../../models/dados-fundacao';

export const displayProperties = ['nome'];

export const moduleLabel = 'Perfil Fundação';

export const moduleName = 'DADOS_FUNDACAO';

export const headCells: readonly HeadCell<DadosFundacao>[] = [
  {
    id: 'nomeDiretorPresidente',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'email',
  },
];
