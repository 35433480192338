import dayjs from 'dayjs';

export const setLabelWhenChangeDateFuncao = (formData: any): string => {
  const today = dayjs();
  if (formData.vinculoInstitucional?.inicioFuncao) {
    const monthDiff = today.diff(formData.vinculoInstitucional.inicioFuncao, 'month');

    const years = Math.floor(monthDiff / 12) > 0 ? Math.floor(monthDiff / 12) + ' ano(s)' : '';
    const month = monthDiff % 12 > 0 ? (monthDiff % 12) + ' mês(es)' : '';

    return `${years} ${month}`;
  }
  return '';
};

export const setLabelWhenChangeDateServico = (formData: any): string => {
  const today = dayjs();
  if (formData.vinculoInstitucional?.inicioServico) {
    const monthDiff = today.diff(formData.vinculoInstitucional.inicioServico, 'month');

    const years = Math.floor(monthDiff / 12) > 0 ? Math.floor(monthDiff / 12) + ' ano(s)' : '';
    const month = monthDiff % 12 > 0 ? (monthDiff % 12) + ' mês(es)' : '';

    return `${years} ${month}`;
  }
  return '';
};
