import { Dayjs } from 'dayjs';
import dayjs from '../dayjs/sig-dayjs';

export const normalize = (date: string) => dayjs.utc(date).local();

export const ptBrformatDate = (date: Dayjs) => date.format('DD/MM/YYYY HH:mm:ss');

export const changeTimeFromDate = (date: Dayjs, time: number) => {
  let newDate = date.add(dayjs.duration({ hours: time }));
  if (time < 0) {
    newDate = date.subtract(dayjs.duration({ hours: time * -1 }));
  }
  return newDate;
};

export const compareDates = (
  firstDate: Dayjs,
  secondDate: Dayjs,
  operator: '>' | '<' | '=' | '>=' | '<=',
) => {
  switch (operator) {
    case '>':
      return firstDate.isAfter(secondDate);
    case '<':
      return firstDate.isBefore(secondDate);
    case '=':
      return firstDate.isSame(secondDate);
    case '>=':
      return firstDate.isSameOrAfter(secondDate);
    case '<=':
      return firstDate.isSameOrBefore(secondDate);
  }
};
