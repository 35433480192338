import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { ValorDiaria } from '../../../models/valor-diaria';

export const displayProperties = ['descricao', 'localidade'];

export const moduleLabel = 'Diárias';

export const moduleName = 'CONFIGURACOES_INICIAIS.VALOR_DIARIA';

export const headCells: readonly HeadCell<ValorDiaria>[] = [
  {
    id: 'nivelAcademicoId',
    numeric: false,
    disablePadding: false,
    label: 'Nível Acadêmico',
    resource: {
      name: 'nivelAcademico',
      target: 'tipo',
    },
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'moedaEstrangeiraId',
    numeric: false,
    disablePadding: false,
    label: 'Moeda Estrangeira',
    resource: {
      name: 'moedaEstrangeira',
      target: 'nome',
    },
  },
  {
    id: 'valorDiaria',
    numeric: false,
    disablePadding: false,
    label: 'Valor Diária',
    mask: 'coin',
  },
];
