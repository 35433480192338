export const makeCustomRequiredIfBrasil = (field: string) => ({
  field,
  hasError: (value: any, formData: any) =>
    formData?.['paisId-codigo'] === 'brasil' ? !value : false,
  error: {
    keyword: 'customRequired',
    message: 'Campo obrigatório',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    schemaPath: '#/required',
    params: {
      missingProperty: field,
    },
  },
});

export const makePositiveNumberIfBrasil = (field: string) => ({
  field,
  hasError: (value: any, formData: any) =>
    formData?.['paisId-codigo'] === 'brasil'
      ? Number.isNaN(Number(value)) || Number(value) <= 0
      : false,
  error: {
    keyword: 'positiveNumber',
    message: 'O campo deve ser um número positivo.',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});
