/**
 * importa automaticamente todos os "Custom Render" do diretorio src/jsonForms/renderers/*
 */
const requireComponent = require.context('./', false);

export const customRenderers: any[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.tsx'))
  .flatMap((fileName: any) => Object.values(requireComponent(fileName)))
  .filter(
    (exported: any) =>
      exported && typeof exported === 'object' && 'tester' in exported && 'renderer' in exported,
  );
