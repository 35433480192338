import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import AlertCustom from '../../../components/crud/alert-custom';
import { useCrudContext } from '../../../components/crud/crud-context';
import { translateAction } from '../../../components/crud/i18n';
import client from '../../../config/axios-config';
import { Severity } from '../../../components/crud/protocols/severity';

interface PermissionNode {
  [key: string]: PermissionNode | string[];
}

interface OpenState {
  [key: string]: boolean;
}

interface IPerfilPermissionamento {
  id: number;
  perfilId: number;
  nomePermissionamento: string;
}

interface IAction {
  permission: string;
  dependsOn: string[];
}

function parsePermissions(permissions: string[]): PermissionNode {
  const result: PermissionNode = {};

  permissions.forEach((permission) => {
    const parts = permission.split('.');
    addToNode(result, parts);
  });

  return result;
}

function addToNode(
  node: PermissionNode,
  parts: string[],
  index: number = 0,
  parent: string = '',
): void {
  if (index >= parts.length - 1) return;

  const part = parts[index];
  const isPenultimate = index === parts.length - 2;

  if (isPenultimate) {
    const key = parent ? `${parent}.${part}` : part;

    if (!node[key]) node[key] = [];

    if (Array.isArray(node[key])) {
      const value = `${key}.${parts[index + 1]}`;

      if (!(node[key] as string[]).includes(value)) {
        (node[key] as string[]).push(value);
      }
    }
  } else {
    const key = parent ? `${parent}.${part}` : part;

    if (!node[key]) node[key] = {};

    addToNode(node[key] as PermissionNode, parts, index + 1, key);
  }
}

const PerfilPermissionamentoView: React.FC = () => {
  const [permissions, setPermissions] = useState<PermissionNode>({});
  const [permissionsArray, setPermissionsArray] = useState<string[]>([]);
  const [open, setOpen] = useState<OpenState>({});
  const [checked, setChecked] = useState<string[]>([]);
  const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
  const [messageAlert, setMessageAlert] = useState<string>('Permissões salvas com sucesso!');
  const [dependsOn, setDependsOn] = useState<Map<string, string[]>>(new Map());

  const {
    openAlert,
    setOpenAlert,
    parameterFilters: {
      perfil: { id: profileId },
    },
  } = useCrudContext();

  const fetchPermissions = async (): Promise<PermissionNode> => {
    const { data } = await client.get<IAction[]>('perfil-permissionamento/getAllPermissions');

    const permissions = data.map((item) => item.permission).sort();

    const parsedPermissions = parsePermissions(permissions);

    setDependsOn(new Map(data.map((item) => [item.permission, item.dependsOn])));
    setPermissions(parsedPermissions);

    return parsedPermissions;
  };

  const fetchPerfilPermissions = async (permissions: string[]): Promise<void> => {
    const { data }: any = await client.get<string[]>(
      `perfil-permissionamento/getPermissionsByProfileId/${profileId}`,
    );

    const checkedPermissions = data.map(
      (permission: IPerfilPermissionamento) => permission.nomePermissionamento,
    );

    setChecked(() => {
      const newChecked = [...checkedPermissions];

      checkedPermissions.forEach((permission: string) => {
        permissions.forEach((item: string) => {
          if (item.startsWith(permission)) {
            if (!newChecked.includes(item)) {
              newChecked.push(item);
            }
          }
        });
      });

      return newChecked;
    });
  };

  const setPermissionsArrayState = async (permissions: PermissionNode): Promise<string[]> => {
    const paths = objectPathsToArray(permissions);
    setPermissionsArray(paths);
    return paths;
  };

  const handleToggleCollapse = (key: string): void => {
    setOpen((open) => ({ ...open, [key]: !open[key] }));
  };

  const handleToggleChecked = (key: string): void => {
    setChecked((currentChecked) => {
      const newChecked = [...currentChecked];
      const index = currentChecked.indexOf(key);

      if (index !== -1) {
        newChecked.splice(index, 1);
        removeParentPermission(key, newChecked);
        removeAllChildPermissions(key, newChecked);
      } else {
        const depedencies = dependsOn.get(key) ?? [];
        newChecked.push(...[...depedencies, key]);
        addAllChildPermissions(key, newChecked);
      }

      return newChecked.sort().filter((item, index, array) => array.indexOf(item) === index);
    });
  };

  const removeParentPermission = (key: string, checkedList: string[]): void => {
    const parts = key.split('.');

    if (parts.length > 1) {
      const parent = parts.slice(0, parts.length - 1).join('.');
      const index = checkedList.indexOf(parent);

      if (index !== -1) {
        checkedList.splice(index, 1);
        removeParentPermission(parent, checkedList);
      }
    }
  };

  const addAllChildPermissions = (prefix: string, checkedList: string[]): void => {
    permissionsArray.forEach((permission) => {
      if (permission.startsWith(`${prefix}.`)) {
        if (!checkedList.includes(permission)) {
          const depedencies = dependsOn.get(permission) ?? [];
          checkedList.push(...[...depedencies, permission]);
        }
      }
    });
  };

  const removeAllChildPermissions = (prefix: string, checkedList: string[]): void => {
    const aux = [...checkedList];
    aux.forEach((permission) => {
      if (permission.startsWith(`${prefix}.`)) {
        const index = checkedList.indexOf(permission);

        if (index !== -1) {
          checkedList.splice(index, 1);
        }
      }
    });
  };

  const handleToggleSaveButton = async () => {
    try {
      const values = [...checked];

      checked.map((value) => {
        removeAllChildPermissions(value, values);
      });

      const response = await client.post<any>('perfil-permissionamento/createMany', {
        perfilId: profileId,
        permissionamentos: values,
      });

      if (response.status === 201) {
        showSuccess('Permissões salvas com sucesso!');
      } else {
        showError('Ocorreu um erro.');
      }
    } catch (error: any) {
      let errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
    }
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const handleKeyName = (key: string): string => {
    const lastKey = key.split('.').pop();

    if (!lastKey) return key;

    return translateAction(lastKey);
  };

  const handleKeyDescription = (key: string): string => {
    return `Módulo para gerenciamento de ${handleKeyName(key)}`;
  };

  const objectPathsToArray = (obj: PermissionNode): string[] => {
    let paths: string[] = [];
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        if (!paths.includes(key)) {
          paths.push(key);
        }

        (obj[key] as string[]).forEach((item) => {
          if (!paths.includes(item)) {
            paths.push(item);
          }
        });
      } else if (typeof obj[key] === 'object') {
        if (!paths.includes(key)) {
          paths.push(key);
        }
        paths = paths.concat(objectPathsToArray(obj[key] as PermissionNode));
      }
    }
    return paths;
  };

  const renderPermissions = (
    node: PermissionNode | string[],
    path: string = '',
    level: number = 0,
  ): JSX.Element => {
    if (Array.isArray(node)) {
      return (
        <List component='div' disablePadding>
          {node.map((item, index) => {
            return (
              <ListItemButton key={index} sx={{ pl: 4 + level * 2 }}>
                <ListItemText primary={handleKeyName(item)} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                    <Switch
                      onClick={() => handleToggleChecked(item)}
                      checked={checked.indexOf(item) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={checked.indexOf(item) !== -1 ? 'Permitido' : 'Não permitido'}
                    sx={{ width: 125, textAlign: 'right' }}
                  />
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      );
    } else {
      return (
        <>
          {Object.entries(node).map(([key, value]) => {
            const _key = path ? `${path}.${key}` : key;
            return (
              <React.Fragment key={_key}>
                <ListItemButton
                  onClick={() => handleToggleCollapse(_key)}
                  sx={{
                    pl: 2 + level * 2,
                    backgroundColor: level === 0 ? '#1351B4' : 'white',
                    mt: 1,
                    color: level === 0 ? 'white' : 'black',
                    ':hover': {
                      backgroundColor: level === 0 ? '#1049a0' : '#f5f5f5',
                    },
                  }}
                >
                  <ListItemText primary={handleKeyName(key)} />
                  <ListItemText
                    primary={handleKeyDescription(key)}
                    primaryTypographyProps={{
                      style: {
                        fontSize: '0.9rem',
                        color: '#A7A7A7',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                      <Switch
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleChecked(key);
                        }}
                        checked={checked.indexOf(key) !== -1}
                        color='primary'
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        checked.indexOf(key) !== -1
                          ? 'Acesso Total ao Módulo'
                          : 'Acesso Parcial ao Módulo'
                      }
                      sx={{ width: 200, textAlign: 'center', marginRight: 1 }}
                    />
                    {open[_key] ? <ExpandLess /> : <ExpandMore />}
                  </Box>
                </ListItemButton>
                <Collapse in={open[_key]} timeout='auto' unmountOnExit>
                  {renderPermissions(value as PermissionNode, _key, level + 1)}
                </Collapse>
              </React.Fragment>
            );
          })}
        </>
      );
    }
  };

  useEffect(() => {
    async function loadPermissionsAndProfile() {
      const permissions = await fetchPermissions();
      const paths = await setPermissionsArrayState(permissions);

      if (profileId) {
        await fetchPerfilPermissions(paths);
      }
    }

    loadPermissionsAndProfile();
  }, [profileId]);

  return (
    <Container sx={{ marginBottom: 6 }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {renderPermissions(permissions)}
      </List>
      <Container sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: 2 }}>
        <Button variant='contained' onClick={handleToggleSaveButton} size='large'>
          Salvar
        </Button>
      </Container>
      <AlertCustom open={openAlert} severity={severityAlert} message={messageAlert} />
    </Container>
  );
};

export default PerfilPermissionamentoView;
