import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeLoginApi } from '../../../api/login-api';
import client from '../../../config/axios-config';
import { Anexo } from '../../../models/anexo';
import { Instituicao } from '../../../models/instituicao';
import { Usuario } from '../../../models/usuario';

export interface IConfiguracoesFundacao {
  fundacaoInstituicaoId: string;
  instituicao: Instituicao;
  logoCabecalho?: Anexo;
  [key: string]: any;
}

interface AuthData {
  token: string;
  refreshToken: string;
  user: Usuario;
  permissions: string[];
  expiresIn: number;
}

export interface AuthContextData {
  login: (authData: AuthData) => void;
  logout: () => void;
  dadosFundacao: (configuracoesFundacao: IConfiguracoesFundacao) => void;
  isLoggedIn: boolean;
  token: string;
  refreshToken: string;
  permissions: string[];
  configuracoesFundacao: IConfiguracoesFundacao;
  loggedInUser?: Usuario;
  updateUserProfileImage: (profileImage: string) => void;
  updateUserName: (nome: string) => void;
  verificarEmail: () => void;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const refreshToken = localStorage.getItem('refreshToken') || '';
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]') as string[];
  const configuracoesFundacao = JSON.parse(
    localStorage.getItem('configuracoesFundacao') || '{}',
  ) as IConfiguracoesFundacao;
  const isLoggedIn = !!token;
  const [loggedInUser, setLoggedInUser] = React.useState<Usuario>();

  useEffect(() => {
    if (isLoggedIn) {
      const loginApi = makeLoginApi();
      loginApi
        .obterConfiguracoesFundacao()
        .then((config) => {
          if (config?.status === 200 && config.data.fundacaoInstituicaoId) {
            dadosFundacao(config.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      getLoggedInUser();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      getLoggedInUser();
    }
  }, []);

  const getLoggedInUser = async () => {
    const response = await client.get<Usuario>('/usuario/autenticado');
    if (response.status === 200) {
      setLoggedInUser(response.data);
    }
    return response.data;
  };

  const verificarEmail = useCallback(() => {
    if (!loggedInUser) getLoggedInUser();
    else setLoggedInUser({ ...loggedInUser!, emailVerificado: true });
  }, [loggedInUser]);

  const updateUserProfileImage = (profileImage: string) => {
    setLoggedInUser({ ...loggedInUser!, fotoPerfil: profileImage });
  };

  const updateUserName = (nome: string) => {
    setLoggedInUser({ ...loggedInUser!, nome: nome });
  };

  const login = async (authData: AuthData): Promise<void> => {
    localStorage.setItem('token', authData.token);
    localStorage.setItem('permissions', JSON.stringify(authData.permissions));
    localStorage.setItem('refreshToken', authData.refreshToken);

    const loggedInUser = await getLoggedInUser();
    if (loggedInUser?.emailVerificado) {
      navigate('/home');
    } else {
      navigate('/editar-perfil');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('permissions');
    navigate('/login');
  };

  const dadosFundacao = (configuracoesFundacao: IConfiguracoesFundacao) => {
    localStorage.setItem('configuracoesFundacao', JSON.stringify(configuracoesFundacao));
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        dadosFundacao,
        token,
        refreshToken,
        permissions,
        isLoggedIn,
        configuracoesFundacao,
        loggedInUser,
        updateUserProfileImage,
        updateUserName,
        verificarEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext deve ser usado dentro de um AuthProvider');
  }
  return context;
};
