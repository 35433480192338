import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { useCrudContext } from '../../components/crud/crud-context';
import CustomMultipleSelectMembroChip from '../../components/custom-multiple-user-select/custom-multiple-select-membro-chip';
import { TipoFuncaoParticipante } from '../../models/funcao-participante';
import {
  PropostaAtividadeMembro,
  PropostaConviteMembro,
  TipoSituacao,
} from '../../models/proposta';

const CustomMultipleSelectMembroTester = rankWith(5, uiTypeIs('CustomMultipleSelectMembro'));

export const CustomMultipleSelectMembroRenderer = {
  tester: CustomMultipleSelectMembroTester,
  renderer: withJsonFormsControlProps(
    ({ schema, uischema, data, path, visible, enabled, handleChange, ...props }) => {
      const { formData } = useCrudContext();

      const filters = {
        situacao: TipoSituacao.aceito,
        propostaId: formData.id,
      };

      const apiSearchInstance = makePropostaConviteMembroApi();

      const [propostaConviteMembro, setPropostaConviteMembro] = useState<PropostaConviteMembro[]>(
        [],
      );

      const [selectedPropostaConviteMembro, setSelectedPropostaConviteMembro] = useState<
        PropostaConviteMembro[]
      >([]);

      const handleAdd = (value: PropostaConviteMembro[]) => {
        handleChange(
          path,
          value.map((membro) => ({
            membroId: membro.id,
          })),
        );
      };

      useEffect(() => {
        apiSearchInstance?.getAll?.(filters).then((apiData: PropostaConviteMembro[]) => {
          setPropostaConviteMembro(apiData);
        });
      }, [path]);

      useEffect(() => {
        const selectedMembros = propostaConviteMembro.filter((membro) =>
          !isEmpty(data)
            ? data.some(
                (atividadeMembro: PropostaAtividadeMembro) =>
                  atividadeMembro.membroId === membro.id,
              )
            : membro.funcaoParticipante?.codigo === TipoFuncaoParticipante.coordenador,
        );

        setSelectedPropostaConviteMembro(selectedMembros);
        if (isEmpty(data)) handleAdd(selectedMembros);
      }, [propostaConviteMembro]);

      return (
        <Hidden xsUp={!visible}>
          <CustomMultipleSelectMembroChip
            propostaConviteMembro={propostaConviteMembro}
            selectedPropostaConviteMembroState={{
              selectedPropostaConviteMembro,
              setSelectedPropostaConviteMembro,
            }}
            handleAdd={handleAdd}
            enabled={enabled}
          ></CustomMultipleSelectMembroChip>
        </Hidden>
      );
    },
  ),
};
