import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { HistoricoAjusteValorBolsa as HistoricoAjusteValorBolsaModel } from '../../../models/historico-ajuste-valor-bolsa';
import { headCells } from './historico-ajuste-valor-bolsa.headcell';
import historicoAjusteValorBolsaUiSchema from './historico-ajuste-valor-bolsa.uischema';

function HistoricoAjusteValorBolsa() {
  const { parameterFilters } = useCrudContext();
  const { nivelBolsaId } = useParams();
  const nivelBolsaIdNumber = Number(nivelBolsaId);
  const nivelBolsa = parameterFilters?.['nivelBolsa'];

  const defaultForm = {
    nivelBolsaId: nivelBolsaIdNumber,
    nivelBolsa: nivelBolsa,
  };

  return (
    <Crud<HistoricoAjusteValorBolsaModel>
      titleConfig={{ value: 'Ajuste do Valor', show: true }}
      apiUrl={'/historico-ajuste-valor-bolsa'}
      headCells={headCells}
      uischema={historicoAjusteValorBolsaUiSchema}
      queryFilters={{
        nivelBolsaId: nivelBolsaIdNumber,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      defaultForm={defaultForm}
      hideView
      hideUpdate
      hideDelete
    ></Crud>
  );
}

export default HistoricoAjusteValorBolsa;

export const rootViews = ['modalidade-bolsa', 'nivel-bolsa'];
