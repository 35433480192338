import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { LinhaDeAcao } from '../../../models/linha-de-acao';

export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.LINHA_DE_ACAO';

export const headCells: readonly HeadCell<LinhaDeAcao>[] = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Título',
  },
  {
    id: 'dataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Data de Início',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataTermino',
    numeric: false,
    disablePadding: false,
    label: 'Data de Término',
    dateFormat: 'DD/MM/YYYY',
  },
];
