import { or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useState } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';
import { insertDecimals } from '../../helpers/insert-decimals';

const isNumberRender = or(
  schemaMatches(
    (schema) => !isEmpty(schema) && (schema as any)?.type === 'integer' && !(schema as any)?.format,
  ),
  uiTypeIs('Number'),
  uiTypeIs('Float'),
);

const numberRenderTester = rankWith(5, isNumberRender);

export const numberRender = {
  tester: numberRenderTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, data, path, schema, enabled, ...props }) => {
      const uischema = props.uischema as IControlElement;
      const isFloatRender = (props.uischema?.type as String) === 'Float';
      const isIntegerRender = (schema as any)?.type === 'integer';

      const defaultValue = data && isFloatRender ? insertDecimals(data).toString() : data;

      const [value, setValue] = useState<string>(defaultValue);

      const ctx = useCrudContext();
      const { formData, validationMode, crudStates, disabledFields, isRequiredField } = ctx;

      const required = uischema.options?.required ?? false;

      const isDisabled: boolean =
        !enabled ||
        crudStates.view ||
        uischema?.options?.disabled ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        disabledFields.includes(path);

      const numberStep = uischema?.options?.numberStep;
      let step = numberStep;

      if (isFloatRender && !step) {
        step = 0.1;
      }

      if (isIntegerRender && !step) {
        step = 1;
      }

      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { isPositiveNumber, handleChange } = uischema?.options || {};

        const val = event?.target?.value?.trim?.();
        const numValue = val ? Number(val) : undefined;

        if (isFloatRender && val.includes('.')) {
          const [, decimalPart] = val.split('.');
          if (decimalPart.length > 2) {
            return;
          }
        }

        if (numValue && numberStep && numValue % step !== 0) {
          return;
        }

        if (numValue === undefined || numValue === null) {
          props.handleChange(path, null);
          handleChange?.(null, props?.handleChange, formData, ctx, path);
          setValue('');
          return;
        }

        if (!Number(numValue) || (!!isPositiveNumber && Number(numValue) <= 0)) {
          props.handleChange(path, 0);
          handleChange?.(0, props?.handleChange, formData, ctx, path);
          setValue('0');
        } else {
          if (isFloatRender) {
            const intValue = String(parseFloat(val).toFixed(2)).replace('.', '');
            props.handleChange(path, intValue);
            handleChange?.(intValue, props?.handleChange, formData, ctx, path);
          } else {
            props.handleChange(path, numValue);
            handleChange?.(numValue, props?.handleChange, formData, ctx, path);
          }
          setValue(val);
        }
      };

      return (
        <Hidden xsUp={!visible}>
          <div className='custom-input-container'>
            <TextField
              {...props}
              size='small'
              type='number'
              className={`${isDisabled && 'disabled-field'}
                ${props.errors && validationMode === 'ValidateAndShow' && 'has-error'}
              `}
              label={props.label}
              required={isRequiredField(required, ctx)}
              disabled={isDisabled}
              value={value}
              inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
              onChange={handleChange}
              InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
              inputProps={{ step: uischema?.options?.numberStep }}
            />
            {validationMode === 'ValidateAndShow' && props.errors && (
              <span className='error-message'>{getFirstError(props.errors)}</span>
            )}
          </div>
        </Hidden>
      );
    },
  ),
};
