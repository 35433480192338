import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Edital } from '../../../models/edital';

export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';

export const displayProperties = ['nome'];

export const moduleLabel = 'Formulário de Consultoria';

export const headCells: readonly HeadCell<Edital>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Edital',
  },
];
