import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { headCells } from './funcao-participante.headcell';
import funcaoParticipanteUiSchema from './funcao-participante.uischema';
import { FuncaoParticipante as FuncaoParticipanteModel } from '../../../models/funcao-participante';

function FuncaoParticipante() {
  const customErrors = [whiteSpaces('nome')];

  return (
    <Crud<FuncaoParticipanteModel>
      headCells={headCells}
      titleConfig={{ value: 'Funções Participantes', show: true }}
      apiUrl={'/funcao-participante'}
      uischema={funcaoParticipanteUiSchema}
      customErrors={customErrors}
      hideView
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default FuncaoParticipante;
