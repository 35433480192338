import { List, TableBody, TableHead } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { makePropostaApi } from '../../../api/proposta-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import { AbrangenciaMunicipio } from '../../../models/abrangencia-municipio';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';
import { Estado, Estado as EstadoModel } from '../../../models/estado';
import { Instituicao, Instituicao as InstituicaoModel } from '../../../models/instituicao';
import { Abrangencia, Proposta, Proposta as PropostaModel } from '../../../models/proposta';
import { Unidade, Unidade as UnidadeModel } from '../../../models/unidade';
import { FiletypePdf } from '@styled-icons/bootstrap/FiletypePdf';
import {
  BasicListSpanIn,
  BasicListSpanOut,
  BasicListSpanOutFlex,
  DocumentosDocsDaPropostaDefaut,
  Frame26085664,
  Frame26085668,
  Frame26085700,
  Frame26085750,
  SPDDivider,
  SPDHeaderDiv,
  SPDHeaderSpan,
  SPDStyledButtonContained,
  SPDTable,
  SPDTableBodyCell,
  SPDTableBodyRow,
  SPDTableHeaderCell,
  SPDTableRow,
  SPDTitleDiv,
  SPDTitleSpan,
  SPDValueDiv,
  SPDValueSpan,
  Substepper,
} from './style/submissao-proposta-detalhes';

import {
  CounterText,
  MenuItem,
  StyledButtonContained,
  StyledCheckCheckIcon,
  StyledCircleIcon,
  StyledHorizontalRuleIcon,
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledListSubItemButton,
  StyledListSubItemText,
} from '../../../jsonforms/renderers/styled/categorization-menu';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { IControlElement } from '../../../jsonforms/uischema';
import { Endereco } from '../../../models/endereco';
import { Usuario, VinculoInstitucional } from '../../../models/usuario';
import dayjs from 'dayjs';

function SubmissaoPropostaDetalhes() {
  const routeParams = useParams();
  const propostaId = Number(routeParams['submissaoPropostaDetalhesId'] ?? null) ?? null;
  const [proposta, setProposta] = useState<PropostaModel>(null);
  const [instituicao, setInstituicao] = useState<InstituicaoModel>(null);
  const [unidade, setUnidade] = useState<UnidadeModel>(null);
  const [estados, setEstados] = useState<EstadoModel[]>(null);

  useEffect(() => {
    makePropostaApi()
      .get(propostaId)
      .then((propostaObject) => {
        setProposta(propostaObject);
      });
    makeApi<Estado>('/estado')
      .getAll()
      .then((EstadoObject) => {
        setEstados(EstadoObject);
      });
  }, []);

  useEffect(() => {
    if (proposta) {
      makeApi<Instituicao>('/instituicao')
        .get(proposta?.instituicaoExecutoraId)
        .then((InstituicaoObject) => {
          setInstituicao(InstituicaoObject);
        });
      makeApi<Unidade>('/unidade')
        .get(proposta?.unidadeExecutoraId)
        .then((UnidadeObject) => {
          setUnidade(UnidadeObject);
        });
    }
  }, [proposta]);

  const { setCurrentTitle } = useCrudContext();
  const titlePage = 'Visualização da Proposta';
  setCurrentTitle(titlePage);

  const makeGroupInline = (obj) => {
    return obj.map((item: { title: string; value }) => {
      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
        </>
      );
    });
  };

  const makeBasicList = (obj) => {
    return obj.map((item: { type?: string; title?: string; value }) => {
      if (item.type === 'inline') {
        return (
          <>
            {item.value}
            <br />
          </>
        );
      } else if (item.type === 'multiple-line') {
        return (
          <>
            <BasicListSpanOutFlex>
              <BasicListSpanIn>{item.title}</BasicListSpanIn>: <div>{item.value}</div>
            </BasicListSpanOutFlex>
            <br />
          </>
        );
      }

      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
          <br />
        </>
      );
    });
  };
  const makeNestedString = (obj: string[][]) => {
    return obj?.map((item: string[], index: number) => {
      return (
        <>
          {item.join(' > ')}
          <br />
        </>
      );
    });
  };
  const makeTable = (data: string[], titles: string[]) => {
    return (
      <div>
        <SPDTable>
          <TableHead>
            <SPDTableRow>
              {titles.map((item, index) => {
                const borderRadiusTL = index === 0 ? '2px' : '0px';
                const borderRadiusTR = index === titles.length - 1 ? '2px' : '0px';
                return (
                  <>
                    <SPDTableHeaderCell
                      borderTopLeftRadius={borderRadiusTL}
                      borderTopRightRadius={borderRadiusTR}
                    >
                      {item}
                    </SPDTableHeaderCell>
                  </>
                );
              })}
            </SPDTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <>
                <SPDTableBodyRow>
                  {titles.map((_, j) => (
                    <>
                      <SPDTableBodyCell>{data[i][j]}</SPDTableBodyCell>
                    </>
                  ))}
                </SPDTableBodyRow>
              </>
            ))}
          </TableBody>
        </SPDTable>
      </div>
    );
  };

  const buildAbrangenciaMunicipios = (abrangenciaMunicipios: AbrangenciaMunicipio[]) => {
    return abrangenciaMunicipios
      .map((item) => {
        return item.municipio;
      })
      .join(', ');
  };

  const buildAbrangencia = (abrangencia: Abrangencia[]) => {
    const abrangenciaObj = [];

    for (let i = 0; i < abrangencia?.length; i++) {
      abrangenciaObj.push([
        [getEstadoById(abrangencia[i].estadoId)],
        [buildAbrangenciaMunicipios(abrangencia[i].abrangenciaMunicipio)],
      ]);
    }

    return abrangenciaObj;
  };

  const buildInformacoesIniciais = (proposta: Proposta) => {
    return [
      {
        title: 'Título do Edital',
        value: proposta?.edital?.nome,
      },
      {
        title: 'Título do Projeto',
        value: proposta?.tituloDoProjeto,
      },
      {
        title: 'Coordenador',
        value: proposta?.criadoPor?.nome,
      },
      {
        title: 'E-mail',
        value: proposta?.criadoPor?.email,
      },
      {
        title: 'Duração do Projeto em Meses',
        value: proposta?.duracao,
      },
      {
        title: 'Instituição Executora',
        value: instituicao?.nome,
      },
      {
        title: 'Unidade Executora',
        value: unidade?.nome,
      },
      {
        title: 'Áreas de Conhecimentos da Proposta',
        value: makeNestedString(buildAreaDeConhecimento(proposta?.areaDeConhecimento)),
        type: 'multiple-line',
      },
    ];
  };

  const buildCoordenadorEndereco = (endereco: Endereco) => {
    const enderecoGroup = [
      ...(endereco?.logradouro
        ? [
            {
              title: 'Endereço',
              value: endereco.logradouro,
            },
          ]
        : []),
      ...(endereco?.bairro
        ? [
            {
              title: 'Bairro',
              value: endereco.bairro,
            },
          ]
        : []),
      ...(endereco?.cep
        ? [
            {
              title: 'CEP',
              value: endereco.cep,
            },
          ]
        : []),
    ];

    return [
      ...(enderecoGroup.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(enderecoGroup),
            },
          ]
        : []),
    ];
  };

  const buildCoordenadorDadosAcademicos = (criadoPor: Usuario) => {
    const instituicaoUnidadeNivelAcademico = [
      ...(criadoPor?.instituicaoId
        ? [
            {
              title: 'Instituição',
              value: criadoPor.instituicao.nome,
            },
          ]
        : []),
      ...(criadoPor?.unidadeId
        ? [
            {
              title: 'Unidade',
              value: criadoPor.unidade.nome,
            },
          ]
        : []),
      ...(criadoPor?.nivelAcademicoId
        ? [
            {
              title: 'Nível Acadêmico',
              value: criadoPor.nivelAcademico.tipo,
            },
          ]
        : []),
    ];

    const lattesLinkedin = [
      ...(criadoPor?.lattes
        ? [
            {
              title: 'Currículo Lattes',
              value: criadoPor.lattes,
            },
          ]
        : []),
      ...(criadoPor?.linkedin
        ? [
            {
              title: 'LinkedIn',
              value: criadoPor.linkedin,
            },
          ]
        : []),
    ];

    return [
      ...(instituicaoUnidadeNivelAcademico.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(instituicaoUnidadeNivelAcademico),
            },
          ]
        : []),
      ...(lattesLinkedin.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(lattesLinkedin),
            },
          ]
        : []),
      {
        title: 'Áreas de Conhecimentos',
        type: 'multiple-line',
        value: makeNestedString(buildAreaDeConhecimento(criadoPor?.areaDeConhecimento)),
      },
    ];
  };

  const buildCoordenadorDadosProfissionais = (vinculoInstitucional: VinculoInstitucional) => {
    const vinculoInicioServicoRegimeTrabalho = [
      ...(vinculoInstitucional?.tipoVinculoInstitucionalId
        ? [
            {
              title: 'Tipo de Vínculo',
              value: vinculoInstitucional.tipoVinculoInstitucional.nome,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioServico
        ? [
            {
              title: 'Início de Serviço',
              value: dayjs(vinculoInstitucional.inicioServico).format('DD/MM/YYYY'),
            },
          ]
        : []),
      ...(vinculoInstitucional?.regimeTrabalhoId
        ? [
            {
              title: 'Regime de Trabalho',
              value: vinculoInstitucional.regimeTrabalho.nome,
            },
          ]
        : []),
    ];

    const fCInicioFC = [
      ...(vinculoInstitucional?.funcao
        ? [
            {
              title: 'Função/Cargo',
              value: vinculoInstitucional.funcao,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioFuncao
        ? [
            {
              title: 'Início da Função/Cargo',
              value: dayjs(vinculoInstitucional.inicioFuncao).format('DD/MM/YYYY'),
            },
          ]
        : []),
    ];

    return [
      ...(vinculoInicioServicoRegimeTrabalho.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(vinculoInicioServicoRegimeTrabalho),
            },
          ]
        : []),
      ...(fCInicioFC.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(fCInicioFC),
            },
          ]
        : []),
    ];
  };

  const buildCoordenadorDadosPessoais = (criadoPor: Usuario) => {
    const paisCpfEmail = [
      ...(criadoPor?.paisId
        ? [
            {
              title: 'País de Nacionalidade',
              value: criadoPor.pais.nomePt,
            },
          ]
        : []),
      ...(criadoPor?.documento
        ? [
            {
              title: 'CPF',
              value: criadoPor.documento,
            },
          ]
        : []),
      ...(criadoPor?.email
        ? [
            {
              title: 'E-mail',
              value: criadoPor.email,
            },
          ]
        : []),
    ];

    return [
      {
        title: 'Nome Completo',
        value: criadoPor?.nome,
      },
      ...(criadoPor?.nomeSocial
        ? [
            {
              title: 'Nome Social',
              value: criadoPor.nomeSocial,
            },
          ]
        : []),
      ...(paisCpfEmail.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(paisCpfEmail),
            },
          ]
        : []),
    ];
  };

  const getCoordenadorDadosPessoais = () => {
    return makeBasicList(buildCoordenadorDadosPessoais(proposta?.criadoPor));
  };

  const getCoordenadorEndereco = () => {
    return makeBasicList(buildCoordenadorEndereco(proposta?.criadoPor?.endereco));
  };

  const getCoordenadorDadosAcademicos = () => {
    return makeBasicList(buildCoordenadorDadosAcademicos(proposta?.criadoPor));
  };

  const getCoordenadorDadosProfissionais = () => {
    return makeBasicList(
      buildCoordenadorDadosProfissionais(proposta?.criadoPor?.vinculoInstitucional),
    );
  };

  const buildAreaDeConhecimento = (areaDeConhecimento: AreaDeConhecimento[]) => {
    const areDeConhecimentoObj = [];

    for (let i = 0; i < areaDeConhecimento?.length; i++) {
      const entry = [areaDeConhecimento[i].grandeAreaNome, areaDeConhecimento[i].areaNome];

      if (areaDeConhecimento[i].subAreaNome) {
        entry.push(areaDeConhecimento[i].subAreaNome);
      }

      if (areaDeConhecimento[i].especialidadeNome) {
        entry.push(areaDeConhecimento[i].especialidadeNome);
      }

      areDeConhecimentoObj.push(entry);
    }

    return areDeConhecimentoObj;
  };

  const getEstadoById = (estadoId: number): string => {
    const estado = estados.filter((estado) => estado.id === estadoId);
    return estado[0] ? estado[0].nome : '';
  };

  const getAbrangencia = (abrangencia: Abrangencia[]) => {
    return makeTable(buildAbrangencia(proposta?.abrangencia), ['Estado', 'Municípios']);
  };

  const getInformacoesIniciais = () => {
    return makeBasicList(buildInformacoesIniciais(proposta));
  };

  const makeMenu = () => {
    return [
      {
        label: 'Caracterização da Proposta',
        type: 'Category',
        elements: [
          {
            label: 'Informações Iniciais',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
          {
            label: 'Abrangência',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
        ],
      },
      {
        label: 'Dados do Coordenador',
        type: 'Category',
        elements: [
          {
            label: 'Dados Pessoais',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
          {
            label: 'Endereço',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
          {
            label: 'Dados Acadêmicos',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
          {
            label: 'Dados Profissionais',
            type: 'Category',
            elements: [
              {
                type: 'AnyOne',
              },
            ],
          },
        ],
      },
    ];
  };

  const categories = makeMenu();

  const categoryElements = useMemo(() => {
    const finalElements: IControlElement[] = [];
    const findFinalElements = (items: IControlElement[]) => {
      items.forEach((item) => {
        if (item.type === 'Category') {
          if (item.elements && item.elements.some((x: IControlElement) => x.type === 'Category')) {
            findFinalElements(item.elements);
          } else {
            finalElements.push(item);
          }
        }
      });
    };
    findFinalElements(categories);
    return finalElements.filter((x) => x.elements.length > 0);
  }, [categories]);

  const [selectedCategory, setSelectedCategory] = useState<IControlElement | null>(
    categoryElements[0],
  );

  const isAnyParentSelected = (item: any): boolean => {
    if (selectedCategory.label === item.label) return true;
    return item.elements?.some(
      (child: IControlElement) =>
        selectedCategory.label === child.label ||
        (child.type === 'Category' && isAnyParentSelected(child)),
    );
  };

  const countSubItems = (item: any, currentDepth: number, targetDepth: number): number => {
    if (!item.elements) return 0;

    return item.elements.reduce((count: number, subItem: any) => {
      if (subItem.type === 'Category' && subItem.elements.length > 0) {
        if (currentDepth + 1 === targetDepth) {
          return count + 1;
        } else {
          return count + countSubItems(subItem, currentDepth + 1, targetDepth);
        }
      }
      return count;
    }, 0);
  };

  const showProgress = true;

  const iconsByDepth = {
    0: <StyledCheckCheckIcon />,
    1: <StyledCircleIcon />,
    2: <StyledHorizontalRuleIcon />,
  };

  const [open, setOpen] = useState<Array<{ [key: string]: boolean }>>([]);

  const handleClick = (key: string, depth: number) => {
    setOpen((prevState) => {
      const newOpen = [...prevState];
      newOpen[depth] = {
        ...Object.keys(newOpen[depth] || {}).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      };
      newOpen[depth][key] = !prevState[depth]?.[key];
      return newOpen;
    });
  };

  const renderMenuItems = (items: any[], depth: number = 0) => {
    return items
      .filter((x) => x.type === 'Category')
      .map((item, index) => {
        let subItemCount = 0;
        const icon = iconsByDepth[depth] || iconsByDepth[2];
        if (showProgress) subItemCount = countSubItems(item, depth, depth + 1);

        const isOpen = true;
        if (item.elements.some((x: IControlElement) => x.type === 'Category')) {
          return (
            <MenuItem key={index} depth={depth}>
              <StyledListItemButton onClick={() => handleClick(item.label, depth)}>
                <StyledListItemIcon>{icon}</StyledListItemIcon>
                <StyledListItemText primary={item.label} />
                {showProgress && <CounterText>{`0/${subItemCount}`}</CounterText>}
                {isOpen || open[depth]?.[item.label] ? <ExpandLess /> : <ExpandMore />}
              </StyledListItemButton>
              <Collapse in={isOpen || open[depth]?.[item.label]} timeout='auto' unmountOnExit>
                <StyledList dense role='list'>
                  {renderMenuItems(item.elements, depth + 1)}
                </StyledList>
              </Collapse>
            </MenuItem>
          );
        } else if (item.elements.length > 0) {
          return (
            <StyledListSubItemButton
              key={index}
              depth={depth}
              selected={selectedCategory.label === item.label}
            >
              <StyledListItemIcon>{icon}</StyledListItemIcon>
              <StyledListSubItemText primary={item.label} />
            </StyledListSubItemButton>
          );
        }
        return null;
      });
  };

  const submissaoPropostaObj = [
    {
      title: 'CARACTERIZAÇÃO DA PROPOSTA',
      value: [
        {
          title: 'Informações Iniciais',
          value: getInformacoesIniciais(),
        },
        proposta?.abrangencia.length
          ? {
              title: 'Abrangências',
              value: getAbrangencia(proposta.abrangencia),
            }
          : null,
      ],
    },
    {
      title: 'DADOS DO COORDENADOR',
      value: [
        {
          title: 'Dados Pessoais',
          value: getCoordenadorDadosPessoais(),
        },
        {
          title: 'Endereço',
          value: getCoordenadorEndereco(),
        },
        {
          title: 'Dados Acadêmicos',
          value: getCoordenadorDadosAcademicos(),
        },
        {
          title: 'Dados Profissionais',
          value: getCoordenadorDadosProfissionais(),
        },
      ],
    },
  ];

  return (
    <>
      <DocumentosDocsDaPropostaDefaut>
        <SPDHeaderDiv>
          <SPDHeaderSpan>{titlePage}</SPDHeaderSpan>
        </SPDHeaderDiv>
        <Substepper></Substepper>
        <Frame26085664>
          <Frame26085750>
            <Frame26085668>
              {submissaoPropostaObj.map((x, i) => {
                return (
                  <>
                    <SPDTitleDiv>
                      <SPDTitleSpan>
                        {i + 1}. {x.title}
                      </SPDTitleSpan>
                      <SPDDivider></SPDDivider>
                    </SPDTitleDiv>
                    {Array.isArray(x.value)
                      ? x.value.map((y, j) => {
                          if (y) {
                            return (
                              <>
                                <SPDValueDiv>
                                  <SPDValueSpan>
                                    {i + 1}.{j + 1}. {y.title}
                                  </SPDValueSpan>
                                  <div>{y.value}</div>
                                </SPDValueDiv>
                              </>
                            );
                          } else {
                            return '';
                          }
                        })
                      : ''}
                  </>
                );
              })}
            </Frame26085668>
          </Frame26085750>
          <Frame26085700>
            <List>{renderMenuItems(categories)}</List>
            <SPDStyledButtonContained
              fullWidth
              variant='contained'
              size='small'
              aria-label='baixar-pdf'
              onClick={() => {
                alert('Em Breve');
              }}
              sx={{ padding: '8px' }}
            >
              <FiletypePdf size={20} />
              Baixar PDF
            </SPDStyledButtonContained>
          </Frame26085700>
        </Frame26085664>
      </DocumentosDocsDaPropostaDefaut>
    </>
  );
}
export default SubmissaoPropostaDetalhes;
