import { Link } from 'react-router-dom';
import notFoundImg from './images/not-found.png';

function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '99vh',
      }}
    >
      <h1>Página não encontrada</h1>
      <p>A página que você está procurando não existe.</p>
      <Link to='/login' style={{ color: '#0000ff', textDecoration: 'underline' }}>
        Voltar para a página inicial
      </Link>
      <img src={notFoundImg} style={{ height: '360px', marginLeft: '5px' }}></img>
    </div>
  );
}

export default NotFound;
