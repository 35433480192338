import { camelCase } from 'change-case';

export type OrderFunction<T> = (a: T, b: T) => number;
export type ElementType<T> = T extends (infer U)[] ? U : T;
export type OrderBy<T> = {
  [K in keyof T as `order${Capitalize<string & K>}By`]?: OrderFunction<ElementType<T[K]>>;
};
export type OrderRelationBy<T> = {
  [key in keyof T]?: OrderTypeBy<ElementType<T[key]>>;
};
export type OrderTypeBy<T> = OrderBy<T> & OrderRelationBy<T>;

export const isSortFunction = <T>(obj: any): obj is OrderFunction<T> => {
  return typeof obj === 'function';
};

export const isOrderRelation = <T>(obj: any): obj is OrderRelationBy<T> => {
  return typeof obj === 'object';
};

export const isOrderBy = (key: string): string | undefined => {
  if (key.startsWith('order') && key.endsWith('By') && key.length > 'orderBy'.length) {
    return camelCase(key.substring('order'.length, key.length - 'By'.length));
  }
  return key;
};

export const sortRelationBy = <T>(obj: T, order: OrderTypeBy<T>) => {
  Object.entries(order).forEach(([key, value]) => {
    key = isOrderBy(key);
    const relation = obj[key as keyof T];
    if (Array.isArray(relation) && isSortFunction(value)) {
      obj[key as keyof T] = relation.sort(value);
    }
    if (isOrderRelation(value)) {
      [relation].flat().forEach((relation) => sortRelationBy(relation, value));
    }
  });
  return obj;
};
