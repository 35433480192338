import { Trash3 } from '../../../components/styled-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import CustomMultipleUserSelect from '../../../components/custom-multiple-user-select/custom-multiple-user-select';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Usuario } from '../../../models/usuario';
import { headCells, moduleLabel, moduleName } from './convite-participantes.headcell';
import { EditalConviteParticipante } from '../../../models/edital-convite-participante';
import { Severity } from '../../../components/crud/protocols/severity';

function ConviteParticipantes() {
  const { id, setId, setCurrentTitle } = useCrudContext();
  const { editalId } = useParams();
  const editalIdNumber = Number(editalId);
  const apiSearchInstance = makeApi<Usuario>('/usuario');
  const apiCreateInstance = makeApi<EditalConviteParticipante>('/edital-convite-participante');

  const [openDialog, setOpenDialog] = useState(false);
  const [apiListData, setApiListData] = useState([]);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [selectedUsuarios, setSelectedUsuarios] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({ editalId: editalIdNumber });
  const [openAlert, setOpenAlert] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
  const [messageAlert, setMessageAlert] = useState<string>('Permissões salvas com sucesso!');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(10);

  const onTextChange = (searchText: string) => {
    setFilters({ editalId: editalIdNumber, nomeUsuario: searchText });
  };

  const onCreateParticipants = () => {
    setFilters({ editalId: editalIdNumber });
  };

  const handleOpenDelete = (id: number) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDialog(false);
    setTimeout(() => setId(-1), 100);
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const fetchData = async () => {
    try {
      const response = await apiCreateInstance
        ?.getAllForPagination?.({ filters, skip: page * rowsPerPage, take: rowsPerPage })
        .catch((e) => {
          showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          return undefined;
        });

      if (response) {
        const data = Array.isArray(response) ? response : response.data;
        const count = Array.isArray(response) ? response.length : response.count;

        setApiListData(data as EditalConviteParticipante[]);
        setTotalCount(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters, page, rowsPerPage]);

  const destroy = async () => {
    await apiCreateInstance
      ?.delete?.(id)
      .then(() => {
        fetchData();
        handleCloseDelete();
        showSuccess('Excluído com sucesso.');
      })
      .catch((e) => {
        showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
      });
  };

  const handleAdd = (e: any) => {
    const fetchCreateData = async () => {
      const createData = selectedUsuarios.map((usuario) => {
        return { editalId: editalId, usuarioId: usuario.id };
      });
      apiCreateInstance
        .post({ editalConviteParticipantes: createData })
        .then(() => {
          showSuccess('Participante(s) adicionado(s) com sucesso!');
          onCreateParticipants();
          setSelectedUsuarios([]);
          setUsuarios([]);
        })
        .catch((e) => {
          showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
        });
    };
    fetchCreateData();
  };

  useEffect(() => {
    setCurrentTitle(moduleLabel);
  }, []);

  const deletarParticipante = {
    hasPermission: ['DELETAR'],
    moduleName: moduleNameFor('convite-participantes'),
    icon: <Trash3 size={20} style={{ color: 'red' }} />,
    title: 'Deletar Participante',
    handler: (row: any) => {
      handleOpenDelete(row.id);
    },
  };

  return (
    <CustomMultipleUserSelect
      enabled={true}
      hasSecure={true}
      handleAdd={handleAdd}
      headCells={headCells}
      totalCount={totalCount}
      moduleName={moduleName}
      moduleLabel={moduleLabel}
      apiListData={apiListData}
      onTextChange={onTextChange}
      pageState={{ page, setPage }}
      rowsPerPageOptions={[10, 20, 30]}
      apiSearchInstance={apiSearchInstance}
      usuariosState={{ usuarios, setUsuarios }}
      customActions={[() => deletarParticipante]}
      rowsPerPageState={{ rowsPerPage, setRowsPerPage }}
      alertCustom={{ openAlert, severityAlert, messageAlert }}
      dialogProps={{ openDialog, handleCloseDelete, destroy }}
      selectedUsuariosState={{ selectedUsuarios, setSelectedUsuarios }}
    ></CustomMultipleUserSelect>
  );
}

export default ConviteParticipantes;

export const rootViews = ['edital'];
