import { IUiSchema } from '../../../jsonforms/uischema';

const conviteParticipantesUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      label: 'Nome',
      type: 'Control',
      scope: '#/properties/nome',
    },
  ],
};

export default conviteParticipantesUiSchema;
