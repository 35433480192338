import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Add } from '../../../components/styled-icons';
import { customRequired } from '../../../helpers/custom-errors';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { ModalidadeBolsa as ModalidadeBolsaModel } from '../../../models/modalidade-bolsa';
import { headCells } from './modalidade-bolsa.headcell';
import modalidadeBolsaUiSchema from './modalidade-bolsa.uischema';

function ModalidadeBolsa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const customErrors = [
    customRequired('nome'),
    customRequired('sigla', 'O campo Sigla é obrigatório.'),
  ];

  const nivelBolsa = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('nivel-bolsa'),
    icon: <Add size='24' color='#009900' />,
    title: 'Nível de Bolsa',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        modadalidadeBolsa: row,
      });
      navigate(`/modalidade-bolsa/${row?.id}/nivel-bolsa`);
    },
  };

  const showNivelBolsa = (row: any) => {
    if (row.deletedAt === null) return nivelBolsa;
  };

  return (
    <Crud<ModalidadeBolsaModel>
      titleConfig={{ value: ' Modalidade de Bolsa', show: true }}
      apiUrl={'/modalidade-bolsa'}
      customErrors={customErrors}
      headCells={headCells}
      uischema={modalidadeBolsaUiSchema}
      queryFilters={{ withDeleted: true }}
      customActions={[showNivelBolsa]}
      hideView
    ></Crud>
  );
}

export default ModalidadeBolsa;
