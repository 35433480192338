import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { TermoAditivoInstrumentoJuridico as TermoAditivoInstrumentoJuridicoModel } from '../../../models/termo-aditivo-instrumento-juridico';
import { headCells } from './termo-aditivo-instrumento-juridico.headcell';
import termoAditivoInstrumentoJuridicoUiSchema from './termo-aditivo-instrumento-juridico.uischema';

function TermoAditivoInstrumentoJuridico() {
  const { instrumentoJuridicoId } = useParams();
  const instrumentoJuridicoIdNumber = Number(instrumentoJuridicoId);

  return (
    <Crud<TermoAditivoInstrumentoJuridicoModel>
      titleConfig={{ value: 'Termos Aditivos do Instrumento Jurídico', show: true }}
      queryFilters={{ instrumentoJuridicoId: instrumentoJuridicoIdNumber }}
      defaultForm={{ instrumentoJuridicoId: instrumentoJuridicoIdNumber }}
      uischema={termoAditivoInstrumentoJuridicoUiSchema}
      apiUrl={'/termo-aditivo-instrumento-juridico'}
      customSave={{ show: false }}
      headCells={headCells}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export const rootViews = ['instrumento-juridico'];

export default TermoAditivoInstrumentoJuridico;
