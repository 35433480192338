import { Rule, RuleEffect } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';

const tesouroUiSchema: IUiSchema = {
  type: 'HorizontalLayout',
  elements: [
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/ano',
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorPrometido',
          rule: { effect: RuleEffect.HIDE, condition: {} } as Rule,
        },
      ],
    },
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorPrevisto',
          options: {
            required: true,
          },
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorAlocado',
          rule: { effect: RuleEffect.HIDE, condition: {} } as Rule,
        },
      ],
    },
  ],
};

export default tesouroUiSchema;
