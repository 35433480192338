import { IUiSchema } from '../../../jsonforms/uischema';

const nivelBolsaUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/codigo',
      label: 'Código',
    },
    {
      type: 'Control',
      scope: '#/properties/nivel',
      label: 'Nível',
      options: {
        required: true,
      },
    },
    {
      type: 'Select',
      scope: '#/properties/nivelAcademicoId',
      label: 'Nível Acadêmico',
      options: {
        required: true,
      },
    },
    {
      type: 'Control',
      scope: '#/properties/cargaHoraria',
      label: 'Carga Horária',
    },
    {
      type: 'MonetaryInput',
      scope: '#/properties/valorAtualBolsa',
      label: 'Valor',
      options: {
        required: true,
      },
    },
  ],
};

export default nivelBolsaUiSchema;
