import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import {
  BoxFlex,
  ButtonLabel,
  ComponentBox,
  CustomButton,
  Header,
  ListItemEdital,
  ListItemEditalName,
  Search,
  TituloLabel,
  WidthFull,
  WidthFullBox,
  WidthFullListItem,
} from './style/todos-editais';
import { SearchInput } from '../../../components/search-input/search-input';
import { DocumentText } from '../../../components/styled-icons';
import ValidityChecker from '../../../components/validity-checker/validity-checker';

function TodosEditais() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const { setCurrentTitle } = useCrudContext();
  const title = 'Todos os editais';

  useEffect(() => {
    setCurrentTitle(title);

    makeApi('/edital')
      .getAll({ vigentes: 'true', orderBy: 'chamadaEdital.termino', orderDirection: 'ASC' })
      .then((x) => {
        setData(x);
      });
  }, []);

  const editalDetalhes = (id: number) => {
    navigate(`/edital-detalhes/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.nome.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <>
      <ComponentBox>
        <Header>
          <TituloLabel>Editais</TituloLabel>
        </Header>
        <Search>
          <SearchInput
            value={searchInput}
            onChange={handleSearchChange}
            placeholder='Pesquisar por nome de edital'
          />
        </Search>
        <WidthFull>
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemEdital disablePadding>
                <WidthFullListItem>
                  <WidthFullBox>
                    <ListItemEditalName>{item.nome}</ListItemEditalName>
                    <BoxFlex>
                      <ValidityChecker item={item} />
                      <CustomButton onClick={() => editalDetalhes(item.id)}>
                        <DocumentText size='18' />
                        <ButtonLabel>Visualizar Edital</ButtonLabel>
                      </CustomButton>
                    </BoxFlex>
                  </WidthFullBox>
                </WidthFullListItem>
              </ListItemEdital>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </WidthFull>
      </ComponentBox>
    </>
  );
}

export default TodosEditais;
