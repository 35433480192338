import { GroupLayout, LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import {
  MaterialLabelableLayoutRendererProps,
  MaterialLayoutRenderer,
} from '@jsonforms/material-renderers';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import Typography from '@mui/material/Typography';
import React from 'react';

const groupTester: RankedTester = rankWith(5, uiTypeIs('Group'));

const GroupComponent = React.memo(function GroupComponent({
  visible,
  enabled,
  uischema,
  label,
  ...props
}: MaterialLabelableLayoutRendererProps) {
  const groupLayout = uischema as GroupLayout;
  const title = props?.schema?.title ?? undefined;

  if (!visible) {
    return null;
  }

  return (
    <>
      {title && <Typography variant='h6'>{title}</Typography>}
      <MaterialLayoutRenderer
        {...props}
        visible={visible}
        enabled={enabled}
        elements={groupLayout.elements}
      />
    </>
  );
});

const MaterializedGroupLayoutRenderer = ({
  uischema,
  schema,
  path,
  visible,
  enabled,
  renderers,
  cells,
  direction,
  label,
}: LayoutProps) => {
  const groupLayout = uischema as GroupLayout;

  return (
    <GroupComponent
      elements={groupLayout.elements}
      schema={schema}
      path={path}
      direction={direction}
      visible={visible}
      enabled={enabled}
      uischema={uischema}
      renderers={renderers}
      cells={cells}
      label={label}
    />
  );
};

export const materialGroupLayoutRenderer = {
  tester: groupTester,
  renderer: withJsonFormsLayoutProps(MaterializedGroupLayoutRenderer),
};
