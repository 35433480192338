import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import AlertCustom from '../../../components/crud/alert-custom';
import { useCrudContext } from '../../../components/crud/crud-context';
import { EnhancedTableToolbar } from '../../../components/crud/data-table/table-toolbar';
import JsonForm from '../../../components/crud/json-form';
import Loading from '../../../components/crud/loading';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { AreaDeConhecimento as AreaDeConhecimentoModel } from '../../../models/area-de-conhecimento';
import { AreaDeConhecimentoApi, makeAreaDeConhecimentoApi } from './area-de-conhecimento-api';
import AreaDeConhecimentoUiSchema from './area-de-conhecimento.uischema';
import RecursiveTable from './recursive-table';
import { SaveButton, Container } from '../../../components/crud/style/crud.styled';
import { Severity } from '../../../components/crud/protocols/severity';
import { CrudStatesOptions } from '../../../jsonforms/uischema';

function AreaDeConhecimento() {
  const {
    crudStates,
    updateCrudStates,
    apiListData,
    setApiListData,
    formData,
    setFormData,
    validationMode,
    setValidationMode,
    errorsJsonForms,
    setErrorsJsonForm,
    additionalErrors,
    setAdditionalErrors,
    setSchema,
    schema,
    load,
    setLoad,
    disabledFields,
    setDisabledFields,
    setCurrentTitle,
  } = useCrudContext();
  const [api, setApi] = React.useState<AreaDeConhecimentoApi | null>(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState('');
  const [severityAlert, setSeverityAlert] = React.useState<Severity>(Severity.SUCCESS);
  const [id, setId] = React.useState(-1);
  const onlyGrandeArea = [...disabledFields, 'areaNome', 'subAreaNome', 'especialidadeNome'];
  const onlyArea = [...disabledFields, 'grandeAreaNome', 'subAreaNome', 'especialidadeNome'];
  const onlySubArea = [...disabledFields, 'grandeAreaNome', 'areaNome', 'especialidadeNome'];
  const onlyEspecialidade = [...disabledFields, 'grandeAreaNome', 'areaNome', 'subAreaNome'];

  const title = 'Áreas de Conhecimento';

  React.useEffect(() => {
    if (!api) {
      const apiInstance = makeAreaDeConhecimentoApi();
      setApi(apiInstance as any);
      setCurrentTitle(title);
    }
  }, [api]);

  React.useEffect(() => {
    // Carrega o schema
    setLoad(true);
    api
      ?.getSchema?.()
      .then((schema) => {
        if (schema) {
          setSchema(schema);
          setTimeout(() => setLoad(false), 200);
        } else {
          console.error('O schema é undefined');
          setLoad(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    // Carrega os dados pra listagem
    if (crudStates.list) {
      setLoad(true);
      api
        ?.buscarGrandesAreas?.()
        .then((data) => {
          setApiListData(data);
          setDisabledFields([]);
          setTimeout(() => setLoad(false), 200);
        })
        .catch((err) => {
          console.error(err);
          setLoad(false);
        });
    }
  }, [api, crudStates.list]);

  const editView = (row: AreaDeConhecimentoModel) => {
    api?.get?.(row?.id).then((data) => {
      switch (data?.numeroNivel) {
        case 1:
          setDisabledFields(onlyGrandeArea);
          break;
        case 2:
          setDisabledFields(onlyArea);
          break;
        case 3:
          setDisabledFields(onlySubArea);
          break;
        case 4:
          setDisabledFields(onlyEspecialidade);
          break;
      }
      setFormData(data);
    });
    updateCrudStates(CrudStatesOptions.EDIT);
    setId(row?.id);
  };

  const clearErrors = () => {
    setErrorsJsonForm([]);
    setAdditionalErrors([]);
    setValidationMode('ValidateAndHide');
  };

  const back = () => {
    clearErrors();
    updateCrudStates(CrudStatesOptions.LIST);
    setId(-1);
    setFormData({});
    setDisabledFields([]);
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const save = async () => {
    setValidationMode('ValidateAndShow');

    if (errorsJsonForms?.length || additionalErrors?.length) {
      return;
    }

    try {
      if (crudStates.add) {
        const response = await api?.post?.(formData);
        if (response?.status === 201) {
          back();
          showSuccess('Adicionado com sucesso.');
        } else {
          console.error(response);
        }
      } else if (crudStates.edit) {
        const response = await api?.put?.(id, formData);
        if (response?.status === 200) {
          back();
          showSuccess('Editado com sucesso.');
        } else {
          console.error(response);
        }
      }
    } catch (error: any) {
      console.error(error);
      let errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
    }
  };

  const addView = () => {
    updateCrudStates(CrudStatesOptions.ADD);
    setDisabledFields(onlyGrandeArea);
    setFormData({ ...formData, numeroNivel: 1 });
  };

  const addSubArea = async (row: AreaDeConhecimentoModel) => {
    switch (row?.numeroNivel) {
      case 1:
        setDisabledFields(onlyArea);
        setFormData({ ...row, numeroNivel: 2, grandeAreaId: row.id });
        break;
      case 2:
        setDisabledFields(onlySubArea);
        setFormData({ ...row, numeroNivel: 3, areaId: row.id });
        break;
      case 3:
        setDisabledFields(onlyEspecialidade);
        setFormData({ ...row, numeroNivel: 4, subAreaId: row.id });
        break;
    }
    updateCrudStates(CrudStatesOptions.ADD);
  };

  const destroy = async (row: AreaDeConhecimentoModel, setTableData: Function) => {
    try {
      if (row) {
        await api?.delete?.(row?.id).then(() => {
          showSuccess('Desativado com sucesso.');
          updateTable(row, setTableData);
        });
      }
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const restore = async (row: AreaDeConhecimentoModel, setTableData: Function) => {
    try {
      await api?.restore?.(row?.id).then(() => {
        updateTable(row, setTableData);
        showSuccess('Ativado com sucesso.');
      });
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  };

  const onChangeJsonForms = (errorsFromJsonForm: any, dataFromJsonForm: any) => {
    setErrorsJsonForm(errorsFromJsonForm);
    if (dataFromJsonForm && Object.keys(dataFromJsonForm)?.length > 0 && !crudStates.list) {
      setFormData(dataFromJsonForm);
    }
  };

  const getSubArea = async (row: AreaDeConhecimentoModel) => {
    switch (row.numeroNivel) {
      case 1:
        return await api?.buscarAreaPorGrandeAreaId?.(row?.id).catch((err) => {
          console.error(err);
          showError('Erro de conexão');
        });
      case 2:
        return await api?.buscarSubAreaPorAreaId?.(row?.id).catch((err) => {
          console.error(err);
          showError('Erro de conexão');
        });
      case 3:
        return await api?.buscarEspecialidadePorSubAreaId?.(row?.id).catch((err) => {
          console.error(err);
          showError('Erro de conexão');
        });
    }
  };

  const updateTable = async (
    row: AreaDeConhecimentoModel | undefined,
    setTableData: Function | undefined,
  ) => {
    switch (row?.numeroNivel) {
      case 1:
        await api
          ?.buscarGrandesAreas?.()
          .then((data: any) => {
            setTableData?.(data);
          })
          .catch((err) => {
            console.error(err);
            showError('Erro de conexão');
          });
        break;
      case 2:
        await api
          ?.buscarAreaPorGrandeAreaId?.(row?.grandeAreaId)
          .then((data: any) => {
            setTableData?.(data);
          })
          .catch((err) => {
            console.error(err);
            showError('Erro de conexão');
          });
        break;
      case 3:
        await api
          ?.buscarSubAreaPorAreaId?.(row?.areaId)
          .then((data: any) => {
            setTableData?.(data);
          })
          .catch((err) => {
            console.error(err);
            showError('Erro de conexão');
          });
        break;
      case 4:
        await api
          ?.buscarEspecialidadePorSubAreaId?.(row?.subAreaId)
          .then((data: any) => {
            setTableData?.(data);
          })
          .catch((err) => {
            console.error(err);
            showError('Erro de conexão');
          });
        break;
    }
  };

  return (
    <>
      {load && <Loading isLoading={load} />}
      {!load && crudStates.list && (
        <>
          <EnhancedTableToolbar
            title={title}
            addHandler={addView}
            buttonText='Adicionar Área de Conhecimento'
            moduleName={moduleNameFor('area-de-conhecimento')}
          />
          <RecursiveTable
            destroyHandler={destroy}
            data={apiListData}
            addHandler={addSubArea}
            restoreHandler={restore}
            editHandler={editView}
            expandHandler={getSubArea}
          />
        </>
      )}
      {!load && !crudStates.list && (
        <>
          <JsonForm
            uischema={AreaDeConhecimentoUiSchema}
            schema={schema}
            load={load}
            data={formData}
            onChange={({ errors, data }) => onChangeJsonForms(errors, data)}
            additionalErrors={additionalErrors}
            readonly={crudStates.view || false}
            validationMode={validationMode}
          ></JsonForm>
          <Container>
            {crudStates.view && (
              <Tooltip title='Editar'>
                <Button
                  onClick={() => {
                    updateCrudStates(CrudStatesOptions.EDIT);
                  }}
                >
                  <EditIcon></EditIcon>
                  Editar
                </Button>
              </Tooltip>
            )}
            {(crudStates.edit || crudStates.add) && (
              <SaveButton onClick={save}>
                <DoneIcon></DoneIcon>
                Salvar
              </SaveButton>
            )}
          </Container>
        </>
      )}
      <AlertCustom
        open={openAlert}
        severity={severityAlert}
        message={messageAlert}
        handleClose={handleCloseAlert}
      ></AlertCustom>
    </>
  );
}

export default AreaDeConhecimento;
