import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { FocusEvent, useEffect, useRef } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import client from '../../config/axios-config';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const cepRenderTester = rankWith(5, uiTypeIs('Cep'));

export const cepRender = {
  tester: cepRenderTester,
  renderer: withJsonFormsControlProps(({ data, path, label, enabled, ...props }) => {
    const fieldRef = useRef<HTMLDivElement>(null);
    const uischema = props.uischema as IControlElement;

    useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        if (childNode.nodeType === 1 && (childNode as HTMLElement).tagName === 'INPUT') {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && data !== undefined) {
            inputElement.value = data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
    }, [data]);

    const ctx = useCrudContext();
    const { isRequiredField, validationMode, crudStates, disabledFields } = ctx;

    const isDisabled: boolean =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const required = uischema.options?.required ?? false;

    const handleChange = async (event: FocusEvent<HTMLInputElement>) => {
      const cep = event?.target?.value;
      props.handleChange(path, cep);
      if (cep?.replace?.(/\D/g, '')?.length === 8) {
        const response = await client.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { logradouro, bairro, localidade, uf } = response.data;
        const relativePath = path.split('.').slice(0, -1).join('.');
        props.handleChange(`${relativePath}.logradouro`, logradouro || '');
        props.handleChange(`${relativePath}.bairro`, bairro || '');
        props.handleChange(`${relativePath}.municipio`, localidade || '');
        props.handleChange(`${relativePath}.estado`, uf || '');
      }
    };

    return (
      <Hidden xsUp={!props.visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            size='small'
            className={`${isDisabled ? 'disabled-field' : ''}
              ${validationMode === 'ValidateAndShow' && props.errors ? 'has-error' : ''}
            `}
            label={label}
            required={isRequiredField(required, ctx)}
            disabled={isDisabled}
            defaultValue={data ?? ''}
            inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
            onBlur={handleChange}
            InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
