import styled from '@emotion/styled';

export const ContentWrapper = styled.div`
  width: 95%;
  height: 100%;
  max-width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1920px) {
    width: 100%;
  }
`;
