import first from 'lodash/first';
import client from '../../../config/axios-config';
import { CrudContextData } from '../../../jsonforms/uischema';

export const atualizarEditarPerfilHandler = async (
  { paths }: any,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback: any,
): Promise<boolean> => {
  const { showError, formData } = ctx;

  const newPaths = paths
    .map((path: string) => first(path?.split('.')))
    .filter((path: string, index: number, arr: string[]) => arr.indexOf(path) === index);

  const currentData = Object.entries(formData).reduce((acc: any, [key, value]) => {
    if (newPaths.includes(key)) acc[key] = value;
    return acc;
  }, {});

  try {
    const response = await client.put('/usuario/editar-perfil', currentData);
    if (response.status === 200) {
      if (callback) return callback(ctx);
      return true;
    } else {
      showError('Ocorreu um erro');
      return false;
    }
  } catch (error: any) {
    let message = error.response.data.message || 'Ocorreu um erro';
    if (Array.isArray(message)) message = treatMessageErrorVinculoEmpregaticio(message);
    if (message.startsWith('endereco.')) message = message.replace('endereco.', '');
    if (message.startsWith('vinculoInstitucional.'))
      message = message.replace('vinculoInstitucional.', '');
    showError(message);
    return false;
  }
};

const treatMessageErrorVinculoEmpregaticio = (messages: string[]) => {
  if (messages.length === 1) return messages[0];
  if (messages.includes('vinculoInstitucional.Tipo de vínculo institucional é obrigatório.')) {
    return 'Tipo de vínculo institucional é obrigatório';
  } else if (messages.includes('vinculoInstitucional.Início de serviço é obrigatório')) {
    return 'Início de serviço é obrigatório';
  } else if (messages.includes('vinculoInstitucional.Regime de trabalho é obrigatório')) {
    return 'Regime de trabalho é obrigatório';
  } else if (messages.includes('vinculoInstitucional.Função é obrigatório')) {
    return 'Função é obrigatório';
  } else if (messages.includes('vinculoInstitucional.Início de função é obrigatório')) {
    return 'Início de função é obrigatório';
  } else {
    return messages[0];
  }
};
