import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { TipoEvento } from '../../../models/tipo-evento';

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.TIPO_EVENTO';

export const moduleLabel = 'Tipos de Eventos';

export const headCells: readonly HeadCell<TipoEvento>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Tipos de Eventos',
  },
];
