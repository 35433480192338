import { IUiSchema } from '../../../jsonforms/uischema';

const bancoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/codigo',
      label: 'Código',
      options: {
        required: true,
      },
    },
    {
      type: 'Control',
      scope: '#/properties/nome',
      options: {
        required: true,
      },
    },
  ],
};

export default bancoUiSchema;
