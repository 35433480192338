import { HeadCell } from '../../components/crud/protocols/head-cell';
import { Instituicao } from '../../models/instituicao';

export const moduleName = 'INSTITUICAO.INSTITUICAO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Instituição';

export const headCells: readonly HeadCell<Instituicao>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
  {
    id: 'paisId',
    numeric: false,
    disablePadding: false,
    label: 'País',
    resource: {
      name: 'pais',
      target: 'nomePt',
    },
  },
];
