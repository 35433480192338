import cloneDeep from 'lodash/cloneDeep';
import { CrudContextData } from '../../jsonforms/uischema';

class ErrorsManager {
  constructor(private readonly ctx: CrudContextData) {}

  // Atualiza o additionalErrors com os errorsCustom que estão com erro
  addCustomErrors = () => {
    const { errorsCustom, formData } = this.ctx;

    if (!errorsCustom || errorsCustom.length <= 0) {
      return errorsCustom;
    }
    const errorsToAdd = [];
    for (const customError of errorsCustom) {
      let valueForValidate = formData?.[customError?.field];
      if (customError?.field?.includes?.('.')) {
        const tokens = customError?.field?.split?.('.');
        let valueNested = formData;
        for (const token of tokens) {
          valueNested = valueNested?.[token];
        }
        valueForValidate = valueNested;
      }
      const hasError = customError?.hasError?.(valueForValidate, formData);
      if (hasError) {
        errorsToAdd.push(customError?.error);
      }
    }
    if (errorsToAdd.length) {
      this.insertAdditionalErrors(errorsToAdd);
    }
    return errorsToAdd;
  };

  insertAdditionalErrors = (errorsToAdd: any[]) => {
    const { additionalErrors, setAdditionalErrors } = this.ctx;

    const errorsToInsert = cloneDeep(additionalErrors);
    for (const errorToAdd of errorsToAdd) {
      if (!this.hasInsertedError(additionalErrors, errorToAdd)) {
        errorsToInsert.push(errorToAdd);
      }
    }
    setAdditionalErrors(errorsToInsert);
  };

  removeAdditionalErrors = (errorsToRemove: any[]) => {
    const { additionalErrors, setAdditionalErrors } = this.ctx;

    let additionalErrorsCopy = cloneDeep(additionalErrors);
    for (const errorToRemove of errorsToRemove) {
      additionalErrorsCopy = additionalErrorsCopy.filter(
        (error) =>
          error?.keyword !== errorToRemove?.keyword &&
          error?.propertyName !== errorToRemove?.propertyName,
      );
    }
    setAdditionalErrors(additionalErrorsCopy);
  };

  hasInsertedError = (errors: any[], errorToInsert: any) => {
    return errors?.find?.(
      (error: any) =>
        error.keyword === errorToInsert?.keyword &&
        (error?.field === errorToInsert?.field ||
          (error?.propertyName && error?.propertyName === errorToInsert?.propertyName) ||
          (error?.params?.missingProperty &&
            error?.params?.missingProperty === errorToInsert?.params?.missingProperty) ||
          (error?.instancePath && error?.instancePath === errorToInsert?.instancePath)),
    );
  };

  removeCustomErrors = () => {
    const { formData, errorsCustom } = this.ctx;

    // Remove os custom errors
    if (errorsCustom && errorsCustom.length > 0) {
      const errorsToRemove = [];
      for (const customError of errorsCustom) {
        let valueForValidate = formData?.[customError?.field];
        if (customError?.field?.includes?.('.')) {
          const tokens = customError?.field?.split?.('.');
          let valueNested = formData;
          for (const token of tokens) {
            valueNested = valueNested?.[token];
          }
          valueForValidate = valueNested;
        }
        const hasError = customError?.hasError?.(valueForValidate, formData);
        if (!hasError) {
          errorsToRemove.push(customError?.error);
        }
      }
      if (errorsToRemove.length) this.removeAdditionalErrors(errorsToRemove);
    }
  };

  clearErrors = () => {
    const { setErrorsJsonForm, setAdditionalErrors, setValidationMode } = this.ctx;

    setErrorsJsonForm([]);
    setAdditionalErrors([]);
    setValidationMode('ValidateAndHide');
  };
}

export const makeErrorsManager = (ctx: CrudContextData) => {
  return new ErrorsManager(ctx);
};
