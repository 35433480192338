import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import { ChangeEvent, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { IControlElement } from '../uischema';

const colorPickerTester = rankWith(5, uiTypeIs('Color'));

export const FileRender = {
  tester: colorPickerTester,
  renderer: withJsonFormsControlProps(({ schema, data, path, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const [value, setValue] = useState<string>(data ?? '');
    const { crudStates, disabledFields } = useCrudContext();

    const isDisabled =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      setValue(value);
      props.handleChange(path, value);
    };

    return (
      <Hidden xsUp={!props.visible}>
        <Box sx={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
          <span>{props.label}: </span>
          <input type='color' onChange={handleChange} value={value} disabled={isDisabled} />
        </Box>
      </Hidden>
    );
  }),
};
