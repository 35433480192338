import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import PropostaConviteMembros from '../../components/proposta/proposta-convite-membros/proposta-convite-membros';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta';
import {
  AccordionContainer,
  ConvitesBox,
  ConvitesHeader,
  ConvitesHeaderLabel,
  FooterBox,
  FooterButton,
  QuantidadeConvitesLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiversity3,
  StyledExpandMoreIcon,
  StyledMarkEmailUnread,
  TodosConvitesAnchor,
} from './style/convites-home';

type ConvitesHomeProps = {
  onValidate: (isValid: boolean) => void;
  splitLength?: number;
};

export const ConvitesHome = ({ onValidate, splitLength }: ConvitesHomeProps) => {
  const navigate = useNavigate();
  const [apiPropostaResult, setApiPropostaResult] = useState<{
    count: number;
    data: PropostaConviteMembro[];
  }>();
  const [load, setLoad] = useState<boolean>(true);
  const propostaConviteMembroApi = makePropostaConviteMembroApi();

  useEffect(() => {
    loadConvites();
  }, []);

  async function loadConvites() {
    propostaConviteMembroApi
      .getAllReceived({
        situacao: TipoSituacao.pendente,
        take: 2,
      })
      .then((resultData) => {
        setApiPropostaResult(resultData);
        setLoad(false);

        const hasConvites = !isEmpty(resultData) && resultData.count > 0;
        onValidate(hasConvites);
      });
  }

  if (isEmpty(apiPropostaResult) || apiPropostaResult?.count === 0) {
    return null;
  }

  return (
    <ConvitesBox>
      <ConvitesHeader>
        <StyledMarkEmailUnread />
        <ConvitesHeaderLabel>Convites</ConvitesHeaderLabel>
      </ConvitesHeader>
      <StyledAccordion defaultExpanded>
        <AccordionContainer>
          <StyledAccordionSummary
            expandIcon={<StyledExpandMoreIcon />}
            aria-controls='convitesAccordion'
            id='convitesAccordion-header'
          >
            <StyledDiversity3 />
            Participação de Projetos
            <QuantidadeConvitesLabel align='center'>
              {apiPropostaResult ? `•  ${apiPropostaResult.count} Pendentes` : ``}
            </QuantidadeConvitesLabel>
          </StyledAccordionSummary>
        </AccordionContainer>
        <StyledAccordionDetails>
          <PropostaConviteMembros
            apiResultData={apiPropostaResult.data}
            propostaConviteMembroApi={propostaConviteMembroApi}
            loadState={{ load, setLoad }}
            loadConvites={loadConvites}
            splitLength={splitLength}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
      <FooterBox>
        <FooterButton>
          <TodosConvitesAnchor onClick={() => navigate('/todos-convites')}>
            Ver Mais ({apiPropostaResult.count})
          </TodosConvitesAnchor>
        </FooterButton>
      </FooterBox>
    </ConvitesBox>
  );
};
