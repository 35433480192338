import { FormApi, GenericApi, IGenericApiOptions } from './generic-api';
import client from '../config/axios-config';
import { JsonSchema } from '@jsonforms/core';
import { Proposta } from '../models/proposta';
import { sortRelationBy } from '../helpers/sort-relation-by';

export interface IPropostaApi extends FormApi {
  getSchema(): Promise<JsonSchema | undefined>;
}

export class PropostaApi extends GenericApi<Proposta> implements IPropostaApi {
  constructor(
    public readonly editalId: number,
    public readonly url: string,
    public readonly apiOptions?: IGenericApiOptions<Proposta>,
  ) {
    super(url, apiOptions);
  }

  async get(id: number): Promise<Proposta> {
    console.log('teste minhas propostas');
    try {
      const response = await client.get<Proposta>(`${this.url}/minhas-propostas/${id}`);
      return sortRelationBy(response.data, this.apiOptions?.orderRelationBy ?? {});
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async getAll(options?: { [key: string]: any }): Promise<any> {
    try {
      return (await this.getAllForPagination(options)).data;
    } catch (e) {
      return [];
    }
  }

  async getAllForPagination(options?: {
    [key: string]: any;
  }): Promise<{ data: Proposta[]; count: number }> {
    try {
      return await this.getAllForPagination(options);
    } catch (e) {
      return { data: [], count: 0 };
    }
  }

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(`${this.url}/schema?editalId=${this.editalId}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }

  async buscarMinhasPropostas(
    options: { [key: string]: any } = { withDeleted: false },
  ): Promise<any> {
    try {
      const urlBuild = Object.entries(options).reduce(
        (acc, [chave, valor], i) => {
          return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
        },
        `${this.url}${
          this.apiOptions?.customPaths?.['buscarMinhasPropostas'] ?? '/minhas-propostas'
        }`,
      );
      const response = await client.get<{ data: Proposta[] }>(urlBuild);

      return response?.data?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }
}

export const makePropostaApi = (editalId?: number, apiOptions?: IGenericApiOptions<Proposta>) => {
  return new PropostaApi(editalId, '/proposta', apiOptions);
};
