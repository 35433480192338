import { HeadCell } from '../../../components/crud/protocols/head-cell';
import {
  PerguntaAvaliacao,
  TipoRespostaPerguntaAvaliacao,
} from '../../../models/pergunta-avaliacao';

export const moduleName = 'AVALIACAO.PERGUNTA_AVALIACAO.PERGUNTA_AVALIACAO';

export const displayProperties = ['pergunta'];

export const moduleLabel = 'Pergunta Avaliação';

export const headCells: readonly HeadCell<PerguntaAvaliacao>[] = [
  {
    id: 'pergunta',
    numeric: false,
    disablePadding: false,
    label: 'Pergunta',
  },
  {
    id: 'tipoResposta',
    numeric: false,
    disablePadding: false,
    label: 'Tipo da resposta',
    computedRowLabel: (row) => {
      const tipoResposta = row.tipoResposta;

      switch (tipoResposta) {
        case TipoRespostaPerguntaAvaliacao.nota:
          return 'Nota';
        case TipoRespostaPerguntaAvaliacao.multiplaEscolha:
          return 'Múltipla Escolha';
        case TipoRespostaPerguntaAvaliacao.descritiva:
          return 'Descritiva';
        default:
          return '-';
      }
    },
  },
];
