import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { FuncaoParticipante } from '../../../models/funcao-participante';

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.FUNCAO_PARTICIPANTE';

export const moduleLabel = 'Funções Participantes';

export const headCells: readonly HeadCell<FuncaoParticipante>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Funções Participantes',
  },
];
