import { HeadCell } from '../../components/crud/protocols/head-cell';
import { Edital } from '../../models/edital';

export const displayProperties = ['nome'];

export const moduleLabel = 'Publicar Edital';

export const moduleName = 'EDITAL.EDITAL';

export const headCells: readonly HeadCell<Edital>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
