import styled from '@emotion/styled';
import Loading from '../loading';

export const StyledLoading = styled(Loading)`
  height: 100vh !important;
`;

export const Form = styled.div`
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  width: 95%;
  max-width: 1750px;
  margin-top: 2rem;
  height: 100%;

  @media (max-width: 1920px) {
    width: 85%;
    margin-top: 1rem;
  }

  @media (max-width: 1440px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
`;

export const Loader = styled.div`
  height: 100vh !important;
`;
