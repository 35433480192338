import axios from 'axios';

const fileClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_ADDR}${process.env.REACT_APP_API_PATH_PREFIX ?? ''}/`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

fileClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default fileClient;
