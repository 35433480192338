import { JsonSchema } from '@jsonforms/core';
import { AxiosResponse } from 'axios';
import client from '../config/axios-config';
import { OrderTypeBy, sortRelationBy } from '../helpers/sort-relation-by';

export interface FormApi<T = any> {
  get url(): string;
  getSchema: () => Promise<JsonSchema | undefined>;
  get: (id: number) => Promise<any>;
  getAll: (options?: { [key: string]: any }) => Promise<any>;
  getAllForPagination: (options?: { [key: string]: any }) => Promise<any>;
  put: (id: number, data: any) => Promise<any>;
  post: (data: any) => Promise<any>;
  delete: (id: number) => Promise<any>;
  restore: (id: number) => Promise<any>;
}

export interface IGenericApiOptions<T> {
  customPaths?: {
    [key: string]: string;
  };
  orderRelationBy?: OrderTypeBy<T>;
}

export class GenericApi<T> implements FormApi<T> {
  constructor(
    public readonly url: string,
    public readonly apiOptions?: IGenericApiOptions<T>,
  ) {}

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(`${this.url}/schema`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }

  async get(id: number): Promise<T> {
    try {
      const response = await client.get<T>(`${this.url}/${id}`);
      return sortRelationBy(response.data, this.apiOptions?.orderRelationBy ?? {});
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async getAll(options: { [key: string]: any } = { withDeleted: false }): Promise<T[]> {
    return (await this.getAllForPagination(options)).data;
  }

  async getAllForPagination(
    options: { [key: string]: any } = { withDeleted: false },
  ): Promise<{ data: T[]; count: number }> {
    try {
      const urlBuild = Object.entries(options).reduce(
        (acc, [chave, valor], i) => {
          return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
        },
        `${this.url}${this.apiOptions?.customPaths?.['getAll'] ?? ''}`,
      );
      const response = await client.get<{ data: T[]; count: number }>(urlBuild);

      return response?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }

  async restore(id: number): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.put<T>(`${this.url}/restore/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Restore error: ', { cause: error });
    }
  }

  async put(id: number, data: any): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.put<T>(
        `${this.url}/${id}${this.apiOptions?.customPaths?.['put'] ?? ''}`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }

  async post(data: any): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.post<T>(`${this.url}`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Post error: ', { cause: error });
    }
  }

  async delete(id: number): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.delete<T>(`${this.url}/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Delete error: ', { cause: error });
    }
  }
}

export const makeApi = <T>(url: string, apiOptions?: IGenericApiOptions<T>) => {
  return new GenericApi<T>(url, apiOptions);
};
