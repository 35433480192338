import { rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsStateContext, useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { ProducaoProposta, TipoProducao, tipoProducaoI18N } from '../../models/proposta';
import './renderer.css';
import {
  StyledPaper,
  StyledTable,
  StyledTextField,
  StyledTextOneField,
  TableLayoutCell,
  TableLayoutHeader,
  TableLayoutHeaderCell,
  TableLayoutNameHeaderCell,
  TableLayoutRow,
  TableLayoutTwoColumnsHeaderCell,
  TableQuantityCell,
  TableTwoQuantityCell,
} from './styled/indicador-producao-proposta-renderer';

const indicadorProducaoPropostaTester = rankWith(5, uiTypeIs('IndicadorProducaoProposta'));

export const IndicadorProducaoPropostaRender = {
  tester: indicadorProducaoPropostaTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, data, handleChange, path, schema, enabled, ...props }) => {
      const {
        core: { data: formData },
      }: JsonFormsStateContext = useJsonForms();

      const calcularQtdProducao = (newData: ProducaoProposta[]) =>
        newData.reduce((acc, item: ProducaoProposta) => {
          if (!item.tipoProducao) {
            acc[item.producaoId] = item.quantidade ?? 0;
          } else {
            if (!acc[item.producaoId]) {
              acc[item.producaoId] = { [item.tipoProducao]: item.quantidade };
            } else {
              acc[item.producaoId][item.tipoProducao] = item.quantidade;
            }
          }
          return acc;
        }, {});
      const typedData = data as ProducaoProposta[];

      type QuantidadePorProducao = {
        [key: number]: number | { [key: string]: number };
      };
      const [qtdProducao, setQtdProducao] = useState<QuantidadePorProducao>(
        calcularQtdProducao(typedData ?? []),
      );

      const handleQuantityChange = (e, producaoId, tipoProducao = undefined) => {
        const quantidade = parseInt(e.target.value);

        const newData = (typedData ?? []).filter(
          (item: ProducaoProposta) =>
            item.producaoId !== producaoId || item.tipoProducao !== tipoProducao,
        );
        const producao: Partial<ProducaoProposta> = {
          producaoId,
          quantidade,
        };
        if (tipoProducao) {
          producao.tipoProducao = tipoProducao;
        }
        handleChange(path, [...newData, producao]);
        setQtdProducao(calcularQtdProducao(newData));
      };

      return (
        <StyledPaper>
          <StyledTable>
            {formData?.edital?.indicadorProducaoEdital?.map((indicador, index) => (
              <React.Fragment key={`indicador-producao-${index}`}>
                <TableLayoutHeader>
                  <TableRow>
                    <TableLayoutNameHeaderCell>
                      {indicador.indicadorProducao.nome}
                    </TableLayoutNameHeaderCell>
                    {indicador.indicadorProducao.nacionalInternacional ? (
                      <>
                        <TableLayoutTwoColumnsHeaderCell rowSpan={2}>
                          Quantidade
                          <TableLayoutHeaderCell colSpan={2}>
                            {tipoProducaoI18N.NACIONAL}
                          </TableLayoutHeaderCell>
                          <TableLayoutHeaderCell>
                            {tipoProducaoI18N.INTERNACIONAL}
                          </TableLayoutHeaderCell>
                        </TableLayoutTwoColumnsHeaderCell>
                      </>
                    ) : (
                      <TableLayoutHeaderCell>Quantidade</TableLayoutHeaderCell>
                    )}
                  </TableRow>
                </TableLayoutHeader>

                <TableBody>
                  {indicador.indicadorProducao.producao.map(({ texto, id }, prodId) => (
                    <TableLayoutRow key={`producao-${index}-${prodId}`}>
                      <TableLayoutCell>{texto}</TableLayoutCell>
                      {indicador.indicadorProducao.nacionalInternacional ? (
                        <>
                          <TableTwoQuantityCell colSpan={2}>
                            <StyledTextField
                              key={`nacional-${id}`}
                              size='small'
                              type='number'
                              value={qtdProducao[id]?.[TipoProducao.nacional]}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    padding: '0px',
                                  },
                                },
                                style: { height: '30px' },
                              }}
                              onChange={(e) => {
                                handleQuantityChange(e, id, TipoProducao.nacional);
                              }}
                            />
                            <StyledTextField
                              key={`internacional-${id}`}
                              size='small'
                              type='number'
                              value={qtdProducao[id]?.[TipoProducao.internacional]}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    padding: '0px',
                                  },
                                },
                                style: { height: '30px' },
                              }}
                              onChange={(e) => {
                                handleQuantityChange(e, id, TipoProducao.internacional);
                              }}
                            />
                          </TableTwoQuantityCell>
                        </>
                      ) : (
                        <TableQuantityCell>
                          <StyledTextOneField
                            key={`neutro-${id}`}
                            size='small'
                            type='number'
                            value={qtdProducao[id]}
                            InputProps={{
                              inputProps: {
                                style: {
                                  padding: '0px',
                                },
                              },
                              style: { height: '30px' },
                            }}
                            onChange={(e) => {
                              handleQuantityChange(e, id);
                            }}
                          />
                        </TableQuantityCell>
                      )}
                    </TableLayoutRow>
                  ))}
                </TableBody>
              </React.Fragment>
            ))}
          </StyledTable>
        </StyledPaper>
      );
    },
  ),
};
