import { JsonSchema, or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { DatePicker, DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import React from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';
import { StyledTitle } from './styled/date-render';
import { getParentSchema } from '../../helpers/get-parent-schema';

const dateRenderTester = rankWith(
  6,
  or(
    schemaMatches((schema) => schema.format === 'Date'),
    uiTypeIs('Date'),
  ),
);

export const dateRender = {
  tester: dateRenderTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, path, rootSchema, schema, enabled, ...props }) => {
      const uischema = props.uischema as IControlElement;
      const fieldRef = React.useRef<HTMLDivElement>(null);
      const ctx = useCrudContext();
      const { isRequiredField, validationMode, crudStates, disabledFields } = ctx;

      const [value, setValue] = React.useState<string | null>(props.data ?? null);
      const [valueFormatted, setValueFormatted] = React.useState<Dayjs | null>(null);
      const dateFormatProperty = uischema?.options?.dateFormat;

      const getFormatDateString = (format: string) => {
        let formatDate = [];
        if (format.includes('YYYY')) {
          formatDate.push('YYYY');
        }
        if (format.includes('MM')) {
          formatDate.push('MM');
        }
        if (format.includes('DD')) {
          formatDate.push('DD');
        }
        if (formatDate.length === 0) {
          return 'YYYY-MM-DD';
        } else {
          return formatDate.join('-');
        }
      };

      React.useEffect(() => {
        // Carrega o Default quando não começa com valor
        if (!value) {
          const dateDefaultProperty = uischema?.options?.dateDefault;
          if (!dateDefaultProperty) return;
          const defaultValue =
            dateDefaultProperty === 'now' ? dayjs() : dayjs(dateDefaultProperty, 'YYYY-MM-DD');
          setValueFormatted(defaultValue);
          handleChange(defaultValue);
        }

        if (value) {
          const newValue = dayjs(
            value,
            dateFormatProperty
              ? getFormatDateString(dateFormatProperty.toUpperCase())
              : 'YYYY-MM-DD',
          );
          setValueFormatted(newValue);
          handleChange(newValue);
        }
      }, [value]);

      React.useEffect(() => {
        fieldRef?.current?.childNodes?.forEach?.((childNode) => {
          const node = childNode as HTMLElement;
          const htmlInput = node.children?.[1]?.children?.[0];
          if (
            childNode.nodeType === 1 &&
            (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
          ) {
            const inputElement = childNode as HTMLInputElement;
            if (inputElement && props.data !== undefined) {
              inputElement.value = props.data;
            } else if (inputElement) {
              inputElement.value = '';
            }
          }
        });
      }, [props.data]);

      const isDisabled: boolean =
        !enabled ||
        crudStates.view ||
        uischema?.options?.disabled ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        disabledFields.includes(path);

      const required = uischema.options?.required ?? false;

      const handleChange = (value: Dayjs | null) => {
        const strVal = value?.format(
          dateFormatProperty
            ? dateFormatProperty.toUpperCase().length < 10
              ? dateFormatProperty.toUpperCase()
              : 'YYYY-MM-DD'
            : 'YYYY-MM-DD',
        );
        setValue(strVal ?? null);
        props.handleChange(path, strVal);
      };

      const getViews = (dateFormat: string): DateView[] | undefined => {
        let views: DateView[] | undefined = [];
        if (dateFormat.includes('DD')) {
          views?.push('day');
        }
        if (dateFormat.includes('MM')) {
          views?.push('month');
        }
        if (dateFormat.includes('YYYY')) {
          views?.push('year');
        }
        if (views.length % 3 === 0) {
          views = undefined;
        }
        return views;
      };

      return (
        <Hidden xsUp={!visible}>
          <div className='custom-input-container' ref={fieldRef}>
            {schema?.title && (
              <StyledTitle>{`${schema?.title}${getParentSchema(rootSchema, path)?.required?.includes(path.split('.').pop()) ? '*' : ''}`}</StyledTitle>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
              <DatePicker
                onChange={handleChange}
                defaultValue={valueFormatted}
                value={valueFormatted}
                disabled={isDisabled}
                className={`${isDisabled && 'disabled-field'}
                          ${validationMode === 'ValidateAndShow' && props.errors && 'has-error'}
              `}
                slots={{ openPickerIcon: CalendarTodayOutlinedIcon }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: isRequiredField(required, ctx),
                    InputProps: {
                      disableUnderline: true,
                    } as Partial<OutlinedInputProps>,
                  },
                }}
                label={schema?.title ? 'Data' : props.label}
                format={dateFormatProperty?.toUpperCase() ?? 'DD/MM/YYYY'}
                views={dateFormatProperty ? getViews(dateFormatProperty.toUpperCase()) : undefined}
              />
            </LocalizationProvider>
            {validationMode === 'ValidateAndShow' && props.errors && (
              <span className='error-message'>{getFirstError(props.errors)}</span>
            )}
          </div>
        </Hidden>
      );
    },
  ),
};
