import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
}

export const displayProperties = ['usuario.nome'];

export const moduleLabel = 'Convite de Participantes';

export const moduleName = 'EDITAL.EDITAL_CONVITE_PARTICIPANTE.EDITAL_CONVITE_PARTICIPANTE';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'usuario.nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
