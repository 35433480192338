export enum TipoParceria {
  concedente = 'CONCEDENTE',
  convenente = 'CONVENENTE',
  executor = 'EXECUTOR',
  interveniente = 'INTERVENIENTE',
  participe = 'PARTICIPE',
  proponente = 'PROPONENTE',
}

export type TipoParceriaI18N = {
  [key in TipoParceria]: string;
};

export const tipoParceriaI18N: TipoParceriaI18N = {
  [TipoParceria.concedente]: 'Concedente',
  [TipoParceria.convenente]: 'Convenente',
  [TipoParceria.executor]: 'Executor',
  [TipoParceria.interveniente]: 'Interveniente',
  [TipoParceria.participe]: 'Participe',
  [TipoParceria.proponente]: 'Proponente',
};
