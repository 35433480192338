import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { NavigateFunction } from 'react-router-dom';
import { FormApi } from '../../api/generic-api';
import { circleFlagBuilder } from '../../components/circle-flag';
import { AcademicCap, Attachment, BriefcaseFill, Home, User } from '../../components/styled-icons';
import {
  CrudContextData,
  CrudStatesOptions,
  IUiSchema,
  hideIfBrasil,
  showIfBrasil,
} from '../../jsonforms/uischema';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { AuthContextData } from '../login/auth/auth-context';
import { atualizarEditarPerfilHandler } from './handlers/atualizar-editar-perfil-handler';
import {
  setLabelWhenChangeDateFuncao,
  setLabelWhenChangeDateServico,
} from './handlers/set-label-when-change-date';

const iconConfig = [
  {
    category: 'Dados Pessoais',
    icon: User,
  },
  {
    category: 'Endereço',
    icon: Home,
  },
  {
    category: 'Dados Acadêmicos',
    icon: AcademicCap,
  },
  {
    category: 'Dados Profissionais',
    icon: BriefcaseFill,
  },
  {
    category: 'Documentos Pessoais',
    icon: Attachment,
  },
];

const areaDeConhecimentoHandleChange = async (
  item: any,
  handler: (path: string, value: any) => void,
  formData: any,
  ctx?: CrudContextData,
  path?: string,
) => {
  const pathToSet = path?.split('.').slice(0, -1).join('.') || '';
  if (pathToSet && item?.id) handler(`${pathToSet}.id`, item?.id);

  if (!item) {
    let lastId = null;
    const areaConhecimento =
      path
        ?.split('.')
        .slice(0, -1)
        .reduce((acc, curr) => acc && acc[curr], formData) || '';
    const element = path?.split('.').pop();

    if (areaConhecimento) {
      if (element !== 'especialidadeId' && areaConhecimento.especialidadeId) {
        lastId = areaConhecimento.especialidadeId;
      } else if (element !== 'subAreaId' && areaConhecimento.subAreaId) {
        lastId = areaConhecimento.subAreaId;
      } else if (element !== 'areaId' && areaConhecimento.areaId) {
        lastId = areaConhecimento.areaId;
      } else if (element !== 'grandeAreaId' && areaConhecimento.grandeAreaId) {
        lastId = areaConhecimento.grandeAreaId;
      }
    }

    if (lastId) {
      handler(`${pathToSet}.id`, lastId);
    }
  }
};

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const showIfPossuiVinculoInstitucional: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/possuiVinculoInstitucional',
    schema: {
      const: true,
    },
  } as Condition,
};

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const editarPerfilUiSchema: IUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados Pessoais',
      options: {
        stepHandler: {
          handler: atualizarEditarPerfilHandler,
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/nome',
                  label: 'Nome',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/nomeSocial',
                  label: 'Nome social',
                },
                {
                  type: 'Date',
                  scope: '#/properties/dataNascimento',
                  label: 'Data de nascimento',
                  options: {
                    dateFormat: 'DD/MM/YYYY',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/email',
                  label: 'E-mail',
                  options: {
                    mask: 'email',
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/racaCorId',
                  label: 'Raça/Cor',
                },
                {
                  type: 'Select',
                  scope: '#/properties/paisId',
                  label: 'País',
                  options: {
                    required: true,
                    handleChange: (
                      item: any,
                      handler: (path: string, value: any) => void,
                      formData: any,
                    ) => {
                      if (
                        formData?.['paisId-codigo'] &&
                        !['brasil'].includes(formData?.['paisId-codigo'])
                      ) {
                        handler('documento', '');
                      }
                    },
                    iconBuilder: circleFlagBuilder,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/documento',
                  label: 'CPF',
                  rule: showIfBrasil,
                  options: {
                    mask: '999.999.999-99',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Endereço',
      options: {
        additionalPaths: ['paisId'],
        stepHandler: {
          handler: atualizarEditarPerfilHandler,
          callback: (ctx: CrudContextData) => {
            const { formData, showError } = ctx;
            if (!formData?.endereco) {
              showError('Preencha o endereço antes de prosseguir.');
              return false;
            }
            return true;
          },
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Cep',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'CEP',
                  rule: showIfBrasil,
                  options: {
                    mask: '99999-999',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'ZipCode',
                  rule: hideIfBrasil,
                  options: {
                    mask: '999999999',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/logradouro',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/numero',
                  label: 'Número',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/complemento',
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/bairro',
                  label: 'Bairro',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/municipio',
                  label: 'Município',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/estado',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/estado',
                  label: 'Estado/Região',
                  rule: hideIfBrasil,
                  options: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Dados Acadêmicos',
      options: {
        stepHandler: {
          handler: atualizarEditarPerfilHandler,
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/instituicaoId',
                  label: 'Instituição',
                },
                {
                  type: 'Select',
                  scope: '#/properties/unidadeId',
                  label: 'Unidade',
                  options: {
                    filter: {
                      formFields: ['instituicaoId'],
                      handler: (formData: any, listData: any[], api: FormApi) => {
                        if (!formData.instituicaoId) return Promise.resolve([]);
                        return api.getAll({ instituicaoId: formData?.instituicaoId });
                      },
                    },
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/nivelAcademicoId',
                  label: 'Nível Acadêmico',
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/lattes',
                  label: 'Currículo Lattes',
                },
                {
                  type: 'Control',
                  scope: '#/properties/linkedin',
                  label: 'Linkedin',
                },
                {
                  type: 'Control',
                  scope: '#/properties/interesseConsultor',
                  label: 'Interesse em ser consultor?',
                },
              ],
            },
          ],
        },
        {
          type: 'Control',
          scope: '#/properties/areaDeConhecimento',
          label: 'Suas Áreas de Conhecimento',
          options: {
            label: 'Área de Conhecimento',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/grandeAreaId',
                  label: 'Grande área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    handleChange: areaDeConhecimentoHandleChange,
                    displayProperties: ['grandeAreaNome'],
                    filter: {
                      handler: (formData: any, listData: any[], api: AreaDeConhecimentoApi) => {
                        return api.buscarGrandesAreas();
                      },
                    },
                    disableHandler: disableIfNoOptions,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/areaId',
                  label: 'Área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    handleChange: areaDeConhecimentoHandleChange,
                    displayProperties: ['areaNome'],
                    filter: {
                      formFields: ['grandeAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/subAreaId',
                  label: 'Sub-área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    displayProperties: ['subAreaNome'],
                    handleChange: areaDeConhecimentoHandleChange,
                    filter: {
                      formFields: ['areaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/especialidadeId',
                  label: 'Especialidade',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    displayProperties: ['especialidadeNome'],
                    handleChange: areaDeConhecimentoHandleChange,
                    filter: {
                      formFields: ['subAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarEspecialidadePorSubAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Dados Profissionais',
      options: {
        stepHandler: {
          handler: atualizarEditarPerfilHandler,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/possuiVinculoInstitucional',
              label: 'Possuo vínculo institucional',
            },
            {
              type: 'VerticalLayout',
              rule: showIfPossuiVinculoInstitucional,
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/vinculoInstitucional/properties/tipoVinculoInstitucionalId',
                  label: 'Tipo de vínculo',
                  options: {
                    required: (ctx: CrudContextData) => {
                      const { formData } = ctx;
                      return formData.possuiVinculoInstitucional;
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/possuiVinculoEmpregaticio',
                  label: 'Possuo vínculo empregatício',
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/vinculoInstitucional/properties/inicioServico',
                      label: 'Início de Serviço',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'Label',
                      scope: '#/properties/vinculoInstitucional/properties/tempoServico',
                      label: 'Tempo de Serviço',
                      options: {
                        formatOptionLabel: setLabelWhenChangeDateServico,
                        componentProps: {
                          variant: 'h5',
                          align: 'left',
                          sx: {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'Select',
                  scope: '#/properties/vinculoInstitucional/properties/regimeTrabalhoId',
                  label: 'Regime de Trabalho',
                  options: {
                    required: (ctx: CrudContextData) => {
                      const { formData } = ctx;
                      return formData.possuiVinculoEmpregaticio;
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/vinculoInstitucional/properties/funcao',
                  label: 'Função/Cargo',
                  options: {
                    required: (cxt: CrudContextData) => {
                      const { formData } = cxt;
                      return formData.possuiVinculoEmpregaticio;
                    },
                  },
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/vinculoInstitucional/properties/inicioFuncao',
                      label: 'Início de Função/Cargo',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: (cxt: CrudContextData) => {
                          const { formData } = cxt;
                          return formData.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'Label',
                      scope: '#/properties/vinculoInstitucional/properties/tempoFuncao',
                      label: 'Tempo de Função/Cargo',
                      options: {
                        formatOptionLabel: setLabelWhenChangeDateFuncao,
                        componentProps: {
                          variant: 'h5',
                          align: 'left',
                          sx: {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          },
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Documentos Pessoais',
      options: {
        onFinish: (
          navigate: NavigateFunction,
          ctx: CrudContextData,
          authContext: AuthContextData,
        ) => {
          const { updateUserName } = authContext;
          const { formData } = ctx;
          updateUserName(formData?.nome);
        },
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => {
            const { showSuccess, updateCrudStates } = ctx;
            updateCrudStates(CrudStatesOptions.VIEW);
            showSuccess('Editado com sucesso.');
            return true;
          },
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              label: 'Documentos Pessoais',
              type: 'File',
              scope: '#/properties/usuarioAnexo',
              options: {
                withoutFk: true,
              },
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    showEditButton: true,
    icons: iconConfig,
  },
};

export default editarPerfilUiSchema;
