import styled from '@emotion/styled';
import { Box, Paper, Table, TableCell, TableRow, TextField } from '@mui/material';
import TableHead from '@mui/material/TableHead';

export const StyledPaper = styled(Paper)({
  marginBottom: '0.75rem',
  overflow: 'hidden',
  width: '100%',
});

export const StyledTable = styled(Table)({
  paddingBottom: '2rem',
  fontFamily: 'Raleway, sans-serif',
  '& button, & td, & th': {
    fontFamily: 'Raleway, sans-serif',
  },
});

export const StyledTextField = styled(TextField)({
  width: '100%',
  padding: '0.5rem',
});

export const StyledTextOneField = styled(StyledTextField)({
  width: '100px',
});

export const TableLayoutHeader = styled(TableHead)({
  height: '40px',
  backgroundColor: '#1351B4',
});

export const TableLayoutHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  color: '#fff !important',
  border: '1rem',
  padding: '0px',
  align: 'center',
});

export const TableLayoutNameHeaderCell = styled(TableLayoutHeaderCell)({
  paddingLeft: '25px',
});

export const TableLayoutTwoColumnsHeaderCell = styled(TableLayoutHeaderCell)({
  width: '200px',
});

export const TableLayoutRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: '1rem',
  },
});

export const TableLayoutCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  wordBreak: 'break-word',
  padding: '0px',
  height: '40px',
  paddingLeft: '25px',
});

export const TableQuantityCell = styled(TableCell)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  height: '40px',
  width: '100%',
  align: 'center',
});

export const TableTwoQuantityCell = styled(TableQuantityCell)({
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '200px',
});

export const TableLayoutBody = styled(Box)({
  overflow: 'auto',
  maxHeight: '20rem',
});
