import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../api/generic-api';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import { Gear } from '../../components/styled-icons';
import { fixDate } from '../../helpers/fix-date';
import { moduleNameFor } from '../../helpers/module-name-for';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import { Usuario as UsuarioModel } from '../../models/usuario';
import { headCells } from './usuario.headcell';
import usuarioUiSchema from './usuario.uischema';

function Usuario() {
  const {
    updateCrudStates,
    setLoad,
    setFormData,
    schema,
    setId,
    setValidationMode,
    crudStates,
    showSuccess,
    showError,
  } = useCrudContext();
  const navigate = useNavigate();
  const usuarioApi = makeApi<UsuarioModel>('/usuario');
  const configUserActions = {
    hasPermission: ['CONFIGURACOES_DO_USUARIO'],
    moduleName: moduleNameFor('usuario'),
    icon: <Gear size='24' color='#009900' />,
    title: 'Configurações do Usuário',
    handler: (row: UsuarioModel) => {
      setLoad(true);
      usuarioApi
        ?.get?.(row.id)
        .then((data: UsuarioModel) => {
          fixDate(data, schema);
          setFormData(data);
          setTimeout(() => setLoad(false), 200);
        })
        .catch((err) => {
          console.error(err);
          setLoad(false);
        });
      setId(row.id);
      navigate(`/usuario/${row.id}`);
      updateCrudStates(CrudStatesOptions.EDIT);
    },
  };

  const customSaveButton = {
    button: (
      <Button>
        <DoneIcon> Salvar</DoneIcon>
      </Button>
    ),
    handler: async (id: number, formData: UsuarioModel, defaultSave: () => Promise<void>) => {
      setValidationMode('ValidateAndShow');

      try {
        if (crudStates.edit) {
          const response = await usuarioApi.put(id, formData);
          if (response.status === 200) {
            setValidationMode('ValidateAndHide');
            updateCrudStates(CrudStatesOptions.LIST);
            showSuccess('Configuração salva com sucesso');
          } else {
            console.error(response);
          }
        } else {
          await defaultSave();
        }
      } catch (error: any) {
        console.error(error);
        let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
        if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
        showError(errorMessage || 'Ocorreu um erro.');
      }
    },
  };

  return (
    <Crud<UsuarioModel>
      headCells={headCells}
      titleConfig={{ value: 'Usuários', show: true }}
      uischema={usuarioUiSchema}
      apiUrl={'/usuario'}
      queryFilters={{
        withDeleted: true,
        orderBy: 'nome',
        orderDirection: 'ASC',
      }}
      hideCreate
      hideView
      hideUpdate
      customActions={[() => configUserActions]}
      customSave={customSaveButton}
    ></Crud>
  );
}

export default Usuario;
