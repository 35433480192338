import Autocomplete from '@mui/material/Autocomplete';
import Hidden from '@mui/material/Hidden';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { useCrudContext } from '../../../components/crud/crud-context';
import { IControlElement } from '../../uischema';
import '../renderer.css';
import { SelectMultiple } from './multiple';
import { CustomSelectProps, getLabel, handleChange } from './select-utils';
import { getFirstError } from '../../../utils/get-first-error';

export function SelectDefault(props: CustomSelectProps) {
  const {
    path,
    multiple,
    selectedItem,
    setSelectedItem,
    isVisible,
    isDisabled,
    uischema,
    apiUrl,
    options,
  } = props;

  const ctx = useCrudContext();

  const { validationMode, isRequiredField } = ctx;

  const required = uischema.options?.required ?? false;

  const iconBuilder = uischema.options?.iconBuilder;

  const getClassNamesDefault = () =>
    `${
      (isDisabled && 'disabled-field') ||
      (props.errors && validationMode === 'ValidateAndShow' && 'has-error')
    }`;

  const customProps = React.useCallback(() => props, [props]);

  if (multiple) return <SelectMultiple {...customProps()} />;

  return (
    <Hidden xsUp={!isVisible} key={`${path}-select-default`}>
      <div className='custom-input-container'>
        <Autocomplete
          className={getClassNamesDefault()}
          options={options}
          value={selectedItem}
          getOptionLabel={(option: any) => String(option?.label || '')}
          onChange={(_, content) =>
            handleChange(content, uischema as IControlElement, apiUrl, props, ctx, setSelectedItem)
          }
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          disabled={isDisabled}
          noOptionsText={'Sem opções'}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label={getLabel(uischema as IControlElement, apiUrl)}
              required={isRequiredField(required, ctx)}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {iconBuilder && iconBuilder(option)}
                  {parts?.map((part, index) => (
                    <span
                      key={`${part.text}-${index}`}
                      style={{
                        color: part.highlight ? '#1E90FF' : '#333333',
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
        {validationMode === 'ValidateAndShow' && props.errors && (
          <span className='error-message'>{getFirstError(props.errors)}</span>
        )}
      </div>
    </Hidden>
  );
}
