import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

interface DefaultProps {
  componentColor?: string;
}

interface CustomListItemButtonProps extends ListItemButtonProps {
  isDisabled?: string;
}

export const QuestionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  margin: '8px 0 !important',
});

export const QuestionText = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '12px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  lineHeight: '16px',
  marginRight: '2px',
});

export const WarningText = styled(Typography)({
  fontFamily: 'Raleway',
  fontStyle: 'italic',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '1.2rem',
  letterSpacing: '0.5px',
  marginRight: '2px',
});

export const GridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0 !important',
  padding: '0 !important',
});

export const GridItem = styled(Grid)({
  height: '100%',
  padding: '0 !important',
});

export const CustomButton = styled(Button)({
  width: '90px',
  height: '26px',
  margin: '4px !important',
});

export const TransferListTitle = styled(Typography)<DefaultProps>(({ componentColor }) => ({
  fontFamily: 'Raleway',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  color: componentColor,
}));

export const PaperList = styled(Paper)<DefaultProps>(({ componentColor }) => ({
  marginTop: '4px !important',
  border: componentColor,
  padding: '0',
}));

export const CustomList = styled(List)({
  backgroundColor: '#FFFFFF !important',
  fontFamily: 'Raleway',
  overflow: 'auto',
  height: '221px',
  margin: '5px 3px 5px 5px !important',
  padding: '0',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const CustomListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontFamily: 'Raleway',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
});

export const CustomListItemButton = styled(ListItemButton)<CustomListItemButtonProps>(
  ({ isDisabled }) => ({
    marginRight: '5px',
    borderBottom: '1px solid #CAC4D0',
    justifyContent: 'space-between',
    padding: '8px 2px 8px 2px',
    cursor: isDisabled,
  }),
);
