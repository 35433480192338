import { AxiosResponse } from 'axios';
import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
import { CronogramaDesembolso } from '../../../models/cronograma-desembolso';

export class CronogramaDesembolsoApi extends GenericApi<CronogramaDesembolso> {
  constructor(url: string) {
    super(url);
  }

  async makePayment(id: number, data: any): Promise<AxiosResponse<CronogramaDesembolso, any>> {
    try {
      const response = await client.put<CronogramaDesembolso>(
        `${this.url}/${id}/makePayment`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }

      throw new Error('Put error: ', { cause: error });
    }
  }
}

export const makeCronogramaDesembolsoApi = (url: string) => new CronogramaDesembolsoApi(url);
