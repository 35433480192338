import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { Rubrica as RubricaModel } from '../../../models/rubrica';
import { headCells } from './rubrica.headcell';
import rubricasUiSchema from './rubrica.uischema';

function Rubrica() {
  const customErrors = [whiteSpaces('codigo'), whiteSpaces('nome')];
  const defaultForm = { nome: '', codigo: '' };

  return (
    <Crud<RubricaModel>
      headCells={headCells}
      titleConfig={{ value: 'Rubricas', show: true }}
      uischema={rubricasUiSchema}
      apiUrl={'/rubrica'}
      customErrors={customErrors}
      defaultForm={defaultForm}
      queryFilters={{ withDeleted: true, orderBy: 'codigo', orderDirection: 'ASC' }}
      hideView
    ></Crud>
  );
}

export default Rubrica;
