import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { DepartamentoFundacao } from '../../../models/departamento-fundacao';

export const moduleName = 'CONFIGURACOES_INICIAIS.DEPARTAMENTO_FUNDACAO';

export const moduleLabel = 'Departamentos';

export const displayProperties = ['nome'];

export const headCells: readonly HeadCell<DepartamentoFundacao>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome do Departamento',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
