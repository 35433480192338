import { FormApi, GenericApi } from './generic-api';
import client from '../config/axios-config';
import { Edital } from '../models/edital';

export interface IEditalApi extends FormApi {
  getEditalFileToken(): Promise<any>;
}

export class EditalApi extends GenericApi<Edital> implements IEditalApi {
  async getEditalFileToken(): Promise<any> {
    try {
      const response = await client.get<any>(`${this.url}/file-token`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }
}

export const makeEditalApi = () => {
  return new EditalApi('/edital');
};
