interface CheckedCheckboxIconProps {
  isDisabled?: boolean;
}

export const CheckedCheckboxIcon: React.FC<CheckedCheckboxIconProps> = ({ isDisabled }) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='12' height='12' rx='2' fill={!isDisabled ? '#1351B4' : '#C0C0C0'} />
    <path
      d='M4.18182 8.21591L1.79545 5.82955L1 6.625L4.18182 9.80682L11 2.98864L10.2045 2.19318L4.18182 8.21591Z'
      fill={!isDisabled ? 'white' : '#E0E0E0'}
    />
  </svg>
);
