import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import LogoFundacao from '../../components/layout/logo-fundacao';
import {
  BackButton,
  Background,
  DivGroup,
  LoginForm,
  RecoverPassword,
  Paper,
  LogoLogin,
} from './login.styled';

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<LoginApi>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [login, setlogin] = useState<string>('');

  useEffect(() => {
    const loginApi = makeLoginApi();
    setApi(loginApi);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setlogin(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!login?.match?.(emailRegex)) {
      showError('E-mail inválido');
      return;
    }
    api
      ?.recuperarSenha(login)
      .then((res) => {
        showSuccess('E-mail enviado para recuperação de senha. Verifique sua caixa de entrada.');
      })
      .catch((err) => {
        showError(
          err?.cause?.response?.data?.message?.[0] ||
            err?.response?.data?.message ||
            'Ocorreu um erro.',
        );
      });
  };

  return (
    <DivGroup>
      <Background />

      <RecoverPassword>
        <CssBaseline />

        <Paper>
          <LogoLogin as={LogoFundacao} />
          <BackButton onClick={() => navigate('/login')} style={{ marginTop: '1.5rem' }}>
            <ArrowBackIcon />
            Voltar
          </BackButton>
          <Typography
            component='h1'
            variant='h5'
            style={{
              color: '#191B22',
              fontWeight: '600',
              fontFamily: 'Raleway',
              fontSize: '22px',
              marginTop: '1.5rem',
            }}
          >
            Recuperar a senha
          </Typography>
          <LoginForm onSubmit={handleSubmit}>
            <Typography
              component='h1'
              variant='subtitle1'
              style={{
                color: '#737784',
                fontWeight: '400',
                marginTop: '2.62rem',
                fontFamily: 'Raleway',
                fontSize: '16px',
              }}
            >
              Insira o e-mail cadastrado para redefinir a senha de acesso.
            </Typography>
            <TextField
              size='small'
              margin='normal'
              required
              fullWidth
              id='login'
              label='Email'
              name='login'
              value={login}
              onChange={handleChange}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                backgroundColor: '#003D92',
                marginTop: '1.5rem',
                fontFamily: 'Raleway',
                paddingLeft: '0px',
              }}
            >
              Enviar
            </Button>
          </LoginForm>
        </Paper>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert} />
      </RecoverPassword>
    </DivGroup>
  );
};

export default RecuperarSenha;

export const disableAutoRoute = true;
