import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../api/generic-api';
import { TodosEditaisAnchor } from '../edital/lista-editais/style/edital-home';
import {
  AvisosHeaderLabel,
  AvisosIcon,
  ComponentBox,
  FooterBox,
  FooterButton,
  Header,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from './style/avisos-home';
import { Aviso } from '../../models/aviso';

export const AvisosHome = () => {
  const navigate = useNavigate();
  const avisosApi = makeApi<Aviso>('/aviso');
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    avisosApi
      .getAllForPagination({
        dataAtual: new Date().toISOString(),
        orderBy: 'dataEncerramento',
        orderDirection: 'ASC',
        take: 3,
      })
      .then((response) => {
        const data = Array.isArray(response) ? response : response.data;
        const count = Array.isArray(response) ? response.length : response.count;
        setData(data);
        setCount(count);
      });
  }, []);

  return (
    <>
      {data.length ? (
        <ComponentBox>
          <Header>
            <AvisosIcon />
            <AvisosHeaderLabel>Avisos</AvisosHeaderLabel>
          </Header>
          <Divider />
          <StyledList dense={true}>
            {data.map((item, index) => (
              <StyledListItem key={index}>
                <StyledListItemText primary={item.titulo} />
              </StyledListItem>
            ))}
          </StyledList>
          <Divider />
          <FooterBox>
            <FooterButton>
              <TodosEditaisAnchor onClick={() => navigate('/todos-avisos')}>
                {`Ver Mais (${count})`}
              </TodosEditaisAnchor>
            </FooterButton>
          </FooterBox>
        </ComponentBox>
      ) : (
        ''
      )}
    </>
  );
};
