import { EdicaoRecursoLinhaDeAcao } from './edicao-recurso-linha-de-acao';

export enum TipoHistoricoAjusteEdicaoRecursoLinhaDeAcao {
  corte = 'CORTE',
  aditivo = 'ADITIVO',
}

export interface HistoricoAjusteEdicaoRecursoLinhaDeAcao {
  id: number;
  edicaoRecursoLinhaDeAcaoId: number;
  edicaoRecursoLinhaDeAcao: EdicaoRecursoLinhaDeAcao;
  justificativa?: string;
  tipo: TipoHistoricoAjusteEdicaoRecursoLinhaDeAcao;
  valorAjuste: number;
  valorFinal: number;
  deletedAt: Date;
  createdAt: Date;
}
