import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { HistoricoAjusteValorBolsa } from '../../../models/historico-ajuste-valor-bolsa';

export const moduleName =
  'BOLSA.MODALIDADE_BOLSA.NIVEL_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA';

export const moduleLabel = ' Ajuste do Valor da Bolsa';

export const headCells: readonly HeadCell<HistoricoAjusteValorBolsa>[] = [
  {
    id: 'valorAjuste',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data da Atualização',
    dateFormat: 'DD/MM/YYYY',
  },
];
