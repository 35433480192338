import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import Loading from '../../components/crud/loading';
import { makeLoginApi } from '../../api/login-api';
import { LoginContainer, Paper, LoaderConfirmEmail } from './login.styled';

const ConfirmarEmail = () => {
  const navigate = useNavigate();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const { search } = useLocation();

  const obterTokenDaURL = (search: string) => {
    const parametros = new URLSearchParams(search);
    return parametros.get('token');
  };

  const token = obterTokenDaURL(search);

  useEffect(() => {
    const loginApi = makeLoginApi();
    setTimeout(() => {
      if (token) {
        loginApi
          ?.confirmarEmail(token)
          .then((res) => {
            showSuccess('E-mail verificado com sucesso.');
            navigate('/login');
          })
          .catch((err) => {
            showError('Falha ao verificar e-mail.');
            navigate('/login');
          });
      } else {
        showError('Falha ao verificar e-mail.');
        navigate('/login');
      }
    }, 1000);
  }, []);

  return (
    <LoginContainer>
      <CssBaseline />
      <Paper>
        <Typography component='h1' variant='h5'>
          Confirmando e-mail
        </Typography>
        <LoaderConfirmEmail>
          <Loading isLoading={true} />
        </LoaderConfirmEmail>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert}></AlertCustom>
      </Paper>
    </LoginContainer>
  );
};

export default ConfirmarEmail;

export const disableAutoRoute = true;
