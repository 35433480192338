import { createContext, useState } from 'react';

type BreadcrumbProps = {
  name: string;
  pathname: string;
  defaultState?: unknown;
};

type BreadcrumbsCustomProps = {
  breadcrumb: BreadcrumbProps[];
  setBreadcrumb: (value: BreadcrumbProps[]) => void;
};

export const BreadcrumbsContext = createContext({} as BreadcrumbsCustomProps);

export default function BreadcrumbsProvider({ children }: any) {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbProps[]>([]);
  return (
    <BreadcrumbsContext.Provider value={{ breadcrumb, setBreadcrumb }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}
