import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Perfil } from '../../../models/perfil';

export const moduleName = 'PESSOA.PERFIL.PERFIL';

export const displayProperties = ['nome'];

export const moduleLabel = 'Perfil';

export const headCells: readonly HeadCell<Perfil>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
