import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import { useCrudContext } from '../../components/crud/crud-context';
import LogoGov from '../../components/layout/images/govbr.png';
import LogoFundacao from '../../components/layout/logo-fundacao';
import { useAuthContext } from './auth/auth-context';
import {
  Background,
  CompleteLine,
  CreateLink,
  DivGroup,
  GovImage,
  LineCenter,
  Lines,
  LinksContainer,
  LinkStyled,
  LoginContainer,
  LoginForm,
  LogoLogin,
  Paper,
  RememberMe,
} from './login.styled';

interface LoginFormState {
  login: string;
  senha: string;
}

const Login = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<LoginApi>();
  const { login, isLoggedIn } = useAuthContext();
  const { showError } = useCrudContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginFormState>({
    login: '',
    senha: '',
  });

  useEffect(() => {
    if (isLoggedIn) navigate('/home');
    const loginApi = makeLoginApi();
    setApi(loginApi);
    const rememberState = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberState);
    if (rememberState) {
      const userEmail = localStorage.getItem('userEmail') ?? '';
      setFormData({ login: userEmail, senha: '' });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEmail(formData.login)) {
      showError('E-mail inválido');
      return;
    }

    if (!formData.senha) {
      showError('Senha inválida');
      return;
    }

    localStorage.setItem('rememberMe', String(rememberMe));
    localStorage.setItem('userEmail', formData.login);

    api
      ?.login(formData)
      .then((res) => {
        const loginResponseData = res?.data;
        if (res?.status === 201 && loginResponseData.token) {
          setFormData({ login: '', senha: '' });
          login(loginResponseData);
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) showError('Usuário ou senha inválidos');
        if (err?.response?.status === 400)
          showError(
            err?.cause?.response?.data?.message?.[0] ||
              err?.response?.data?.message ||
              'Ocorreu um erro.',
          );
      });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <DivGroup>
      <Background />
      <LoginContainer>
        <CssBaseline />
        <Paper>
          <LogoLogin as={LogoFundacao} />
          <Typography
            component='h4'
            sx={{
              color: '#737784',
              fontFamily: 'Raleway',
              fontSize: '16px',
              marginTop: '4.25rem',
            }}
          >
            Seja bem-vindo!
          </Typography>
          <Typography
            component='h1'
            variant='h5'
            sx={{
              color: '#191B22',
              fontWeight: '600',
              fontFamily: 'Raleway',
              fontSize: '22px',
              marginBottom: '2.62rem',
            }}
          >
            Acessar o sistema
          </Typography>

          <LoginForm onSubmit={handleSubmit}>
            <TextField
              size='small'
              margin='normal'
              required
              fullWidth
              id='login'
              label='Email'
              name='login'
              value={formData.login}
              onChange={handleChange}
              sx={{ marginBottom: '32px' }}
            />
            <TextField
              size='small'
              style={{ margin: '0' }}
              required
              fullWidth
              name='senha'
              label='Senha'
              type={showPassword ? 'text' : 'password'}
              id='senha'
              value={formData.senha}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ marginBottom: '24px' }}
            />
            <RememberMe>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    color='primary'
                  />
                }
                label='Lembrar de mim'
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#434653',
                }}
              />
              <LinkStyled href='/recuperar-senha'>Esqueceu sua senha?</LinkStyled>
            </RememberMe>

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              style={{
                backgroundColor: '#003D92',
                marginBottom: '4px',
              }}
            >
              Acessar
            </Button>
          </LoginForm>

          <CompleteLine>
            <Lines></Lines>
            <LineCenter>ou</LineCenter>
            <Lines></Lines>
          </CompleteLine>

          <Button
            type='submit'
            fullWidth
            variant='contained'
            style={{
              color: '#003D92',
              backgroundColor: '#DFE2E7',
              fontFamily: 'Raleway',
              fontWeight: '600',
              marginTop: '4px',
              marginBottom: '24px',
            }}
          >
            Entrar com
            <GovImage src={LogoGov} />
          </Button>

          <LinksContainer>
            Não tem conta?
            <CreateLink to='/registrar'>Crie uma agora!</CreateLink>
          </LinksContainer>
        </Paper>
      </LoginContainer>
    </DivGroup>
  );
};

export default Login;

export const disableAutoRoute = true;
