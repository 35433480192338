import { EditButton, CustomEditIcon } from './crud.styled';
import { useCrudContext } from '../crud-context';
import { CrudStatesOptions } from '../../../jsonforms/uischema';

export function CustomEditButton() {
  const ctx = useCrudContext();
  const { crudStates, updateCrudStates } = ctx;
  return (
    !crudStates.edit && (
      <EditButton onClick={() => updateCrudStates(CrudStatesOptions.EDIT)}>
        <CustomEditIcon />
        Editar
      </EditButton>
    )
  );
}

export default CustomEditButton;
