import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ComponentBox = styled(Box)({
  width: '100%',
});

export const Header = styled('div')({
  width: '100%',
  borderBottom: 'solid 1px #C5C6D0',
});

export const TituloLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: 'Raleway',
  lineHeight: '18.78px',
  color: '#333333 !important',
  paddingBottom: '24px',
  paddingLeft: '16px',
});
