import { Crud } from '../../../components/crud/crud';
import CustomEditButton from '../../../components/crud/style/editButton';
import { TermoPoliticaPrivacidade as TermoPoliticaPrivacidadeModel } from '../../../models/termo-politica-privacidade';
import { makeTermoPoliticaPrivacidadeApi } from './termo-politica-privacidade-api';
import { headCells } from './termo-politica-privacidade.headcell';
import termoPoliticaPrivacidadeUiSchema from './termo-politica-privacidade.uischema';

function TermoPoliticaPrivacidade() {
  const apiClient = makeTermoPoliticaPrivacidadeApi();

  return (
    <Crud<TermoPoliticaPrivacidadeModel>
      headCells={headCells}
      titleConfig={{ value: 'Termo de Política e Privacidade', show: true }}
      apiUrl={'/termo-politica-privacidade'}
      apiClient={apiClient}
      uischema={termoPoliticaPrivacidadeUiSchema}
      initView
      customButtons={<CustomEditButton />}
    ></Crud>
  );
}

export default TermoPoliticaPrivacidade;
