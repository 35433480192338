import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { Proposta } from '../../../models/proposta';
import { useAuthContext } from '../../login/auth/auth-context';
import {
  ComponentBoxProposta,
  FooterBox,
  FooterButton,
  Header,
  PropostaHeaderLabel,
  PropostaIcon,
  TodasPropostasAnchor,
} from './style/proposta-home';
import ListaPropostas from '../../../components/proposta/lista-propostas';

type PropostaHomeProps = {
  onValidate: (isValid: boolean) => void;
  splitLength?: number;
};

export const PropostaHome = ({ onValidate, splitLength }: PropostaHomeProps) => {
  const { loggedInUser } = useAuthContext();
  const [data, setData] = useState<Proposta[]>([]);
  const [count, setCount] = useState<number>(0);
  const propostaApi = makeApi<Proposta>('/proposta', {
    orderRelationBy: {
      orderCreatedAtBy: (a, b) => b.getTime() - a.getTime(),
    },
    customPaths: { getAll: '/minhas-propostas' },
  });

  const navigate = useNavigate();

  useEffect(() => {
    loadPropostas();
  }, [loggedInUser, onValidate]);

  function loadPropostas() {
    if (loggedInUser) {
      propostaApi
        .getAllForPagination({ take: 6, orderBy: 'createdAt', orderDirection: 'DESC' })
        .then((response) => {
          const data = Array.isArray(response) ? response : response.data;
          const count = Array.isArray(response) ? response.length : response.count;
          setData(data);
          setCount(count);
          onValidate(data.length > 0);
        });
    }
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <ComponentBoxProposta>
      <Header>
        <PropostaIcon />
        <PropostaHeaderLabel>Propostas</PropostaHeaderLabel>
      </Header>
      <Divider />
      <ListaPropostas splitLength={splitLength} propostas={data} />
      <FooterBox>
        <FooterButton>
          <TodasPropostasAnchor onClick={() => navigate('/todas-propostas')}>
            {`Ver Mais (${count})`}
          </TodasPropostasAnchor>
        </FooterButton>
      </FooterBox>
    </ComponentBoxProposta>
  );
};
