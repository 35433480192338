export enum TipoRecursoLinhaDeAcao {
  instrumentoJuridico = 'INSTRUMENTO_JURIDICO',
  tesouro = 'TESOURO',
}

export type TipoRecursoLinhaDeAcaoI18N = {
  [key in TipoRecursoLinhaDeAcao]: string;
};

export const tipoRecursoLinhaDeAcaoI18N: TipoRecursoLinhaDeAcaoI18N = {
  [TipoRecursoLinhaDeAcao.instrumentoJuridico]: 'Instrumento Jurídico',
  [TipoRecursoLinhaDeAcao.tesouro]: 'Tesouro',
};
