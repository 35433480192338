import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { NaturezaDespesa } from '../../../models/natureza-despesa';

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.NATUREZA_DESPESA';

export const moduleLabel = 'Natureza da Despesa';

export const headCells: readonly HeadCell<NaturezaDespesa>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
