import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Edital } from '../../../models/edital';

export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Formulário de Enquadramento';

export const headCells: readonly HeadCell<Edital>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Edital',
  },
];
