import { RuleEffect } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';

const edicaoProgramaUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Date',
      scope: '#/properties/ano',
      label: 'Ano',
      options: {
        // required: true,
        dateDefault: 'now',
        dateFormat: 'yyyy',
      },
    },
    {
      type: 'Control',
      scope: '#/properties/valorTotal',
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: 'Control',
      scope: '#/properties/somaRecursoAlocado',
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
  ],
};

export default edicaoProgramaUiSchema;
