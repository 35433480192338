import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';

export const NavCategoryLine = styled(Box)`
  width: 3px;
  height: 100%;
  margin: 3px 0px;
`;

export const NavCategoryButton = styled(ListItemButton)<{ open: boolean }>`
  border-radius: 5px;
  height: 38px;
  padding: ${({ open }) => (open ? '3px 10px' : '3px 0px')};
  width: ${({ open }) => (!open ? '100%' : 'auto')};
  &:hover {
    background-color: #d4e0e4;
  }
`;

export const NavIcon = styled(ListItemIcon)<{ open: boolean }>`
  min-width: 30px !important;
  color: var(--color-icon) !important;
  flex-direction: column-reverse;
  justify-content: ${({ open }) => (!open ? 'left' : 'center')};
  width: ${({ open }) => (!open ? '100%' : 'auto')};
  padding-left: ${({ open }) => (!open ? '10px' : '0px')};
`;

export const SubItem = styled(Collapse)`
  padding-left: 20px;
`;
