import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { NivelBolsa } from '../../../models/nivel-bolsa';

export const moduleName = 'BOLSA.MODALIDADE_BOLSA.NIVEL_BOLSA.NIVEL_BOLSA';

export const moduleLabel = 'Nível de Bolsa';

export const headCells: readonly HeadCell<NivelBolsa>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'nivel',
    numeric: false,
    disablePadding: false,
    label: 'Nível',
  },
  {
    id: 'valorAtualBolsa',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
  {
    id: 'cargaHoraria',
    numeric: true,
    disablePadding: false,
    label: 'Carga Horária',
  },
];
