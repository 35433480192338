import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

export const StyledTitle = styled(Typography)<{ error?: any }>(({ error }) => ({
  color: (error ? '#C42525' : 'black') + ' !important',
  fontFamily: 'Raleway',
  fontSize: '12px',
  fontWeight: '700',
  paddingBottom: '5px',
}));
