import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import CustomAvatarEditor from '../../components/layout/custom-avatar-editor';
import { Usuario } from '../../models/usuario';
import { makeLoginApi } from '../../api/login-api';
import { validateCPF } from '../../utils/cpf-validator';
import { useAuthContext } from '../login/auth/auth-context';
import './editar-perfil.css';
import { headCells } from './editar-perfil.headcell';
import {
  EditarPerfilHeader,
  UsuarioInfo,
  UsuarioInfoNome,
  UsuarioInfoPais,
} from './editar-perfil.styled';
import editarPerfilUiSchema from './editar-perfil.uischema';
import { UsuarioApi, makeUsuarioApi } from './usuario-api';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import WarningAlert from '../../components/layout/components/alerts/warning-alert';

function EditarPerfil() {
  const { updateUserProfileImage, loggedInUser } = useAuthContext();
  const { formData, setFormData, crudStates, showSuccess, showError, updateCrudStates } =
    useCrudContext();

  const loginApi = makeLoginApi();

  const handleResendEmail = async () => {
    try {
      await loginApi?.reenviarEmailConfirmacao(loggedInUser.email);
      showSuccess('E-mail de confirmação reenviado com sucesso.');
    } catch (error) {
      showError('Falha ao reenviar e-mail de confirmação.');
    }
  };

  const [apiClient, setApiClient] = useState<UsuarioApi>(null);
  const [userImageData, setUserImageData] = useState(formData);
  const [userHeaderData, setUserHeaderData] = useState(formData);
  const defaultForm = {
    ...cloneDeep(formData),
    possuiVinculoInstitucional: false,
    instituicaoNome: '',
  };
  const customErrors = [validateCPF('documento')];

  useEffect(() => {
    if (loggedInUser?.emailVerificado) {
      updateCrudStates(CrudStatesOptions.VIEW);
    } else {
      updateCrudStates(CrudStatesOptions.EDIT);
    }

    const api = makeUsuarioApi();
    setApiClient(api);
  }, []);

  useEffect(() => {
    if (Object.keys(formData).length) {
      setUserImageData(formData.fotoPerfil);
      setUserHeaderData(formData);
    }
  }, [crudStates.view, formData]);

  const saveHandler = async (profileImage: any) => {
    try {
      await apiClient?.atualizarFotoPerfil?.({ fotoPerfil: profileImage }).then(() => {
        showSuccess('Foto atualizada');
        setUserImageData(profileImage);
        updateUserProfileImage(profileImage);
      });
    } catch (error: any) {
      showError(error?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  return (
    <>
      <EditarPerfilHeader>
        <CustomAvatarEditor
          username={userHeaderData?.nome}
          image={userImageData}
          saveHandler={saveHandler}
        />
        <UsuarioInfo>
          <UsuarioInfoNome>{userHeaderData?.nome}</UsuarioInfoNome>
          <UsuarioInfoPais>
            <CircleFlag
              countryCode={userHeaderData ? userHeaderData.pais?.sigla?.toLowerCase() : ''}
            />
            {userHeaderData?.pais?.nomePt}
          </UsuarioInfoPais>
        </UsuarioInfo>
      </EditarPerfilHeader>
      {!loggedInUser?.emailVerificado && (
        <WarningAlert onClick={handleResendEmail}>
          Conta de e-mail não verificada. Acesse seu e-mail ou clique aqui para enviar novamente a
          confirmação
        </WarningAlert>
      )}
      <Crud<Usuario>
        headCells={headCells}
        titleConfig={{ value: 'Editar Perfil', show: false }}
        uischema={editarPerfilUiSchema}
        apiClient={apiClient}
        defaultForm={defaultForm}
        customErrors={customErrors}
        customSave={{ show: false }}
        initView
        ignorePermissions
      ></Crud>
    </>
  );
}
export default EditarPerfil;
