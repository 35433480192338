import { IUiSchema } from '../../../jsonforms/uischema';

const indicadorProducaoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      label: 'Nome',
      scope: '#/properties/nome',
      options: {
        required: true,
      },
    },
    {
      type: 'Control',
      label: 'Nacional e Internacional',
      scope: '#/properties/nacionalInternacional',
    },
    {
      type: 'Control',
      scope: '#/properties/producao',
      label: 'Produção',
      options: {
        itemLayout: 'SimpleItemLayout',
        required: false,
        detail: {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/texto',
              label: 'Texto da Produção',
            },
          ],
        },
      },
    },
  ],
};

export default indicadorProducaoUiSchema;
