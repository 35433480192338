import { Money, BuildingMultiple } from '../../../components/styled-icons';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { InstrumentoJuridico as InstrumentoJuridicoModel } from '../../../models/instrumento-juridico';
import { headCells } from './instrumento-juridico.headcell';
import instrumentoJuridicoUiSchema from './instrumento-juridico.uischema';

function InstrumentoJuridico() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const defaultForm = { nome: '' };

  const termoAditivoAction = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    icon: <Money size='24' color='#009900' />,
    title: 'Termos Aditivos',
    moduleName: moduleNameFor('termo-aditivo-instrumento-juridico'),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        instrumentoJuridico: row,
      });
      navigate(`/instrumento-juridico/${row?.id}/termo-aditivo-instrumento-juridico`);
    },
  };

  const instituicaoInstrumentoJuridico = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    icon: <BuildingMultiple size='24' color='var(--color-icon)' />,
    title: 'Instituição do Instrumento Jurídico',
    moduleName: moduleNameFor('instituicao-instrumento-juridico'),
    handler: (row: InstrumentoJuridicoModel) => {
      setParameterFilters({
        ...parameterFilters,
        instrumentoJuridico: row,
      });
      navigate(`/instrumento-juridico/${row?.id}/instituicao-instrumento-juridico`);
    },
  };

  return (
    <Crud<InstrumentoJuridicoModel>
      headCells={headCells}
      titleConfig={{ value: 'Instrumentos Jurídicos', show: true }}
      apiUrl={'/instrumento-juridico'}
      uischema={instrumentoJuridicoUiSchema}
      defaultForm={defaultForm}
      queryFilters={{
        withDeleted: true,
        orderBy: 'inicioVigencia',
        orderDirection: 'DESC',
      }}
      customActions={[() => instituicaoInstrumentoJuridico, () => termoAditivoAction]}
      customSave={{ show: false }}
    ></Crud>
  );
}

export default InstrumentoJuridico;
