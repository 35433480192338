import { or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { IControlElement } from '../uischema';
import './renderer.css';
import {
  HeadLineSpanContent,
  HeadLineSpanTitle,
  MultiplaEscolhaContent,
  MultiplaEscolhaListItemButton,
  MultiplaEscolhaListItemIcon,
  MultiplaEscolhaRadio,
  MultiplaEscolhaRadioGroup,
  RadioGroupList,
  RequiredSpan,
} from './styled/multipla-escolha';

const MultiplaEscolhaTester = rankWith(
  6,
  or(
    schemaMatches((schema) => schema.format === 'MultiplaEscolha'),
    uiTypeIs('MultiplaEscolha'),
  ),
);

export const MultiplaEscolha = {
  tester: MultiplaEscolhaTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, path, enabled, data, schema, ...props }) => {
      const uischema = props.uischema as IControlElement;
      const [obrigatorio, setObrigatorio] = useState<boolean>(false);
      const [checked, setChecked] = useState<number>(null);
      const ctx = useCrudContext();
      const { validationMode, crudStates, disabledFields } = ctx;

      useEffect(() => {
        if (data) setChecked(data);
        else setObrigatorio(false);
      }, [validationMode]);

      const isDisabled =
        !enabled ||
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      useEffect(() => {
        if (checked) {
          setObrigatorio(false);
          props.handleChange(path, checked);
        }
      }, [checked]);

      const handleToggle = (value: number) => () => {
        if (!isDisabled) setChecked(value);
      };

      return (
        <>
          <Hidden xsUp={!visible} key={`${path}-multipla-escolha`}>
            <MultiplaEscolhaContent requiredColor={obrigatorio ? '#BA1A1A' : '#757780'}>
              <div className='headLine'>
                <HeadLineSpanTitle requiredColor={obrigatorio ? '#BA1A1A' : '#1D1B20'}>{`${
                  schema.title || uischema.label
                } `}</HeadLineSpanTitle>
                <HeadLineSpanContent requiredColor={obrigatorio ? '#BA1A1A' : '#1351B4'}>
                  (Selecione apenas uma opção)
                </HeadLineSpanContent>
                {uischema.options?.required ? (
                  <HeadLineSpanTitle requiredColor={obrigatorio ? '#BA1A1A' : '#1D1B20'}>
                    {` *`}
                  </HeadLineSpanTitle>
                ) : (
                  ''
                )}
              </div>
              <MultiplaEscolhaRadioGroup
                overflowType={(schema?.oneOf?.length || 0) >= 4 ? 'scroll' : 'auto'}
              >
                <RadioGroupList
                  sx={{
                    '--List-gap': '8px',
                    '--ListItem-paddingY': '16px',
                    '--ListItem-radius': '8px',
                    '--ListItemDecorator-size': '32px',
                  }}
                >
                  {schema?.oneOf?.map((item) => (
                    <>
                      <MultiplaEscolhaListItemButton
                        key={item.const}
                        role='listitem'
                        onClick={handleToggle(item.const)}
                        isDisabled={!isDisabled ? 'auto' : 'not-allowed'}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '12px',
                            fontFamily: 'Raleway, sans-serif',
                          }}
                          id={item.const}
                          primary={item.title}
                        />
                        <MultiplaEscolhaListItemIcon>
                          <MultiplaEscolhaRadio
                            size='small'
                            checked={checked === item.const}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': item.title,
                            }}
                            requiredColor={obrigatorio ? '#BA1A1A' : 'auto'}
                            disabled={isDisabled}
                          />
                        </MultiplaEscolhaListItemIcon>
                      </MultiplaEscolhaListItemButton>
                    </>
                  ))}
                </RadioGroupList>
              </MultiplaEscolhaRadioGroup>
            </MultiplaEscolhaContent>
            {obrigatorio ? <RequiredSpan>Campo Obrigatório!</RequiredSpan> : null}
          </Hidden>
        </>
      );
    },
  ),
};
