import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const CustomButton = styled(Button)({
  width: '180px',
  height: '36px',
  borderRadius: '4px',
  padding: '10px, 16px, 10px, 16px',
  backgroundColor: '#003D92',
  color: 'white',
  fontSize: '12px',
  marginTop: '24px',
  marginBottom: '24px',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const ComponentBox = styled(Box)({
  marginRight: '16px',
  marginLeft: '16px',
  width: '100%',

  '@media (max-width: 1920px)': {
    width: '90%',
  },
});

export const Header = styled('div')({
  fontSize: 22,
  fontWeight: 600,
  marginLeft: 16,
});

export const BoxContent = styled(Box)({
  flexGrow: 1,
});

export const BoxAnexoName = styled(Box)({
  flexGrow: 1,
  border: 'solid 1px',
  height: 500,
  borderRadius: '6px',
  marginTop: '12px',
  borderColor: 'lightgray',
  padding: 16,
});

export const BoxAnexoContent = styled(Box)({
  flexGrow: 1,
  border: 'solid 1px',
  height: 500,
  borderRadius: '6px',
  marginTop: 16,
  borderColor: 'lightgray',
  padding: 0,
});

export const PdfContent = styled('iframe')({
  border: 'none',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
});

export const EmptyAnexoMsg = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ButtonContent = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  fontFamily: 'Raleway',
});

export const ButtomLabel = styled('span')({
  marginLeft: 4,
});

export const BoxAnexoNameItem = styled(Typography)({
  marginTop: 32,
  fontFamily: 'Raleway',
});

export const BoxAnexoContentItem = styled(Typography)({
  marginTop: 32,
  marginLeft: '5%',
});

export const AnexoLabelSelected = styled(Typography)({
  marginTop: 32,
});

export const AnexoLabelUnselected = styled(Typography)({
  marginTop: 32,
});

export const ShowAnexoLabel = styled('span')<{ colorValue?: string }>(({ colorValue }) => ({
  backgroundColor: colorValue ?? 'white !important',
  color: colorValue ? '#225abd' : '#1f2023',
  width: '100%',
  display: 'block',
  borderRadius: colorValue ? '2px' : 'auto',
  fontWeight: 600,
  padding: '4px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const TextoEdital = styled(Typography)({
  marginTop: '12px',
  marginBottom: '44px',
  marginRight: '62px',
  fontSize: '16px',
});
