import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Rubrica } from '../../../models/rubrica';

export const displayProperties: Function = (item: any) => {
  return `${item.codigo} - ${item.nome}`;
};

export const moduleName = 'CONFIGURACOES_INICIAIS.RUBRICA';

export const headCells: readonly HeadCell<Rubrica>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'naturezaDespesaId',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
    resource: {
      name: 'naturezaDespesa',
      target: 'nome',
    },
  },
];
