import { Rule } from '@jsonforms/core';
import { GenericApi } from '../../../api/generic-api';
import { DocumentAttach, Info } from '../../../components/styled-icons';
import { IUiSchema } from '../../../jsonforms/uischema';
import {
  PerguntaAvaliacao,
  TipoRespostaPerguntaAvaliacao,
} from '../../../models/pergunta-avaliacao';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import perguntaAvaliacaoUiSchema from '../pergunta-avaliacao/pergunta-avaliacao.uischema';
import { handlerFormularioEnquadramento } from './handler/handler-formulario-enquadramento';

const iconsConfig = [
  {
    category: 'Dados do Formulário',
    icon: Info,
  },
  {
    category: 'Perguntas do Formulário',
    icon: DocumentAttach,
  },
];

const hideIfNotCategorizada = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/perguntasCategorizadas',
    schema: {
      const: false,
    },
  },
} as Rule;

const hideIfCategorizada = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/perguntasCategorizadas',
    schema: {
      const: true,
    },
  },
} as Rule;

const limparPergunta = (pergunta: PerguntaAvaliacao) => {
  if (!pergunta) return null;

  let multiplaEscolha = undefined;
  let descritiva = undefined;
  let nota = undefined;

  if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.multiplaEscolha) {
    multiplaEscolha = pergunta.multiplaEscolha?.map((item) => ({
      alternativa: item.alternativa,
    }));
  } else if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.descritiva) {
    descritiva = { minimo: pergunta.descritiva?.minimo, maximo: pergunta.descritiva?.maximo };
  } else if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
    nota = { notaMinima: pergunta.nota?.notaMinima, notaMaxima: pergunta?.nota?.notaMaxima };
  }

  return {
    ...pergunta,
    perguntaBase: false,
    id: undefined,
    multiplaEscolha,
    descritiva,
    nota,
  };
};

const gerenciarFormularioUiSchema: IUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados do Formulário',
      options: {
        stepHandler: {
          handler: () => stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/titulo',
          label: 'Título',
        },
        {
          type: 'Control',
          scope: '#/properties/perguntasCategorizadas',
          label: 'Será um formulário com perguntas categorizadas',
          options: {
            onlyCreate: true,
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Perguntas do Formulário',
      options: {
        stepHandler: {
          handler: handlerFormularioEnquadramento,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/formularioEnquadramentoCategorizado',
          label: 'Perguntas Categorizadas',
          options: {
            elementLabelProp: 'tituloCategoria',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/tituloCategoria',
                  label: 'Título da Categoria',
                },
                {
                  type: 'Select',
                  scope: '#/properties/perguntaAvaliacaoId',
                  label: 'Pergunta de Avaliação',
                  options: {
                    addEmptyOption: true,
                    handleChange: (values: PerguntaAvaliacao, handler) => {
                      handler('perguntaInfo', limparPergunta(values));
                    },
                    filter: {
                      handler: (
                        data: PerguntaAvaliacao,
                        listData: any[],
                        api: GenericApi<PerguntaAvaliacao>,
                        queryFilterValues: any,
                        path: string,
                      ): Promise<PerguntaAvaliacao[]> => {
                        return api.getAll({ perguntaBase: true });
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/formularioEnquadramentoCategorizadoPerguntaAvaliacao',
                  label: 'Perguntas',
                  options: {
                    detail: perguntaAvaliacaoUiSchema(),
                    elementLabelProp: 'pergunta',
                    defaultValue: (formData: any) =>
                      formData.perguntaInfo ?? {
                        perguntaBase: false,
                        descritiva: {
                          minimo: 0,
                          maximo: 0,
                        },
                        nota: {
                          notaMinima: 0,
                          notaMaxima: 0,
                        },
                      },
                    showSortButtons: true,
                  },
                },
              ],
            },
            showSortButtons: true,
          },
          rule: hideIfNotCategorizada,
        },
        {
          type: 'Select',
          scope: '#/properties/perguntaAvaliacaoId',
          label: 'Pergunta de Avaliação',
          options: {
            addEmptyOption: true,
            handleChange: (values: PerguntaAvaliacao, handler) => {
              handler('perguntaInfo', limparPergunta(values));
            },
            filter: {
              handler: (
                data: PerguntaAvaliacao,
                listData: any[],
                api: GenericApi<PerguntaAvaliacao>,
                queryFilterValues: any,
                path: string,
              ): Promise<PerguntaAvaliacao[]> => {
                return api.getAll({ perguntaBase: true });
              },
            },
          },
          rule: hideIfCategorizada,
        },
        {
          type: 'Control',
          scope: '#/properties/formularioEnquadramentoPergunta',
          label: 'Perguntas',
          options: {
            detail: perguntaAvaliacaoUiSchema(),
            elementLabelProp: 'pergunta',
            defaultValue: (formData: any) =>
              formData.perguntaInfo ?? {
                perguntaBase: false,
                descritiva: {
                  minimo: 0,
                  maximo: 0,
                },
                nota: {
                  notaMinima: 0,
                  notaMaxima: 0,
                },
              },
            showSortButtons: true,
          },
          rule: hideIfCategorizada,
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconsConfig,
  },
};

export default gerenciarFormularioUiSchema;
