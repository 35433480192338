import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ExclamationTriangleFill } from 'styled-icons/bootstrap';

export const ComponentBox = styled(Box)({
  minWidth: '536px',
  width: '100%',
  fontFamily: 'Raleway',
  borderRadius: '4px',
  border: 'solid 1px #C5C6D0',
  marginBottom: '16px',
  marginLeft: '6px',
  marginRight: '6px',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const AvisosIcon = styled(ExclamationTriangleFill)({
  width: '16px',
  height: '16px',
  color: '#003D92',
  marginLeft: '16px',
});

export const AvisosHeaderLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
});

export const StyledList = styled(List)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 0,
});

export const AvisosEmpty = styled(Typography)({
  marginLeft: '20px',
});

export const StyledListItem = styled(ListItem)({
  borderStyle: 'solid',
  borderWidth: '0.05px 0px 0.05px 0px',
  borderColor: '#C5C6D0',
  padding: 0,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '35px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '2px',
    height: '2px',
    backgroundColor: '#1A1B20',
    borderRadius: '50%',
  },
});

export const StyledListItemText = styled(ListItemText)({
  display: 'list-item',
  marginLeft: '40px',
  marginTop: '0px',
  marginBottom: '0px',
  '& .css-et1ao3-MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Raleway',
    lineHeight: '14.09px',
    letterSpacing: '0.5px',
    padding: '8px',
  },
});

export const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '16px',
});

export const FooterButton = styled(Typography)({
  fontSize: '12px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
});

export const TodosAvisosAnchor = styled('a')({
  cursor: 'pointer',
});
