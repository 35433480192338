import styled from '@emotion/styled';
import { ListItemButton, ListItemIcon } from '@mui/material';

export const StyledListItemButton = styled(ListItemButton)`
  border-radius: 15px !important;
  padding: 3px 10px !important;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;
  color: var(--color-icon) !important;
`;
