import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Programa } from '../../../models/programa';

export const moduleName = 'ORCAMENTO.PROGRAMA.PROGRAMA';

export const moduleLabel = 'Programas';

export const displayProperties = ['nome'];

export const headCells: readonly HeadCell<Programa>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'dataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Data de Início',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataTermino',
    numeric: false,
    disablePadding: false,
    label: 'Data de Término',
    dateFormat: 'DD/MM/YYYY',
  },
];
