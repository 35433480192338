import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { TermoPoliticaPrivacidade } from '../../../models/termo-politica-privacidade';

export const displayProperties = ['nome'];

export const moduleLabel = 'Termo Politica Privacidade';

export const moduleName =
  'CONFIGURACOES_INICIAIS.TERMO_POLITICA_PRIVACIDADE.TERMO_POLITICA_PRIVACIDADE';

export const headCells: readonly HeadCell<TermoPoliticaPrivacidade>[] = [
  {
    id: 'politicaDePrivacidade',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
