export const getUischemaElements = (root: any): any[] => {
  let result: any[] = [];

  if (!Array.isArray(root)) {
    if (root?.elements) {
      if (root.type !== 'Category') {
        result.push(root);
      }
      result = result.concat(getUischemaElements(root.elements));
      return result;
    }
    return [];
  }

  for (const element of root) {
    if (element?.type !== 'Category') {
      result.push(element);
    }
    if (element?.elements) {
      result = result.concat(getUischemaElements(element.elements));
    }
  }

  return result;
};
