import { or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import { useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { OneOf } from './select/one-of';
import { CheckboxIcon } from './styled/checkbox-icon';
import { CheckedCheckboxIcon } from './styled/checked-checkbox-icon';
import {
  ChoiceCheckBox,
  ChoiceContainer,
  ChoiceItem,
  ChoiceText,
  ErrorText,
  MultipleChoiceContainer,
  QuestionContainer,
  QuestionText,
  RequiredSpan,
  WarningText,
} from './styled/multipla-selecao-pequena';

const MultipleChoiceTester = rankWith(
  6,
  or(
    schemaMatches((schema) => schema.format === 'MultiplaSelecaoPequena'),
    uiTypeIs('MultiplaSelecaoPequena'),
  ),
);

export const MultipleChoice = {
  tester: MultipleChoiceTester,
  renderer: withJsonFormsControlProps(
    ({ schema, uischema, visible, enabled, data, path, ...props }) => {
      const [selectedItems, setSelectedItems] = useState<(number | string)[]>([]);
      const ctx = useCrudContext();
      const { crudStates, disabledFields } = ctx;

      useEffect(() => {
        if (data) setSelectedItems(data);
      }, []);

      const isDisabled =
        !enabled ||
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      useEffect(() => {
        props.handleChange(path, selectedItems);
      }, [selectedItems]);

      const itemClickHandler = (itemConst: number | string) => {
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.includes(itemConst)
            ? prevSelectedItems.filter((selectedItem) => selectedItem !== itemConst)
            : [...prevSelectedItems, itemConst],
        );
      };

      const oneOf = schema.oneOf as OneOf;

      //Construir lógica de estilização quando houverem erros na resposta do formulário
      const hasError = false;

      const getColor = (color: string) => (hasError ? '#BA1A1A !important' : `${color} !important`);

      return (
        <Hidden xsUp={!visible}>
          <MultipleChoiceContainer borderColor={getColor('#757780')}>
            <QuestionContainer>
              <QuestionText color={getColor('#1D1B20')}>
                <>{schema?.title || uischema.label}</>
              </QuestionText>

              <WarningText color={getColor('#1351B4')}>
                (Se necessário, marque mais de uma)
              </WarningText>

              {uischema?.options?.required && (
                <RequiredSpan color={getColor('var(--color-foreground)')}>*</RequiredSpan>
              )}
            </QuestionContainer>
            <ChoiceContainer>
              {oneOf?.map((item, index) => (
                <>
                  <ChoiceItem
                    key={`alternativa-${item.title}`}
                    onClick={() => (!isDisabled ? itemClickHandler(item?.const) : null)}
                    isDisabled={!isDisabled ? 'auto' : 'not-allowed'}
                  >
                    <ChoiceText>{item?.title}</ChoiceText>
                    <ChoiceCheckBox
                      checked={selectedItems.includes(item?.const)}
                      checkedIcon={<CheckedCheckboxIcon isDisabled={isDisabled} />}
                      icon={<CheckboxIcon color={hasError ? '#BA1A1A' : '#757780'} />}
                      disabled={isDisabled}
                    />
                  </ChoiceItem>
                  {schema?.oneOf?.length && index < schema.oneOf.length - 1 && <Divider />}
                </>
              ))}
            </ChoiceContainer>
          </MultipleChoiceContainer>
          {hasError && <ErrorText>Campo obrigatório!</ErrorText>}
        </Hidden>
      );
    },
  ),
};
