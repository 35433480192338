import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import LogoFundacao from '../../components/layout/logo-fundacao';
import { Background, ChangePassword, DivGroup, LoginForm, LogoLogin, Paper } from './login.styled';
interface NewPasswordFormState {
  senha: string;
  senhaConfirmar: string;
}

const AlterarSenha = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [api, setApi] = useState<LoginApi>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);
  const [formData, setFormData] = useState<NewPasswordFormState>({
    senha: '',
    senhaConfirmar: '',
  });

  useEffect(() => {
    const loginApi = makeLoginApi();
    setApi(loginApi);
  }, []);

  const obterTokenDaURL = (search: string) => {
    const parametros = new URLSearchParams(search);
    return parametros.get('token');
  };

  const token = obterTokenDaURL(search);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.senha !== formData.senhaConfirmar) {
      showError('A senha e a confirmação da senha não conferem.');
      return;
    }

    // String deve ter entre 8 e 64 char, contendo 1 letra minuscula, 1 maiuscula, 1 numero e 1 caracter especial, pelo menos.
    const validations =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~])[\w!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~]{8,64}$/;
    if (!formData.senha?.match?.(validations)) {
      showError(
        'A senha deve ter entre 8 e 64 caracteres, contendo pelo menos 1 letra minúscula, 1 maiúscula, 1 número e 1 caracter especial.',
      );
      return;
    }

    if (token) {
      api
        ?.alterarSenha(token, formData.senha)
        .then((res) => {
          showSuccess('Senha alterada com sucesso.');
          navigate('/login');
        })
        .catch((err) => {
          showError(err?.response?.data?.message || 'Ocorreu um erro.');
        });
    } else {
      showError('Erro ao alterar senha.');
      navigate('/recuperar-senha');
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  return (
    <DivGroup>
      <Background />

      <ChangePassword>
        <CssBaseline />
        <Paper>
          <LogoLogin as={LogoFundacao} />
          <Typography
            component='h1'
            variant='h5'
            sx={{
              color: '#191B22',
              fontWeight: '600',
              Size: '22px',
              marginBottom: '2.62rem',
              marginTop: '4.25rem',
              fontFamily: 'Raleway',
            }}
          >
            Redefinir a senha
          </Typography>
          <LoginForm onSubmit={handleSubmit}>
            <TextField
              size='small'
              margin='normal'
              required
              fullWidth
              name='senha'
              label='Nova senha'
              type={showPassword ? 'text' : 'password'}
              id='senha'
              value={formData.senha}
              onChange={handleChange}
              sx={{ marginBottom: '0px', marginTop: '0px', width: '439px', height: '48px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              size='small'
              margin='normal'
              required
              fullWidth
              name='senhaConfirmar'
              label='Confirme sua nova senha'
              type={showPasswordConfirm ? 'text' : 'password'}
              id='senhaConfirmar'
              value={formData.senhaConfirmar}
              onChange={handleChange}
              sx={{ marginTop: '2rem', marginBottom: '1.5rem', width: '439px', height: '48px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordConfirmVisibility}>
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ backgroundColor: '#003D92', width: '439px', height: '40px' }}
            >
              Redefinir
            </Button>
          </LoginForm>
        </Paper>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert} />
      </ChangePassword>
    </DivGroup>
  );
};
export default AlterarSenha;

export const disableAutoRoute = true;
