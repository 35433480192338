import { or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const isTextRender = or(
  schemaMatches(
    (schema) =>
      !isEmpty(schema) &&
      (schema as any)?.type === 'string' &&
      !(schema as any)?.enum &&
      !(schema as any)?.format,
  ),
  uiTypeIs('Text'),
);

const textRenderTester = rankWith(5, isTextRender);

export const textRender = {
  tester: textRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const fieldRef = useRef<HTMLDivElement>(null);
    const position = useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = useState<string>(props.data ?? '');
    const [maxLength, setMaxLength] = useState<number | null>(null);
    const [minLength, setMinLength] = useState<number | null>(null);

    const ctx = useCrudContext();
    const { validationMode, crudStates, disabledFields, formData, isRequiredField } = ctx;

    const required = uischema.options?.required ?? false;

    useEffect(() => {
      // Pega min e max do schema
      schema?.minLength && setMinLength(schema?.minLength);
      schema?.maxLength && setMaxLength(schema?.maxLength);

      // Sobrescreve min e max do options do uischema
      uischema?.options?.minLength && setMinLength(Number(uischema?.options?.minLength));
      uischema?.options?.maxLength && setMaxLength(Number(uischema?.options?.maxLength));
    }, []);

    useEffect(() => {
      // Atualiza o valor do texto de acordo com o que vem do json forms (props.data)
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children?.[1]?.children[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
          inputElement.click();
        }
      });
      setValue(props.data);
    }, [props.data]);

    const isDisabled =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const val = event?.target?.value;
      setValue(val);

      if (uischema.options?.handleChange) {
        uischema.options?.handleChange?.(val, props.handleChange, formData, ctx, path);
      }

      props.handleChange(path, val.trim());
    };

    const inputProps: InputBaseComponentProps = {
      maxLength: maxLength ?? undefined,
      minLength: minLength ?? undefined,
    };

    return (
      <Hidden xsUp={!visible} key={`${path}-text`}>
        <div id={path} className='custom-input-container' ref={fieldRef}>
          <TextField
            size='small'
            // // className={`input
            //   ${isDisabled ? 'disabled-field' : ''}
            //   ${props.errors && validationMode === 'ValidateAndShow' ? 'has-error' : ''}
            // `}
            label={props.label}
            required={isRequiredField(required, ctx)}
            disabled={isDisabled}
            value={value}
            inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
            onChange={handleChange}
            inputProps={inputProps}
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
