import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { CalendarEventFill } from '@styled-icons/bootstrap/CalendarEventFill';

export const StyledBackgroundList = styled(List)({
  width: '100%',
  minHeight: '414px',
  maxHeight: '414px',
  padding: '0px',
});

export const StyledBackgroundListItem = styled(ListItem)({
  width: '100%',
  borderStyle: 'solid',
  borderWidth: '0px 0px 0.05px 0px',
  borderColor: '#C5C6D0',
});

export const StyledBackgroundListItemButton = styled(ListItemButton)({
  width: '100%',
  minHeight: '69px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledContent = styled(Box)({
  width: '100%',
});

export const StyledLineContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const TituloLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#454545 !important',
});

export const InformacoesLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: 500,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#434653 !important',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
});

export const DateContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignContent: 'center',
});

export const DateLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: 500,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#1351B4 !important',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
});

export const StyledCalendarIcon = styled(CalendarEventFill)({
  width: '12px',
  height: '12px',
  color: '#1351B4',
});
