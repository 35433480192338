import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MarkEmailUnread } from '@mui/icons-material';
import Diversity3 from '@mui/icons-material/Diversity3';

export const ConvitesBox = styled(Box)({
  minWidth: '536px',
  fontFamily: 'Raleway',
  borderRadius: '4px',
  border: 'solid 1px #C5C6D0',
  marginLeft: '6px',
  marginRight: '6px',
});

export const ConvitesHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const ConvitesHeaderLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#333333',
});

export const StyledMarkEmailUnread = styled(MarkEmailUnread)({
  marginLeft: '16px',
  width: '16px',
  height: '16px',
  color: '#1351B4',
});

export const StyledAccordion = styled(Accordion)({
  minHeight: '416px',
  maxHeight: '416px',
  margin: '0px !important',
  backgroundColor: 'transparent',
  borderTop: 'solid 1px #C5C6D0',
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  width: '100%',
  backgroundColor: 'transparent',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  color: '#333333 !important',
  padding: '0px !important',
  maxHeight: '41px !important',
  minHeight: '41px !important',
});

export const AccordionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '16px',
  boxShadow: 'inset 0 -1px 0 0 #C5C6D0',
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0px !important',
  borderBottom: 'solid 1px #C5C6D0',
});

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  color: '#1351B4 !important',
});

export const StyledDiversity3 = styled(Diversity3)({
  marginLeft: '16px',
  marginRight: '4px',
  width: '16px',
  height: '16px',
  color: '#1351B4',
});

export const QuantidadeConvitesLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#003D92 !important',
  lineHeight: '10px',
  letterSpacing: '0.5px',
  display: 'inline-flex',
  alignItems: 'center',
});

export const FooterBox = styled(Box)({
  minHeight: '46px',
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '16px',
});

export const FooterButton = styled(Typography)({
  fontSize: '12px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
});

export const TodosConvitesAnchor = styled('a')({
  cursor: 'pointer',
});
