import { CrudContextData, IControlElement, IUiSchema } from '../jsonforms/uischema';
import { getUischemaElements } from './get-uischema-elements';
import { scopeToPath } from './scope-to-path';

export const getUischemaConfigs = (uischema: IUiSchema | IControlElement, ctx: CrudContextData) => {
  const paths: string[] = uischema?.options?.additionalPaths ?? [];
  const requiredPaths: string[] = [];
  const instancePaths: string[] = [];
  const requiredScopes: string[] = [];

  const elements = getUischemaElements(uischema);

  elements.forEach((el: any) => {
    const path = scopeToPath(el?.scope);
    const required = el?.options?.required;
    if (
      (typeof required === 'function' && required(ctx)) ||
      (typeof required === 'boolean' && required)
    ) {
      requiredScopes.push(el?.scope);
      requiredPaths.push(path);
    }
    paths.push(path);
    instancePaths.push(`/${path?.replace?.('.', '/')}`);
  });

  return {
    paths,
    requiredPaths,
    instancePaths,
    requiredScopes,
  };
};
