import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Pergunta, TipoPergunta, TipoResposta } from '../../../models/pergunta';

export const moduleName = 'EDITAL.PERGUNTA.PERGUNTA';

export const displayProperties = ['pergunta'];

export const moduleLabel = 'Perguntas Propostas';

export const headCells: readonly HeadCell<Pergunta>[] = [
  {
    id: 'pergunta',
    numeric: false,
    disablePadding: false,
    label: 'Pergunta',
  },
  {
    id: 'tipoPergunta',
    numeric: false,
    disablePadding: false,
    label: 'Tipo da pergunta',
    computedRowLabel: (row) => {
      const tipoPergunta = row.tipoPergunta;

      switch (tipoPergunta) {
        case TipoPergunta.informacoesComplementares:
          return 'Informações Complementares';
        case TipoPergunta.descricaoProjeto:
          return 'Descrição do Projeto';
        default:
          return '-';
      }
    },
  },
  {
    id: 'tipoResposta',
    numeric: false,
    disablePadding: false,
    label: 'Tipo da resposta',
    computedRowLabel: (row) => {
      const tipoResposta = row.tipoResposta;

      switch (tipoResposta) {
        case TipoResposta.data:
          return 'Data';
        case TipoResposta.multiplaEscolha:
          return 'Múltipla Escolha';
        case TipoResposta.multiplaSelecao:
          return 'Múltipla Seleção';
        case TipoResposta.descritiva:
          return 'Descritiva';
        default:
          return '-';
      }
    },
  },
];
