import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Aviso } from '../../../models/aviso';

export const moduleName = 'CONFIGURACOES_INICIAIS.AVISO';

export const displayProperties = ['aviso'];

export const moduleLabel = 'Avisos';

export const headCells: readonly HeadCell<Aviso>[] = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Título do Aviso',
  },
  {
    id: 'dataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Data e Hora de Início',
    isDateTime: true,
    dateFormat: 'DD/MM/YYYY HH:mm:ss',
  },
  {
    id: 'dataEncerramento',
    numeric: false,
    disablePadding: false,
    label: 'Data e Hora de Encerramento',
    isDateTime: true,
    dateFormat: 'DD/MM/YYYY HH:mm:ss',
  },
];
